import React, { ReactElement } from 'react'
import { Link, Redirect } from 'react-router-dom'
import * as api from 'Utilities/pharaoh'
import { v3 } from 'Utilities/pharaoh'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import { Route } from 'Utilities/Route'
import useUser, { PowerLevel, Response as User } from 'Utilities/Hooks/useUser'
import { useForm } from 'react-hook-form'
import { post } from 'Utilities/fetch++'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { isProduction, obeliskMode } from 'Utilities/config'
import { useWhiteLabel } from 'Utilities/Hooks/useWhiteLabel'
import moment from 'moment'
import styles from './index.module.scss'
import { Broker } from '../Utilities/pharaoh.types'

const Landing: React.FC = () => {
  const async = useUser<[User, Broker, Venues]>(async user => {
    const venues = await api.v3.users().venues() as Venues
    const broker = await v3.brokers.GET() as Broker
    return [user, broker, venues]
  })
  const {
    register,
    handleSubmit
  } = useForm()
  const label = useWhiteLabel().label
  const isObelisk = obeliskMode(label)

  if (async.loading) return <Loader/>
  if (async.error) return <Error error={async.error}/>

  const [user, broker, venues] = async.value!
  const groups = venues.groups.reduce((rv: any, group: any) => {
    rv[group.id] = group
    return rv
  }, {})
  const redirect = onlyOneDestination(venues)
  if (redirect) {
    return <Redirect to={redirect}/>
  }

  function mapper(id: string, route: string) {
    const group = groups[id]
    return {
      id: id,
      route: route,
      effectiveDate: moment(api.utcMidnightToLocalMidnight(group.effectiveDate)).format('M/D/YYYY'),
      isCurrent: new Date(new Date().setFullYear(new Date(group.effectiveDate).getFullYear() + 1)) > new Date(),
      group: groups[id]
    }
  }

  const stargateERs = venues.stargate.er.map((id: string) => mapper(id, `${Route.stargate}/employer`))
  const stargateEEs = venues.stargate.ee.map((id: string) => mapper(id, `${Route.stargate}/employee`))
  const anubisERs = venues.anubis.er.map((id: string) => mapper(id, Route.dashboardEmployer))
  const anubisEEs = venues.anubis.ee.map((id: string) => mapper(id, Route.dashboardEmployee))

  let key = 0

  function sortdate(a: any, b: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new Date(b.group.effectiveDate) - new Date(a.group.effectiveDate)
  }

  return <div className={styles.indexPage}>
    <div className={styles.mainCol}>
      <h1>Welcome, {user.name || user.email}</h1>
      {venues.anubis.agency && <>
        <h2>Your Agency</h2>
        <div className={styles.agency}>
          <Link to={Route.agencyDashboardHome}>
            <h3>{broker.agency?.name}</h3>
            <span className={styles.button}>Go to Agency</span>
          </Link>
        </div>
      </>}
      {(!!anubisERs.length || !!anubisEEs.length) && <>
        <h2>Dashboards</h2>
        <div className={styles.row}>
          {!!anubisEEs.length &&
            <div className={styles.col}>
              {!!anubisERs.length && !!anubisEEs.length && <h3>For You</h3>}
              {anubisEEs
                .sort(sortdate)
                .map(e =>
                  <div key={`ee-${e.id}`} className={e.isCurrent ? styles.employee : styles.linkout}>
                    <Link to={e.route} onClick={() => {
                      localStorage.overrideGroupID = e.id
                    }}>
                      <h3>{e.group.name}</h3>
                      {e.effectiveDate} | {e.group.agencyName}
                      <span className={styles.button}>Go to Dashboard</span>
                    </Link>
                  </div>)}
            </div>
          }
          {!!anubisERs.length &&
            <div className={styles.col}>
              {!!anubisERs.length && !!anubisEEs.length && <h3>For Your Company</h3>}
              {anubisERs
                .sort(sortdate)
                .map(e =>
                  <div key={`er-${e.id}`} className={e.isCurrent ? styles.employer : styles.linkout}>
                    <Link to={e.route} onClick={() => {
                      localStorage.overrideGroupID = e.id
                    }}>
                      <h3>{e.group.name}</h3>
                      {e.effectiveDate} | {e.group.agencyName}
                      <span className={styles.button}>Go to Dashboard</span>
                    </Link>
                  </div>)}
            </div>
          }
        </div>
      </>}
      {(stargateEEs.length || stargateERs.length) && <>
        <h2>Shopping</h2>
        <div className={styles.row}>
          {!!stargateEEs.length &&
            <div className={styles.col}>
              {!!stargateEEs.length && !!stargateERs.length && <h3>For You</h3>}
              {stargateEEs
                .sort(sortdate)
                .map(e =>
                  <div key={`shop-ee-${e.id}`} className={e.isCurrent ? styles.employee : styles.linkout}>
                    <Link to={e.route} onClick={() => {
                      localStorage.overrideGroupID = e.id
                    }}>
                      <h3>{e.group.name}</h3>
                      {e.effectiveDate} | {e.group.agencyName}
                      <span className={styles.button}>Go to Shop</span>
                    </Link>
                  </div>)}
            </div>
          }
          {!!stargateERs.length &&
            <div className={styles.col}>
              {!!stargateEEs.length && !!stargateERs.length && <h3>For Your Employees</h3>}
              {stargateERs
                .sort(sortdate)
                .map(e =>
                  <div key={`shop-ee-${e.id}`} className={e.isCurrent ? styles.employer : styles.linkout}>
                    <Link to={e.route} onClick={() => {
                      localStorage.overrideGroupID = e.id
                    }}>
                      <h3>{e.group.name}</h3>
                      {e.effectiveDate} | {e.group.agencyName}
                      <span className={styles.button}>Go to Shop</span>
                    </Link>
                  </div>)}
            </div>
          }
        </div>
      </>}

      {(superAdmin() || !isProduction()) && <>
        <h1 key={key++} style={{ marginTop: '6rem' }}>Superuser Actions</h1>
        <form onSubmit={handleSubmit(onSubmit)} key={key++}>
          <CandorInput
            name="email"
            ref={register}
            placeholder="Switch User"
            style={{
              width: '30rem',
              margin: '1rem 0'
            }}/>
          <input type="submit"/>
        </form>
      </>}
    </div>
    <div className={styles.sideCol}>
      {user.powerLevel >= PowerLevel.broker && <article>
        <h2>Invite Users to Shop</h2>
        To invite new users to your benefit portal’s shopping experience use the following
        link:
        {shopLink()}
      </article>}
    </div>
  </div>

  async function onSubmit(data: any) {
    // NOTE not catching because who cares for Candor ees only
    const rsp = await post('/v2/support/user', data)
    localStorage.adminToken = localStorage.token
    localStorage.adminName = user.name
    api.setToken(rsp.token)
    window.location.reload()
  }

  function superAdmin() {
    return user.powerLevel >= PowerLevel.candorEmployee
  }

  function shopLink(): ReactElement {
    const path = isObelisk
      ? `/shop/${user.slug}`
      : '/shop'
    const host = window.location.host
    const prefix = process.env.REACT_APP_BASENAME || ''
    const display = `${window.location.protocol}//${host}${prefix}${path}`
    return <div className={styles.shoplink}><Link to={path}>{display}</Link></div>
  }
}

export function relativePath(): string {
  const host = window.location.host
  const prefix = process.env.REACT_APP_BASENAME || ''
  return `${window.location.protocol}//${host}${prefix}`
}

function onlyOneDestination({
  anubis,
  stargate
}: Venues): string | null {
  let count = anubis.ee.length
  count += anubis.er.length
  if (anubis.agency) count += 1
  count += stargate.ee.length
  count += stargate.er.length

  // user has options, render them
  if (count > 1) return null

  if (anubis.ee.length) return Route.dashboardEmployee
  if (anubis.er.length) return Route.dashboardEmployer
  if (stargate.ee.length) {
    localStorage.overrideGroupID = stargate.ee[0]
    return '/shop/employee'
  }
  if (stargate.er.length) return '/shop/employer'
  if (anubis.agency) return Route.agencyDashboardHome

  // well hi there user who entered the shop but didn’t create a group yet
  return Route.erStargate_GroupType
}

interface Venues {
  groups: { id: string, name: string, effectiveDate: Date, agencyName: string }[]
  anubis: { ee: string[], er: string[], agency: boolean }
  stargate: { ee: string[], er: string[] }
}

export default Landing
