import React from 'react'
import { Contributions, GroupPlanType } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import Plan, { PlanBody } from 'Components/Plans/plan-subcomponents/Plan'
import PlanTitle from 'Components/Plans/plan-subcomponents/PlanTitle'
import EETierRate from './EESupplementalSubComp/EETierRate'
import { Member } from 'Utilities/Hooks/useStargate'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import BenefitAmountContainer from './EESupplementalSubComp/BenefitAmountContainer'
import benefitStyle from './EESupplementalSubComp/EESupplementalSubComp.module.scss'

interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan, isRenewal: boolean }
  selected?: boolean
  label: Label
  selectHandler?(plan: SupplementalPlan): void
  contributions: Contributions
  showWeeklyPayments: boolean
  premium?: PremiumSplits
  member: Member
}

export const replaceDollarSigns = (inputString: string) => {
  const resultsArray: any[] = []
  inputString.split('$').filter(s => s !== '').map(s => resultsArray.push(<><span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amountLong}>{s}</span></>))
  return resultsArray
}

const CancerPlanEE: React.FC<Props> = (props) => {
  const { plan, selected, label, selectHandler } = props

  const [stemCellDollar, stemCellDescriptor] = plan.plan.data.stemCellAndBoneMarrowTransplant.substring(1).split(/(?<=^\S+)\s/)
  const benefitAmount = plan?.plan?.data?.benefitAmount.split(' ')[0].substring(1)

  return <Plan selected={!!selected}>
    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier={plan.plan.carrier}
      selected={selected}
      includesProsperBenefits={false}
      planType={GroupPlanType.cancer}
      label={label}
      sbc={plan.plan.data.sbc}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
    />
    <PlanBody>
      <div className={styles.planBodyContainer}>
        <EETierRate
          plan={plan}
          contributions={props.contributions}
          showWeeklyPayments={props.showWeeklyPayments}
          member={props.member}
          showAfterEmpCont={true}
        />
        <div className={styles.vertContainer}>
          <BenefitAmountContainer innerclassname={styles.noLeftMargin}>
            <div className={styles.vertFlex}>
              <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].initial_diagnosis_benift}</div>
              <div>
                <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                  <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{benefitAmount}</span>
                </div>
              </div>
            </div>
          </BenefitAmountContainer>
          <BenefitAmountContainer innerclassname={styles.noLeftMargin}>
            <div className={styles.vertFlex}>
              <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].stem_cell_bone_marrow_transplant}</div>
              <div>
                <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                  <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{stemCellDollar}</span>
                  <p className={benefitStyle.benefit_amount_label}>{stemCellDescriptor}</p>
                </div>
              </div>
            </div>
          </BenefitAmountContainer>
        </div>
        <div className={styles.vertContainer}>
          <BenefitAmountContainer>
            <div className={styles.vertFlex}>
              <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].hospice_care}</div>
              <div>
                <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                  {replaceDollarSigns(plan.plan.data.hospiceCare)}
                </div>
              </div>
            </div>
          </BenefitAmountContainer>
          <BenefitAmountContainer>
            <div className={styles.vertFlex}>
              <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].surgical_anesthesia}</div>
              <div>
                <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                  {replaceDollarSigns(plan.plan.data.surgicalAndAnesthesia)}
                </div>
              </div>
            </div>
          </BenefitAmountContainer>
        </div>
      </div>
    </PlanBody>
  </Plan>
}

export default CancerPlanEE
