import React from 'react'
import { Contributions, GroupPlanType } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import Plan, { PlanBody } from 'Components/Plans/plan-subcomponents/Plan'
import PlanTitle from 'Components/Plans/plan-subcomponents/PlanTitle'
import EETierRate from './EESupplementalSubComp/EETierRate'
import BenefitComp from './EESupplementalSubComp/BenefitComp'
import { Member } from 'Utilities/Hooks/useStargate'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import BenefitAmountContainer from './EESupplementalSubComp/BenefitAmountContainer'
import benefitStyle from './EESupplementalSubComp/EESupplementalSubComp.module.scss'
import { GlobalStringConstant } from 'GlobalStringConstant'
import Tooltip from 'Components/Stargate/ToolTip/Tooltip'

interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan, isRenewal: boolean }
  contributions: Contributions
  selected?: boolean
  label: Label
  showWeeklyPayments: boolean
  premium?: PremiumSplits
  member: Member
  selectHandler?(plan: SupplementalPlan): void
}
const STDEE: React.FC<Props> = (props) => {
  const { plan, selected, label, selectHandler } = props
  const sbc = plan.plan.data.sbc
  const monthlyBenefitPayment = plan.plan.data.monthlyBenefitPayment.replaceAll('$', '').split(' ')
  const monthlyBen1 = monthlyBenefitPayment[0]
  const monthlyBen2 = monthlyBenefitPayment[2]
  const elimPeriodSplit = plan.plan.data.eliminationPeriod.split(' ')
  const elimSickness = `${elimPeriodSplit[4]} ${elimPeriodSplit[5]}`
  const elimAccident = `${elimPeriodSplit[0]} ${elimPeriodSplit[1]}`
  return <> <Plan selected={!!selected}>
    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier={plan.plan.carrier}
      selected={selected}
      includesProsperBenefits={false}
      planType={GroupPlanType.std}
      label={label}
      sbc={sbc}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
    />
    <PlanBody noWrap={true}>
      <EETierRate
        plan={plan}
        contributions={props.contributions}
        showWeeklyPayments={props.showWeeklyPayments}
        member={props.member}
        showAfterEmpCont={true}
        onlyIndividual={true}
      />
      <BenefitComp amountLabel={GlobalStringConstant[0].monthly}
        name={GlobalStringConstant[0].benefit_payment}
        benefitAmount={monthlyBen1}
        benefitAmount2={monthlyBen2}
      />
      <div className={styles.vertContainer}>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].benefit_period}</div>
            <div>
              <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                <span className={benefitStyle.amount}>{plan.plan.data.benefitPeriod}</span>
              </div>
            </div>
          </div>
        </BenefitAmountContainer>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <Tooltip
              id='STDPlansTooltip'
              place={'right'}
              delayHide={100}
              backgroundColor='linear-gradient(135deg, #0B4BF7 0%, #8B17BB 100%)'
              textColor='#6925cb'
            >
              <span>Also known as Exclusion Period. The waiting period is the number of days before your coverage will begin.</span>
            </Tooltip>
            <div className={styles.STDPlansTooltip}>
              <div className={benefitStyle.benefitBoxTitle} data-tip data-for='STDPlansTooltip'>{GlobalStringConstant[0].waiting_period}</div>
            </div>
            <div>
              <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                <div className={styles.horizFlex}>
                  <div className={styles.waitingPeriod}>
                    <p className={benefitStyle.benefit_amount_label}>{GlobalStringConstant[0].accident}</p>
                    <span className={benefitStyle.amount}>{elimAccident}</span>
                  </div>
                  <div className={styles.waitingPeriod}>
                    <p className={benefitStyle.benefit_amount_label}>{GlobalStringConstant[0].sickness}</p>
                    <span className={benefitStyle.amount}>{elimSickness}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BenefitAmountContainer>
      </div>
    </PlanBody>
  </Plan>
  </>
}

export default STDEE
