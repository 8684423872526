import { isHBA, isVault, logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import React from 'react'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { TierCount, calculatePremiumFigures } from '../Proposal'
import PlanProposal, { ProposalType, enrollInfo, tablePlanHeader, tableRow, tableRowCopayOrRx } from './PlanProposal'
import ContributionsCalculator, { moneyString } from 'Utilities/Plans/ContributionCalculator'

interface Props {
  medicalPlan: MedicalPlan[]
  enrollCount: TierCount
  groupName: string
  groupEffectiveDate?: Date
  counts: string[]
  calc: ContributionsCalculator
}

export const MedicalPlanProposal: React.FC<Props> = ({ medicalPlan, enrollCount, groupName, groupEffectiveDate, counts, calc }) => {
  return <PlanProposal
    groupName={groupName}
    groupEffectiveDate={groupEffectiveDate}
    tablePlanHeaders={tablePlanHeaders(counts, medicalPlan[0], medicalPlan[1], medicalPlan[2], medicalPlan[3], medicalPlan[4])}
    proposalHeader={ProposalType.medical}
    tableRows={tableRows(calc, enrollCount, medicalPlan[0], medicalPlan[1], medicalPlan[2], medicalPlan[3], medicalPlan[4])}
    enrollmentInfo={enrollInfo(enrollCount)}
  />
}

function tablePlanHeaders(counts: string[], current: MedicalPlan, renewal?: MedicalPlan, planA?: MedicalPlan, planB?: MedicalPlan, planC?: MedicalPlan) {
  return <>
    {tablePlanHeader(counts[0], current.name, 0, logoFor(current.carrier))}
    {renewal && tablePlanHeader(counts[1], renewal.name, 1, logoFor(renewal.carrier))}
    {planA && tablePlanHeader(counts[2], planA ? planA.name : '', 2, logoFor(planA.carrier))}
    {planB && tablePlanHeader(counts[3], planB ? planB.name : '', 3, logoFor(planB.carrier))}
    {planC && tablePlanHeader(counts[4], planC ? planC.name : '', 4, logoFor(planC.carrier))}
  </>
}

function tableRows(calc: ContributionsCalculator, enrollCount: TierCount, current: MedicalPlan, renewal?: MedicalPlan, planA?: MedicalPlan, planB?: MedicalPlan, planC?: MedicalPlan) {
  const premiumsA = planA && getMedicalPremiums(enrollCount, planA)
  const premiumsB = planB && getMedicalPremiums(enrollCount, planB)
  const premiumsC = planC && getMedicalPremiums(enrollCount, planC)
  const premiumsRen = renewal && getMedicalPremiums(enrollCount, renewal)
  const premiumsCurr = getMedicalPremiums(enrollCount, current)

  const currentContribution = moneyString(calc?.premiums([current], false).er)
  const renewalContribution = renewal && moneyString(calc?.premiums([renewal], false).er)
  const planAContribution = planA && moneyString(calc?.premiums([planA], false).er)
  const planBContribution = planB && moneyString(calc?.premiums([planB], false).er)
  const planCContribution = planC && moneyString(calc?.premiums([planC], false).er)

  const currentAnnualCont = moneyString(calc?.premiums([current], false).er * 12)
  const renewalAnnualCont = renewal && moneyString(calc?.premiums([renewal], false).er * 12)
  const planAAnnualCont = planA && moneyString(calc?.premiums([planA], false).er * 12)
  const planBAnnualCont = planB && moneyString(calc?.premiums([planB], false).er * 12)
  const planCAnnualCont = planC && moneyString(calc?.premiums([planC], false).er * 12)

  const hasNoOOPMax = (plan: MedicalPlan) => {
    const isHBAPlan = isHBA(plan.carrier)
    return (isHBAPlan && !!plan.name.match(/MEC 1/)) || (isVault(plan.carrier) && plan.oopMax === '$0.00')
  }
  const oOPMCopy = (plan: MedicalPlan) => {
    const hasNoOOPM = hasNoOOPMax(plan)
    return hasNoOOPM ? 'N/A' : plan.oopMax
  }

  return <>
    {tableRow('Plan Type', 0, current.type, renewal?.type, planA?.type, planB?.type, planC?.type)}
    {tableRow('Prosper Benefits', 1, determineProsper(current), determineProsper(renewal), determineProsper(planA), determineProsper(planB), determineProsper(planC))}
    {tableRowCopayOrRx(false, current, renewal, planA, planB, planC)}
    {tableRow('IN-NETWORK', 2, '', renewal ? '' : undefined, planA ? '' : undefined, planB ? '' : undefined, planC ? '' : undefined)}
    {tableRow('deductible', 3, current.deductible, renewal?.deductible, planA?.deductible, planB?.deductible, planC?.deductible)}
    {tableRow('oop max', 4, oOPMCopy(current), renewal && oOPMCopy(renewal), planA && oOPMCopy(planA), planB && oOPMCopy(planB), planC && oOPMCopy(planC))}
    {tableRow('co-insurance', 5, current.coinsurance, renewal?.coinsurance, planA?.coinsurance, planB?.coinsurance, planC?.coinsurance)}
    {tableRowCopayOrRx(true, current, renewal, planA, planB, planC)}
    {tableRow('RATES', 7, '', renewal ? '' : undefined, planA ? '' : undefined, planB ? '' : undefined, planC ? '' : undefined)}
    {tableRow('employee', 8, current.premium.employee.individual, renewal?.premium.employee.individual, planA?.premium.employee.individual, planB?.premium.employee.individual, planC?.premium.employee.individual)}
    {tableRow('employee + spouse', 9, current.premium.employee.couple, renewal?.premium.employee.couple, planA?.premium.employee.couple, planB?.premium.employee.couple, planC?.premium.employee.couple)}
    {tableRow('employee + child', 10, current.premium.employee.singleParent, renewal?.premium.employee.singleParent, planA?.premium.employee.singleParent, planB?.premium.employee.singleParent, planC?.premium.employee.singleParent)}
    {tableRow('family', 11, current.premium.employee.family, renewal?.premium.employee.family, planA?.premium.employee.family, planB?.premium.employee.family, planC?.premium.employee.family)}
    {tableRow('monthly premium', 12, premiumsCurr?.monthly, premiumsRen?.monthly, premiumsA?.monthly, premiumsB?.monthly, premiumsC?.monthly)}
    {tableRow('annual premium', 13, premiumsCurr?.annual, premiumsRen?.annual, premiumsA?.annual, premiumsB?.annual, premiumsC?.annual)}
    {tableRow('monthly contribution', 14, currentContribution, renewalContribution, planAContribution, planBContribution, planCContribution)}
    {tableRow('annual contribution', 14, currentAnnualCont, renewalAnnualCont, planAAnnualCont, planBAnnualCont, planCAnnualCont)}
  </>
}

function determineProsper(plan?: MedicalPlan) {
  return plan !== undefined ? plan.hasProsper ? 'Included' : 'Not Included' : undefined
}

export function getMedicalPremiums(enrollCount: TierCount, plan: MedicalPlan) {
  return calculatePremiumFigures(
    ({ premium: parseFloat(removeCommaOrDollarSign(plan.premium.employee.individual)), enrolled: enrollCount.individual }),
    ({ premium: parseFloat(removeCommaOrDollarSign(plan.premium.employee.couple)), enrolled: enrollCount.couple }),
    ({ premium: parseFloat(removeCommaOrDollarSign(plan.premium.employee.singleParent)), enrolled: enrollCount.single }),
    ({ premium: parseFloat(removeCommaOrDollarSign(plan.premium.employee.family)), enrolled: enrollCount.family })
  )
  function removeCommaOrDollarSign(s: string) {
    return s.replace('$', '').replace(',', '')
  }
}
