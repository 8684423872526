import React from 'react'
import Landing from './Landing/Landing'
import Prosper from './Prosper/Prosper'
import { LegalNotice } from './LegalNotice/LegalNotice'
import { PageOneNotice } from './E+EENotices/PageOne/PageOne'
import { PageTwoNotice } from './E+EENotices/PageTwo/PageTwo'
import { PageThreeNotice } from './E+EENotices/PageThree/PageThree'
import { PageFourNotice } from './E+EENotices/PageFour/PageFour'
import { PageNineNotice } from './E+EENotices/PageNine/PageNine'
import { PageTenNotice } from './E+EENotices/PageTen/PageTen'
import { Broker, MedicalPlan } from 'Utilities/pharaoh.types'
import ContributionsCalculator, {
  AncillaryPlanUnion,
  GroupPlanType, isLifeDisability,
  moneyString
} from 'Utilities/Plans/ContributionCalculator'
import { isDental } from 'Components/Plans/DentalPlan/index.helpers'
import { isVision } from 'Components/Plans/VisionPlan/index.helpers'
import _ from 'lodash'
import styles from './Proposal.module.scss'
import { DentalPlanProposal } from './PlanProposal/DentalPlanProposal'
import { GroupLifePlanProposal } from './PlanProposal/GroupLifePlanProposal'
import { MedicalPlanProposal } from './PlanProposal/MedicalPlanProposal'
import { SupplementalPlanProposal } from './PlanProposal/SupplementalPlanProposal'
import { VisionPlanProposal } from './PlanProposal/VisionPlanProposal'
import { TieredSupplementalPlanProposal } from './PlanProposal/TieredSupplementalPlanProposal'
import { AgeBandedProposal } from './PlanProposal/AgeBandedProposal'
import { PageBreak } from './PlanProposal/PageBreak'
import { Member } from 'Utilities/Hooks/useStargate'
export interface ProposalProps {
  broker: Broker
  group: ProposalGroupInfo
  enrollCount: TierCount
  brokerCommission: string
  proposedMedical: MedicalPlan[]
  proposedAncillary: AncillaryPlanUnion[]
  renewalPlans: MedicalPlan[]
  members: Member[]
  calc: ContributionsCalculator
}

export interface ProposalGroupInfo {
  name: string
  id: string
  effectiveDate?: Date
  groupState?: string
}
export interface TierCount {
  individual: number
  couple: number
  single: number
  family: number
}

const Proposal: React.FC<ProposalProps> = ({ broker, group, enrollCount, brokerCommission, proposedMedical, proposedAncillary, renewalPlans, members, calc }) => {
  const proposedWorksite = proposedAncillary.filter(plan => [GroupPlanType.criticalIllness, GroupPlanType.accident, GroupPlanType.hospital, GroupPlanType.cancer].includes(plan.plan.type))
  const proposedSupplementary = proposedAncillary.filter(plan => isLifeDisability(plan.plan.type))
  proposedAncillary = proposedAncillary.sort((x, y) => x.isRenewal === true ? -1 : y.isRenewal === true ? 1 : 0)
  renewalPlans = sortRenewal(renewalPlans)
  proposedMedical = proposedMedical.filter(plan => !renewalPlans.some(p => p.id === plan.id))
  const medicalPlanCounts: string[] = renewalPlans.map(r => r.name.endsWith('(Current)') ? 'Current' : 'Renewal')
  proposedMedical.forEach((_, index) => medicalPlanCounts.push(`Proposed #${index + 1}`))
  proposedMedical = [...renewalPlans, ...proposedMedical]

  const plans = extractPlans()
  const dentalPlanCounts = getAncillaryPlanCount(plans.dental)
  const visionPlanCounts = getAncillaryPlanCount(plans.vision)
  const worksiteCounts = getAncillaryPlanCount(plans.supplementary_tiered)
  const supplementaryPlanCounts = getAncillaryPlanCount(plans.supplementary)
  const currentInsuranceCompany = plans.medical ? plans.medical.length > 0 ? plans.medical[0][0].carrier : 'Insurance Company' : 'Insurance Company'
  const planHeaders = chunkArray(medicalPlanCounts, 5)

  return <div className={styles.main}>
    <Landing broker={broker} groupName={group.name} />
    <Prosper />
    {
      plans.medical.length > 0 && <>
        { plans.medical.map((plan, i) => <MedicalPlanProposal counts={planHeaders[i]} key={'uniquekeyMedical'} enrollCount={enrollCount} medicalPlan={plan} groupName={group.name} groupEffectiveDate={group.effectiveDate} calc={calc} />) }
        <PageBreak />
      </>
    }
    {
      plans.medical.length > 0 && <>
        { plans.medical.map((plan, idx) => <AgeBandedProposal key={idx} medicalPlan={plan} group={group} members={members} calc={calc} />) }
        <PageBreak />
      </>
    }
    {
      plans.dental.length > 0 && <>
        { plans.dental.map((plan, i) => <DentalPlanProposal counts={dentalPlanCounts[i]} key={'uniquekeyDental'} enrollCount={enrollCount} dentalPlans={plan} groupName={group.name} groupEffectiveDate={group.effectiveDate} />) }
        <PageBreak />
      </>
    }
    {plans.vision.length > 0 && <>
      { plans.vision.map((plan, i) => <VisionPlanProposal counts={visionPlanCounts[i]} key={'uniquekeyVision'} enrollCount={enrollCount} visionPlans={plan} groupName={group.name} groupEffectiveDate={group.effectiveDate} />)}
      <PageBreak />
    </>
    }

    {
      plans.supplementary_tiered.length > 0 && <>
        { plans.supplementary_tiered.map((plan, i) => <TieredSupplementalPlanProposal counts={worksiteCounts[i]} key={'uniquekeySupplementaryTiered'} enrollCount={enrollCount} suplementaryPlans={plan} groupName={group.name} groupEffectiveDate={group.effectiveDate} />)}
        <PageBreak />
      </>
    }
    {plans.supplementary.length > 0 && <>
      { plans.supplementary.map((plan, i) => <SupplementalPlanProposal counts={supplementaryPlanCounts[i]} key={'uniquekeySupplementary'} enrollCount={enrollCount} suplementaryPlans={plan} groupName={group.name} groupEffectiveDate={group.effectiveDate} />)}
      <PageBreak />
    </>
    }
    {/* GroupLifePlanProposal may be needed in the future */}
    <div style={{ display: 'none' }}><GroupLifePlanProposal groupName={group.name} groupEffectiveDate={group.effectiveDate} /></div>
    <LegalNotice brokerCommission={brokerCommission} agencyName={broker.agency!.name} employerName={group.name}/>
    <PageOneNotice />
    <PageTwoNotice companyName={group.name} insuranceCompany={currentInsuranceCompany} />
    <PageThreeNotice companyName={group.name} insuranceCompany={currentInsuranceCompany} />
    <PageFourNotice groupState={group.groupState}/>
    <PageNineNotice />
    <PageTenNotice />
  </div>

  function extractPlans() {
    return {
      medical: chunkArray(proposedMedical, 5) as MedicalPlan[][],
      dental: chunkArray(proposedAncillary.filter(({ plan }) => isDental(plan)), 5) as AncillaryPlanUnion[][],
      vision: chunkArray(proposedAncillary.filter(({ plan }) => isVision(plan)), 5) as AncillaryPlanUnion[][],
      supplementary_tiered: chunkArray(proposedWorksite, 5) as AncillaryPlanUnion[][],
      supplementary: chunkArray(proposedSupplementary, 5) as AncillaryPlanUnion[][]
    }
  }

  function getAncillaryPlanCount(plans: AncillaryPlanUnion[][]) {
    const counts: string[][] = []
    let count = 1
    plans.forEach((p) => {
      const arr: string[] = []
      p.forEach((p1) => {
        if (p1.isRenewal) arr.push('Renewal')
        else { arr.push(`Proposed #${count}`); count++ }
      })
      counts.push(arr)
    })

    return counts
  }
}

function chunkArray(array: any[], size: number) {
  const result = []
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size)
    result.push(chunk)
  }
  return result
}
interface PremiumVariables {
  premium: number
  enrolled: number
}

export function calculatePremiumFigures(individual: PremiumVariables, couple: PremiumVariables, singleParent: PremiumVariables, family: PremiumVariables) {
  const monthly = (individual.premium * individual.enrolled) + (couple.premium * couple.enrolled) + (singleParent.premium * singleParent.enrolled) + (family.premium * family.enrolled)
  const annual = monthly * 12
  return ({ monthly: moneyString(monthly, 2), annual: moneyString(annual, 2) })
}

function sortRenewal(plans: MedicalPlan[]) {
  const sortedPlans: MedicalPlan[] = []
  plans.forEach(p => p.name.endsWith('(Current)') ? sortedPlans.unshift(p) : sortedPlans.push(p))
  return sortedPlans
}

export default Proposal
