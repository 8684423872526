import React from 'react'
import { Contributions } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import PlanTitle from '../plan-subcomponents/PlanTitle'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import benefitStyle from 'Components/Plans/EESupplementalPlans/EESupplementalSubComp/EESupplementalSubComp.module.scss'
import BenefitAmountContainer from 'Components/Plans/EESupplementalPlans/EESupplementalSubComp/BenefitAmountContainer'
import Plan, { PlanBody } from '../plan-subcomponents/Plan'
import SupplementalPlan from '../SupplementalPlan/index.helpers'
import PremiumBreakdown, { isEmployerProps, EmployerProps } from '../plan-subcomponents/PremiumBreakdown'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { replaceDollarSigns } from 'Components/Plans/EESupplementalPlans/CancerPlanEE'

interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan, isRenewal: boolean }
  selected?: boolean
  selectHandler?(plan: SupplementalPlan): void
  label: Label
  contributions: Contributions
  showWeeklyPayments: boolean
}

const CancerPlanComp: React.FC<EmployerProps & Props> = (props) => {
  const { plan, selected, selectHandler, label, contributions, showWeeklyPayments } = props
  const sbc = plan.plan.data.sbc

  const [stemCellDollar, stemCellDescriptor] = plan.plan.data.stemCellAndBoneMarrowTransplant.substring(1).split(/(?<=^\S+)\s/)
  const benefitAmount = plan?.plan?.data?.benefitAmount.split(' ')[0].substring(1)

  return <Plan selected={!!selected}>
    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier={plan.plan.carrier}
      selected={selected}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
      includesProsperBenefits={false}
      planType={props.plan.plan.type}
      label={label}
      sbc={sbc}
    />
    <PlanBody>

      {breakdown()}

      <div className={styles.vertContainer}>
        <BenefitAmountContainer innerclassname={styles.noLeftMargin}>
          <div className={benefitStyle.ERCancerBoxTitle}>{GlobalStringConstant[0].initial_diagnosis_benift}</div>
          <div className={benefitStyle.centerGrid}>
            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{benefitAmount}</span>
            </div>
          </div>
        </BenefitAmountContainer>
        <BenefitAmountContainer innerclassname={styles.noLeftMargin}>
          <div className={benefitStyle.ERCancerBoxTitle}>{GlobalStringConstant[0].stem_cell_bone_marrow_transplant}</div>
          <div className={benefitStyle.centerGrid}>
            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{stemCellDollar}</span>
              <p className={benefitStyle.benefit_amount_label}>{stemCellDescriptor}</p>
            </div>
          </div>
        </BenefitAmountContainer>
      </div>
      <div className={styles.vertContainer}>
        <BenefitAmountContainer>
          <div className={benefitStyle.ERCancerBoxTitle}>{GlobalStringConstant[0].hospice_care}</div>
          <div className={benefitStyle.centerGrid}>
            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              {replaceDollarSigns(plan.plan.data.hospiceCare)}
            </div>
          </div>
        </BenefitAmountContainer>
        <BenefitAmountContainer>
          <div className={benefitStyle.ERCancerBoxTitle}>{GlobalStringConstant[0].surgical_anesthesia}</div>
          <div className={benefitStyle.centerGrid}>
            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              {replaceDollarSigns(plan.plan.data.surgicalAndAnesthesia)}
            </div>
          </div>
        </BenefitAmountContainer>
      </div>
    </PlanBody>
  </Plan>

  function breakdown() {
    const base = { plan, contributions, showWeeklyPayments }
    if (isEmployerProps(props)) {
      return <PremiumBreakdown {...base} members={props.members} />
    }
  }
}

export default CancerPlanComp
