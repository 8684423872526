import React from 'react'
import { Contributions, GroupPlanType } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import Plan, { PlanBody } from 'Components/Plans/plan-subcomponents/Plan'
import PlanTitle from 'Components/Plans/plan-subcomponents/PlanTitle'
import EETierRate from './EESupplementalSubComp/EETierRate'
import BenefitComp from './EESupplementalSubComp/BenefitComp'
import BenefitAmountContainer from './EESupplementalSubComp/BenefitAmountContainer'
import benefitStyle from './EESupplementalSubComp/EESupplementalSubComp.module.scss'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { Member } from 'Utilities/Hooks/useStargate'

interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan, isRenewal: boolean }
  contributions: Contributions
  selected?: boolean
  label: Label
  selectHandler?(plan: SupplementalPlan): void
  showWeeklyPayments: boolean
  premium?: PremiumSplits
  member: Member
}

const CriticalIllnessEE: React.FC<Props> = ({ plan, selected, label, selectHandler, contributions, showWeeklyPayments, member }) => {
  const sbc = plan.plan.data.sbc
  const subSequentAmount = GetStringNumber(plan.plan.data.subsequentCriticalIllnessEvent)
  const suddenCardiacArrestBenefit = GetStringNumber(plan.plan.data.suddenCardiacArrestBenefit)
  const criticleIllnessEventPrice = GetStringNumber(plan.plan.data.majorCriticalIllnessEvent)
  return <> <Plan selected={!!selected}>
    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier={plan.plan.carrier}
      selected={selected}
      includesProsperBenefits={false}
      planType={GroupPlanType.criticalIllness}
      label={label}
      sbc={sbc}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
    />
    <PlanBody noWrap={true}>
      <EETierRate
        plan={plan}
        contributions={contributions}
        showWeeklyPayments={showWeeklyPayments}
        member={member}
        showAfterEmpCont={true}
      />
      <BenefitComp amountLabel={GlobalStringConstant[0].payable_one_per_lifetime} name={GlobalStringConstant[0].major_criticalIllness_event} benefitAmount={criticleIllnessEventPrice?.[0]} />
      <div className={styles.vertContainer}>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].subsequent_critical_illness_event}</div>
            <div>
              <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{subSequentAmount?.[0]}</span>
              </div>
              <div className={benefitStyle.hospital_confinement} >{''}</div>
            </div>
          </div>
        </BenefitAmountContainer>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].sudden_cardiac_arrest_benefit}</div>
            <div>
              <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{suddenCardiacArrestBenefit?.[0]}</span>
                <p className={benefitStyle.benefit_amount_label}>{GlobalStringConstant[0].payable_one_per_lifetime}</p>
              </div>
            </div>
          </div>
        </BenefitAmountContainer>
      </div>
    </PlanBody>
  </Plan>
  </>
}
// fix this later
export const GetStringNumber = (str = 'string') => {
  return str.replace(/,/g, '').match(/\d+/g)?.map(item => {
    const num = parseFloat(item)
    return num.toLocaleString('en-US')
  })
}

export default CriticalIllnessEE
