import React from 'react'
import { moneyString } from '../../../Utilities/Plans/ContributionCalculator'
import styles from './StyledRate.module.scss'

interface StyledRateProps {
  rate: string | number | undefined
  precision: number
  period: string
}

class StyledRate extends React.Component<StyledRateProps> {
  public static defaultProps = {
    precision: 2,
    period: '/mo'
  };

  render() {
    const { rate, precision, period } = this.props
    const display = moneyString(rate, precision).replace('$', '').split('.')
    const periodDisplay = period !== '' ? `${period}` : ''
    return <><span>$</span>{display[0]}.<small className={styles.small}>{display[1]}{periodDisplay}</small></>
  }
}

export default StyledRate
