import React from 'react'
import { useToggle } from 'react-use'
import styles from './PlanTitle.module.scss'
import { RemoveCheckbox, SelectCheckbox } from './Checkboxes'
import { logoFor, FundingType, massagedPlanName, isAllstate, Carriers, isHBA, isVault } from './Plan.helpers'
import { Label } from 'Utilities/config'
import { MedicalPlan, Group, AncillaryMemberPremium } from 'Utilities/pharaoh.types'
import { GAButton } from 'Components/Tracking'
import { $enum } from 'ts-enum-util'
import { ReactComponent as ProsperIcon } from '../assets/prosper-icon.svg'
import Modal, { ActionType, BrandColors } from 'Components/Modals/Modal'
import { GroupPlanType, allSupplementalPlans, EEContArray, ERContArray, lifeTypesArray } from 'Utilities/Plans/ContributionCalculator'
import { AppMode, useAppMode } from 'Components/Stargate/TableOfContents'
import MemberRatesModal from 'Components/Anubis/MemberRatesModal/MemberRatesModal'
import MemberPremiumsModal from 'Components/Anubis/MemberPremiumsModal/MemberPremiumsModal'
import { Member, Group as StargateGroup } from 'Utilities/Hooks/useStargate'
import Tooltip from 'Components/Stargate/ToolTip/Tooltip'
import { getBorder } from './Plan'

interface Props {
  available?: boolean
  canBeAgeBanded?: boolean
  carrier: string
  className?: string
  customContributionApplied?: boolean
  fundingType?: FundingType
  group?: Group
  id: string
  includesProsperBenefits: boolean
  infoHandler?: () => void
  isLimitedPlan?: boolean
  label: Label
  members?: Member[]
  ancillaryMemberPremiums?: AncillaryMemberPremium
  needsMemberPremiumsShown?: boolean
  planName: string
  planNameSubtitle?: JSX.Element | string // Will be overridden by includesProsperBenefits
  planType: GroupPlanType
  priorYearsPlan?: boolean
  removeHandler?: () => void // Remove checkbox won't show up unless you pass the handler
  sbc?: string
  selectHandler?: () => void // Select checkbox won't show up unless you pass the handler
  selected?: boolean
  selectedForRemoval?: boolean
  showAgeBanded?: boolean
  showMemberPremiums?: boolean
  showLimitedPlanInfo?: boolean
  showSBC?: boolean
  stargateGroup?: StargateGroup
  toggleShowAgeBanded?: () => void
  toggleShowMemberPremiums?: () => void
  toggleShowSBC?: () => void
  showModal?: boolean
}

const PlanTitle: React.FC<Props> = ({ id, planName, carrier, label, includesProsperBenefits, planNameSubtitle, fundingType, selectHandler, selected, selectedForRemoval, removeHandler, sbc, isLimitedPlan, infoHandler, showLimitedPlanInfo, showSBC, toggleShowSBC, showAgeBanded, toggleShowAgeBanded, priorYearsPlan, available, planType, group, needsMemberPremiumsShown, stargateGroup, canBeAgeBanded, ...props }) => {
  const prosperName = 'Prosper Benefits'
  const isLevelFunded = fundingType === FundingType.levelFunded
  const isBSLife = label === Label.blacksmith && planType === GroupPlanType.life
  const isEmployer = useAppMode() === AppMode.employer
  const showCustomContributionLabel = selectHandler
    ? isEmployer && props.customContributionApplied && selected
    : isEmployer && props.customContributionApplied
  return <>
    { fundingType && !isLimitedPlan && <FundingTypeTab fundingType={fundingType} carrier={carrier} /> }
    {[...EEContArray, ...ERContArray].includes(planType) && <ContributionTypeTab planType={planType}/>}
    { priorYearsPlan &&
      <div style={{ backgroundColor: '#d4f8ef' }} className={styles.fundingTypeContainer} >
        You picked this plan last year
      </div>
    }
    <div className={styles.planTitleContainer}
      style={{
        backgroundColor: isLevelFunded ? '#EDF2FC' : '',
        border: getBorder(isLevelFunded, planType),
        borderBottom: 'none'
      }}>
      { selectHandler && available !== false
        ? <SelectCheckbox
          name='planSelect'
          value={selected || isBSLife}
          onChange={selectHandler}
          className={selected && isLevelFunded ? styles.levelFundedSelected : undefined}
          disabled={isBSLife}
        />
        : selectHandler && !$enum(GroupPlanType).getEntries().map(pt => pt[1] as string).includes(planType)
          ? <h3>Unavailable</h3>
          : null
      }
      { showCustomContributionLabel && <div className={styles.customContributionLabel}>Custom Contribution Applied<span className="material-icons">check</span></div> }
      { logoFor(carrier, planName) || <span>{ carrier }</span> }
      <div className={styles.title}>
        <div style={{ color: isLevelFunded ? '#16346f' : '' }} >{massagedPlanName(planName, carrier)}</div>
        { includesProsperBenefits
          ? <div className={styles.prosper}>
            Includes <ProsperIcon className={styles.prosperIcon} /> <b>{prosperName}</b>
            { isAllstate(carrier) &&
              <>
                <br/>
                Stop-loss insurance is underwritten by: <b>{ natGetStopLossProvider(group) }</b>
              </>
            }
          </div>
          : planNameSubtitle && <div className={styles.prosper}>{planNameSubtitle}</div>
        }
      </div>
      { removeHandler && <RemoveCheckbox name='planSelect' value={selectedForRemoval} onChange={removeHandler} /> }
      <div className={styles.planDetails}>
        { canBeAgeBanded && props.showModal &&
          <AgeBandedButton planType={planType} id={id} sbc={sbc} carrier={carrier} planName={planName} showAgeBanded={showAgeBanded} toggleShowAgeBanded={toggleShowAgeBanded} group={stargateGroup} members={props.members} logo={logoFor(carrier, planName) || <span>{ carrier }</span> }/>
        }
        { needsMemberPremiumsShown && props.showModal &&
          <MemberPremiumsButton planType={planType} id={id} sbc={sbc} carrier={carrier} planName={planName} showMemberPremiums={showAgeBanded} toggleShowMemberPremiums={toggleShowAgeBanded} group={stargateGroup} members={props.members} memberPremiums={props.ancillaryMemberPremiums} logo={logoFor(carrier, planName) || <span>{ carrier }</span> }/>
        }
        <PlanDetailsButton planType={planType} id={id} sbc={sbc} carrier={carrier} planName={planName} showSBC={showSBC} toggleShowSBC={toggleShowSBC} />
        { isLimitedPlan && infoHandler &&
          <PlanDetailsInfoButton showInfo={!!showLimitedPlanInfo} infoHandler={infoHandler} />
        }
      </div>
      { selectedForRemoval && <div className={styles.remove}/>}
    </div>
  </>
}

interface SBCProps {
  id: string
  sbc?: string
  carrier: string
  planName: string
  showSBC?: boolean
  toggleShowSBC?: () => void
  planType?: GroupPlanType
}

interface AgeBandedProps {
  id: string
  sbc?: string
  carrier: string
  planName: string
  showAgeBanded?: boolean
  toggleShowAgeBanded?: () => void
  planType?: GroupPlanType
  logo: JSX.Element
  group: any
  members?: any
}

interface MemberPremiumProps {
  id: string
  sbc?: string
  carrier: string
  planName: string
  showMemberPremiums?: boolean
  memberPremiums?: AncillaryMemberPremium
  toggleShowMemberPremiums?: () => void
  planType?: GroupPlanType
  logo: JSX.Element
  group: any
  members?: any
}

const natGetStopLossProvider: (group?: Group) => string = (group?: Group) => {
  switch (group?.address.state) {
  case 'CO':
  case 'CT':
  case 'NY':
  case 'VT': return 'Integon National Insurance Company'
  case 'FL': return 'Integon Indemnity Corporation'
  default: return 'National Health Insurance Company'
  }
}

const PlanDetailsButton: React.FC<SBCProps> = props => {
  const [showSBC, toggleShowSBC] = (props.showSBC !== undefined && props.toggleShowSBC) ? [props.showSBC, props.toggleShowSBC] : useToggle(false)
  const planDetails = allSupplementalPlans().has(props.planType!) || props.planType === GroupPlanType.life

  if (props.carrier === Carriers.Prosper) return <></>
  return <>
    <PlanDetailsModal {...props} showSBC={showSBC} toggleShowSBC={toggleShowSBC} />
    <GAButton analytics={`SBC (${PlanDetailsButton.name})`} className={styles.viewDetails} id={planDetailsButtonID(props.id)} onClick={toggleShowSBC}>{planDetails ? 'Plan Details' : 'SBC'}</GAButton>
  </>
}

const AgeBandedButton: React.FC<AgeBandedProps> = props => {
  const [showAgeBanded, toggleShowAgeBanded] = (props.showAgeBanded !== undefined && props.toggleShowAgeBanded) ? [props.showAgeBanded, props.toggleShowAgeBanded] : useToggle(false)
  return <>
    {showAgeBanded &&
      <MemberRatesModal isOpen={showAgeBanded}
        onRequestClose={toggleShowAgeBanded}
        logo={props.logo}
        planName={props.planName}
        planID={props.id}
        carrier={props.carrier}
        groupID={props.group.id}
        members={props.members}/>
    }
    <GAButton analytics={`Age banded (${AgeBandedButton.name})`} className={styles.ageBandedButton} id={ageBandedButtonID(props.id)} onClick={toggleShowAgeBanded}>Age Banded Rates</GAButton>

  </>
}

const MemberPremiumsButton: React.FC<MemberPremiumProps> = props => {
  const [showMemberPremiums, toggleShowMemberPremiums] = (props.showMemberPremiums !== undefined && props.toggleShowMemberPremiums) ? [props.showMemberPremiums, props.toggleShowMemberPremiums] : useToggle(false)
  return <>
    {showMemberPremiums &&
      <MemberPremiumsModal isOpen={showMemberPremiums}
        onRequestClose={toggleShowMemberPremiums}
        logo={props.logo}
        planName={props.planName}
        planID={props.id}
        carrier={props.carrier}
        groupID={props.group.id}
        members={props.members}
        memberPremiums={props.memberPremiums!}
      />
    }
    <GAButton analytics={`Employee premiums (${MemberPremiumsButton.name})`} className={styles.ageBandedButton} id={ageBandedButtonID(props.id)} onClick={toggleShowMemberPremiums}>Employee Premiums</GAButton>

  </>
}

const PlanDetailsInfoButton: React.FC<{ showInfo: boolean, infoHandler: () => void }> = ({ showInfo, infoHandler }) => {
  return <button className={styles.planDetailsInfo} onClick={infoHandler}>
    {showInfo
      ? <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 15.5C3.375 15.5 0 12.125 0 8C0 3.875 3.375 0.5 7.5 0.5C11.625 0.5 15 3.875 15 8C15 12.125 11.625 15.5 7.5 15.5Z" fill="#372DA3"/>
        <path d="M5.9375 11.75C5.5625 11.75 5.3125 11.5 5.3125 11.125C5.3125 10.75 5.5625 10.5 5.9375 10.5H7.1875V8H5.9375C5.5625 8 5.3125 7.75 5.3125 7.375C5.3125 7 5.5625 6.75 5.9375 6.75H7.8125C8.1875 6.75 8.4375 7 8.4375 7.375V10.5H9.6875C10.0625 10.5 10.3125 10.8125 10.3125 11.125C10.3125 11.5 10.0625 11.75 9.6875 11.75H5.9375Z" fill="white"/>
        <path d="M7.5 6.125C7 6.125 6.5625 5.6875 6.5625 5.1875C6.5625 4.9375 6.6875 4.6875 6.8125 4.5C7 4.375 7.25 4.25 7.5 4.25C8 4.25 8.4375 4.6875 8.4375 5.1875C8.4375 5.6875 8 6.125 7.5 6.125Z" fill="white"/>
      </svg>
      : <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.49976 15.5C3.37476 15.5 -0.000244141 12.125 -0.000244141 8C-0.000244141 3.875 3.37476 0.5 7.49976 0.5C11.6248 0.5 14.9998 3.875 14.9998 8C14.9998 12.125 11.6248 15.5 7.49976 15.5ZM7.49976 1.875C4.12476 1.875 1.37476 4.625 1.37476 8C1.37476 11.375 4.12476 14.125 7.49976 14.125C10.8748 14.125 13.6248 11.375 13.6248 8C13.6248 4.625 10.8748 1.875 7.49976 1.875Z" fill="#372DA3" />
        <path d="M5.93726 11.75C5.56226 11.75 5.31226 11.5 5.31226 11.125C5.31226 10.75 5.56226 10.5 5.93726 10.5H7.18726V8H5.93726C5.56226 8 5.31226 7.75 5.31226 7.375C5.31226 7 5.56226 6.75 5.93726 6.75H7.81226C8.18726 6.75 8.43726 7 8.43726 7.375V10.5H9.68726C10.0623 10.5 10.3123 10.8125 10.3123 11.125C10.3123 11.5 10.0623 11.75 9.68726 11.75H5.93726Z" fill="#372DA3" />
        <path d="M7.49976 6.125C6.99976 6.125 6.56226 5.6875 6.56226 5.1875C6.56226 4.9375 6.68726 4.6875 6.81226 4.5C6.99976 4.375 7.24976 4.25 7.49976 4.25C7.99976 4.25 8.43726 4.6875 8.43726 5.1875C8.43726 5.6875 7.99976 6.125 7.49976 6.125Z" fill="#372DA3" />
      </svg>
    }
  </button>
}

// Doing this because I don't want to expose the 'PlanDetailsModal' state/toggle outside of the 'Plan Details' button
const planDetailsButtonID = (id: string) => `${id}-sbc`
const ageBandedButtonID = (id: string) => `${id}-ageBanded`
export function clickPlanDetailsButtonFor(plan: MedicalPlan) {
  const buttonID = planDetailsButtonID(plan.id)
  const button = document.getElementById(buttonID)
  if (button) button.click()
}

interface SBCPModalProps extends SBCProps {
  showSBC: boolean
  toggleShowSBC(): void
}

export const PlanDetailsModal: React.FC<SBCPModalProps> = ({ sbc, showSBC, toggleShowSBC, carrier, planName }) => {
  const sbcCopy = carrier === Carriers['Sidecar Health'] ? 'FAQs' : 'SBC'
  return <Modal
    gaModalName={PlanDetailsModal.name}
    isOpen={showSBC}
    onRequestClose={toggleShowSBC}
    header={<>
      {carrier === Carriers['Sidecar Health'] ? 'Sidecar Health FAQs' : 'Summary of Benefits and Coverage'}
      <div className={styles.sbcPlan}>
        <div>
          { logoFor(carrier, planName) || <div>{ carrier }</div> }
          <div className={styles.planName}>{massagedPlanName(planName, carrier)}</div>
        </div>
        { sbc && <a className={styles.downloadSBC} href={sbc}>
          <span className='material-icons'>cloud_download</span>
          Download {sbcCopy}
        </a> }
      </div>
    </>}
  >
    <div className={styles.sbcWrapper}>
      { sbc
        ? <object className={styles.sbcViewer} title="sbc" data={sbc} type="application/pdf">
          <a href={sbc}>{sbcCopy} document</a>
        </object>
        : <div className={styles.noSBC}>{sbcCopy} file not found, please use the chat button<br/>at the bottom right corner to request one.</div>
      }
    </div>
  </Modal>
}

const FundingTypeTab: React.FC<{ fundingType: FundingType, carrier: string }> = ({ fundingType, carrier }) => {
  const [showExplanation, toggleShowExplanation] = useToggle(false)
  const planTypeExplanation = explanationFor(fundingType)
  const color = fundingType === FundingType.levelFunded ? 'white' : ''
  let title = fundingType as string

  if (fundingType === FundingType.levelFunded) {
    title += ` - ${(isHBA(carrier) || isVault(carrier)) ? 'Employer' : 'Employee'} Level`
  }

  if (fundingType === FundingType.fullyFunded) {
    title = 'ACA Community Rated'
  }

  return <div
    style={{ backgroundColor: colorFor(fundingType), color }}
    className={styles.fundingTypeContainer}
  >
    { planTypeExplanation }
    { title }
    { planTypeExplanation &&
      <button
        className={styles.whatAreThose}
        onClick={toggleShowExplanation}
        style={{ color, borderBottomColor: color }}
      >
        What are these?
      </button>
    }
  </div>

  function explanationFor(fundingType: FundingType) {
    switch (fundingType) {
    case FundingType.mec:
      return <MECExplanationModal isOpen={showExplanation} onRequestClose={toggleShowExplanation}/>
    case FundingType.levelFunded: break
      // return <LevelFundedExplanationModal isOpen={showExplanation} onRequestClose={toggleShowExplanation}/>
    case FundingType.fullyFunded: break
    }
  }
}

const ContributionTypeTab: React.FC<{ planType: GroupPlanType }> = ({ planType }) => {
  let title
  let color
  if (EEContArray.includes(planType)) {
    title = 'Voluntary'
    color = '#29b573'
  } else if (ERContArray.includes(planType)) {
    title = 'Employer Paid'
    color = '#3564b9'
  }

  return <div
    style={{ backgroundColor: color, color: 'white' }}
    className={styles.fundingTypeContainer}
  >
    <span data-tip data-for={title === 'Voluntary' ? 'voluntaryTooltip' : 'employerTooltip'}>{ title }</span>
    <Tooltip
      id='voluntaryTooltip'
      place='right'
      offset={{ top: 0, right: 0 }}
      delayHide={100}
    >
      <span className={styles.tooltipTitleSpan}>{`Voluntary ${lifeTypesArray.includes(planType) ? 'Life' : 'Disability'} Insurance`}</span>
      <span className={styles.tooltipSpan}>Employers can not pay or contribute to this plan. Employee participation is optional</span>
    </Tooltip>
    <Tooltip
      id='employerTooltip'
      place='right'
      offset={{ top: 0, right: 0 }}
      delayHide={100}
    >
      <span className={styles.tooltipTitleSpan}>{`Group ${lifeTypesArray.includes(planType) ? 'Life' : 'Disability'} Insurance`}</span>
      <span className={styles.tooltipSpan}>100% paid by the employer and participation is required for all full time employees. Employees can not contribute to this plan</span>
    </Tooltip>
  </div>
}

function colorFor(fundingType: FundingType) {
  switch (fundingType) {
  case FundingType.fullyFunded:
    return '#D9E8FF'
  case FundingType.levelFunded:
    return '#3564B9'
  case FundingType.mec:
    return '#DBE1FF'
  }
}

interface ExplanationModalProps {
  isOpen: boolean
  onRequestClose(): void
}

const MECExplanationModal: React.FC<ExplanationModalProps> = ({ isOpen, onRequestClose }) =>
  <Modal
    gaModalName={MECExplanationModal.name}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    header={`${FundingType.mec} Plans`}
    footerButtons={[{
      gaButtonName: `Close ${MECExplanationModal.name}`,
      content: 'Close',
      onClick: onRequestClose,
      actionType: ActionType.primary,
      color: BrandColors.navyBlue
    }]}
  >
    <div className={styles.explanationContainer}>
      <p>{ FundingType.mec } Plans provide simple to understand and affordable options. They exceed the requirements of the Affordable Care Act satisfying Employer Penalty A and Individual Mandate Penalty*. These plans are not insurance products.</p>
      <h2>What it is</h2>
      <p>Everyday Healthcare Needs. Laboratory Services. Imaging or X-ray Services. Prescription Drugs. By frequency, Premier Plan accounts for 90+% of healthcare service and addresses those everyday healthcare needs.</p>
      <h2>What these plans cover</h2>
      <p>Preventive Care, Primary Care, Office Visits, Specialists Office Visits, Urgent Care Visits, Laboratory Services, Imaging or X-ray Services, Prescription Drugs, and Telemedicine</p>
      <h2>What these plans don’t cover</h2>
      <p>Big ticket items such as hospital stays, chronic diseases, specialty drugs. The Premier Plan includes a hospital component for Inpatient, Outpatient, Emergency Room, and Ambulance Services 50/50 Benefit to $5,000 / Max payout $2,500.</p>
      <h2>Q: Is there a network of doctors or physicians?</h2>
      <p>Yes, the plan uses Preferred Healthcare Services (PHCS), one of the largest physician networks with over 900,000 physicians nationwide. There is no coverage for out‐of‐network services </p>
      <h2>Q: Will I receive an ID Card?</h2>
      <p>Yes, An ID card and welcome kit will be sent to your home address. This usually takes several days after your requested effective date. For questions on benefits, claims, ID cards etc.: <a style={{ color: '#16346f' }} href='tel:+1-888-272-1513'>1-888-272-1513</a></p>
      <h2>Q: Does the plan cover Preventive Care Services?</h2>
      <p>Yes, the plan provides 100% benefits, no copays, no deductibles (in-network) Preventive Care services include: Screenings for blood pressure, cancer, cholesterol, depression, obesity, and Type 2 diabetes. Pediatric screenings for hearing, vision, autism, and developmental disorders, depression, and obesity.</p>
      <small>*These plans may not meet your state&apos;s individual or employer coverage mandates.</small>
    </div>
  </Modal>

export default PlanTitle
