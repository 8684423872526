import React from 'react'
import { Contributions } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import Plan, { PlanBody } from 'Components/Plans/plan-subcomponents/Plan'
import PlanTitle from 'Components/Plans/plan-subcomponents/PlanTitle'
import { Member } from 'Utilities/Hooks/useStargate'
import EETierRate from './EESupplementalSubComp/EETierRate'
import BenefitComp from './EESupplementalSubComp/BenefitComp'
import BenefitAmountContainer from './EESupplementalSubComp/BenefitAmountContainer'
import benefitStyle from './EESupplementalSubComp/EESupplementalSubComp.module.scss'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { LifeEEPlan } from '../LifePlan/index.helpers'

interface Props {
  plan: { rate: TieredRates, plan: LifeEEPlan, isRenewal: boolean }
  contributions: Contributions
  selected?: boolean
  label: Label
  selectHandler?(plan: LifeEEPlan): void
  showWeeklyPayments: boolean
  premium?: PremiumSplits
  member: Member
}

const LifeEEPlanEE: React.FC<Props> = ({ plan, selected, label, selectHandler, contributions, showWeeklyPayments, member }) => {
  const dollarAmount = plan.plan.data.benefitAmount.split(' ')[0].substring(1)
  const benefitAmountDescriptor = plan.plan.data.benefitAmount.split(/(?<=^\S+)\s/)[1]
  const sbc = plan.plan.data.sbc

  return <Plan selected={!!selected}>
    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier= {plan.plan.carrier}
      selected={selected}
      includesProsperBenefits={false}
      planType={plan.plan.type}
      label={label}
      sbc={sbc}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
    />
    <PlanBody noWrap={true} planType={plan.plan.type}>
      <EETierRate
        plan={plan}
        contributions={contributions}
        showWeeklyPayments={showWeeklyPayments}
        member={member}
        showAfterEmpCont={false}
        onlyIndividual
      />
      <BenefitComp amountLabel={benefitAmountDescriptor} name={GlobalStringConstant[0].benefit_amount} benefitAmount={dollarAmount}/>
      <div className={styles.vertContainer}>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].plan_term}</div>
            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              <span className={benefitStyle.amount}>{plan.plan.data.planTerm}</span>
            </div>
          </div>
        </BenefitAmountContainer>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].portability}</div>
            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              <span className={benefitStyle.portability_text}>{plan.plan.data.portability}</span>
            </div>
          </div>
        </BenefitAmountContainer>
      </div>
    </PlanBody>
  </Plan>
}

export default LifeEEPlanEE
