import React from 'react'
import { Member } from 'Utilities/Hooks/useStargate'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { ProposalGroupInfo } from '../Proposal'
import styles from './AgeBandedProposal.module.scss'
import { ProposalCompanyHeader } from './ProposalCompanyHeader'
import PlanMemberRatesTable from 'Components/Anubis/PlanMemberRatesTable/PlanMemberRatesTable'
import ContributionsCalculator from 'Utilities/Plans/ContributionCalculator'
import { RateType } from '../../../../../../shop/employer/plans'

export const AgeBandedProposal: React.FC<{ medicalPlan: MedicalPlan[], group: ProposalGroupInfo, members: Member[], calc: ContributionsCalculator }> = ({ medicalPlan, group, members, calc }) => {
  return <div className={styles.mainComponent}>
    <ProposalCompanyHeader groupName={group.name} effectiveDate={group.effectiveDate} />
    <div className={styles.innerDivOne}>
      <div className={styles.innerDivTwo}>
        <p className={styles.medicalPlanHeader}>medical plan Proposal</p>
        <p className={styles.ageBandedRates}>Age Banded Rates</p>
        {medicalPlan.map((plan, idx) => {
          let pages
          if (!plan.memberPremiums) {
            pages = ['']
          } else {
            pages = Array(Math.ceil(members.length / 5)).fill(0)
          }
          calc.contributions.rateType = RateType.ageBanded
          return pages.map((_, i) => {
            return <div id='UnsplittableProposalComponent' key={`${idx}${i}`}>
              <PlanMemberRatesTable
                plan={plan}
                showContributions={true}
                showAll={true}
                scrollable={false}
                calc={calc}
                forProposal
                paginate={{
                  page: i + 1,
                  limit: 5
                }}
              />
            </div>
          })
        }
        )}
      </div>
    </div>
  </div>
}
