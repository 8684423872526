import React, { useEffect, useState } from 'react'
import styles from './Confirmation.module.scss'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { PowerLevel } from 'Utilities/Hooks/useUser'
import DashboardModal from './DashboardModal'
import { Link } from 'react-router-dom'
import SendPlansModal from '../plans/review/components/SendPlansModal'
import Laptop from 'Assets/LaptopMan.svg'
import Envelope from 'Assets/Envelope.svg'
import { useToggle } from 'react-use'
import ProgressButton from 'react-progress-button'
import useToast from 'Utilities/Hooks/useToast'
import * as api from 'Utilities/pharaoh'
import './ConfirmationSendButton.css'
import { post } from 'Utilities/fetch++'
import { Venue } from 'Utilities/pharaoh.types'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { GAButton } from 'Components/Tracking'

const ERShopCongratulations: React.FC<PrivateWizardPageProps> = ({ stargate }) => {
  const { group, members } = stargate
  const [isRequestReviewModalOpen, toggleRequestReviewModal] = useToggle(false)
  function handleSendPlans(email: string) {
    return api.v2.groups(group?.id).invite.manager(email)
  }
  useFullContentArea()
  useEffect(() => {
    if (stargate.user.power_level === PowerLevel.groupManager) {
      // ^^ don’t email a broker browsing their client
      post('/v3/souvenir', { context: stargate.group?.id, venue: Venue.group }).catch(console.error)
    }
  }, [stargate.user, stargate.group])

  const [buttonState, setButtonState] = useState('')
  const [isOpen, toggle] = useToggle(false)
  const addToast = useToast()
  const exploreDashCopy = 'Explore my Employer Dashboard'

  return <>
    <SendPlansModal
      isOpen={isRequestReviewModalOpen}
      onRequestClose={toggleRequestReviewModal}
      members={members}
      callback={handleSendPlans}
    />
    <div className={styles.mainContainer}>
      <DashboardModal isOpen={isOpen} onRequestClose={toggle}/>
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <div className={styles.checkTitle}>
            <div className={styles.confirmCheck}>
              <i className="material-icons">check</i>
            </div>
            <h1>All done. You made it!</h1>
          </div>
          <p className={styles.mediumInstructions}>The next step is to invite employees to participate. You may send the email now or later through your dashboard.</p>
          <p className={styles.smallInstructions}>Additional documents may be required by the carrier and can be submitted on your dashboard.</p>
          <div className={styles.inviteButtons}>
            <ProgressButton
              durationSuccess={5000}
              durationError={5000}
              onClick={sendEmailToEmployees}
              state={buttonState}
              onSuccess={() => setButtonState('disabled')}
            >
            Send Invites to All Employees
            </ProgressButton>
            <GAButton analytics={`I'll do it later (${ERShopCongratulations.name})`} onClick={sendLater} className={styles.laterButton}>I’ll do it later</GAButton>
          </div>
          <p className={`${styles.smallInstructions} ${styles.alignLeft}`}>You can send them later via your employer dashboard.</p>
        </div>
      </div>
      <div className={`${styles.linksContainer} ${styles.marginTop}`}>
        <div className={styles.linkBox}>
          <img src={Laptop} alt="Man with laptop" className={styles.laptop} />
          <div className={styles.linkBoxTextContainer}>
            <h2>Explore Your Dashboard</h2>
            <p>View selected plans and employee information, track the enrollment status for your group, and access key documents and reports on the employer dashboard.</p>
            <Link to="/dashboard/employer" onClick={onLinkClick}>
              <GAButton className={styles.exploreDashboard} analytics={`${exploreDashCopy} (${ERShopCongratulations.name})`}>{exploreDashCopy}
                <i className={`material-icons ${styles.inlineIcon}`}>chevron_right</i>
              </GAButton>
            </Link>
          </div>
        </div>
        <div className={styles.linkBox}>
          <img src={Envelope} alt="Envelope" className={styles.envelope} />
          <div className={styles.linkBoxTextContainer}>
            <h2>Invite a Group Manager</h2>
            <p>Invite a group manager to review your benefits package.</p>
            <GAButton className={styles.exploreDashboard} onClick={toggleRequestReviewModal} analytics={`${exploreDashCopy} (${ERShopCongratulations.name})`}>Invite a Group Manager
              <i className={`material-icons ${styles.inlineIcon}`}>chevron_right</i>
            </GAButton>
          </div>
        </div>
      </div>
    </div>
  </>

  async function sendEmailToEmployees() {
    if (buttonState === 'disabled') {
      return false
    }

    setButtonState('loading')
    try {
      await api.v2.groups(stargate.group?.id).users.invite()
      setButtonState('success')
      addToast('Your employees have been notified to set up their account!')
    } catch (err) {
      setButtonState('error')
      addToast(err)
    }
  }

  function sendLater() {
    addToast('Okay, you can email them anytime from your dashboard!')
  }

  function onLinkClick() {
    if (stargate.group?.id) {
      localStorage.overrideGroupID = stargate.group?.id
    } else {
      localStorage.removeItem('overrideGroupID')
    }
  }
}

export default ERShopCongratulations
