import { BasePlan } from 'Utilities/pharaoh.types'
import { GroupPlanType } from 'Utilities/Plans/ContributionCalculator'

interface AccidentPlan extends BasePlan {
  type: GroupPlanType.accident
}

export function isAccident(plan: any): plan is AccidentPlan {
  return plan.type === 'accident' || plan?.plan?.type === 'accident'
}

interface CancerPlan extends BasePlan {
  type: GroupPlanType.cancer
}

export function isCancer(plan: any): plan is CancerPlan {
  return plan.type === 'cancer' || plan?.plan?.type === 'cancer'
}

interface CriticalIllnessPlan extends BasePlan {
  type: GroupPlanType.criticalIllness
}

export function isCriticalIllness(plan: any): plan is CriticalIllnessPlan {
  return plan.type === 'criticalIllness' || plan?.plan?.type === 'criticalIllness'
}

interface HospitalPlan extends BasePlan {
  type: GroupPlanType.hospital
}

export function isHospital(plan: any): plan is HospitalPlan {
  return plan.type === 'hospital' || plan?.plan?.type === 'hospital'
}

interface STDPlan extends BasePlan {
  type: GroupPlanType.std
}

export function isSTD(plan: any): plan is STDPlan {
  return plan.type === 'std' || plan?.plan?.type === 'std'
}
