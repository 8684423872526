import React, { useState } from 'react'
import { useAsync, useToggle } from 'react-use'
import { v3 } from 'Utilities/pharaoh'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import styles from './index.module.scss'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import Heading from 'Components/Stargate/Heading'
import { MemberPlus } from 'Utilities/Plans/ContributionCalculator'
import { MQType, StargateConfig } from 'Utilities/Hooks/useStargate'
import useToast from 'Utilities/Hooks/useToast'
import { GAButton } from 'Components/Tracking'
import { classNames } from 'Utilities/etc'
import BackToTop from 'Components/BackToTop'
import { employeePerTierCount } from 'Routes/dashboard/agency/clients/ID'
import CommissionsModal from 'Routes/dashboard/agency/clients/ID/Proposals/CommissionsModal/CommissionsModal'
import { ProposalGroupInfo, TierCount } from 'Routes/dashboard/agency/clients/ID/Proposals/Proposal'
import useUser, { PowerLevel } from 'Utilities/Hooks/useUser'
import { Broker } from 'Utilities/pharaoh.types'
import { post } from 'Utilities/fetch++'
import { BackButton } from '../plans/ancillary/sub-components/search'
import { Route } from '../../../../Utilities/Route'
import { useHistory } from 'react-router-dom'

const ERShopProposal: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const { value, loading, error } = useAsync(async() => {
    const broker = await v3.brokers.GET()
    const ees = await v3.groups(stargate.group?.id).users().GET() as MemberPlus[]
    return {
      broker,
      ees
    }
  })
  const config = stargate.config
  useFullContentArea()
  if (loading) return <Loader />
  if (error) return <Error error={error} />
  const { broker, ees } = value!

  return <Content
    config={config}
    stargate={stargate}
    onwards={onwards}
    broker={broker}
    groupInfo={{ name: stargate.group!.name, id: stargate.group!.id, effectiveDate: stargate.group!.effectiveDate, groupState: stargate.group!.state }}
    enrollCount={employeePerTierCount(ees)}

  />
}

interface Props extends PrivateWizardPageProps {
  config: StargateConfig
  broker: Broker
  groupInfo: ProposalGroupInfo
  enrollCount: TierCount
}

const Content: React.FC<Props> = ({ stargate, onwards, broker, groupInfo, enrollCount }) => {
  const [modalVisible, setModalVisible] = useToggle(false)
  const [loadingProposal, setLoadingProposal] = useToggle(true)
  const [disabled, setDisabled] = useToggle(false)
  const [showDownloadButtons, setShowDownloadButtons] = useState(false)
  const { group, imqsSent, membersCompletedTheirIMQs, isLevelFundedGroup, mqType } = stargate
  const addToast = useToast()
  const { value: user } = useUser()
  const canPropose = user && user?.powerLevel >= PowerLevel.broker
  const imqButtonCopy = imqsSent ? 'Medical Questionnaire Status' : 'Send Medical Questionnaires'
  const needsToSendMQs = isLevelFundedGroup && !membersCompletedTheirIMQs && (mqType !== MQType.hba && mqType !== MQType.none) && (stargate.imqOverride === false)
  const history = useHistory()

  return <>
    <Heading innerclassname={styles.supplementalHeading}>
      <BackButton onBackClick={() => history.push(Route.erStargate_AncillaryPlans)}>Plans</BackButton>
      <h1>Proposal {mqType === MQType.alf && 'and Medical Questionnaires'}</h1>

      <div className={styles.supplementalHeading}>
        <div></div>
      </div>

    </Heading>
    {(canPropose) && <>
      <div className={styles.proposal_div}>
        <p><b>Download your proposal.</b><br/> All plans in your cart are included in your proposal. To make changes to your proposal add or remove plans from your cart. </p>
        <GAButton analytics="Generate Proposal ERShop" className={styles.proposalBtn} onClick={setModalVisible} disabled={loadingProposal}>{loadingProposal ? 'Loading Proposal' : 'Generate Proposal'}</GAButton>
        <CommissionsModal
          isOpen={modalVisible}
          onRequestClose={() => {
            setShowDownloadButtons(false)
            setModalVisible()
          }}
          broker={broker} group={groupInfo}
          enrollCount={enrollCount}
          loadingProposal={setLoadingProposal}
          members={stargate.members}
          contributions={stargate.contributions!}
          splits={stargate.splits}
          showDownloadButtons={showDownloadButtons}
          setShowDownloadButtons={setShowDownloadButtons}
        />
      </div>
    </>}
    {needsToSendMQs &&
      <div className={styles.mq_info_div}>
        <p><span className={styles.bold}>The plan(s) you have selected require medical questionnaires.</span>
          The button below will invite all employees in your census to create an account and complete a medical questionnaire.<br/><br/>
          Once the medical questionnaire is submitted, the employee will be emailed a link to digitally sign one or more
          forms to provided information to the selected carriers.
          <br/><br/>
          On the next screen you will see the progress and status of each employee and be able to send reminders.
        </p>
        <div className={styles.warning}>
          <b>Warning</b>
          You will not be able to change plan selections once invites are sent out.
        </div>
      </div>}
    <div className={styles.buttonMargin}>
      {onwardsButton()}
    </div>
    <BackToTop/>
  </>

  function onwardsButton() {
    return <>
      {needsToSendMQs
        ? <GAButton
          analytics={`${imqButtonCopy} (${ERShopProposal.name})`}
          className={classNames('shop-next-button', styles.imqButton, styles.shop_next_button)}
          onClick={sendIMQs}
        >
          {imqButtonCopy}
        </GAButton>
        : <GAButton
          analytics={`Next (${ERShopProposal.name})`}
          className={classNames('shop-next-button', styles.nextButton)}
          onClick={() => go(stargate?.mqType || MQType.none)}
          disabled={disabled}
        >Next</GAButton>
      }
    </>
  }

  function sendIMQs() {
    setDisabled(true)
    onwards(Promise.resolve({ mqType: MQType.allstate }))
      .then(() => {
        if (!imqsSent) {
          return post(`/v2/groups/${group!.id}/users/invite/imq`)
            .then(() => addToast('Success! Medical Questionnaire invites have been sent to your employees.', { appearance: 'info' }))
        }
        setDisabled(false)
      })
      .catch(addToast)
  }

  function go(mqType: MQType) {
    onwards(Promise.resolve({ mqType }))
  }
}

export default ERShopProposal
