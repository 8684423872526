import React from 'react'
import { GlobalStringConstant } from 'GlobalStringConstant'
import styles from '../index.module.scss'

interface Props {
  name: string
  benifitAmount: string | any
  benifitLabel: string
  noDollarSign?: boolean
}
const BenifitAmountComp: React.FC<Props> = ({ benifitAmount, benifitLabel, name, noDollarSign }) => {
  return (
    <div className={`${styles.section} ${styles.fourSpecs} ${styles.benifitContainer}`}>
      <table className={styles.box}>
        <thead>
          <tr>
            <th>{name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.lump_sum}>
              <div className={styles.dollarBox}>
                {!noDollarSign && <span className={styles.dollar}>{GlobalStringConstant[0].dolar}</span>}
                {benifitAmount || '-'}
              </div>
              <small className={styles.benefitLabel}>{benifitLabel}</small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
export default BenifitAmountComp
