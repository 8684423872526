import { BasePlan } from 'Utilities/pharaoh.types'
import { GroupPlanType } from 'Utilities/Plans/ContributionCalculator'

export default interface LifePlan extends BasePlan {
  type: GroupPlanType.life
  data: {
    'supplementalAD&DIncluded?': string
    benefitsIndividual?: string
    benefitsPerChild?: string
    benefitsSpouse?: string
    disabilityProvision?: string
    sbc?: string
    willPreparationAndEstateResolutionServices?: string
  }
}

export interface LifeEEPlan extends BasePlan {
  type: GroupPlanType.lifeEE
  data: {
    'supplementalAD&DIncluded?': string
    benefitAmount: string
    benefitsIndividual?: string
    benefitsPerChild?: string
    benefitsSpouse?: string
    disabilityProvision?: string
    planTerm: string
    portability: string
    sbc?: string
    willPreparationAndEstateResolutionServices?: string
  }
}

export interface LifeERPlan extends BasePlan {
  type: GroupPlanType.lifeER
  data: {
    'supplementalAD&DIncluded?': string
    accidentalDeathAndDismemberment: string
    benefitAmount: string
    benefitsIndividual?: string
    benefitsPerChild?: string
    benefitsSpouse?: string
    conversion: string
    disabilityProvision?: string
    sbc?: string
    willPreparationAndEstateResolutionServices?: string
  }
}

export function isLife(plan: any): plan is LifePlan {
  return plan.type === 'life' || plan?.plan?.type === 'life'
}

export function isLifeEE(plan: any): plan is LifeEEPlan {
  return plan.type === 'lifeEE' || plan?.plan?.type === 'lifeEE'
}

export function isLifeER(plan: any): plan is LifeERPlan {
  return plan.type === 'lifeER' || plan?.plan?.type === 'lifeER'
}
