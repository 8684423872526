import React, { useState } from 'react'
import ProfileSection from 'Components/Anubis/ProfileSection'
import { useHistory } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import ContributionsCalculator, {
  AncillaryPlanUnion,
  GroupPlanType, isLifeDisability,
  isMedical
} from 'Utilities/Plans/ContributionCalculator'
import styles from './index.module.scss'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { getPlanIDFrom, logoFor, massagedPlanName } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { isSupplemental } from 'Components/Plans/SupplementalPlan/index.helpers'
import StyledRate from 'Components/Anubis/StyledRate/StyledRate'
import AgeBands from 'Components/Anubis/AgeBands/AgeBands'
import PlanMemberRatesTable from 'Components/Anubis/PlanMemberRatesTable/PlanMemberRatesTable'
import { GAButton } from 'Components/Tracking'
import { PlanDetailsModal } from 'Components/Plans/plan-subcomponents/PlanTitle'
import { useToggle } from 'react-use'

type Props = {
  id: string
  calc: ContributionsCalculator
  defaultOpen: boolean
}

export const healthcareDetailsTitle = 'Healthcare Plans'

const GroupsProfileMedicalPlansSection: React.FC<Props> = ({ calc, id, defaultOpen }) => {
  const [showSBC, toggleShowSBC] = useToggle(false)
  const [selectedPlan, setSelectedPlan] = useState<MedicalPlan | AncillaryPlanUnion>()
  const history = useHistory()
  let tableStripe = 1

  function planSelectAction(plan?: MedicalPlan | AncillaryPlanUnion) {
    setSelectedPlan(plan)
    toggleShowSBC(true)
  }

  function getSbcFromPlan(selectedPlan: MedicalPlan | AncillaryPlanUnion) {
    return isMedical(selectedPlan) ? selectedPlan.sbc : selectedPlan.plan.data?.sbc
  }

  function userFriendlyType(type: string | undefined) {
    switch (type) {
    case GroupPlanType.stdER:
    case GroupPlanType.stdEE:
    case GroupPlanType.std:
      return 'Short-Term Disability'
    case GroupPlanType.ltdER:
    case GroupPlanType.ltdEE:
      return 'Long-Term Disability'
    case GroupPlanType.lifeER:
    case GroupPlanType.lifeEE:
    case GroupPlanType.life:
      return 'Life'
    case GroupPlanType.criticalIllness:
      return 'Critical Illness'
    default:
      if (typeof type === 'string') {
        return type[0].toUpperCase() + type.substring(1)
      }
      return type
    }
  }

  return <ProfileSection
    name={'Plan Rates'}
    addButtonName="Shop Healthcare Plans"
    expanded={defaultOpen}
    onAddButton={() => {
      localStorage.overrideGroupID = id
      history.push(Route.erStargate_Plans)
    }}
  >
    {selectedPlan &&
      <PlanDetailsModal
        id={getPlanIDFrom(selectedPlan)}
        sbc={getSbcFromPlan(selectedPlan)}
        showSBC={showSBC}
        toggleShowSBC={toggleShowSBC}
        carrier={isMedical(selectedPlan) ? selectedPlan.carrier : selectedPlan.plan.carrier}
        planName={isMedical(selectedPlan) ? selectedPlan.name : selectedPlan.plan.name}
      />
    }
    <table className={styles.table}>
      <colgroup>
        <col/>
        <col/>
        <col/>
        <col style={{ width: '80px' }}/>
        <col style={{ width: '80px' }}/>
        <col style={{ width: '80px' }}/>
        <col style={{ width: '80px' }}/>
        <col style={{ width: '80px' }}/>
      </colgroup>
      <thead>
        <tr>
          <th>Type</th>
          <th>Carrier</th>
          <th>Plan</th>
          <th>Indv.</th>
          <th>Couple</th>
          <th>Single Parent</th>
          <th>Family</th>
          <th>SBC</th>
        </tr>
      </thead>
      <tbody>

        {Object.values(calc.plans).map((plans: MedicalPlan[] | AncillaryPlanUnion[]) => {
          return Object.values(plans).map((plan: MedicalPlan | AncillaryPlanUnion, i) => {
            tableStripe = tableStripe === 1 ? 2 : 1
            const rowStyling = tableStripe === 1 ? styles.odd : styles.even
            if (isMedical(plan)) {
              return <MedicalRow plan={plan} calc={calc} rowStyling={rowStyling} key={i}/>
            } else if (isLifeDisability(plan.plan.type)) {
              return <LifeDisabilityRow plan={plan} calc={calc} rowStyliong={rowStyling} key={i}/>
            } else {
              return <React.Fragment key={i}><tr className={rowStyling}>
                <td>{userFriendlyType(plan.plan.type)}</td>
                <td>{logoFor(plan.plan.carrier, plan.plan.name) || plan.plan.carrier}</td>
                <td>{massagedPlanName(plan.plan.name, plan.plan.carrier)}</td>
                {isLifeDisability(plan.plan.type) &&
                  <td colSpan={4}>Rate Per Employee</td>
                }
                {!isLifeDisability(plan.plan.type) &&
                  <>
                    <td><StyledRate rate={plan.rate.individual} precision={2}/></td>
                    <td><StyledRate rate={plan.rate.couple} precision={2}/></td>
                    <td><StyledRate rate={plan.rate.singleParent} precision={2}/></td>
                    <td><StyledRate rate={plan.rate.family} precision={2}/></td>
                  </>
                }
                <td>
                  <GAButton analytics={`Details (${plan.plan.name})`} className={styles.details} onClick={() => planSelectAction(plan)}>{isSupplemental(plan) ? 'Details' : 'SBC' }</GAButton>
                </td>
              </tr>
              {isLifeDisability(plan.plan.type) && <tr className={[rowStyling, styles.nopad].join(' ')}>
                <td></td>
                <td colSpan={6}>
                  <PlanMemberRatesTable calc={calc} plan={plan} showDependents={false} showAll={true} showTier={false} showClasses={false} showTitle={false} showContributions={false}/>
                </td>
              </tr>}
              </React.Fragment>
            }
          })
        })
        }
      </tbody>
    </table>
  </ProfileSection>

  function MedicalRow(props: { calc: ContributionsCalculator, plan: MedicalPlan, rowStyling: any }) {
    const {
      calc,
      plan,
      rowStyling
    } = props
    const [ageBandedState, setAgeBandedState] = useState<boolean>(false)
    const [premiumsState, setPremiumsState] = useState<boolean>(false)

    return <>
      <tr className={rowStyling}>
        <td>Medical</td>
        <td>{logoFor(plan.carrier, plan.name) || plan.carrier}</td>
        <td>{massagedPlanName(plan.name, plan.carrier)}</td>
        {calc.isAgeBanded() && !plan.memberPremiums &&
          <>
            <td colSpan={4} className={styles.warning}>Plan not eligible for Age Banded Contributions</td>
          </>
        }
        {calc.isAgeBanded() && plan.memberPremiums &&
          <>
            <td colSpan={2} className={styles.clickable} onClick={() => setAgeBandedState(!ageBandedState)}>{ageBandedState ? 'Hide' : 'Show'} Age Bands
            </td>
            <td colSpan={2} className={styles.clickable} onClick={() => setPremiumsState(!premiumsState)}>{premiumsState ? 'Hide' : 'Show'} Member Rates
            </td>
          </>
        }
        {calc.isComposite() &&
          <>
            <td><StyledRate rate={plan.premium.employee.individual} precision={2}/></td>
            <td><StyledRate rate={plan.premium.employee.couple} precision={2}/></td>
            <td><StyledRate rate={plan.premium.employee.singleParent} precision={2}/></td>
            <td><StyledRate rate={plan.premium.employee.family} precision={2}/></td>
          </>
        }
        <td>
          <GAButton analytics={`Details (${plan.name})`} className={styles.details} onClick={() => planSelectAction(plan)}>SBC</GAButton>
        </td>
      </tr>
      {plan.ageBands && ageBandedState &&
        <tr className={[rowStyling, styles.nopad].join(' ')}>
          <td colSpan={7}>
            <h4>Age Bands</h4>
            <AgeBands plan={plan} bands={plan.ageBands} showTitle={false}/>
          </td>
        </tr>
      }
      {plan.memberPremiums && premiumsState &&
        <tr className={[rowStyling, styles.nopad].join(' ')}>
          <td colSpan={7}>
            <h4>Age Banded Member Rates</h4>
            <PlanMemberRatesTable calc={calc} plan={plan} showAll={true} showClasses={true} showTitle={false} showContributions={calc.isAgeBanded()}/>
          </td>
        </tr>
      }
    </>
  }

  function LifeDisabilityRow(props: { calc: ContributionsCalculator, plan: AncillaryPlanUnion, rowStyliong: any }) {
    const {
      calc,
      plan,
      rowStyliong
    } = props
    const [premiumsState, setPremiumsState] = useState<boolean>(false)

    return <>
      <tr className={rowStyliong}>
        <td>{userFriendlyType(plan.plan.type)}</td>
        <td>{logoFor(plan.plan.carrier, plan.plan.name) || plan.plan.carrier}</td>
        <td>{massagedPlanName(plan.plan.name, plan.plan.carrier)}</td>
        {calc.isAgeBanded() && !plan.memberPremiums &&
          <>
            <td colSpan={4} className={styles.warning}>Plan not eligible for Age Banded Contributions</td>
          </>
        }
        {plan.memberPremiums &&
          <>
            <td colSpan={4} className={styles.clickable} onClick={() => setPremiumsState(!premiumsState)}>{premiumsState ? 'Hide' : 'Show'} Member Rates
            </td>
          </>
        }
        <td>
          <GAButton analytics={`Details (${plan.plan.name})`} className={styles.details} onClick={() => planSelectAction(plan)}>SBC</GAButton>
        </td>
      </tr>
      {plan.memberPremiums && premiumsState &&
        <tr className={[rowStyliong, styles.nopad].join(' ')}>
          <td></td>
          <td colSpan={6}>
            <PlanMemberRatesTable calc={calc} plan={plan} showDependents={false} showAll={true} showTier={false} showClasses={false} showTitle={false} showContributions={false}/>
          </td>
        </tr>
      }
    </>
  }
}

export default GroupsProfileMedicalPlansSection
