import React from 'react'
import queryString from 'query-string'
import { PrivateWizardPageProps } from '../Wizard/WizardRoute'
import { ReturnEvent } from 'Utilities/DocuSignForm'
import { GAButton } from 'Components/Tracking'
import { PipelineStatus } from 'Utilities/pharaoh.types'
import ALFImqForm from 'Components/Stargate/Underwriting/ALFImqForm'

const Underwriting: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const { groupMember, group } = stargate
  if (!groupMember || !group) return <p>You must complete the earlier steps before you can continue.</p>

  const parsedQuery = queryString.parse(window.location.search)

  if (parsedQuery.event === ReturnEvent.SIGNING_COMPLETE || groupMember.medical_underwriting_complete) {
    const canProceed = (stargate.membersCompletedTheirIMQs && group.ratesLocked) || group.pipelineStatus === PipelineStatus.enrolled
    return <header>
      <h1 className='shop-h1-periwinkle'>Application</h1>
      <h2 style={{ marginBottom: '4rem' }} className='shop-h2-navy'>Thank you for completing your medical questionnaire. Our team of trusted advisors will review your information and find the best plans for your group. Once your employer chooses plans to offer, we will send you an invite to shop and enroll.</h2>
      <GAButton
        analytics={`Next Step (${Underwriting.name})`}
        className='shop-next-button'
        onClick={next}
        disabled={!canProceed}
      >
        Next Step
      </GAButton>
    </header>
  } else {
    return <ALFImqForm stargate={stargate}/>
  }

  function next() {
    onwards(Promise.resolve())
  }
}

export default Underwriting
