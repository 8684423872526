import React from 'react'
import styles from './CensusErrors.module.scss'
import * as XLSX from 'xlsx'
import moment from 'moment'

interface Props {
  hasErrors: boolean
  errorsObj: ErrorObj[]
}

type ErrorRow = {row: number, name?: string, errors: JSX.Element[]}

export interface ErrorObj {
  rowNumber: number
  name: string
  errors: {
    birthDate: ErrorAndReason
    biologicalSex: ErrorAndReason
    zipCode: ErrorAndReason
    class: ErrorAndReason
    enrollmentType: ErrorAndReason
    tobaccoUse: ErrorAndReason
    firstName: ErrorAndReason
    lastName: ErrorAndReason
    fullPartTime: ErrorAndReason
    email: ErrorAndReason
  }
}
type ErrorAndReason = {error: boolean, reason: string}

export const validateSpreadsheet = (jsonData: any[]) => {
  const errorsObjArray: ErrorObj[] = []

  jsonData.map((r, i) => {
    errorsObjArray.push({
      rowNumber: i + 5,
      name: '',
      errors: {
        birthDate: { error: false, reason: '' },
        biologicalSex: { error: false, reason: '' },
        zipCode: { error: false, reason: '' },
        class: { error: false, reason: '' },
        enrollmentType: { error: false, reason: '' },
        tobaccoUse: { error: false, reason: '' },
        firstName: { error: false, reason: '' },
        lastName: { error: false, reason: '' },
        fullPartTime: { error: false, reason: '' },
        email: { error: false, reason: '' }
      }
    })
    r[1] = r[1]?.toLowerCase()?.trim()
    r[3] = r[3]?.toLowerCase()?.trim()
    r[5] = r[5]?.toLowerCase()?.trim()
    // Birth Date
    const date = new Date((r[0] - (25567 + 1)) * 86400 * 1000)
    if (!moment(date).isValid()) {
      errorsObjArray[i].errors.birthDate.error = true
      errorsObjArray[i].errors.birthDate.reason = 'Birth date is not valid'
    }
    // Biological Sex
    const maleRegex = new RegExp(/^m(r\.?)?$/).test(r[1])
    const femaleRegex = new RegExp(/^m(r?s|iss(es)?)\.?$/).test(r[1])
    if ((!['male', 'make', 'mister', 'f', 'female', 'femake'].includes(r[1])) && !maleRegex && !femaleRegex) {
      errorsObjArray[i].errors.biologicalSex.error = true
      errorsObjArray[i].errors.biologicalSex.reason = 'Biological sex must be either "Male" or "Female"'
    }
    // Zip Code
    if (r[2]?.toString().length === 4) (r[2] = `0${r[2]}`)
    const zipRegexTest = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(r[2])
    if (!zipRegexTest) {
      errorsObjArray[i].errors.zipCode.error = true
      errorsObjArray[i].errors.zipCode.reason = 'Zip code is not valid'
    }
    // Class
    const childRegex = new RegExp(/^child(\s\d+)?$/).test(r[3])
    const classArray = ['ee', 'employee', 'spouse', 'domestic partner', 'dependent', 'ch', 'dp', 'dep', 'sp', 'wife']
    if (!classArray.includes(r[3]) && !childRegex) {
      errorsObjArray[i].errors.class.error = true
      errorsObjArray[i].errors.class.reason = 'Class must be "Employee", "Spouse", "Domestic Partner" or "Dependent"'
    }
    // Enrollment Type
    const enrollmentTypeArray = [
      'employee', 'employee+spouse', 'employee + spouse', 'employee+children', 'employee + children', 'family'
    ]
    const depEnrollmentTypeArray = ['spouse', 'domestic partner', 'dependent', 'ch', 'dp', 'dep', 'sp', 'wife']
    if (r[4] && typeof r[4] === 'string') {
      r[4] = r[4]?.toLowerCase()?.trim()
      const coupleRegex = new RegExp(/^e(e|mployee)\s?[+,/&]\s?sp(ouse)?$/).test(r[4])
      const singleParentRegex = new RegExp(/^e(e|mployee)\s?[+,/&]\s?ch(ild(\(?ren\)?)?)?$/).test(r[4])
      const empAndDepRegex = new RegExp(/^e(e|mployee)\s?[+,/&]\s?dep(endent)?s?$/).test(r[4])

      if (!['ee', 'employee'].includes(r[3])) {
        if (!enrollmentTypeArray.includes(r[4]) && !coupleRegex && !singleParentRegex && !empAndDepRegex) {
          errorsObjArray[i].errors.enrollmentType.error = true
          errorsObjArray[i].errors.enrollmentType.reason = `${r[4]} is not a valid enrollment type. Enrollment type must be: Employee, Employee + Spouse, Employee + Children or Family`
        }
      } else if (depEnrollmentTypeArray.includes(r[3])) {
        if (r[4]) {
          errorsObjArray[i].errors.enrollmentType.error = true
          errorsObjArray[i].errors.enrollmentType.reason = `A group member of class ${r[3]} must have a blank cell for Enrollment Type`
        }
      }
    }
    // Tobacco Use
    if (!['smoker', 't', 'true', 'yes', 'no', 'n', 'nonsmoker', 'f', 'false'].includes(r[5])) {
      errorsObjArray[i].errors.tobaccoUse.error = true
      errorsObjArray[i].errors.tobaccoUse.reason = 'Tobacco Use must be either "Yes" or "No"'
    }
    // First Name
    r[6] && (r[6] = r[6].trim())
    r[7] && (r[7] = r[7].trim())
    if (!r[6]) {
      errorsObjArray[i].errors.firstName.error = true
      errorsObjArray[i].errors.firstName.reason = 'First Name must be provided'
    }
    // Last Name
    if (!r[7]) {
      errorsObjArray[i].errors.lastName.error = true
      errorsObjArray[i].errors.lastName.reason = 'Last Name must be provided'
    } else {
      if (r[6]) {
        errorsObjArray[i].name = `${r[6]} ${r[7]}`
      }
    }
    // Full/Part Time
    if (r[8] && typeof r[8] === 'string') {
      r[8] = r[8]?.toLowerCase()?.trim()
      if (!['ee', 'employee'].includes(r[3])) {
        if (!['f', 'ft', 'full', 'full time', 'p', 'pt', 'part', 'part time'].includes(r[8])) {
          errorsObjArray[i].errors.fullPartTime.error = true
          errorsObjArray[i].errors.fullPartTime.reason = 'Employment must be either "Full Time" or "Part Time"'
        }
      } else if (depEnrollmentTypeArray.includes(r[3])) {
        if (r[8]) {
          errorsObjArray[i].errors.fullPartTime.error = true
          errorsObjArray[i].errors.fullPartTime.reason = `A ${r[3]} must not have a value for Employment Type`
        }
      }
    }
    // Email
    const validateEmail = (email: string) => {
      return String(email)
        .toLowerCase()
        .match(
          /[0-9a-z._%+-]+@([a-z0-9-]+\.)+[a-z]{2,64}/
        )
    }
    if (['ee', 'employee'].includes(r[3])) {
      if (!validateEmail(r[9])) {
        errorsObjArray[i].errors.email.error = true
        errorsObjArray[i].errors.email.reason = `Email address ${r[9]} is not valid`
      }
    } else {
      if (r[9]) {
        errorsObjArray[i].errors.email.error = true
        errorsObjArray[i].errors.email.reason = `A ${r[3]} must not have an email address`
      }
    }
  })
  return errorsObjArray
}

export const trimJsonData = (jsonData: any[]) => {
  jsonData.splice(0, 4)
  return jsonData.map(a => {
    return a.map((b: any) => {
      if (typeof b === 'string') {
        if (b.trim() === '') {
          b = undefined
        } else {
          b = b.trim()
        }
      }
      return b
    })
  }).filter(array => !array.every((item: any) => !item))
}

export async function readExcelFile(file: File): Promise<any[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      const fileData = e.target!.result
      const workbook = XLSX.read(fileData, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
      resolve(trimJsonData(jsonData))
    }

    reader.onerror = (e) => {
      reject(e)
    }

    reader.readAsArrayBuffer(file)
  })
}

const CensusErrors: React.FC<Props> = ({ hasErrors, errorsObj }) => {
  const errorsForPrint: ErrorRow[] = []

  if (hasErrors) {
    errorsObj.map((r: any) => {
      const errorObj: ErrorRow = { row: r.rowNumber, errors: [] }
      if (r.name) errorObj.name = r.name
      errorsForPrint.push(errorObj)
      for (const property in r.errors) {
        if (r.errors[property].error) {
        errorsForPrint.find(e => e.row === r.rowNumber)!.errors.push(<span key={property}>{r.errors[property].reason}</span>)
        }
      }
    })
  }

  const printErrors = () => {
    return errorsForPrint.map((r, i) => {
      if (r.errors.length) {
        return <React.Fragment key={i}>
          <div className={styles.rowInfo}><span className={r.name && styles.hasName}>{`Row number ${r.row}`}</span>{ r.name && <span>{r.name}</span>}</div>
          <ul key={i}>
            {r.errors.map((e, i) => {
              return <li key={i}>{e}</li>
            })}
          </ul>
        </React.Fragment>
      }
    })
  }

  return <>{ hasErrors &&
    <div className={styles.censusErrors}>
      <div className={styles.errorsTitle}>Your census has the following errors:</div>
      {printErrors()}
    </div>
  }</>
}

export default CensusErrors
