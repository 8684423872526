/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useAsync, useSetState, useToggle } from 'react-use'
import { put } from 'Utilities/fetch++'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import styles from './index.module.scss'
import { Label } from 'Utilities/config'
import EEContributionContainer from 'Components/EEContributionContainer'
import AccidentplanEE from 'Components/Plans/EESupplementalPlans/AccidentplanEE'
import VTLPlanEE from 'Components/Plans/EESupplementalPlans/VTLPlanEE'
import LifeERPlanEE from 'Components/Plans/EESupplementalPlans/LifeERPlanEE'
import LifeEEPlanEE from 'Components/Plans/EESupplementalPlans/LifeEEPlanEE'
import ABCLifePlanEE from 'Components/Plans/EESupplementalPlans/ABCLifePlanEE'
import CancerPlanEE from 'Components/Plans/EESupplementalPlans/CancerPlanEE'
import CriticalIllnessEE from 'Components/Plans/EESupplementalPlans/CriticalIllnessEE'
import HospitalEE from 'Components/Plans/EESupplementalPlans/HospitalEE'
import STDEE from 'Components/Plans/EESupplementalPlans/STDEE'
import STDEEPlanEE from 'Components/Plans/STDPlan/STDEEPlanEE'
import STDERPlanEE from 'Components/Plans/STDPlan/STDERPlanEE'
import LTDEEPlanEE from 'Components/Plans/LTDPlan/LTDEEPlanEE'
import LTDERPlanEE from 'Components/Plans/LTDPlan/LTDERPlanEE'
import useToast from 'Utilities/Hooks/useToast'
import { $enum } from 'ts-enum-util'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { v3 } from 'Utilities/pharaoh'
import { GAButton } from 'Components/Tracking'
import { StargateConfig } from 'Utilities/Hooks/useStargate'
import Heading from 'Components/Stargate/Heading'
import { classNames } from 'Utilities/etc'
import ContributionsCalculator,
{
  allSupplementalPlans,
  allAncillaryContributionEligibleLines,
  AncillaryPlanUnion,
  GroupPlanType,
  moneyNumber,
  moneyString,
  moneyWeekly
} from 'Utilities/Plans/ContributionCalculator'
import { compact } from 'lodash'
import { isABC } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import headerStyles from 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss'
import { isSupplemental } from 'Components/Plans/SupplementalPlan/index.helpers'
import { isLife, isLifeEE, isLifeER } from 'Components/Plans/LifePlan/index.helpers'
import { isLtdEE, isLtdER } from 'Components/Plans/LTDPlan/index.helpers'

const EEShopPlansSupplemental: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const { value: plans, loading, error } = useAsync(async() => {
    return await v3.groups(stargate.group?.id).users(stargate.user.id).plans.options.ancillary() as AncillaryPlanUnion[]
  })
  const config = stargate.config
  useFullContentArea()

  if (loading) return <Loader />
  if (error) return <Error error={error} />
  const hasSuppPlans = plans?.some((p) => (isSupplemental(p) || isLife(p) || isLifeEE(p) || isLifeER(p) || isLtdEE(p) || isLtdER(p)))

  if (!plans || plans.length <= 0 || !hasSuppPlans) {
    // So, we need them to manually move to the next page, or bad things happen
    return <><Heading innerclassname={headerStyles.eeHeading}>
      <div>
        <div className={headerStyles.title}>
          <h1 className={styles.coverageHead}>Supplemental Coverage</h1>
        </div>
        <GAButton
          analytics={`Next ${EEShopPlansSupplemental.name}`}
          className={classNames('shop-next-button', styles.nextButton)}
          onClick={() => onwards(Promise.resolve())}>
          Next Step
        </GAButton>
      </div>
    </Heading>
    <section className={styles.mainContainer}>
      <section className={styles.eeNoPlansSection}>
        <h2>No plans available at this time.</h2>
      </section>
    </section>
    </>
  }

  const visibleTypes: Set<GroupPlanType> = new Set((plans || []).map(({ plan }) => plan.type))

  if (!config.showLifePlans) visibleTypes.delete(GroupPlanType.life)
  if (!config.showDisabilityPlan) visibleTypes.delete(GroupPlanType.disability)

  const visibleOrderedTypes = $enum(GroupPlanType).getValues().filter(type => visibleTypes.has(type))
  return <Content stargate={stargate} onwards={onwards} plans={plans} types={visibleOrderedTypes} config={config}/>
}

interface Props extends PrivateWizardPageProps {
  plans: AncillaryPlanUnion[]
  types: GroupPlanType[]
  config: StargateConfig
}

interface ERPlansObj {
  lifeERPlan: ERPlanObjKey
  ltdERPlan: ERPlanObjKey
  stdERPlan: ERPlanObjKey
}

type ERPlanObjKey = {plan: AncillaryPlanUnion|undefined, type: GroupPlanType}

const Content: React.FC<Props> = ({ stargate, onwards, config, plans, types }) => {
  const { members, group, groupMember, contributions, user } = stargate

  const prosper = stargate.carrierSpecificData?.prosper
  const { showWeeklyPayments, moneyDecimals } = config
  const addToast = useToast()
  const [selections, setSelections] = useSetState(defaultSelections())
  const [disabled, setDisabled] = useToggle(false)

  let allSupplementalTypes = types.filter(t => (allSupplementalPlans().has(t) ||
  [GroupPlanType.lifeEE, GroupPlanType.lifeER, GroupPlanType.ltdEE, GroupPlanType.ltdER, GroupPlanType.stdEE, GroupPlanType.stdER].includes(t)) &&
  !allAncillaryContributionEligibleLines().has(t))

  const ERPlansObj: ERPlansObj = {
    lifeERPlan: { plan: plans.find(p => p.plan.type === GroupPlanType.lifeER), type: GroupPlanType.lifeER },
    ltdERPlan: { plan: plans.find(p => p.plan.type === GroupPlanType.ltdER), type: GroupPlanType.ltdER },
    stdERPlan: { plan: plans.find(p => p.plan.type === GroupPlanType.stdER), type: GroupPlanType.stdER }
  }

  // if an ER type is available automatically select it
  useEffect(() => {
    Object.keys(ERPlansObj).forEach(p => {
      if (ERPlansObj[p as keyof ERPlansObj].plan) {
        if (!selections[ERPlansObj[p as keyof ERPlansObj].type]) {
          setSelections({ [ERPlansObj[p as keyof ERPlansObj].type]: ERPlansObj[p as keyof ERPlansObj].plan!.plan.id })
        }
      }
    })
  }, [])

  // if 'life' plan type exists, append it to the end of the array so 'voluntary term life' appears after 'short term disability'
  const lifeType = types.find(e => e === GroupPlanType.life)
  if (lifeType) {
    allSupplementalTypes = allSupplementalTypes.filter(e => e !== GroupPlanType.life)
    allSupplementalTypes.push(lifeType)
  }

  return <>
    <Heading innerclassname={headerStyles.eeHeading}>
      <div>
        <div className={headerStyles.title}>
          <h1 className={styles.coverageHead}>Supplemental Coverage</h1>
          {aboutCopy()}
        </div>
        <GAButton
          analytics={`Next (${EEShopPlansSupplemental.name})`}
          className={classNames('shop-next-button', styles.nextButton)}
          onClick={() => onwards(Promise.resolve())}
          disabled={disabled}>
           Next Step
        </GAButton>
      </div>
    </Heading>
    <section className={styles.mainContainer}>
      { allAncillarySection() }
      {allSupplementalTypes.sort().map(render)}
      <GAButton
        analytics={`Next (${EEShopPlansSupplemental.name})`}
        className={classNames('shop-next-button', styles.nextButton)}
        onClick={() => onwards(Promise.resolve())}
        disabled={disabled}>
           Next
      </GAButton>
    </section>
  </>

  function allAncillarySection() {
    if (!contributions) return <Error error='Please complete earlier steps'/>
    const dentalPlan = plans.find((p) => p.plan.id === groupMember!.enrolled_dental_plan_id)
    const visionPlan = plans.find((p) => p.plan.id === groupMember!.enrolled_vision_plan_id)
    const allAncillary = moneyNumber(contributions?.baseContributions.allAncillary, moneyDecimals)
    if (!allSupplementalTypes.length || !allAncillary) return
    const frequency = showWeeklyPayments ? '/weekly' : '/month'
    const p = compact([...allSupplementalTypes.map(t => plans.find(p => p.plan.id === selections[t])), dentalPlan, visionPlan])

    const calc = new ContributionsCalculator(p, contributions!, [], members.filter(m => m.id === groupMember?.id), moneyDecimals)
    const premium = calc.premiumsForAncillary()
    const erAmount = amount(allAncillary)
    const erAmountLeft = amount(allAncillary - premium.er)
    const eeAmount = amount(premium.ee)

    return <section className={styles.allAncillarySection}>
      {config.label !== Label.blacksmith &&
        <EEContributionContainer
          erAmount={erAmount}
          frequency={frequency}
          eeAmount={eeAmount}
          erAmountLeft={erAmountLeft}
          showWeeklyPayments={showWeeklyPayments}
          border={true}
        />
      }
    </section>

    function amount(monthly: string | number) {
      return showWeeklyPayments ? moneyWeekly(monthly) : moneyString(monthly)
    }
  }

  function render(type: GroupPlanType) {
    const groupPlans = plans.filter(p => p.plan.type === type)
    const hasABC = groupPlans.some(p => isABC(p.plan.carrier))
    const hasBlacksmith = config.label === Label.blacksmith
    const planNames = h2(type)

    return <>{ planNames &&
        <article key={type}>
          <h2>{h2(type)} Plans</h2>
          { type === GroupPlanType.life && !hasABC && !hasBlacksmith &&
          <div className={styles.planTypeInfo}>
            <p>Voluntary Term Life is intended to be an employee paid benefit, meaning that an employer is unlikely to contribute. You may take this policy with you after leaving the company.</p>
          </div>
          }
          { groupPlans.map(plan) }
        </article>}
    </>
  }

  function plan(plan: AncillaryPlanUnion) {
    const isSelected = selections[plan.plan.type] === plan.plan.id
    const props: any = {
      selected: isSelected,
      contributions,
      key: plan.plan.id,
      splits: [],
      plan,
      selectHandler: toggle,
      showWeeklyPayments,
      member: members.find(m => m.id === groupMember?.id),
      label: stargate.config.label
    }
    switch (plan.plan.type) {
    case GroupPlanType.accident:
      return <AccidentplanEE {...props}/>
    case GroupPlanType.cancer:
      return <CancerPlanEE {...props}/>
    case GroupPlanType.hospital:
      return <HospitalEE {...props}/>
    case GroupPlanType.std:
      return <STDEE {...props}/>
    case GroupPlanType.criticalIllness:
      return <CriticalIllnessEE {...props}/>
    case GroupPlanType.life:
      return isABC(plan.plan.carrier) ? <ABCLifePlanEE {...props}/> : <VTLPlanEE {...props} />
    case GroupPlanType.lifeEE:
      return <LifeEEPlanEE {...props} />
    case GroupPlanType.lifeER:
      return isABC(plan.plan.carrier) ? <ABCLifePlanEE {...props}/> : <LifeERPlanEE {...props} />
    case GroupPlanType.ltdEE:
      return <LTDEEPlanEE {...props} />
    case GroupPlanType.ltdER:
      return <LTDERPlanEE {...props} />
    case GroupPlanType.stdEE:
      return <STDEEPlanEE {...props} />
    case GroupPlanType.stdER:
      return <STDERPlanEE {...props} />
    }

    async function toggle(plan: any) {
      try {
        setDisabled(true)
        if (isSelected) {
          await v3.users(groupMember?.id).plans(plan.type).DELETE()
        } else {
          await put(`/v3/groups/${group?.id}/users/plans/${plan.type}/${plan.id}`)
        }
        setSelections({ [plan.type]: isSelected ? undefined : plan.id })
      } catch (error) {
        addToast(error as Error)
      } finally {
        setDisabled(false)
      }
    }
  }

  function h2(type: GroupPlanType) {
    switch (type) {
    case GroupPlanType.accident:
      return 'Accident'
    case GroupPlanType.cancer:
      return 'Cancer'
    case GroupPlanType.criticalIllness:
      return 'Critical Illness'
    case GroupPlanType.hospital:
      return 'Hospital Indemnity'
    case GroupPlanType.std:
    case GroupPlanType.stdEE:
    case GroupPlanType.stdER:
      return 'Short Term Disability'
    case GroupPlanType.lifeER:
      return 'Life'
    case GroupPlanType.life:
    case GroupPlanType.lifeEE:
      if (config.label === Label.blacksmith) {
        return 'Life, Accidental Death and Dismemberment'
      }
      return 'Voluntary Term Life'
    case GroupPlanType.ltdEE:
    case GroupPlanType.ltdER:
      return 'Long Term Disability'
    }
  }

  function aboutCopy() {
    return <>
      {(config.label !== Label.blacksmith) &&
        <div className={`${styles.eeSupplementalHeading} ${headerStyles.description}`}>
          <label>Select from the following supplemental plans below. Please note that:</label>
          <ul>
            <li>Selecting a plan below does not guarantee issuance as all Aflac supplemental lines require a minimum of
              two employees from your group to enroll. Please contact your group manager for more details.
            </li>
            <li> The Critical Illness and Life plans have different rates based on tobacco use. If you select these
              plans, you will be asked to provide information about your tobacco use.
            </li>
          </ul>
        </div>
      }
      {blacksmithExtra()}
    </>

    function blacksmithExtra() {
      if (config.label !== Label.blacksmith) return null
      const link = 'https://sign.zoho.com/signform?form_link=234b4d535f49562361d89a08a9fdd7abf34c163c27e8331f8d985ca9d0d404b410f0cf02c8d32b6cf1cdd65f3f4cf1b1195855574ccb5015120ad776f1998a4d4f02174ca8918449#'
      return <>
        <p style={{ color: 'yellow' }}>
          Life insurance through Guardian is covered by your employer.  As the carrier for life insurance has changed, it is important to complete a new beneficiary form. <a style={{ color: '#94D6FF' }} target="_blank" rel="noopener noreferrer" href={link}>The new form can be found here. (click here)</a>
        </p>
      </>
    }
  }

  type Selections = {
    [GroupPlanType.prosper]?: boolean
    [GroupPlanType.dental]?: string
    [GroupPlanType.vision]?: string
    [GroupPlanType.life]?: string
    [GroupPlanType.lifeEE]?: string
    [GroupPlanType.lifeER]?: string
    [GroupPlanType.disability]?: string
    [GroupPlanType.ltdEE]?: string
    [GroupPlanType.ltdER]?: string
    [GroupPlanType.accident]?: string
    [GroupPlanType.cancer]?: string
    [GroupPlanType.criticalIllness]?: string
    [GroupPlanType.hospital]?: string
    [GroupPlanType.std]?: string
    [GroupPlanType.stdEE]?: string
    [GroupPlanType.stdER]?: string
    [GroupPlanType.medical]?: never
  }

  function defaultSelections() {
    const selected: Selections = {}
    selected[GroupPlanType.dental] = groupMember?.enrolled_dental_plan_id || user?.enrolled_dental_plan_id
    selected[GroupPlanType.vision] = groupMember?.enrolled_vision_plan_id || user?.enrolled_vision_plan_id
    selected[GroupPlanType.life] = groupMember?.enrolled_life_plan_id || user?.enrolled_life_plan_id
    selected[GroupPlanType.lifeEE] = groupMember?.enrolled_life_plan_id || user?.enrolled_life_plan_id
    selected[GroupPlanType.lifeER] = groupMember?.enrolled_life_plan_id || user?.enrolled_life_plan_id
    selected[GroupPlanType.disability] = groupMember?.enrolled_disability_plan_id || user?.enrolled_disability_plan_id
    selected[GroupPlanType.ltdEE] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p?.plan?.type === GroupPlanType.ltdEE))
    selected[GroupPlanType.ltdER] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p?.plan?.type === GroupPlanType.ltdER))
    selected[GroupPlanType.accident] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p.plan.type === GroupPlanType.accident))
    selected[GroupPlanType.cancer] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p.plan.type === GroupPlanType.cancer))
    selected[GroupPlanType.std] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p?.plan?.type === GroupPlanType.std))
    selected[GroupPlanType.stdEE] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p?.plan?.type === GroupPlanType.stdEE))
    selected[GroupPlanType.stdER] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p?.plan?.type === GroupPlanType.stdER))
    selected[GroupPlanType.criticalIllness] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p.plan.type === GroupPlanType.criticalIllness))
    selected[GroupPlanType.hospital] = groupMember?.supplemental_enrollments?.find(e => plans.find(p => p.plan.id === e && p.plan.type === GroupPlanType.hospital))
    selected[GroupPlanType.prosper] = !!prosper?.signedUp
    return selected
  }
}

export default EEShopPlansSupplemental
