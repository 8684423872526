import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import React from 'react'
import styles from './index.module.scss'
import { ReactComponent as EHQIllustration } from './ehq.svg'
import { useToggle } from 'react-use'
import EHQQuestions from './EHQQuestions'
// import { PowerLevel } from 'Utilities/Hooks/useUser'

const EREHQ: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const [ehqSubmitted, toggleEHQSubmitted] = useToggle(stargate?.group?.ehqSubmitted || false)
  // eslint-disable-next-line camelcase
  // const isBroker = (stargate?.user.power_level || PowerLevel.individual) >= PowerLevel.broker
  const isBroker = false

  if (stargate?.group?.hbaApproved || isBroker) { onwards(Promise.resolve()) }

  return ehqSubmitted
    ? <div className={styles.mainContainer}>
      <div>
        <div className={styles.innerBox}>
          <h1>Complete Forms to Proceed</h1>
          <p>Please have group manager check their email!</p>
          <p>Because you have selected a medical plan under The Health Benefit Alliance, it is required that the group manager sign a completed Employer Health Questionnaire before proceeding.</p>
          <p>This form was sent to the group manager via an email from Zoho Sign for digital signature.  If you have any questions, please contact your account manager directly. Thank you for your patience!</p>
          <p>Please note that the Employer Health Questionnaire must be completed 45 days before the intended effective date.</p>
        </div>
        <p className={styles.footnote}>Please note that the Employer Health Questionnaire and Intent to Implement form must be completed 45 days before the intended effective date.</p>
      </div>
      <EHQIllustration className={styles.illustration}/>
    </div>
    : <EHQQuestions group={stargate.group!} callback={toggleEHQSubmitted}/>
}

export default EREHQ
