import React, { useState } from 'react'
import styles from './ProsperBenefitsModal.module.scss'
import { ReactComponent as ProsperLogo } from './prosper-benefits-logo-white.svg'
import prosperMain from './prosper-main.svg'
import prosperTelehealth from './prosper-telehealth.svg'
import prosperWorkLife from './prosper-work-life.svg'
import prosperHealthAdvocacy from './prosper-health-advocacy.svg'
import prosperMedicalBillSaver from './prosper-medical-bill-saver.svg'
import prosperCobraErisa from './prosper-cobra-erisa.svg'
import { useInterval } from 'react-use'
import { classNames } from 'Utilities/etc'
import { useCycle } from 'Utilities/Hooks/useCycle'
import Modal, { ModalProps } from 'Components/Modals/Modal'

const ProsperBenefitsModal: React.FC<Omit<ModalProps, 'gaModalName' | 'header'>> = props => {
  const [step, { next, prev, set }] = useCycle(ProsperBenefits.length - 1)
  const [interacted, setInteracted] = useState(false)
  useInterval(next, props.isOpen && !interacted ? 5000 : null)

  return <Modal
    gaModalName={ProsperBenefitsModal.name}
    header={<>
      What’s Included?
      <ProsperLogo className={styles.prosperLogo}/>
    </>}
    contentClassName={styles.container}
    {...props}
  >
    <div className={styles.prosperIncluded}>
      <h2>{ProsperBenefits[step].title}</h2>
      <p>{ProsperBenefits[step].copy}</p>
    </div>
    <img src={ProsperBenefits[step].image} alt={ProsperBenefits[step].title}/>
    <div className={styles.controls}>
      <button className={classNames(styles.arrow, styles.prev)} onClick={() => { prev(); setInteracted(true) }}><span className="material-icons">navigate_before</span></button>
      <div className={styles.stepNav}>{renderStepNav()}</div>
      <button className={classNames(styles.arrow, styles.next)} onClick={() => { next(); setInteracted(true) }}><span className="material-icons">navigate_next</span></button>
    </div>
  </Modal>

  function renderStepNav() {
    return ProsperBenefits.map(({ title }, index) =>
      <button
        className={classNames(step === index && styles.stepButtonActive, styles.stepButton)}
        onClick={() => { set(index); setInteracted(true) }}
        key={title}
      />)
  }
}

export const ProsperBenefits = [
  {
    title: 'prosper benefits+',
    image: prosperMain,
    copy: `
    Prosper Benefits+ is a suite of services included with your plan at
    no cost to you. Use of Prosper Benefits+ is unlimited and proven to
    lower a group’s claims history, creating future savings at renewal.
    `
  },
  {
    title: 'Telemedicine',
    image: prosperTelehealth,
    copy: `
    Powered by Teladoc, you now have 24/7 access to a doctor with a $0 visit
    fee for general medical issues. With Teladoc, you can talk to a doctor by
    phone or online video to get a diagnosis, treatment options and prescription,
    if medically necessary.
    `
  },
  {
    title: 'Health Advocate™ Solutions',
    image: prosperWorkLife,
    copy: `
    Healthcare is complicated. Personal Health Advocates help you navigate the
    insurance and healthcare systems. Everything from medical bills and insurance
    claims to clarifying benefits, answering questions, and much more.
    `
  },
  {
    title: 'Everyday Discounts',
    image: prosperHealthAdvocacy,
    copy: `
    NBDeals is your one-stop-shop for exclusive discounts on brands and experiences
    you know and love. Browse over 40 different categories across 500+ merchants for
    endless opportunities to save, with new deals added weekly.
    `
  },
  {
    title: 'Telephonic EAP',
    image: prosperMedicalBillSaver,
    copy: `
    Call for help with depression, stress, family or relationship conflicts,
    substance abuse, debt, help finding services for your children or elderly
    parents, legal and financial concerns, grief, loss, and more.
    `
  },
  {
    title: 'Prescription Discounts',
    image: prosperCobraErisa,
    copy: `
    Make sure you’re always getting the best deal on your prescriptions with
    deep discounts through New Benefits Rx. Save up to 85% on most prescriptions
    at 60,000 retail pharmacies nationwide and through home delivery.
    `
  }
]

export default ProsperBenefitsModal
