/* eslint-disable camelcase */
import React, { ComponentType, useState } from 'react'
import useContentAdjustment from 'Utilities/Hooks/useContentAdjustment'
import styles from './index.module.scss'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import Heading from 'Components/Stargate/Heading'
import { Controller, ControllerProps, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import {
  ContributionAmountInput,
  ContributionSplitDependentType,
  ContributionSplitDependentTypeSelect,
  ContributionSplitType,
  ContributionSplitTypeSelect,
  ContributionType,
  extractContribution
} from 'Components/Stargate/Contribution/ContributionAmount'
import * as api from 'Utilities/pharaoh'
import { useAsync, useEffectOnce, useLocation } from 'react-use'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { ContributionSplit, StargateResponse } from 'Utilities/Hooks/useStargate'
import { isNumber, uniq, upperFirst } from 'lodash'
import ContributionsCalculator, {
  allAncillaryContributionEligibleLines,
  allSupplementalPlans,
  AncillaryPlanUnion,
  BaseContributions,
  Contributions,
  formatAllAncillaryInput,
  GroupPlanContributions,
  GroupPlanType,
  isMedical, moneyNumber,
  moneyString,
  moneyWeekly,
  PlanUnion
} from 'Utilities/Plans/ContributionCalculator'
import {
  extractPlanNameAndCarrier,
  extractPlanType,
  getPlanIDFrom,
  typeOfPlan
} from 'Components/Plans/plan-subcomponents/Plan.helpers'
import PlanContributionTable from './PlanContributionTable'
import useToast from 'Utilities/Hooks/useToast'
import { AncillaryContributionMode } from 'Routes/shop/employer/plans/ancillary'
import { Switch } from 'Components/Rudimentary/CandorSwitch'
import inputStyles from 'Components/Stargate/Contribution/ContributionAmount.module.scss'
import numeral from 'numeral'
import FixedAncPlanContributionTable from './FixedAncPlanContributionTable'
import OverallCart from './OverallCart'
import { RateType } from 'Routes/shop/employer/plans/index'
import PlanMemberRatesTable from 'Components/Anubis/PlanMemberRatesTable/PlanMemberRatesTable'
import StyledRate from 'Components/Anubis/StyledRate/StyledRate'
import { isLifeEE, isLifeER } from '../../../../../Components/Plans/LifePlan/index.helpers'
import { isLtdEE, isLtdER } from '../../../../../Components/Plans/LTDPlan/index.helpers'
import { isStdEE, isStdER } from '../../../../../Components/Plans/STDPlan/index.helpers'

function hasAgeBandedPlans(medical: MedicalPlan[]) {
  let banded = false
  medical.forEach(m => {
    if (m.premium.can_be_age_banded || m.memberPremiums?.premiums) {
      banded = true
    }
  })
  return banded
}

const ERShopPlansContribution: React.FC<PrivateWizardPageProps> = ({ onwards, stargate }) => {
  const { group, splits } = stargate
  const location = useLocation().hash?.replace('#', '')
  const async = useAsync(async() => {
    const medical = await api.v3.groups(group?.id).plans.GET() as MedicalPlan[]
    const ancillary = await api.v3.groups(group?.id).plans.options.ancillary.selected() as AncillaryPlanUnion[]
    const contributions = await api.v3.groups(group?.id).contributions.GET() as Contributions
    const ageBandedPlanSelected = hasAgeBandedPlans(medical)
    contributions.rateType = ageBandedPlanSelected ? group?.rateType || RateType.composite : RateType.composite
    return { medical, ancillary, contributions, ageBandedPlanSelected }
  })
  useContentAdjustment({ maxWidth: 'unset', padding: 0 })

  if (async.loading) return <Loader/>
  if (async.error) return <Error error={async.error}/>

  const contributions = async.value!.contributions
  const ageBandedPlanSelected = async.value!.ageBandedPlanSelected
  contributions.baseContributions.allAncillary = contributions.baseContributions.allAncillary && moneyString(contributions.baseContributions.allAncillary)
  splits.forEach(s => {
    if (!contributions.splitContributions?.some(sc => sc.id === s.id)) {
      contributions.splitContributions?.push({
        id: s.id,
        baseContributions: {
          medical: s.contribution || '0%',
          medicalEquitable: !!s.isEquitable,
          dentalEquitable: false,
          visionEquitable: false,
          lifeEquitable: false,
          disabilityEquitable: false,
          majorCancerEquitable: false,
          accidentEquitable: false
        },
        planContributions: []
      })
    }
  })
  const plans: PlanUnion[] = [...async.value!.medical, ...async.value!.ancillary]
  plans.forEach(p => {
    const planID = getPlanIDFrom(p)
    const defPlan = {
      groupID: group!.id,
      groupPlanID: planID,
      planType: typeOfPlan(p),
      minRate: isMedical(p) ? moneyNumber(p.premium.employee.individual) : moneyNumber(p.rate.individual)
    } as GroupPlanContributions
    if (!contributions.planContributions.some(pc => pc.groupPlanID === planID)) {
      contributions.planContributions.push(defPlan)
    }

    if (isMedical(p)) {
      contributions.splitContributions?.forEach(sc => {
        if (!sc.planContributions.some(pc => pc.groupPlanID === planID)) {
          sc.planContributions.push({ ...defPlan, splitID: sc.id })
        }
      })
    }
  })

  return <ContributionForm {...async.value!} contributions={contributions} stargate={stargate} onwards={onwards} ageBandedPlanSelected={ageBandedPlanSelected} scrollToSection={location}/>
}

interface ContributionFormProps {
  medical: MedicalPlan[]
  ancillary: AncillaryPlanUnion[]
  contributions: Contributions
  stargate: StargateResponse
  onwards(api: Promise<any>): Promise<void>
  scrollToSection?: string
  ageBandedPlanSelected: boolean
}

const ContributionForm: React.FC<ContributionFormProps> = ({ medical, ancillary, stargate, contributions, ageBandedPlanSelected, ...props }) => {
  const { group, splits, members } = stargate
  const { showWeeklyPayments } = stargate.config
  const addToast = useToast()
  const [disabled, setDisabled] = useState(false)
  const [ancillaryContributionMode, setAncillaryContributionMode] = useState(contributions.baseContributions.allAncillary ? AncillaryContributionMode.allEligibleLines : AncillaryContributionMode.perLine)
  const form = useForm({ defaultValues: contributions })
  const { handleSubmit, watch, register, control, setValue } = form
  const inputs = watch() as Contributions
  const calc = new ContributionsCalculator([...ancillary, ...medical], inputs, splits, members, 2)
  const formID = 'groupContributionsSpreadsheet'

  useEffectOnce(() => {
    if (props.scrollToSection) {
      const element = document.getElementById(props.scrollToSection)
      if (element) {
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition - 200
        window.scrollBy({ top: offsetPosition, behavior: 'smooth' })
      }
    }
  })

  return <div className={styles.mainContainer}>
    <Heading >
      <h1 className={styles.contirbHeading}>Set Your Contribution</h1>
    </Heading>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} id={formID} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}>
        <fieldset disabled={disabled}>
          { hiddenInputs() }
          <section>
            <h2 id={GroupPlanType.medical}>Set Your Medical Plan Contribution</h2>
            {ageBandedPlanSelected &&
            <div className={styles.block}>
              <div className={styles.contributionsContainer}>
                <h4 className={styles.policyIssue}>How would you like to issue your policy?</h4>
                <RateTypeSelector/>
              </div>
              {inputs.rateType === RateType.ageBanded &&
                <div className={styles.disclaimer}>
                  * Age banded rates are only available for ACA Community Rated plans.
                </div>
              }
            </div>}
            {!ageBandedPlanSelected &&
              <input type='hidden' name={'rateType'} ref={register}/>
            }
            { planTables(
              inputs.baseContributions,
              contributions.planContributions || [],
              GroupPlanType.medical,
              undefined,
              undefined
            ) }
            { contributions.splitContributions?.map((sc, i) => {
              const split = splits.find(s => s.id === sc.id)
              return planTables(inputs.splitContributions![i].baseContributions, sc.planContributions || [], GroupPlanType.medical, split, i)
            }) }
            <CategoryCart plans={medical} showWeeklyPayments={showWeeklyPayments} calc={calc} type={'medical'}/>
          </section>
          <section>
            <h2 id={'ancillary'}>Set Your Ancillary and Worksite Plan Contribution</h2>
            {contributionModeForm()}
            {tables(GroupPlanType.dental)}
            {tables(GroupPlanType.vision)}
            {tables(GroupPlanType.disability)}
            <CategoryCart
              plans={ancillary}
              showWeeklyPayments={showWeeklyPayments}
              calc={calc} type={'ancillary'}
              multiLineContribution={ancillaryContributionMode === AncillaryContributionMode.allEligibleLines}
            />
          </section>
          <section>
            <h2 id={'ancillary'}>Life and Disability Contributions
              <small>Contributions are set based on the plan selected and can not be modified</small>
            </h2>
            {ancillary.filter(p => isLifeEE(p) || isLifeER(p)).map(plan => <><h4>Life</h4><PlanMemberRatesTable calc={new ContributionsCalculator(ancillary, contributions, splits, members, 2)} plan={plan} showDependents={false} showContributions={true}/></>)}
            {ancillary.filter(p => isStdEE(p) || isStdER(p)).map(plan => <><h4>Short Term Disability</h4><PlanMemberRatesTable calc={new ContributionsCalculator(ancillary, contributions, splits, members, 2)} plan={plan} showDependents={false} showContributions={true}/></>)}
            {ancillary.filter(p => isLtdEE(p) || isLtdER(p)).map(plan => <><h4>Long Term Disability</h4> <PlanMemberRatesTable calc={new ContributionsCalculator(ancillary, contributions, splits, members, 2)} plan={plan} showDependents={false} showContributions={true}/></>)}
          </section>
        </fieldset>
      </form>
    </FormProvider>
    <OverallCart plans={[...medical, ...ancillary]} showWeeklyPayments={showWeeklyPayments} calc={calc} mode={ancillaryContributionMode}>
      <input type='submit' form={formID} value="Next"/>
    </OverallCart>
  </div>

  function hiddenInputs() {
    return <>
      { contributions.planContributions.map((pc, i) => {
        return <React.Fragment key={pc.groupPlanID}>
          <input type='hidden' name={`planContributions[${i}].groupID`} ref={register}/>
          <input type='hidden' name={`planContributions[${i}].groupPlanID`} ref={register}/>
          <input type='hidden' name={`planContributions[${i}].planType`} ref={register}/>
        </React.Fragment>
      })}
      { contributions.splitContributions?.map((sc, si) => {
        return sc.planContributions.map((pc, i) => {
          return <React.Fragment key={pc.groupPlanID}>
            <input type='hidden' name={`splitContributions[${si}].planContributions[${i}].groupID`} ref={register}/>
            <input type='hidden' name={`splitContributions[${si}].planContributions[${i}].groupPlanID`} ref={register}/>
            <input type='hidden' name={`splitContributions[${si}].planContributions[${i}].planType`} ref={register}/>
            <input type='hidden' name={`splitContributions[${si}].planContributions[${i}].splitID`} ref={register}/>
          </React.Fragment>
        })
      })}
    </>
  }

  function tables(type: GroupPlanType) {
    return planTables(
      inputs.baseContributions,
      contributions.planContributions || [],
      type
    )
  }

  function sortByLowestPremium(a: GroupPlanContributions, b: GroupPlanContributions) {
    if (a.minRate < b.minRate) return -1
    if (a.minRate > b.minRate) return 1
    return 0
  }

  function planTables(bc: BaseContributions, planContributions: GroupPlanContributions[], type: GroupPlanType, split?: ContributionSplit, index?: number) {
    if (!contributions.planContributions.filter(pc => pc.planType === type).length) return
    if (allAncillaryContributionEligibleLines().has(type) && ancillaryContributionMode === AncillaryContributionMode.allEligibleLines) return
    const prefix = split && isNumber(index) ? `splitContributions[${index}].` : ''
    const key = split ? `${type}.${split.id}` : type

    return <div key={key}>
      { split &&
        <>
          <h4>{split.name} <span>(Employee Class)</span></h4>
          <input name={`${prefix}id`} type='hidden' ref={register}/>
        </>
      }
      <div className={styles.block}>
        <BaseContributionInput
          prefix={`${prefix}baseContributions.${type}`}
          type={type}
          split={split}
        />
        { !split && type === GroupPlanType.medical &&
          // * Most carriers require a 50% employer contribution to the individual tier. Your contribution may be altered to meet the minimum state requirements. You must meet the minimum employer contribution requirement per carrier guidelines in order to insure.
          <div className={styles.disclaimer}>*Most carriers require a 50% employer contribution to the individual tier. If issuing age banded, most carriers require an employer contribution equivalent to 50% of the employee-only premium for each employee. Your contribution may be altered to meet the minimum state & carriers requirements.</div>
        }
      </div>
      {type === GroupPlanType.medical &&
        <CategoryCart plans={medical} showWeeklyPayments={showWeeklyPayments} calc={calc} type={'medical'}/>
      }
      { planContributions.sort(sortByLowestPremium).map((pc, i) => {
        const id = pc.groupPlanID
        const plan = [...medical, ...ancillary].find(p => getPlanIDFrom(p) === id)

        if (pc.planType !== type || !plan) return

        if (type !== GroupPlanType.medical || inputs.rateType === RateType.composite) {
          return <PlanContributionTable
            plan={plan}
            prefix={`${prefix}planContributions[${i}]`}
            type={type}
            baseContributions={bc}
            calc={calc}
            showWeeklyPayments={showWeeklyPayments}
            split={split}
            key={`${key}.${id}`}
          />
        } else {
          const medicalPlan = plan as MedicalPlan
          return <>
            {type === GroupPlanType.medical &&
              <>
                <PlanMemberRatesTable
                  plan={medicalPlan}
                  split={split}
                  showContributions={true}
                  calc={calc}
                />
              </>
            }
          </>
        }
      })}
    </div>
  }

  function contributionModeForm() {
    const eligibleTypes = uniq(contributions.planContributions.map(pc => pc.planType))
    const eligibleLinesAnc = eligibleTypes.filter(t => allAncillaryContributionEligibleLines().has(t))
    const eligibleLinesSup = eligibleTypes.filter(t => allSupplementalPlans().has(t))
    const eligibleLines = {
      anc: false,
      sup: false
    }
    eligibleLinesAnc.length > 0 ? eligibleLines.anc = true : eligibleLines.anc = false
    eligibleLinesSup.length > 0 ? eligibleLines.sup = true : eligibleLines.sup = false

    if (eligibleLines.anc === false && eligibleLines.sup === false) return null

    return <div className={styles.contributionModeWrapper}>
      <div className={styles.contributionModeContainer}>
        <p className={styles.contributionModeContainer}>Would you like to contribute a fixed amount across ancillary and worksite plans?</p>
        <Switch
          className={styles.contributionModeSwitch}
          value={ancillaryContributionMode === AncillaryContributionMode.allEligibleLines}
          onChange={d => {
            setAncillaryContributionMode(d ? AncillaryContributionMode.allEligibleLines : AncillaryContributionMode.perLine)
          }}
        />
        { ancillaryContributionMode === AncillaryContributionMode.allEligibleLines &&
        <>
          <p>How much would you like to contribute?</p>
          <Controller
            name='baseContributions.allAncillary'
            control={control}
            render={props => {
              return <input
                {...props}
                className={inputStyles.amount}
                placeholder={'$0'}
                onChange={ e => props.onChange(formatAllAncillaryInput(e.target.value))}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            }}
          />
        </>
        }
      </div>
      {ancillaryContributionMode === AncillaryContributionMode.perLine && <div className={styles.modeDisclaimer}>By selecting to contribute a percentage across tiers, contribution may only be applied to dental and vision coverage.</div>}
    </div>

    function onBlur(event: React.ChangeEvent<HTMLInputElement>) {
      const massagedValue = numeral(event.target.value).format('$0,0')
      event.target.value = massagedValue
      setValue('baseContributions.allAncillary', massagedValue)
    }

    function onFocus(event: React.ChangeEvent<HTMLInputElement>) {
      const value = numeral(event.target.value).value()
      if (!value || value === 0) {
        event.target.value = ''
      } else {
        event.target.value = value.toString()
      }
    }
  }

  async function onSubmit(data: Contributions) {
    try {
      setDisabled(true)
      data.planContributions = data.planContributions.map(addOptionalGPCFields)
      data.baseContributions = addRequiredBCFields({ ...data.baseContributions })
      data.splitContributions = data.splitContributions || []
      data.splitContributions.forEach(sc => {
        sc.baseContributions = addRequiredBCFields({ ...sc.baseContributions })
        sc.planContributions = sc.planContributions.map(addOptionalGPCFields)
      })
      await props.onwards(api.v3.groups(group?.id).contributions.PUT(data))
    } catch (error) {
      addToast(error as Error)
    } finally {
      setDisabled(false)
    }

    function addRequiredBCFields(bc: BaseContributions) {
      bc.medicalEquitable = !!bc.medicalEquitable
      bc.dentalEquitable = !!bc.dentalEquitable
      bc.visionEquitable = !!bc.visionEquitable
      bc.lifeEquitable = !!bc.lifeEquitable
      bc.disabilityEquitable = !!bc.disabilityEquitable
      bc.majorCancerEquitable = !!bc.majorCancerEquitable
      bc.accidentEquitable = !!bc.accidentEquitable
      return bc
    }

    // Not sure why we need to do this
    function addOptionalGPCFields(gpc: GroupPlanContributions) {
      gpc.individual = gpc.individual || undefined
      gpc.couple = gpc.couple || undefined
      gpc.singleParent = gpc.singleParent || undefined
      gpc.family = gpc.family || undefined
      gpc.employee = gpc.employee || undefined
      return gpc
    }
  }
}

type RateTypeSelectorProps = & {
}

const RateTypeSelector: React.FC<Omit<ControllerProps<ComponentType<RateTypeSelectorProps>>, 'render' | 'as' | 'name'>> = () => {
  const { control } = useFormContext()
  return <Controller
    name={'rateType'}
    control={control}
    defaultValue={RateType.composite}
    render={({
      value,
      onChange
    }) => {
      return <Switch
        className={`${styles.contributionModeSwitch} ${styles.wideSwitch}`}
        value={value === RateType.composite}
        onChange={e => {
          onChange(e ? RateType.composite : RateType.ageBanded)
        }}
        customYes={'Composite'}
        customNo={'Age Banded'}
      />
    }}/>
}

type BaseContributionInputProps = & {
  prefix: string
  type: GroupPlanType
  split?: ContributionSplit
}

const BaseContributionInput: React.FC<Omit<ControllerProps<ComponentType<BaseContributionInputProps>>, 'render' | 'as' | 'name'>> = ({ prefix, ...props }) => {
  const { control, setValue } = useFormContext()
  const amountName = `${prefix}`
  const dependentName = `${prefix}Dependent`
  const typeName = `${prefix}Equitable`
  const typeNameDependant = `${typeName}Dependent`
  const amount = useWatch({ name: amountName }) as string
  const dependentAmount = useWatch({ name: dependentName }) as string
  const rateType = useWatch({ name: 'rateType' }) as RateType

  return <div className={styles.baseContributionCompositeContainer}>
    <h3 id={props.type}>{copy()}</h3>
    <div className={styles.baseContributionInputContainer}>
      <Controller
        {...props}
        name={amountName}
        control={control}
        defaultValue={'0%'}
        render={({ value, onChange }) => {
          const [amount, type] = extractContribution(value || '0%')
          return <ContributionAmountInput
            value={amount}
            type={type}
            onChange={a => onChange(type === ContributionType.fixed ? `$${a}` : `${a}%`)}
          />
        }}
      />
      <Controller
        {...props}
        name={typeName}
        control={control}
        defaultValue={false}
        render={({ value, onChange }) => {
          const [, type] = extractContribution(amount || '0%')
          const parsedValue = type === ContributionType.fixed
            ? ContributionSplitType.flatContribution
            : value
              ? ContributionSplitType.allTiers
              : ContributionSplitType.perEmployee
          return <ContributionSplitTypeSelect
            planType={props.type}
            value={parsedValue}
            backgroundColor='white'
            rateType={rateType}
            onChange={value => {
              onChange(value === ContributionSplitType.allTiers)
              const [a] = extractContribution(amount || '0%')
              switch (value) {
              case ContributionSplitType.allTiers:
              case ContributionSplitType.perEmployee:
                setValue(amountName, `${a > 100 ? 100 : a}%`)
                break
              case ContributionSplitType.flatContribution:
                setValue(amountName, `$${a}`)
              }
            }}
          />
        }}
      />
    </div>
    {(rateType === RateType.ageBanded && props.type === 'medical') &&
      <>
        <h3 id={props.type}>{copy('dependent')}</h3>
        <div className={styles.baseContributionInputContainer}>
          <Controller
            {...props}
            name={dependentName}
            control={control}
            defaultValue={'0%'}
            render={({
              value,
              onChange
            }) => {
              const [amount, type] = extractContribution(value || '0%')
              return <ContributionAmountInput
                value={amount}
                type={type}
                onChange={a => onChange(type === ContributionType.fixed ? `$${a}` : `${a}%`)}
              />
            }}
          />
          <Controller
            {...props}
            name={`${typeNameDependant}`}
            control={control}
            defaultValue={false}
            render={({ onChange }) => {
              const [, type] = extractContribution(dependentAmount || '0%')
              const parsedValue2 = type === ContributionType.fixed
                ? ContributionSplitDependentType.flatContribution
                : ContributionSplitDependentType.perDependent
              return <ContributionSplitDependentTypeSelect
                planType={props.type}
                value={parsedValue2}
                backgroundColor='white'
                onChange={value => {
                  onChange(value === ContributionSplitDependentType.flatContribution)
                  const [a] = extractContribution(dependentAmount || '0%')
                  switch (value) {
                  case ContributionSplitDependentType.perDependent:
                    setValue(dependentName, `${a > 100 ? 100 : a}%`)
                    break
                  case ContributionSplitDependentType.flatContribution:
                    setValue(dependentName, `$${a}`)
                  }
                }}
              />
            }}
          />
        </div>
      </>
    }
  </div>

  function copy(type? : string) {
    if (type === 'dependent') {
      return props.split
        ? `How would you like to contribute to dependents in the class, ${props.split.name}?`
        : `How would you like to contribute to ${props.type} plans for dependents?*${props.type === GroupPlanType.medical ? '*' : ''}`
    }
    if (rateType === RateType.ageBanded) {
      return props.split
        ? `How would you like to contribute to employees in the class, ${props.split.name}?`
        : `How would you like to contribute to ${props.type} plans for employees?*${props.type === GroupPlanType.medical ? '*' : ''}`
    }
    return props.split
      ? `How would you like to contribute to the employee class, ${props.split.name}?`
      : `How would you like to contribute to ${props.type} plans?${props.type === GroupPlanType.medical ? '*' : ''}`
  }
}

interface CategoryCartProps {
  plans: PlanUnion[]
  type: 'medical' | 'ancillary'
  showWeeklyPayments: boolean
  multiLineContribution?: boolean
  calc: ContributionsCalculator
}

const CategoryCart: React.FC<CategoryCartProps> = ({ plans, calc, showWeeklyPayments, type, multiLineContribution }) => {
  // Note: will not show plans for other ancillary lines if they don't qualify for the All Ancillary Contribution
  // Fix me if we ever require this
  if ((type === 'ancillary' && multiLineContribution)) {
    const amount = calc.premiumsForAncillary().er
    const dentalPlans = plans.filter(p => typeOfPlan(p) === GroupPlanType.dental)
    const visionPlans = plans.filter(p => typeOfPlan(p) === GroupPlanType.vision)
    const supPlans = plans.filter(p => [GroupPlanType.accident, GroupPlanType.cancer, GroupPlanType.criticalIllness, GroupPlanType.hospital, GroupPlanType.std].includes(typeOfPlan(p)))

    return <div className={styles.allAncillaryCart}>
      <h4>Your Plan Selections</h4>
      {dentalPlans.length > 0 &&
      <React.Fragment key={'AllAncillary.dental'}>
        <FixedAncPlanContributionTable
          plans={dentalPlans}
          type={'dental'}
          calc={calc}
          showWeeklyPayments={showWeeklyPayments}
          fixed={true}
        />
      </React.Fragment>
      }
      {visionPlans.length > 0 &&
      <React.Fragment key={'AllAnciallary.vision'}>
        <FixedAncPlanContributionTable
          plans={visionPlans}
          type={'vision'}
          calc={calc}
          showWeeklyPayments={showWeeklyPayments}
          fixed={true}
        />
      </React.Fragment>}
      {supPlans.length > 0 &&
      <React.Fragment key={'AllAnciallary.supplemental'}>
        <FixedAncPlanContributionTable
          plans={supPlans}
          type={'worksite'}
          calc={calc}
          showWeeklyPayments={showWeeklyPayments}
          fixed={true}
        />
      </React.Fragment>}

      <div className={styles.categoryCartContainer}>
        <div className={styles.employerMedicalTotal}>Estimated Employer Ancillary {supPlans.length > 0 && 'and Supplemental'} Contribution Total</div>
        <div className={styles.planContributionBar}>
          <div className={styles.cc_name}>Ancillary {supPlans.length > 0 && 'and Supplemental'} Plans</div>
          <div className={styles.cc_cost}>
            {!showWeeklyPayments &&
            <StyledRate rate={amount} precision={calc.precision} period={' Per Month'}/>}
            {showWeeklyPayments &&
              <StyledRate rate={moneyWeekly(amount)} precision={calc.precision} period={' Per Week'}/>}
          </div>
        </div>
        <div className={styles.disclaimer}>* Final price will depend on employee participation and final carrier rates</div>
      </div>
    </div>
  }

  return <div className={styles.categoryCartContainer}>
    <div className={styles.employerMedicalTotal}>Estimated Employer {upperFirst(type)} Contribution Total</div>
    {plans.sort((a, b) => {
      const aminRate = isMedical(a) ? moneyNumber(a.premium.employee.individual) : moneyNumber(a.rate.individual)
      const bminRate = isMedical(b) ? moneyNumber(b.premium.employee.individual) : moneyNumber(b.rate.individual)
      if (aminRate < bminRate) return -1
      if (aminRate > bminRate) return 1
      return 0
    }).map(p => {
      const planType = extractPlanType(p)
      if (type === 'medical' || planType === 'dental' || planType === 'vision') {
        return planCost(p)
      }
    })}
    <div className={styles.cc_disclaimer}>* Final price will depend on employee <br/>participation and final carrier rates</div>
  </div>

  function planCost(plan: PlanUnion) {
    const premiums = calc.premiums([plan], false)
    const amount = premiums.er
    const { carrier, name } = extractPlanNameAndCarrier(plan)
    return <div key={getPlanIDFrom(plan)} className={styles.planContributionBar}>
      <div className={styles.cc_name}><b>{carrier}</b> <span>{name}</span></div>
      <div className={styles.cc_cost}>
        {!showWeeklyPayments &&
          <StyledRate rate={amount} precision={calc.precision} period={' Per Month'}/>}
        {showWeeklyPayments &&
          <StyledRate rate={moneyWeekly(amount)} precision={calc.precision} period={' Per Week'}/>}
      </div>
    </div>
  }
}

export default ERShopPlansContribution
