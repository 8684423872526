import React, { useState, useRef } from 'react'
import { savePDF } from '@progress/kendo-react-pdf'
import AnubisModal from 'Components/Anubis/AnubisModal'
import Error from 'Components/Primitives/Error'
import CandorInput from 'Components/Rudimentary/CandorInput'
import numeral from 'numeral'
import { useAsync } from 'react-use'
import { ContributionSplit, Member } from 'Utilities/Hooks/useStargate'
import useToast from 'Utilities/Hooks/useToast'
import * as api from 'Utilities/pharaoh'
import { Broker, MedicalPlan } from 'Utilities/pharaoh.types'
import ContributionsCalculator, {
  AncillaryPlanUnion,
  Contributions
} from 'Utilities/Plans/ContributionCalculator'
import Proposal, { ProposalGroupInfo, TierCount } from '../Proposal'
import styles from './CommissionsModal.module.scss'
import { downloadExcel, groupFileName } from './CommisionsModal.helpers'

interface Props {
  isOpen: boolean
  onRequestClose: (shouldReload: boolean) => void
  broker: Broker
  group: ProposalGroupInfo
  enrollCount: TierCount
  loadingProposal: (nextValue?: any) => void
  members: Member[]
  contributions: Contributions
  splits: ContributionSplit[]
  showDownloadButtons: boolean
  setShowDownloadButtons: React.Dispatch<React.SetStateAction<boolean>>
}

const CommissionsModal: React.FC<Props> = ({ ...props }) => {
  const [brokerCommission, setBrokerCommission] = useState<string>('$0.00')
  const commissionRef = useRef('')
  const addToast = useToast()
  const { value, error, loading } = useAsync(async() => {
    const medical = await api.v3.groups(props.group.id).plans.GET() as MedicalPlan[]
    const ancillary = await api.v3.groups(props.group.id).plans.options.ancillary.selected() as AncillaryPlanUnion[]
    const renewal = await api.v3.groups(props.group.id).plans.renewalPlans.renewals()
    return ({ proposedMedical: medical, proposedAncillary: ancillary, renewalPlans: renewal })
  })
  if (loading) return null
  if (error) return <Error error={error} />
  const contributions = props.contributions
  const calc = new ContributionsCalculator([...value!.proposedAncillary, ...value!.proposedMedical], contributions, props.splits, props.members, 2)

  props.loadingProposal(false)
  // If you need to edit the proposal replace these styles with the ones below
  // { width: '100%', height: '100%', zIndex: 2 }
  return <AnubisModal
    {...props}
    name={CommissionsModal.name}
    showClose={true}
    styles={{ width: 500, height: 480, overflow: 'hidden' }}
    onRequestClose={() => {
      commissionRef.current = ''
      props.onRequestClose(false)
    }
    }
  >
    {form()}
  </AnubisModal>

  function form() {
    return <div className={styles.mainDiv}>
      <div className={styles.form}>
        <h1>Generate Proposal</h1>
        <p>Plans in your cart will be included in your proposal. In order to make changes to what is included in the proposal, please add or remove plans in your cart.</p>
        <p>In accordance with the Consolidated Appropriations Act, which requires producer compensation to be disclosed to the client, please enter your total commissions earned. The amount entered will be reflected in the proposal under notices. If you would like to skip this step, you may generate a final proposal after plans are selected by your client or disclose your comp using another method.</p>
        <p>Please enter a flat dollar amount.</p>

        {props.showDownloadButtons
          ? <>
            {commissionRef.current ? <div className={styles.compensationText}>{`Producer Compensation: ${commissionRef.current}`}</div> : <div className={styles.compensationText}>You declined to disclose compensation.</div>}
            <div className={styles.submitOptions}>
              <button type='button' className={styles.compensationButton} onClick={() => { downloadProposal(commissionRef.current) }}>Download PDF</button>
              <button type='button' className={styles.compensationButton}
                onClick={() => { downloadExcel(props.broker, props.group, props.enrollCount, value!.proposedMedical, value!.proposedAncillary, value!.renewalPlans, calc) } }>Download Excel</button>
              <button type='button' className={`${styles.reEnterButton} ${styles.noRightMargin}`} onClick={() => {
                commissionRef.current = ''
                props.setShowDownloadButtons(false)
              } }>Re-enter Compensation</button>
            </div></>
          : <>
            <CandorInput
              name='commission'
              type='text'
              placeholder='$0'
              disabled={props.showDownloadButtons}
              onBlur={ event => {
                event.currentTarget.value = numeral(event.currentTarget.value).format('$0,0.00')
                commissionRef.current = numeral(event.currentTarget.value).format('$0,0.00')
                setBrokerCommission(numeral(event.currentTarget.value).format('$0,0.00'))
              } }
            />
            <div className={styles.submitOptions}>
              <button type='button' className={styles.compensationButton} onClick={() => {
                commissionRef.current ? props.setShowDownloadButtons(true) : addToast('Please enter a compensation amount or click "skip"')
              } }>Add Compensation</button>
              <button type='button' className={styles.skipButton} onClick={() => {
                commissionRef.current = ''
                setBrokerCommission('$0.00')
                props.setShowDownloadButtons(true)
              } }>Skip</button>
            </div>
          </>
        }
      </div>
      <div className={styles.proposalContainer} id='ProposalDocument'><Proposal brokerCommission={brokerCommission} {...props} proposedMedical={value!.proposedMedical} proposedAncillary={value!.proposedAncillary} renewalPlans={value!.renewalPlans} members={props.members} calc={calc} /></div>
    </div>

    function downloadProposal(commission : string) {
      if (commission === '') {
        setBrokerCommission('$0.00')
      } else {
        setBrokerCommission(commission)
      }
      const proposalDoc = document.getElementById('ProposalDocument')
      try {
        if (!props.broker.agency) throw new window.Error("Some of your agency's information is incomplete. Please have your principal correct this, or contact support")
        if (proposalDoc) {
          savePDF(proposalDoc, { paperSize: 'Letter', landscape: true, fileName: `proposal-for-${groupFileName(props.group.name)}`, keepTogether: '#UnsplittableProposalComponent', forcePageBreak: '#ProposalPageBreak' })
        }
      } catch (error) {
        addToast(error as Error)
      }
    }
  }
}

export default CommissionsModal
