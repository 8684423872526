import {
  AncillaryMemberPremium, AncillaryPremium,
  EnrollmentStatus,
  MedicalPlan,
  MemberPremium,
  Relationship,
  SampleQuote,
  Tier,
  TieredRates
} from 'Utilities/pharaoh.types'
import { ContributionSplit, Gender, Member, MemberDependent } from 'Utilities/Hooks/useStargate'
import DentalPlan, { isDental } from 'Components/Plans/DentalPlan/index.helpers'
import VisionPlan, { isVision } from 'Components/Plans/VisionPlan/index.helpers'
import LifePlan, { LifeEEPlan, LifeERPlan, isLife, isLifeEE, isLifeER } from 'Components/Plans/LifePlan/index.helpers'
import LTDPlan, { LtdEEPlan, LtdERPlan, isLTD, isLtdEE, isLtdER } from 'Components/Plans/LTDPlan/index.helpers'
import { StdEEPlan, StdERPlan, isStdEE, isStdER } from 'Components/Plans/STDPlan/index.helpers'
import {
  isAccident,
  isCancer,
  isCriticalIllness,
  isHospital,
  isSTD
} from 'Components/Plans/EESupplementalPlans/index.helpers'
import { centsFormat, dollarsFormat, isDollar, isPercentage } from './PlanCostUtils'
import { flatten, meanBy, reduce } from 'lodash'
import numeral from 'numeral'
import { getPlanIDFrom, typeOfPlan } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import moment from 'moment'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import { RateType } from '../../Routes/shop/employer/plans'

enum EnrolleeType {
  employee = 'employee',
  dependant = 'dependant'
}

export enum GroupPlanType {
  medical = 'medical',
  dental = 'dental',
  vision = 'vision',
  disability = 'disability',
  ltdEE = 'ltdEE',
  ltdER = 'ltdER',
  prosper = 'prosper',
  accident = 'accident',
  cancer = 'cancer',
  criticalIllness = 'criticalIllness',
  hospital = 'hospital',
  std = 'std',
  stdEE = 'stdEE',
  stdER = 'stdER',
  life = 'life',
  lifeEE = 'lifeEE',
  lifeER = 'lifeER',
  // groupLife = 'groupLife'
}

export const EEContArray = [GroupPlanType.lifeEE, GroupPlanType.ltdEE, GroupPlanType.stdEE]
export const ERContArray = [GroupPlanType.lifeER, GroupPlanType.ltdER, GroupPlanType.stdER]
export const lifeTypesArray = [GroupPlanType.lifeER, GroupPlanType.lifeEE]

export const LifeDisabilityArray = [GroupPlanType.life, GroupPlanType.std, GroupPlanType.lifeER, GroupPlanType.lifeEE, GroupPlanType.stdER, GroupPlanType.stdEE, GroupPlanType.ltdER, GroupPlanType.ltdEE]

export function isLifeDisability(type: any): boolean {
  return LifeDisabilityArray.includes(type)
}

export type Contributions = {
  baseContributions: BaseContributions
  splitContributions?: SplitContribution[]
  planContributions: GroupPlanContributions[]
  rateType?: RateType
}

type ContributionsPerMemberString = {
  employee: string
  dependant?: string
}

type Contribution = string

export type BaseContributions = {
  medical?: string
  medicalDependent?: string
  medicalEquitable: boolean
  dental?: string
  dentalEquitable: boolean
  vision?: string
  visionEquitable: boolean
  life?: string
  lifeEquitable: boolean
  disability?: string
  disabilityEquitable: boolean
  majorCancer?: string
  majorCancerEquitable: boolean
  accident?: string
  accidentEquitable: boolean
  allAncillary?: string
  lifeEE?: string
  lifeER?: string
}

type SplitContribution = {
  id: string
  baseContributions: BaseContributions
  planContributions: GroupPlanContributions[]
}

export type GroupPlanContributions = {
  id?: string
  minRate: number
  groupID: string
  groupPlanID: string
  splitID?: string
  planType: GroupPlanType
  [Tier.individual]?: string
  [Tier.couple]?: string
  [Tier.singleParent]?: string
  [Tier.family]?: string
  [EnrolleeType.employee]?: string
}

type Premium = {
  total: number
  er: number
  ee: number
}

export type MemberPlus = Member & {
  ssn?: string
  medical?: PlanUnion
  dental?: PlanUnion
  vision?: PlanUnion
  life?: PlanUnion
  lifeEE?: PlanUnion
  lifeER?: PlanUnion
  disability?: PlanUnion
  ltdEE?: PlanUnion
  ltdER?: PlanUnion
  accident?: PlanUnion
  cancer?: PlanUnion
  criticalIllness?: PlanUnion
  hospital?: PlanUnion
  std?: PlanUnion
  stdEE?: PlanUnion
  stdER?: PlanUnion
  prosper?: string
  hireDate?: Date
  terminationDate?: Date
  policyIDs: {
    medical?: { policyID?: string, memberID?: string }
    dental?: { policyID?: string, memberID?: string }
    vision?: { policyID?: string, memberID?: string }
    life?: { policyID?: string, memberID?: string }
    lifeEE?: { policyID?: string, memberID?: string }
    lifeER?: { policyID?: string, memberID?: string }
    disability?: { policyID?: string, memberID?: string }
    ltdEE?: { policyID?: string, memberID?: string }
    ltdER?: { policyID?: string, memberID?: string }
    cancer?: { policyID?: string, memberID?: string }
    criticalIllness?: { policyID?: string, memberID?: string }
    hospital?: { policyID?: string, memberID?: string }
    accident?: { policyID?: string, memberID?: string }
    std?: { policyID?: string, memberID?: string }
    stdEE?: { policyID?: string, memberID?: string }
    stdER?: { policyID?: string, memberID?: string }
  }
}
// GroupLifePlan
export type AncillaryPlanUnion = {
  isRenewal: boolean
  rate: TieredRates
  policyId?: string
  memberIds?: [{ memberID: string, externalID: string }]
  memberPremiums?: AncillaryMemberPremium
  sampleQuote?: SampleQuote
  plan: DentalPlan | VisionPlan | LifePlan | LifeEEPlan | LifeERPlan | LTDPlan | LtdEEPlan | LtdERPlan | StdEEPlan | StdERPlan | SupplementalPlan
}

export type PlanUnion = AncillaryPlanUnion | MedicalPlan
type NumberMemberPremium = {
  [EnrolleeType.employee]: number
  [EnrolleeType.dependant]?: number
}

type PlansList = {
  [GroupPlanType.medical]: MedicalPlan[]
  [GroupPlanType.dental]: AncillaryPlanUnion[]
  [GroupPlanType.vision]: AncillaryPlanUnion[]
  [GroupPlanType.life]: AncillaryPlanUnion[]
  [GroupPlanType.lifeEE]: AncillaryPlanUnion[]
  [GroupPlanType.lifeER]: AncillaryPlanUnion[]
  [GroupPlanType.disability]: AncillaryPlanUnion[]
  [GroupPlanType.ltdEE]: AncillaryPlanUnion[]
  [GroupPlanType.ltdER]: AncillaryPlanUnion[]
  [GroupPlanType.std]: AncillaryPlanUnion[]
  [GroupPlanType.stdEE]: AncillaryPlanUnion[]
  [GroupPlanType.stdER]: AncillaryPlanUnion[]
  [GroupPlanType.accident]: AncillaryPlanUnion[]
  [GroupPlanType.cancer]: AncillaryPlanUnion[]
  [GroupPlanType.criticalIllness]: AncillaryPlanUnion[]
  [GroupPlanType.hospital]: AncillaryPlanUnion[]
};

export default class ContributionsCalculator {
  plans: PlansList

  contributions: Contributions
  splits: ContributionSplit[]
  members: Member[]
  nonWaivedMembers: Member[]
  precision: number
  applyCustomPlanContributions: boolean // Use this if you want to ignore any applicable customPlanContributions

  constructor(plans: PlanUnion[], contributions: Contributions, splits: ContributionSplit[], members: Member[], precision = 0, applyCustomPlanContributions = true) {
    contributions.baseContributions.lifeER = '100%'
    contributions.baseContributions.lifeEE = '0%'
    if (contributions.splitContributions) {
      contributions.splitContributions.map(sc => {
        sc.baseContributions.lifeER = '100%'
        sc.baseContributions.lifeEE = '0%'
        return sc
      })
    }
    this.contributions = contributions
    this.splits = splits
    this.members = members
    this.nonWaivedMembers = members?.filter(m => !m.is_waived)
    this.precision = precision
    this.applyCustomPlanContributions = applyCustomPlanContributions

    const ancillaryPlans = plans.filter(isAncillaryPlanUnion) as AncillaryPlanUnion[]

    this.plans = {
      medical: plans.filter(isMedical) as MedicalPlan[],
      dental: ancillaryPlans.filter(p => isDental(p.plan)),
      vision: ancillaryPlans.filter(p => isVision(p.plan)),
      life: ancillaryPlans.filter(p => isLife(p.plan)),
      lifeER: ancillaryPlans.filter(p => isLifeER(p.plan)),
      lifeEE: ancillaryPlans.filter(p => isLifeEE(p.plan)),
      disability: ancillaryPlans.filter(p => isLTD(p.plan)),
      std: ancillaryPlans.filter(p => isSTD(p.plan)),
      stdEE: ancillaryPlans.filter(p => isStdEE(p.plan)),
      stdER: ancillaryPlans.filter(p => isStdER(p.plan)),
      ltdEE: ancillaryPlans.filter(p => isLtdEE(p.plan)),
      ltdER: ancillaryPlans.filter(p => isLtdER(p.plan)),
      accident: ancillaryPlans.filter(p => isAccident(p.plan)),
      cancer: ancillaryPlans.filter(p => isCancer(p.plan)),
      criticalIllness: ancillaryPlans.filter(p => isCriticalIllness(p.plan)),
      hospital: ancillaryPlans.filter(p => isHospital(p.plan))
    }
  }

  isAgeBanded = () => {
    // backward compatible
    // if rateType is not set then its composite
    if (!this.contributions.rateType) {
      return false
    }
    return this.contributions.rateType === RateType.ageBanded
  }

  isComposite = () => {
    // backward compatible
    // if rateType is not set then its composite
    if (!this.contributions.rateType) {
      return true
    }
    return this.contributions.rateType === RateType.composite
  }

  baseContributions = (plan: PlanUnion, split?: ContributionSplit): BaseContributions => {
    if (isAncillaryPlanUnion(plan) || !split) return this.contributions.baseContributions
    const base = this.contributions.splitContributions?.find(s => s.id === split?.id)?.baseContributions
    const defaultBase = {
      medical: '0%',
      medicalEquitable: false,
      dentalEquitable: false,
      visionEquitable: false,
      lifeEquitable: false,
      disabilityEquitable: false,
      majorCancerEquitable: false,
      accidentEquitable: false
    }

    return base || defaultBase
  }

  premiums = (plans: PlanUnion[], onlyNonSplitMembers = true, members = this.nonWaivedMembers, split?: ContributionSplit): Premium => {
    const premiumForMemberForPlan = (member: Member, plan: PlanUnion): number => {
      // reduce is going to add .employee and .dependant together
      return reduce(this.memberPremiumForPlan(member, plan), function(sum, next) {
        return sum + (next || 0)
      }, 0)
    }

    members = this.filterSplits(members, split, onlyNonSplitMembers)
    if (!members?.length) return this.premium(0, 0)

    const total = this.sum(flatten(members.map(m => plans.map(p => premiumForMemberForPlan(m, p)))))
    const er = this.sum(flatten(members.map(m => plans.map(p => this.applyContributionForMemberForPlan(m, p)))))
    return this.premium(total, er)
  }

  avgPremiums = (plans: PlanUnion[], onlyNonSplitMembers = true, members = this.nonWaivedMembers, split?: ContributionSplit): Premium => {
    if (!members?.length) {
      return this.premium(0, 0)
    }
    const premium = this.premiums(plans, onlyNonSplitMembers, members, split)
    const total = premium.total / members.length
    const er = premium.er / members.length
    return this.premium(total, er)
  }

  premiumsForMedical = (split?: ContributionSplit, onlyNonSplitMembers = true): Premium => {
    if (!this.plans.medical.length) {
      return this.premium(0, 0)
    }
    const premium = this.premiums(this.plans.medical, onlyNonSplitMembers, this.nonWaivedMembers, split)
    const total = premium.total / this.plans.medical.length
    const er = premium.er / this.plans.medical.length
    return this.premium(total, er)
  }

  // TODO use `allAncillaryContributionEligibleLines` here
  premiumsForAncillary = () => {
    const { allAncillary } = this.contributions.baseContributions

    if (allAncillary) {
      const premiums = this.members.map(m => {
        const dental = this.plans.dental.map(p => this.premiums([p], false, [m]))
        const vision = this.plans.vision.map(p => this.premiums([p], false, [m]))
        const life = this.plans.life.map(p => this.premiums([p], false, [m]))
        const disability = this.plans.disability.map(p => this.premiums([p], false, [m]))
        ///
        const accident = this.plans.accident.map(p => this.premiums([p], false, [m]))
        const cancer = this.plans.cancer.map(p => this.premiums([p], false, [m]))
        const criticalIllness = this.plans.criticalIllness.map(p => this.premiums([p], false, [m]))
        const hospital = this.plans.hospital.map(p => this.premiums([p], false, [m]))
        const std = this.plans.std.map(p => this.premiums([p], false, [m]))

        let eligibleTotal = meanBy(dental, p => p.total) || 0
        eligibleTotal += meanBy(vision, p => p.total) || 0
        eligibleTotal += meanBy(accident, p => p.total) || 0
        eligibleTotal += meanBy(cancer, p => p.total) || 0
        eligibleTotal += meanBy(criticalIllness, p => p.total) || 0
        eligibleTotal += meanBy(hospital, p => p.total) || 0
        eligibleTotal += meanBy(std, p => p.total) || 0

        let total = eligibleTotal
        total += meanBy(life, p => p.total) || 0
        total += meanBy(disability, p => p.total) || 0
        /// eligibleTotal
        let er = eligibleTotal < moneyNumber(allAncillary, this.precision) ? eligibleTotal : moneyNumber(allAncillary, this.precision)
        er += meanBy(life, p => p.er) || 0
        er += meanBy(disability, p => p.er) || 0
        ///
        er += meanBy(accident, p => p.er) || 0
        er += meanBy(cancer, p => p.er) || 0
        er += meanBy(criticalIllness, p => p.er) || 0
        er += meanBy(hospital, p => p.er) || 0
        er += meanBy(std, p => p.er) || 0
        ///
        return this.premium(total, er)
      })
      return this.premium(this.sum(premiums.map(p => p.total)), this.sum(premiums.map(p => p.er)))
    }

    const dental = this.plans.dental.map(p => this.premiums([p], false, this.members))
    const vision = this.plans.vision.map(p => this.premiums([p], false, this.members))
    const life = this.plans.life.map(p => this.premiums([p], false, this.members))
    const disability = this.plans.disability.map(p => this.premiums([p], false, this.members))
    ///
    const accident = this.plans.accident.map(p => this.premiums([p], false, this.members))
    const cancer = this.plans.cancer.map(p => this.premiums([p], false, this.members))
    const criticalIllness = this.plans.criticalIllness.map(p => this.premiums([p], false, this.members))
    const hospital = this.plans.hospital.map(p => this.premiums([p], false, this.members))
    const std = this.plans.std.map(p => this.premiums([p], false, this.members))

    let total = meanBy(dental, p => p.total) || 0
    total += meanBy(vision, p => p.total) || 0
    total += meanBy(life, p => p.total) || 0
    total += meanBy(disability, p => p.total) || 0
    total += meanBy(accident, p => p.total) || 0
    total += meanBy(cancer, p => p.total) || 0
    total += meanBy(criticalIllness, p => p.total) || 0
    total += meanBy(hospital, p => p.total) || 0
    total += meanBy(std, p => p.total) || 0

    let er = meanBy(dental, p => p.er) || 0
    er += meanBy(vision, p => p.er) || 0
    er += meanBy(life, p => p.er) || 0
    er += meanBy(disability, p => p.er) || 0
    er += meanBy(accident, p => p.er) || 0
    er += meanBy(cancer, p => p.er) || 0
    er += meanBy(criticalIllness, p => p.er) || 0
    er += meanBy(hospital, p => p.er) || 0
    er += meanBy(std, p => p.er) || 0

    return this.premium(total, er)
  }

  premiumsForDisability = () => {
    const life = this.plans.lifeER.map(p => this.premiums([p], false, this.members))
    const ltd = this.plans.ltdER.map(p => this.premiums([p], false, this.members))
    const std = this.plans.stdER.map(p => this.premiums([p], false, this.members))

    let total = meanBy(ltd, p => p.total) || 0
    total += meanBy(life, p => p.total) || 0
    total += meanBy(std, p => p.total) || 0

    let er = meanBy(ltd, p => p.er) || 0
    er += meanBy(life, p => p.er) || 0
    er += meanBy(std, p => p.er) || 0

    return this.premium(total, er)
  }

  rateForPlanAllTiresWithContributions = (plan: PlanUnion, split?: ContributionSplit) => {
    return {
      [Tier.individual]: this.rateForPlanForTireWithContributions(plan, Tier.individual, split),
      [Tier.singleParent]: this.rateForPlanForTireWithContributions(plan, Tier.singleParent, split),
      [Tier.couple]: this.rateForPlanForTireWithContributions(plan, Tier.couple, split),
      [Tier.family]: this.rateForPlanForTireWithContributions(plan, Tier.family, split)
    }
  }

  rateForPlanForTireWithContributions = (plan: PlanUnion, tier: Tier, split?: ContributionSplit): Premium => {
    const rate = this.rateByTierForPlan(plan, tier, this.precision).employee
    const contribution = this.contributionForTierForPlan(tier, plan, split)
    const er = this.calculateContribution(contribution, rate)
    return {
      er: er,
      ee: rate - er,
      total: rate
    }
  }

  premiumsForPlanForTier = (plan: PlanUnion, tier: Tier, split?: ContributionSplit, onlyNonSplitMembers = true): Premium => {
    const members = this.nonWaivedMembers?.filter(m => m.tier === tier)
    return this.premiums([plan], onlyNonSplitMembers, members, split)
  }

  avgPremiumsForPlanForTier = (plan: PlanUnion, tier: Tier, split?: ContributionSplit, onlyNonSplitMembers = true): Premium => {
    const members = this.filterSplits(this.nonWaivedMembers?.filter(m => m.tier === tier), split, onlyNonSplitMembers)
    const premiums = this.premiumsForPlanForTier(plan, tier, split, onlyNonSplitMembers)
    const quotient = Math.max(1, members?.length)
    return this.premium(premiums.total / quotient, premiums.er / quotient)
  }

  premiumsFor = (member: MemberPlus) => {
    if (!this.members.some(m => m.id === member.id)) {
      console.error('Member not found in members roster')
      return
    }
    const dentalCost = member.dental ? this.premiums([member.dental], false, [member]).total : 0
    const visionCost = member.vision ? this.premiums([member.vision], false, [member]).total : 0
    const accidentCost = member.accident ? this.premiums([member.accident], false, [member]).total : 0
    const cancerCost = member.cancer ? this.premiums([member.cancer], false, [member]).total : 0
    const criticalIllnessCost = member.criticalIllness ? this.premiums([member.criticalIllness], false, [member]).total : 0
    const hospitalCost = member.hospital ? this.premiums([member.hospital], false, [member]).total : 0
    const stdCost = member.std ? this.premiums([member.std], false, [member]).total : 0
    let trigger = true
    let fixedAncAmount = 0

    const premiumForType = (member: MemberPlus, plan?: PlanUnion): Premium | undefined => {
      if (!plan) return
      const planType = typeOfPlan(plan)
      const { allAncillary } = this.contributions.baseContributions
      if (allAncillaryAndSupplementalPlans().has(planType) && allAncillary) {
        const contribution = moneyNumber(allAncillary, this.precision)
        if (trigger === true) {
          trigger = false
          fixedAncAmount = contribution
        }
        let premium
        switch (planType) {
        case GroupPlanType.dental:
          premium = this.premium(dentalCost, Math.max(0, Math.min(fixedAncAmount, dentalCost)))
          fixedAncAmount -= dentalCost
          return premium
        case GroupPlanType.vision:
          premium = this.premium(visionCost, Math.max(0, Math.min(fixedAncAmount, visionCost)))
          fixedAncAmount -= visionCost
          return premium
        case GroupPlanType.accident:
          premium = this.premium(accidentCost, Math.max(0, Math.min(fixedAncAmount, accidentCost)))
          fixedAncAmount -= accidentCost
          return premium
        case GroupPlanType.cancer:
          premium = this.premium(cancerCost, Math.max(0, Math.min(fixedAncAmount, cancerCost)))
          fixedAncAmount -= cancerCost
          return premium
        case GroupPlanType.criticalIllness:
          premium = this.premium(criticalIllnessCost, Math.max(0, Math.min(fixedAncAmount, criticalIllnessCost)))
          fixedAncAmount -= criticalIllnessCost
          return premium
        case GroupPlanType.hospital:
          premium = this.premium(hospitalCost, Math.max(0, Math.min(fixedAncAmount, hospitalCost)))
          fixedAncAmount -= hospitalCost
          return premium
        case GroupPlanType.std:
          premium = this.premium(stdCost, Math.max(0, Math.min(fixedAncAmount, stdCost)))
          fixedAncAmount -= stdCost
          return premium
          /// TODO
        default:
          break
        }
      }
      return this.premiums([plan], false, [member])
    }

    return {
      medical: premiumForType(member, member.medical),
      dental: premiumForType(member, member.dental),
      vision: premiumForType(member, member.vision),
      life: premiumForType(member, member.life),
      lifeEE: premiumForType(member, member.lifeEE),
      lifeER: premiumForType(member, member.lifeER),
      disability: premiumForType(member, member.disability),
      ltdEE: premiumForType(member, member.ltdEE),
      ltdER: premiumForType(member, member.ltdER),
      accident: premiumForType(member, member.accident),
      cancer: premiumForType(member, member.cancer),
      std: premiumForType(member, member.std),
      stdEE: premiumForType(member, member.stdEE),
      stdER: premiumForType(member, member.stdER),
      criticalIllness: premiumForType(member, member.criticalIllness),
      hospital: premiumForType(member, member.hospital),
      prosper: member.medical ? this.premium(0, 0) : member.prosper ? this.premium(moneyNumber(member.prosper, this.precision), 0) : undefined
    }
  }

  numberOf = (type: Tier, split?: ContributionSplit, onlyNonSplitMembers = true) => {
    const members = this.filterSplits(this.nonWaivedMembers, split, onlyNonSplitMembers)
    return members.filter(m => m.tier === type).length || 0
  }

  filterSplits = (members: Member[], split?: ContributionSplit, onlyNonSplitMembers = true) => {
    if (split) return members?.filter(m => split.members.some(sm => sm === m.id))
    if (onlyNonSplitMembers) return members?.filter(m => !this.splits.some(s => s.members.some(sm => sm === m.id)))
    return members
  }

  getMemberSplit = (member: Member): ContributionSplit | undefined => {
    return this.splits.find(sp => sp.members.includes(member.id))
  }

  /**
   * @private
   */
  memberPremiumForPlan = (member: Member, plan: PlanUnion): NumberMemberPremium => {
    const precision = this.precision

    if (!isMedical(plan)) {
      if ([GroupPlanType.lifeEE, GroupPlanType.lifeER, GroupPlanType.ltdEE, GroupPlanType.ltdER, GroupPlanType.stdEE, GroupPlanType.stdER].includes(plan.plan.type) && plan?.memberPremiums) {
        const mp = plan.memberPremiums.premiums.find(mp => mp.memberID === member.id)
        if (mp) {
          if (numeral(mp.insured_premium).value() === 0 && plan?.sampleQuote) {
            return { employee: moneyNumber(plan?.sampleQuote.rate, this.precision) }
          }
          return { employee: moneyNumber(mp.insured_premium, this.precision) }
        } else {
          console.error('Member not found for Life/Disability calculation', member)
        }
      }
      return rateForMemberTier(plan.rate)
    }

    const mp = plan.memberPremiums?.premiums.find(mp => mp.memberID === member.id) || plan?.memberPremium
    if (this.isAgeBanded() && mp) {
      return {
        employee: moneyNumber(mp.insured_premium, this.precision),
        // eslint-disable-next-line camelcase
        dependant: mp?.dependant_premium ? moneyNumber(mp.dependant_premium, this.precision) : moneyNumber(0, this.precision)
      }
    }

    return rateForMemberTier(plan.premium.employee)

    function rateForMemberTier(rates: TieredRates): NumberMemberPremium {
      return { employee: moneyNumber(rates[memberTier(member)], precision) }
    }
  }

  rateByTierForPlan = (plan: PlanUnion, tier: Tier, precision = 0): NumberMemberPremium => {
    if (!isMedical(plan)) {
      return rateForMemberTier(plan.rate)
    }
    return rateForMemberTier(plan.premium.employee)

    function rateForMemberTier(rates: TieredRates): NumberMemberPremium {
      return { employee: moneyNumber(rates[tier], precision) }
    }
  }

  contributionForMemberForPlan = (member: Member, plan: PlanUnion, dependant = false): Contribution => {
    const planType = typeOfPlan(plan)
    const split = planType === GroupPlanType.medical ? this.splits.find(s => s.members.some(m => m === member.id)) : undefined

    return this.contributionForTierForPlan(member.tier, plan, split, dependant)
  }

  contributionForTierForPlan = (tier: Tier, plan: PlanUnion, split?: ContributionSplit, dependant = false): Contribution => {
    const planType = typeOfPlan(plan)
    const planID = getPlanIDFrom(plan)
    const sc = this.contributions.splitContributions?.find(s => s.id === split?.id)
    const bc = this.baseContributions(plan, split)
    const gpc = split ? sc?.planContributions || [] : this.contributions.planContributions
    const custom = this.applyCustomPlanContributions ? gpc.find(p => p.groupPlanID === planID) : undefined
    // if the line is eligible for All Ancillary, the amount ER Share is $0 because the amount is dependent on other lines
    // That math is done in `premiumsForAncillary`
    if (bc.allAncillary && allAncillaryContributionEligibleLines().has(planType)) return '$0'

    let contribution: Contribution

    switch (planType) {
    case GroupPlanType.lifeEE:
    case GroupPlanType.ltdEE:
    case GroupPlanType.stdEE:
      contribution = this.mangleContribution(tier, '0%')
      break
    case GroupPlanType.lifeER:
    case GroupPlanType.ltdER:
    case GroupPlanType.stdER:
      contribution = this.mangleContribution(tier, '100%')
      break
    case GroupPlanType.medical:
      if (!dependant) {
        contribution = this.mangleContribution(tier, bc?.medical, custom)
      } else {
        contribution = this.mangleContribution(tier, bc?.medicalDependent, custom)
      }
      break
    case GroupPlanType.dental:
      contribution = this.mangleContribution(tier, bc?.dental, custom, bc?.dentalEquitable, plan)
      break
    case GroupPlanType.vision:
      contribution = this.mangleContribution(tier, bc?.vision, custom, bc?.visionEquitable, plan)
      break
    case GroupPlanType.life:
      contribution = this.mangleContribution(tier, bc?.life, custom)
      break
    case GroupPlanType.disability:
      contribution = this.mangleContribution(tier, bc?.disability, custom)
      break
    default:
      contribution = '$0'
      break
    }

    return contribution
  }

  mangleContribution = (tier: Tier, base = '0%', custom?: GroupPlanContributions, equitable?: boolean | undefined, plan?: PlanUnion): Contribution => {
    // Need to do this so we know we "have" a custom group plan contribution on the `/contributions` page
    const hasCustom = shouldApplyCustom(custom, base, equitable)

    // base contribution for the class
    if (!hasCustom) {
      if (equitable === false && isPercentage(base) && plan && isAncillaryPlanUnion(plan)) {
        return moneyString(this.percent(base, moneyNumber(plan.rate.individual, this.precision)), this.precision)
      } else {
        return base
      }
    }

    switch (tier) {
    case Tier.individual:
      return custom?.individual || base
    case Tier.singleParent:
      return custom?.singleParent || base
    case Tier.couple:
      return custom?.couple || base
    case Tier.family:
      return custom?.family || base
    }
  }

  contributionForMemberPerPlanString = (member: Member, plan: PlanUnion): ContributionsPerMemberString => {
    const planType = typeOfPlan(plan)
    const contribution = this.contributionForMemberForPlan(member, plan)

    if (planType !== GroupPlanType.medical || this.isComposite()) {
      return { employee: contribution }
    } else {
      const contributionDependant = this.contributionForMemberForPlan(member, plan, true)
      return {
        employee: contribution,
        dependant: contributionDependant
      }
    }
  }

  applyContributionForMemberForPlan = (member: Member, plan: PlanUnion) => {
    const planType = typeOfPlan(plan)
    const contribution = this.contributionForMemberForPlan(member, plan)
    const memberPremium = this.memberPremiumForPlan(member, plan)
    let amountContributed: number

    let total = memberPremium.employee
    amountContributed = this.calculateContribution(contribution, memberPremium.employee)

    if (planType !== GroupPlanType.medical || this.isComposite()) {
      return commonReturn()
    } else {
      const contributionDependant = this.contributionForMemberForPlan(member, plan, true)

      if (hasDependents(member) && memberPremium.dependant) {
        total = total + memberPremium.dependant
        amountContributed = amountContributed + this.calculateContribution(contributionDependant, memberPremium.dependant)
      }
      return commonReturn()
    }

    function commonReturn() {
      if (amountContributed < 0) amountContributed = 0
      if (total < amountContributed) return total
      return amountContributed
    }
  }

  calculateContribution(contribution: string | undefined, rate: number) {
    if (isDollar(contribution)) {
      const amountContributed = numeral(contribution).value()
      if (amountContributed < 0) return 0
      return amountContributed > rate ? rate : amountContributed
    } else {
      return this.percent(contribution, rate)
    }
  }

  premiumBreakdownForMemberForPlan = (member: Member, plan: PlanUnion): { member: Premium, dependant?: Premium } => {
    const planType = typeOfPlan(plan)
    const contribution = this.contributionForMemberForPlan(member, plan)
    const memberPremium = this.memberPremiumForPlan(member, plan)
    const total = memberPremium.employee
    let amountContributed: number

    if (planType !== GroupPlanType.medical || this.isComposite()) {
      amountContributed = this.calculateContribution(contribution, memberPremium.employee)

      return {
        member: {
          er: amountContributed,
          ee: total - amountContributed,
          total: total
        }
      }
    } else {
      const totalDependant = memberPremium.dependant || 0
      const contributionDependant = this.contributionForMemberForPlan(member, plan, true)
      let amountContributedDependant = 0

      amountContributed = this.calculateContribution(contribution, memberPremium.employee)
      amountContributedDependant = this.calculateContribution(contributionDependant, totalDependant)

      return {
        member: {
          er: amountContributed,
          ee: total - amountContributed,
          total: total
        },
        dependant: {
          er: amountContributedDependant,
          ee: totalDependant - amountContributedDependant,
          total: totalDependant
        }
      }
    }
  }

  percent = (amount = '0%', premium = 0) => {
    if (!isPercentage(amount)) amount = '0%'
    return numeral(amount).multiply(premium).value()
  }

  sum = (nums: (number | undefined)[]) => reduce(nums, (total, next) => total + (next || 0), 0)

  premium = (total: number, er: number): Premium => ({
    total,
    er,
    ee: total - er
  })

  hasCustomPlanContributionFor = (plan: PlanUnion) => {
    return this.applyCustomPlanContributions && hasCustomPlanContributionFor(plan, this.contributions)
  }

  customPlanContributionFor = (plan: PlanUnion, split?: ContributionSplit) => {
    const id = getPlanIDFrom(plan)
    if (split) {
      const sc = this.contributions.splitContributions?.find(sc => sc.id === split.id)
      const spc = sc?.planContributions.find(spc => spc.groupPlanID === id)
      return spc
    }
    return this.contributions?.planContributions?.find(pc => pc.groupPlanID === id)
  }

  fakeMemberForTier = (tier: Tier): Member => {
    const fakeMember: Member = {
      id: 'a',
      name: 'Fake Name',
      email: 'fake.email@yopmail.com',
      dependents: [],
      medical_underwriting_complete: true,
      master_mq_form_complete: true,
      enrollmentStatus: EnrollmentStatus.complete,
      tier,
      is_waived: false
    }
    const fakeSpouse: MemberDependent = {
      id: 'a',
      firstName: '',
      lastName: '',
      gender: Gender.female,
      dateOfBirth: moment('1/1/1980').toDate(),
      relationship: Relationship.spouse
    }
    const fakeChild = {
      id: 'a',
      firstName: '',
      lastName: '',
      gender: Gender.female,
      dateOfBirth: moment('1/1/2020').toDate(),
      relationship: Relationship.child
    }
    switch (tier) {
    case Tier.individual:
      break
    case Tier.couple:
      fakeMember.dependents.push(fakeSpouse)
      break
    case Tier.singleParent:
      fakeMember.dependents.push(fakeChild)
      break
    case Tier.family:
      fakeMember.dependents.push(fakeSpouse)
      fakeMember.dependents.push(fakeChild)
      break
    }
    return fakeMember
  }
}

export function isAncillaryPlanUnion(obj: PlanUnion): obj is AncillaryPlanUnion {
  return !!(obj as any)?.plan
}

export function isMedical(obj: PlanUnion): obj is MedicalPlan {
  return !isAncillaryPlanUnion(obj)
}

export function isMemberPremiumArray(obj: MemberPremium[] | AncillaryPremium[]): obj is MemberPremium[] {
  return !(obj[0] as any)?.premiums
}

export function moneyString(rawInput: string | number | undefined, precision = 0) {
  if (rawInput === 0) {
    return '$0'
  }
  if (rawInput === 'N/A') return rawInput
  const value = moneyNumber(rawInput, precision)
  const format = precision === 0 ? dollarsFormat : centsFormat
  const outputStr = numeral(value).format(format)

  return outputStr
}

export function moneyWeekly(rawInput: string | number | undefined, precision = 0) {
  return moneyString(moneyNumber(rawInput, precision) * 12 / 52, precision)
}

export function moneyNumber(rawInput: string | number | undefined, precision = 0): number {
  if (rawInput === undefined || rawInput === null || rawInput === '') return 0
  let value
  const tenPower = Math.pow(10, precision)
  value = numeral(rawInput).multiply(tenPower).value()
  value = Math.ceil(value) / tenPower
  return value
}

export function contributionFor(type: GroupPlanType, base?: BaseContributions) {
  return {
    contribution: base ? (base as any)[`${type}`] : '0%',
    equitable: base ? (base as any)[`${type}Equitable`] : false
  }
}

export function allAncillaryContributionEligibleLines() {
  return new Set([GroupPlanType.dental, GroupPlanType.vision])
}

export function allSupplementalPlans() {
  return new Set([GroupPlanType.accident, GroupPlanType.cancer, GroupPlanType.criticalIllness, GroupPlanType.hospital, GroupPlanType.std])
}

function allAncillaryAndSupplementalPlans() {
  return new Set([GroupPlanType.dental, GroupPlanType.vision, GroupPlanType.accident, GroupPlanType.cancer, GroupPlanType.criticalIllness, GroupPlanType.hospital, GroupPlanType.std])
}

export function hasCustomPlanContributionFor(plan: PlanUnion, contributions: Contributions) {
  const id = getPlanIDFrom(plan)
  if (isAncillaryPlanUnion(plan) && contributions.baseContributions.allAncillary) return false
  const basePC = contributions?.planContributions?.find(pc => pc.groupPlanID === id)
  if (basePC) {
    const base = contributionFor(typeOfPlan(plan), contributions.baseContributions)
    return shouldApplyCustom(basePC, base.contribution, base.equitable)
  }

  const pcs = (contributions?.splitContributions || []).map(sc => {
    const base = contributionFor(typeOfPlan(plan), sc.baseContributions)
    const scp = sc.planContributions.find(scp => scp.groupPlanID === id)
    return shouldApplyCustom(scp, base.contribution, base.equitable)
  })
  return pcs.some(pc => !!pc)
}

export function getTotalFromMemberPremium(memberPremiums: AncillaryMemberPremium | undefined) {
  if (!memberPremiums?.premiums) {
    return '0'
  }
  let total = 0
  memberPremiums.premiums.forEach((premium) => {
    const cost = numeral(premium.insured_premium).value()
    total = total + cost
  })
  return moneyNumber(total, 2)
}

export function getMinMaxFromMemberPremiums(memberPremiums: AncillaryMemberPremium | undefined) {
  if (!memberPremiums?.premiums) {
    return ['$0', '$0']
  }
  let min = 999999
  let max = 0
  memberPremiums.premiums.forEach((premium) => {
    const cost = numeral(premium.insured_premium).value()
    if (cost < min) {
      min = cost
    }
    if (cost > max) {
      max = cost
    }
  })
  return [moneyString(min, 2), moneyString(max, 2)]
}

function memberTier(member: Member): Tier {
  if (hasSpouse(member) && hasChildren(member)) {
    return Tier.family
  }
  if (hasSpouse(member)) {
    return Tier.couple
  }
  if (hasChildren(member)) {
    return Tier.singleParent
  }
  return Tier.individual
}

function hasDependents(member: Member) {
  return hasSpouse(member) || hasChildren(member)
}

function hasSpouse(member: Member) {
  return member.dependents.some(d => d.relationship !== Relationship.child && !d.terminationDate)
}

function hasChildren(member: Member) {
  return member.dependents.some(d => d.relationship === Relationship.child && !d.terminationDate)
}

function shouldApplyCustom(custom?: GroupPlanContributions, base = '0%', baseEquitable = false) {
  let hasCustom = !!custom
  if (hasCustom) {
    if (!baseEquitable && !isDollar(base)) {
      hasCustom = [custom?.individual, custom?.couple, custom?.singleParent, custom?.family].some(c => isPercentage(c))
    } else {
      const contributions = [custom?.individual, custom?.couple, custom?.singleParent, custom?.family]
      if (
        (isDollar(base) && !contributions.some(c => isDollar(c))) ||
        (baseEquitable && !contributions.some(c => isPercentage(c)))
      ) {
        hasCustom = false
      }
    }
  }
  return hasCustom
}

export function formatAllAncillaryInput(input: string) {
  const value = numeral(input).value()
  return value != null && value >= 0 ? input : ''
}
