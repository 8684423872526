import { GlobalStringConstant } from 'GlobalStringConstant'
import React from 'react'
import styles from './EESupplementalSubComp.module.scss'
import ContributionsCalculator, { Contributions, moneyString, moneyWeekly, PlanUnion } from 'Utilities/Plans/ContributionCalculator'
import { ContributionSplit, Member } from 'Utilities/Hooks/useStargate'
import { tierMarketingCopy } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import numeral from 'numeral'

interface Props {
  plan: PlanUnion
  contributions: Contributions
  showWeeklyPayments: boolean
  splits?: ContributionSplit[]
  member: Member
  showAfterEmpCont: boolean
  onlyIndividual?: boolean
}

const EETierRate: React.FC<Props> = ({ plan, contributions, showWeeklyPayments, splits, member, showAfterEmpCont, onlyIndividual }) => {
  const calc = new ContributionsCalculator([plan], contributions, splits || [], [member])
  const premium = calc.premiums([plan], false, [member])

  const ee = premium.ee
  const erPremium = premium.er
  const frequency = showWeeklyPayments ? '/wk' : '/mo'

  return (
    <div className={styles.individual_tier_container}>
      <div className={styles.heading}>
        <label className={styles.individual_tier}>{onlyIndividual ? 'Individual' : `${tierMarketingCopy(member.tier)} Tier`}</label>
      </div>
      <div className={styles.individual_tier_price}>
        <div>
          <div className={styles.eeContprice}>
            <span className={styles.dolar}>{GlobalStringConstant[0].dolar}</span><span className={styles.amount}>{numeral(showWeeklyPayments ? moneyWeekly(ee) : moneyString(ee)).value()}</span><span className={styles.mon}>{frequency}</span>
          </div>
          {showAfterEmpCont && <div className={styles.after_employer_contribution}>{GlobalStringConstant[0].after_employer_contribution}</div>}
        </div>
        <div className={styles.your_employer_contri}>
          <span className={styles.er}>{GlobalStringConstant[0].your_employer_contributes} <em>${erPremium}{frequency}</em> {GlobalStringConstant[0].to_this_plan}</span>
        </div>
      </div>
    </div>
  )
}

export default EETierRate
