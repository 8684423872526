import React from 'react'
import styles from '../index.module.scss'
import { GlobalStringConstant } from 'GlobalStringConstant'

interface Props{
  compName: string
  daysOrWeak: string | any
  label:string
}

const PeriodComp: React.FC<Props> = ({ ...props }) => {
  const { compName, label, daysOrWeak } = props
  const splitDaysOrWeak = daysOrWeak?.split('$').filter((n: string) => n)
  return (
    <table className={styles.box}>
      <thead>
        <tr>
          <th>{compName}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          {label.length > 0
            ? <td className={styles.lump_sum}>
              {splitDaysOrWeak?.map((s: string, i: number) => {
                return (<span key={i}>
                  {(daysOrWeak[0] === GlobalStringConstant[0].dolar && daysOrWeak.length < 30) && <span className={styles.dollar}>{GlobalStringConstant[0].dolar}</span>}
                  <p className={`${styles.fourSpecTableSpan} ${daysOrWeak.length < 30 ? styles.fourSpecRegSize : styles.fourSpecSmallSize}`}>{(daysOrWeak[0] === GlobalStringConstant[0].dolar && daysOrWeak.length > 30) && GlobalStringConstant[0].dolar}{s}</p>
                </span>)
              })}
              <small>{label}</small>
            </td>
            : <td>{daysOrWeak}</td>}
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  )
}

export default PeriodComp
