import React, { useState } from 'react'
import styles from './UploadCensus.module.scss'
import Loader from 'Components/Rudimentary/Loader'
import useToast from 'Utilities/Hooks/useToast'
import { upload } from 'Utilities/fetch++'
import { StargateConfig } from 'Utilities/Hooks/useStargate'
import CensusErrors, { readExcelFile, validateSpreadsheet, ErrorObj } from 'Components/CensusErrors/CensusErrors'

const UploadCensus: React.FC<{callback(census: any): void, config: StargateConfig }> = ({ callback }) => {
  const [disabled, setDisabled] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [errors, setErrors] = useState<ErrorObj[]>([])
  const addToast = useToast()

  const clearErrors = () => {
    setHasErrors(false)
    setErrors([])
  }

  return <><form className={styles.censusUploadForm}>
    { disabled && <div className={styles.loaderContainer}><Loader/></div> }
    <fieldset disabled={disabled}>
      <p>Fill out our <a href="https://public.myhealthily.com/support/census.xlsx" download>template</a>
        , then click the upload button to submit your census</p>
      <label onClick={clearErrors}>
        <span>Upload completed<br/>Census Template</span>
        <input type="file" name='census' disabled={disabled} onChange={parseCensus} />
      </label>
    </fieldset>
  </form>
  <CensusErrors hasErrors={hasErrors} errorsObj={errors}/>
  </>

  async function parseCensus(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist() // or event.target.value = '' fails
    let hasError = false
    const jsonData = await readExcelFile(event.target.files![0])
    const errorsObjArray = validateSpreadsheet(jsonData)

    errorsObjArray.map((r: ErrorObj) => {
      for (const property in r.errors) {
        if (r.errors[property as keyof ErrorObj['errors']].error) {
          setErrors(errorsObjArray)
          if (!hasError) hasError = true
          if (!hasErrors) {
            setHasErrors(true)
          }
        }
      }
    })

    if (!hasError) {
      try {
        setDisabled(true)
        const parsedCensus = await upload('/groups/xlsx', (event.target.files || [])[0])
        event.target.value = '' // or onChange won’t fire for the same file
        callback(parsedCensus)
      } catch (error) {
        addToast(error)
      } finally {
        setDisabled(false)
      }
    }
  }
}

export default UploadCensus
