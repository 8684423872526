import React from 'react'
import { Contributions } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import PlanTitle from '../plan-subcomponents/PlanTitle'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import Plan from '../plan-subcomponents/Plan'
import SupplementalPlan from '../SupplementalPlan/index.helpers'
import PremiumBreakdown, { isEmployerProps, EmployerProps } from '../plan-subcomponents/PremiumBreakdown'
import BenifitAmountComp from './ERSupplementalSubComp/BenifitAmountComp'
import PeriodComp from './ERSupplementalSubComp/PeriodComp'
import { GlobalStringConstant } from 'GlobalStringConstant'
interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan, isRenewal: boolean }
  contributions: Contributions
  selected?: boolean
  label: Label
  showWeeklyPayments: boolean
}

const ABCLifePlan: React.FC< EmployerProps & Props> = (props) => {
  const { plan, selected, label, showWeeklyPayments, contributions } = props
  const sbc = plan.plan.data.sbc
  return <Plan selected={!!selected}>
    <section className={styles.accidentPlan}>
      <div className={styles.FutureContainer}>
        <PlanTitle
          id={plan.plan.id}
          planName={plan.plan.name}
          carrier={plan.plan.carrier}
          selected={selected}
          includesProsperBenefits={false}
          planType={props.plan.plan.type}
          label={label}
          sbc= {sbc}
        />
        <article className={styles.custom_plan}>
          <div className={styles.planBodyContainer}>
            <div className={`${styles.section} ${styles.pbContainer}`}>
              {breakdown()}
            </div>
            <BenifitAmountComp name={GlobalStringConstant[0].benefit_amount} benifitAmount={'10,000'} benifitLabel={' '}/>
            <div className={`${styles.section} ${styles.fourSpecs} ${styles.half_height}`}>
              <PeriodComp compName={GlobalStringConstant[0].supp_ad_and_d_inc} daysOrWeak={'$10,000'} label={' '}/>
              <PeriodComp compName={GlobalStringConstant[0].EAP_serv_pro} daysOrWeak={GlobalStringConstant[0].will_prep} label={' '}/>
            </div>
          </div>
        </article>
      </div>
    </section>
  </Plan>

  function breakdown() {
    const base = { plan, contributions, showWeeklyPayments }
    if (isEmployerProps(props)) {
      return <PremiumBreakdown {...base} members={props.members} />
    }
  }
}

export default ABCLifePlan
