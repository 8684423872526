import React from 'react'
import styles from './EEContributionContainer.module.scss'
import baseStyles from 'Components/Plans/plan-subcomponents/Plan.module.scss'
import eeStyles from 'Components/Plans/plan-subcomponents/EEPremiumBreakdown.module.scss'
import { classNames } from 'Utilities/etc'
import numeral from 'numeral'

interface Props {
  erAmount: string
  frequency: string
  eeAmount: string
  erAmountLeft: string
  showWeeklyPayments: boolean
  border: boolean
}

const EEContributionContainer: React.FC<Props> = ({ erAmount, frequency, eeAmount, erAmountLeft, showWeeklyPayments, border }) => {
  return <div className={classNames(styles.aa_cart, border && styles.border)}>
    <div>
      <h2>Fixed Contribution</h2>
      <p>Your employer is contributing a fixed amount of <span className={styles.semiBold}>{erAmount}{frequency}*</span> to distribute across ancillary and supplemental plans for you and your family.</p>
      <p className={styles.disclaimer}>* This amount does not apply to voluntary term life.</p>
      <h3>Remaining Employer Contribution</h3>
    </div>
    <div className={styles.as_grid}>
      <div className={classNames(baseStyles.section, baseStyles.box, eeStyles.container)}>
        <div className={classNames(eeStyles.tier, styles.periwinkle)}>AMOUNT REMAINING</div>
        <div className={eeStyles.premiums}>
          <div className={classNames(styles.amount, styles.ee) }>
            <span>$</span>
            { numeral(erAmountLeft).value()}
            <span>{showWeeklyPayments ? '/wk' : '/mo'}</span>
          </div>
        </div>
      </div>
      <div className={classNames(baseStyles.section, baseStyles.box, eeStyles.container)}>
        <div className={eeStyles.tier}>YOUR COST</div>
        <div className={eeStyles.premiums}>
          <div className={classNames(eeStyles.ee, styles.darkGreen)}>
            <span>$</span>
            { numeral(eeAmount).value() }
            <span>{showWeeklyPayments ? '/wk' : '/mo'}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default EEContributionContainer
