import React, { useState, useEffect } from 'react'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useAsync, useSetState, useToggle } from 'react-use'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import styles from 'Routes/shop/employer/plans/ancillary/index.module.scss'
import { Label } from 'Utilities/config'
import DentalPlan from 'Components/Plans/DentalPlan'
import VisionPlan from 'Components/Plans/VisionPlan'
import ABCLifePlan from 'Components/Plans/ERSupplementalPlans/ABCLifePlan'
import VTLPlan from 'Components/Plans/ERSupplementalPlans/VTLPlan'
import LifeEE from 'Components/Plans/LifePlan/LifeEE'
import LifeER from 'Components/Plans/LifePlan/LifeER'
import LTDPlan from 'Components/Plans/LTDPlan'
import LTDEEPlan from 'Components/Plans/LTDPlan/LTDEE'
import LTDERPlan from 'Components/Plans/LTDPlan/LTDER'
import AccidentPlanComp from 'Components/Plans/ERSupplementalPlans/AccidentPlanComp'
import CancerPlanComp from 'Components/Plans/ERSupplementalPlans/CancerPlanComp'
import CriticleIllness from 'Components/Plans/ERSupplementalPlans/CriticalIllness'
import HospitalPlan from 'Components/Plans/ERSupplementalPlans/HospitalPlan'
import ShortTermDisability from 'Components/Plans/ERSupplementalPlans/ShortTermDisability'
import StdEE from 'Components/Plans/STDPlan/StdEE'
import StdER from 'Components/Plans/STDPlan/StdER'
import { isABC } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { ProposalGroupInfo } from 'Routes/dashboard/agency/clients/ID/Proposals/Proposal'
import { $enum } from 'ts-enum-util'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { v3 } from 'Utilities/pharaoh'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Tab,
  TabWithSubTabs,
  FilterBar,
  SubTab,
  CarrierButton,
  AncillaryFilterType,
  ParamsObj,
  paramKeys,
  CarrierModal,
  AncillaryGroupPlan,
  BackButton,
  StateObjectType
} from './sub-components/search'
import { GAButton } from 'Components/Tracking'
import { StargateConfig } from 'Utilities/Hooks/useStargate'
import Heading from 'Components/Stargate/Heading'
import { classNames } from 'Utilities/etc'
import ContributionAmount, { ContributionSplitType, contributionType } from 'Components/Stargate/Contribution/ContributionAmount'
import {
  AncillaryPlanUnion,
  getTotalFromMemberPremium,
  GroupPlanType,
  moneyString
} from 'Utilities/Plans/ContributionCalculator'
import { Data as DentalPlanData } from 'Components/Plans/DentalPlan/index.helpers'
import { startCase, uniq, compact } from 'lodash'
import useToast from 'Utilities/Hooks/useToast'
import numeral from 'numeral'
import { Route } from '../../../../../Utilities/Route'

export enum SortType {
  none = 'None',
  premium = 'Premium',
  max = 'Annual Max'
}

const ERShopPlansAncillary: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const { value, loading, error } = useAsync(async() => {
    const p1 = await v3.groups(stargate.group?.id).plans.options.ancillary.GET() as AncillaryPlanUnion[]
    const p2 = await v3.groups(stargate.group?.id).plans.options.ancillary.selected() as AncillaryPlanUnion[]
    return [p1, p2]
  })
  const config = stargate.config

  useFullContentArea()

  if (loading) return <Loader />
  if (error) return <Error error={error} />
  const [plans, selectedPlans] = value!

  const selectedPlanIDs = selectedPlans.map((plan: AncillaryPlanUnion) => plan.plan.id)

  const visibleTypes: Set<GroupPlanType> = new Set((plans || []).map(({ plan }) => plan.type))

  const visibleOrderedTypes = $enum(GroupPlanType).getValues().filter(type => visibleTypes.has(type))
  return <Content
    stargate={stargate}
    onwards={onwards}
    plans={plans}
    selectedPlans={selectedPlans}
    selectedPlanIDs={selectedPlanIDs}
    types={visibleOrderedTypes}
    groupInfo={{ name: stargate.group!.name, id: stargate.group!.id, effectiveDate: stargate.group!.effectiveDate, groupState: stargate.group!.state }}
    config={config} />
}

interface Props extends PrivateWizardPageProps {
  plans: AncillaryPlanUnion[]
  selectedPlanIDs: string[]
  selectedPlans: AncillaryPlanUnion[]
  types: GroupPlanType[]
  groupInfo: ProposalGroupInfo
  config: StargateConfig
}

export enum AncillaryContributionMode {
  perLine,
  allEligibleLines
}

const Content: React.FC<Props> = ({ stargate, onwards, config, plans, selectedPlans, selectedPlanIDs }) => {
  const { members, group, contributions } = stargate
  const contributionMode = AncillaryContributionMode.allEligibleLines
  const [contribution, setContribution] = useSetState(defaultContributions())
  const [disabled, setDisabled] = useToggle(false)
  const addToast = useToast()

  const [selected, setSelected] = useState<string[]>(selectedPlanIDs)
  const hasABC = stargate.selectedCarriers.some(isABC)

  const getTotal = getTotalFromMemberPremium

  const dentalPlans = plans.filter((p) => p.plan.type === GroupPlanType.dental)
  const visionPlans = plans.filter((p) => p.plan.type === GroupPlanType.vision)
  const sortedLtdERPlans = plans.filter((p) => p.plan.type === GroupPlanType.ltdER).sort((a, b) => numeral(a.sampleQuote!.rate).value() > numeral(b.sampleQuote!.rate).value() ? 1 : -1)
  const sortedLtdEEPlans = plans.filter((p) => p.plan.type === GroupPlanType.ltdEE).sort((a, b) => numeral(a.sampleQuote!.rate).value() > numeral(b.sampleQuote!.rate).value() ? 1 : -1)
  const disabilityPlans = [...sortedLtdERPlans, ...sortedLtdEEPlans]
  const sortedLifeERPlans = plans.filter((p) => p.plan.type === GroupPlanType.lifeER).sort((a, b) => getTotal(a.memberPremiums) > getTotal(b.memberPremiums) ? 1 : -1)
  const sortedLifeEEPlans = plans.filter((p) => p.plan.type === GroupPlanType.lifeEE).sort((a, b) => getTotal(a.memberPremiums) > getTotal(b.memberPremiums) ? 1 : -1)
  const lifePlans = [...sortedLifeERPlans, ...sortedLifeEEPlans]
  const stdERPlans = plans.filter((p) => p.plan.type === GroupPlanType.stdER).sort((a, b) => numeral(a.sampleQuote!.rate).value() > numeral(b.sampleQuote!.rate).value() ? 1 : -1)
  const stdEEPlans = plans.filter((p) => p.plan.type === GroupPlanType.stdEE).sort((a, b) => numeral(a.sampleQuote!.rate).value() > numeral(b.sampleQuote!.rate).value() ? 1 : -1)
  const stdPlans = [...stdERPlans, ...stdEEPlans]

  const accidentPlans = plans.filter((p) => p.plan.type === GroupPlanType.accident)
  const cancerPlans = plans.filter((p) => p.plan.type === GroupPlanType.cancer)
  const criticalIllnessPlans = plans.filter((p) => p.plan.type === GroupPlanType.criticalIllness)
  const hospitalPlans = plans.filter((p) => p.plan.type === GroupPlanType.hospital)
  const worksitePlans = [...accidentPlans, ...hospitalPlans, ...criticalIllnessPlans, ...cancerPlans]
  const worksitePlanIDs = worksitePlans.map(p => p.plan.id)
  const dentalCarriersArray: string[] = uniq(dentalPlans.map((p) => p.plan.carrier)).sort()
  const visionCarriersArray: string[] = uniq(visionPlans.map((p) => p.plan.carrier)).sort()
  const disabilityCarriersArray: string[] = uniq(disabilityPlans.map((p) => p.plan.carrier)).sort()
  const lifeCarriersArray: string[] = uniq(lifePlans.map((p) => p.plan.carrier)).sort()
  const accidentCarriersArray: string[] = uniq(accidentPlans.map((p) => p.plan.carrier)).sort()
  const cancerCarriersArray: string[] = uniq(cancerPlans.map((p) => p.plan.carrier)).sort()
  const criticalIllnessCarriersArray: string[] = uniq(criticalIllnessPlans.map((p) => p.plan.carrier)).sort()
  const hospitalCarriersArray: string[] = uniq(hospitalPlans.map((p) => p.plan.carrier)).sort()
  const stdCarriersArray: string[] = uniq(stdPlans.map((p) => p.plan.carrier)).sort()

  const worksiteCarriers: string[] = uniq(worksitePlans.map((p) => p.plan.carrier)).sort()

  const [selectedWorksiteCarrier, setSelectedWorksiteCarrier] = useState<string|null>(null)
  const [clickedWorksiteCarrier, setClickedWorksiteCarrier] = useState<string|null>(null)
  const [selectedTab, setSelectedTab] = useState<AncillaryGroupPlan>(GroupPlanType.dental)
  const [worksiteSelected, setWorksiteSelected] = useState(false)
  const [disabilitySelected, setDisabilitySelected] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const worksiteTabsArray = [GroupPlanType.accident, GroupPlanType.hospital, GroupPlanType.criticalIllness, GroupPlanType.cancer]
  const nonWorksiteTabsArray = [GroupPlanType.dental, GroupPlanType.vision, GroupPlanType.life, GroupPlanType.disability, GroupPlanType.std]
  const tabsOrder = [
    ...nonWorksiteTabsArray,
    ...worksiteTabsArray
  ]

  const updateSelectedPlanIDs = async(carrier: string) => {
    const currentSelectedPlans = await v3.groups(stargate.group?.id).plans.options.ancillary.selected() as AncillaryPlanUnion[]

    try {
      uniq(currentSelectedPlans.filter(p => worksiteTabsArray.includes(p.plan.type))
        .map(p => p.plan.id)).forEach(async p => await v3.groups(stargate.group?.id).plans.options.ancillary.DELETE(p))
    } catch (error) {
      addToast(error as Error)
    }

    const worksitePlansOfCarrierWithLowestPremiums: string[] = []
    worksiteTabsArray.forEach(t => {
      if (getLowestPremiumWorksitePlanByType(t, worksitePlans, carrier)) worksitePlansOfCarrierWithLowestPremiums.push(getLowestPremiumWorksitePlanByType(t, worksitePlans, carrier).plan.id)
    })
    compact(worksitePlansOfCarrierWithLowestPremiums).forEach(async p => await v3.groups(stargate.group?.id).plans.options.ancillary.POST(p))

    setSelected(uniq([...selected.filter(p => !worksitePlans.map(p => p.plan.id).includes(p)), ...worksitePlansOfCarrierWithLowestPremiums]))
  }

  const getLowestPremiumWorksitePlanByType = (type: GroupPlanType, plansObj: AncillaryPlanUnion[], carrier: string) => {
    return sortByPremium(plansObj.filter(p => p.plan.type === type && p.plan.carrier === carrier))[0]
  }

  const configObject = {
    dental: {
      sort: [SortType.premium, SortType.max, SortType.none],
      filters: [
        { title: 'Carriers', data: dentalCarriersArray.map(c => c.toLowerCase()), key: 'carriers' }
      ],
      selectedToTop: false
    },
    vision: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: visionCarriersArray.map(c => c.toLowerCase()), key: 'carriers' }
      ],
      selectedToTop: false
    },
    disability: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: disabilityCarriersArray.map(c => c.toLowerCase()), key: 'carriers' },
        { title: 'Contribution Type', data: ['voluntary', 'employer paid'], key: 'contributionType' }
      ],
      selectedToTop: false
    },
    life: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: lifeCarriersArray.map(c => c.toLowerCase()), key: 'carriers' },
        { title: 'Contribution Type', data: ['voluntary', 'employer paid'], key: 'contributionType' }
      ],
      selectedToTop: false
    },
    accident: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: accidentCarriersArray.map(c => c.toLowerCase()), key: 'carriers' }
      ],
      selectedToTop: false
    },
    cancer: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: cancerCarriersArray.map(c => c.toLowerCase()), key: 'carriers' }
      ],
      selectedToTop: false
    },
    criticalIllness: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: criticalIllnessCarriersArray.map(c => c.toLowerCase()), key: 'carriers' }
      ],
      selectedToTop: false
    },
    hospital: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: hospitalCarriersArray.map(c => c.toLowerCase()), key: 'carriers' }
      ],
      selectedToTop: false
    },
    std: {
      sort: [SortType.premium, SortType.none],
      filters: [
        { title: 'Carriers', data: stdCarriersArray.map(c => c.toLowerCase()), key: 'carriers' },
        { title: 'Contribution Type', data: ['voluntary', 'employer paid'], key: 'contributionType' }
      ],
      selectedToTop: false
    }
  }

  const stateObject: StateObjectType = {
    dental: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[]
      },
      selectedToTop: false
    },
    vision: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[]
      },
      selectedToTop: false
    },
    disability: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[],
        contributionType: [] as string[]
      },
      selectedToTop: false
    },
    life: {
      sort: SortType.none,
      filters: {
        carriers: [] as string[],
        contributionType: [] as string[]
      },
      selectedToTop: false
    },
    accident: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[]
      },
      selectedToTop: false
    },
    cancer: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[]
      },
      selectedToTop: false
    },
    criticalIllness: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[]
      },
      selectedToTop: false
    },
    hospital: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[]
      },
      selectedToTop: false
    },
    std: {
      sort: SortType.premium,
      filters: {
        carriers: [] as string[],
        contributionType: [] as string[]
      },
      selectedToTop: false
    }
  }

  const setWorksiteCarrierOnLoad = () => {
    if (!selectedPlans.length) {
      setSelectedWorksiteCarrier(worksiteCarriers[0])
      updateSelectedPlanIDs(worksiteCarriers[0])
    } else if (selectedPlans.some(p => worksitePlanIDs.includes(p.plan.id))) {
      const worksiteCarrier = selectedPlans.filter(p => worksitePlanIDs.includes(p.plan.id))[0].plan.carrier
      setSelectedWorksiteCarrier(worksiteCarrier)
    }
  }

  const setWorksiteCarrierFromModal = (carrier: string) => {
    setSelectedWorksiteCarrier(carrier)
    updateSelectedPlanIDs(carrier)
  }

  const removeParam = (currentParamKeys: any, key: string, value: string|boolean) => {
    const filterParams = params.getAll(currentParamKeys[key]).filter(p => p.toLowerCase() !== value.toString().toLowerCase())
    params.delete(currentParamKeys[key])
    filterParams.map(p => params.append(currentParamKeys[key], p))
  }

  const addParam = (currentParamKeys: any, key: string, value: string|boolean) => {
    params.append(currentParamKeys[key], value as string)
  }

  const setStateFromComponent = (key: string, value: string|boolean) => {
    const currentTypeState = currentState[selectedTab]
    const currentParamKeys = paramKeys[selectedTab]
    const newValue = value as AncillaryGroupPlan
    switch (key) {
    case 'selectedToTop':
      currentTypeState.selectedToTop = !!value
      if (value === 'true') {
        params.set(currentParamKeys[key], value.toString() as string)
      } else {
        params.delete(currentParamKeys[key])
      }
      break
    case 'carriers':
      if (currentTypeState.filters.carriers.includes(value as string)) {
        currentTypeState.filters.carriers = currentTypeState.filters.carriers.filter(c => c !== value)
        removeParam(currentParamKeys, key, value)
      } else {
        currentTypeState.filters.carriers.push(value as string)
        addParam(currentParamKeys, key, value)
      }
      currentPlansObj[selectedTab].set(sortAndFilterPlans(plansObj[selectedTab], selectedTab, currentState[selectedTab].filters))
      break
    case 'contributionType':
      if (currentTypeState.filters.contributionType!.includes(value as string)) {
        currentTypeState.filters.contributionType = currentTypeState.filters.contributionType!.filter(c => c !== value)
        removeParam(currentParamKeys, key, value)
      } else {
        if (currentTypeState.filters.contributionType!.length === 0) {
           currentTypeState.filters.contributionType!.push(value as string)
           addParam(currentParamKeys, key, value)
        } else {
        currentTypeState.filters.contributionType! = []
        removeParam(currentParamKeys, key, value === 'voluntary' ? 'employer paid' : 'voluntary')
        currentTypeState.filters.contributionType!.push(value as string)
        addParam(currentParamKeys, key, value)
        }
      }
      currentPlansObj[selectedTab].set(sortAndFilterPlans(plansObj[selectedTab], selectedTab, currentState[selectedTab].filters))
      break
    case 'sort':
      if (currentTypeState.sort === value) {
        currentTypeState.sort = SortType.none
        params.delete(currentParamKeys[key])
      } else {
        currentTypeState.sort = value as SortType
        params.set(currentParamKeys[key], value.toString().replace(' ', '').toLowerCase())
      }
      currentPlansObj[selectedTab].set(sortAndFilterPlans(plansObj[selectedTab], selectedTab, currentState[selectedTab].filters))
      break
    case 'type':
      nonWorksiteTabsArray.includes(value as GroupPlanType) ? setWorksiteSelected(false) : setWorksiteSelected(true);
      [GroupPlanType.disability, GroupPlanType.ltdEE, GroupPlanType.ltdER, GroupPlanType.std, GroupPlanType.stdEE, GroupPlanType.stdER].includes(value as GroupPlanType) ? setDisabilitySelected(true) : setDisabilitySelected(false)
      setSortParam(newValue)
      setSelectedTab(newValue)
      setSelectedTypes([newValue])
      currentPlansObj[newValue].set(sortAndFilterPlans(plansObj[newValue], newValue, currentState[newValue].filters))

      params.set('type', value as string)
      break
    }

    setCurrentState(currentState)
    history.push({ search: params.toString() })
  }

  const [currentDentalPlans, setCurrentDentalPlans] = useState<AncillaryPlanUnion[]>(dentalPlans)
  const [currentVisionPlans, setCurrentVisionPlans] = useState<AncillaryPlanUnion[]>(visionPlans)
  const [currentDisabilityPlans, setCurrentDisabilityPlans] = useState<AncillaryPlanUnion[]>(disabilityPlans)
  const [currentLifePlans, setCurrentLifePlans] = useState<AncillaryPlanUnion[]>(lifePlans)
  const [currentAccidentPlans, setCurrentAccidentPlans] = useState<AncillaryPlanUnion[]>(accidentPlans)
  const [currentCancerPlans, setCurrentCancerPlans] = useState<AncillaryPlanUnion[]>(cancerPlans)
  const [currentCriticalIllnessPlans, setCurrentCriticalIllnessPlans] = useState<AncillaryPlanUnion[]>(criticalIllnessPlans)
  const [currentHospitalPlans, setCurrentHospitalPlans] = useState<AncillaryPlanUnion[]>(hospitalPlans)
  const [currentStdPlans, setCurrentStdPlans] = useState<AncillaryPlanUnion[]>(stdPlans)
  const previousTab = tabsOrder[tabsOrder.indexOf(selectedTab) - 1]
  const nextTab = tabsOrder[tabsOrder.indexOf(selectedTab) + 1]

  const plansObj = {
    dental: dentalPlans,
    vision: visionPlans,
    disability: disabilityPlans,
    life: lifePlans,
    // groupLife: groupLifePlans,
    accident: accidentPlans,
    cancer: cancerPlans,
    criticalIllness: criticalIllnessPlans,
    hospital: hospitalPlans,
    std: stdPlans
  }
  const currentPlansObj = {
    dental: {
      set: setCurrentDentalPlans,
      get: currentDentalPlans
    },
    vision: {
      set: setCurrentVisionPlans,
      get: currentVisionPlans
    },
    disability: {
      set: setCurrentDisabilityPlans,
      get: currentDisabilityPlans
    },
    life: {
      set: setCurrentLifePlans,
      get: currentLifePlans
    },
    accident: {
      set: setCurrentAccidentPlans,
      get: currentAccidentPlans
    },
    cancer: {
      set: setCurrentCancerPlans,
      get: currentCancerPlans
    },
    criticalIllness: {
      set: setCurrentCriticalIllnessPlans,
      get: currentCriticalIllnessPlans
    },
    hospital: {
      set: setCurrentHospitalPlans,
      get: currentHospitalPlans
    },
    std: {
      set: setCurrentStdPlans,
      get: currentStdPlans
    }
  }
  const [selectedTypes, setSelectedTypes] = useState<GroupPlanType[]>([GroupPlanType.dental])
  const [currentState, setCurrentState] = useState(stateObject)

  const history = useHistory()
  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const params = useQuery()

  const rv: [AncillaryFilterType, string][] = compact(Array.from(params.entries()).map(transform))

  const paramsObject: ParamsObj = {
    dcarrier: rv.filter(i => i[0] === 'dcarrier').map(i => i[1].toLowerCase()),
    vcarrier: rv.filter(i => i[0] === 'vcarrier').map(i => i[1].toLowerCase()),
    discarrier: rv.filter(i => i[0] === 'discarrier').map(i => i[1].toLowerCase()),
    lcarrier: rv.filter(i => i[0] === 'lcarrier').map(i => i[1].toLowerCase()),
    acarrier: rv.filter(i => i[0] === 'acarrier').map(i => i[1].toLowerCase()),
    ccarrier: rv.filter(i => i[0] === 'ccarrier').map(i => i[1].toLowerCase()),
    cricarrier: rv.filter(i => i[0] === 'cricarrier').map(i => i[1].toLowerCase()),
    hcarrier: rv.filter(i => i[0] === 'hcarrier').map(i => i[1].toLowerCase()),
    stdcarrier: rv.filter(i => i[0] === 'stdcarrier').map(i => i[1].toLowerCase()),

    discont: rv.filter(i => i[0] === 'discont').map(i => i[1].toLowerCase()),
    lcont: rv.filter(i => i[0] === 'lcont').map(i => i[1].toLowerCase()),
    stdcont: rv.filter(i => i[0] === 'stdcont').map(i => i[1].toLowerCase()),

    dsort: rv.filter(i => i[0] === 'dsort').map(i => i[1].toLowerCase())[0],
    vsort: rv.filter(i => i[0] === 'vsort').map(i => i[1].toLowerCase())[0],
    dissort: rv.filter(i => i[0] === 'dsort').map(i => i[1].toLowerCase())[0],
    lsort: rv.filter(i => i[0] === 'lsort').map(i => i[1].toLowerCase())[0],
    asort: rv.filter(i => i[0] === 'asort').map(i => i[1].toLowerCase())[0],
    csort: rv.filter(i => i[0] === 'csort').map(i => i[1].toLowerCase())[0],
    crisort: rv.filter(i => i[0] === 'crisort').map(i => i[1].toLowerCase())[0],
    hsort: rv.filter(i => i[0] === 'hsort').map(i => i[1].toLowerCase())[0],
    stdsort: rv.filter(i => i[0] === 'stdsort').map(i => i[1].toLowerCase())[0],

    dselected: rv.filter(i => i[0] === 'dselected').map(i => i[1].toLowerCase())[0],
    vselected: rv.filter(i => i[0] === 'vselected').map(i => i[1].toLowerCase())[0],
    disselected: rv.filter(i => i[0] === 'disselected').map(i => i[1].toLowerCase())[0],
    lselected: rv.filter(i => i[0] === 'lselected').map(i => i[1].toLowerCase())[0],
    aselected: rv.filter(i => i[0] === 'aselected').map(i => i[1].toLowerCase())[0],
    cselected: rv.filter(i => i[0] === 'cselected').map(i => i[1].toLowerCase())[0],
    criselected: rv.filter(i => i[0] === 'criselected').map(i => i[1].toLowerCase())[0],
    hselected: rv.filter(i => i[0] === 'hselected').map(i => i[1].toLowerCase())[0],
    stdselected: rv.filter(i => i[0] === 'stdselected').map(i => i[1].toLowerCase())[0]
  }

  const planTypeParam: string = rv.filter(i => i[0] === 'type').map(i => i[1].toLowerCase())[0]

  function transform(input: [string, any]): [AncillaryFilterType, any] | undefined {
    let [key, value] = input
    if (key.endsWith('[]')) key = key.slice(0, -2)
    for (const typeKey in AncillaryFilterType) {
      if (AncillaryFilterType[typeKey as keyof typeof AncillaryFilterType] === key) return [key, value]
    }
  }

  useEffect(() => {
    switch (planTypeParam) {
    case 'dental':
      paramCheck(paramsObject, GroupPlanType.dental, dentalPlans)
      break
    case 'vision':
      setSelectedTypes([GroupPlanType.vision])
      paramCheck(paramsObject, GroupPlanType.vision, visionPlans)
      setSelectedTab(GroupPlanType.vision)
      break
    case 'disability':
      setSelectedTypes([GroupPlanType.disability])
      paramCheck(paramsObject, GroupPlanType.disability, disabilityPlans)
      setSelectedTab(GroupPlanType.disability)
      setDisabilitySelected(true)
      break
    case 'life':
      setSelectedTypes([GroupPlanType.life])
      paramCheck(paramsObject, GroupPlanType.life, lifePlans)
      setSelectedTab(GroupPlanType.life)
      break
    case 'accident':
      setSelectedTypes([GroupPlanType.accident])
      paramCheck(paramsObject, GroupPlanType.accident, accidentPlans)
      setSelectedTab(GroupPlanType.accident)
      setWorksiteSelected(true)
      break
    case 'cancer':
      setSelectedTypes([GroupPlanType.cancer])
      paramCheck(paramsObject, GroupPlanType.cancer, cancerPlans)
      setSelectedTab(GroupPlanType.cancer)
      setWorksiteSelected(true)
      break
    case 'criticalillness':
      setSelectedTypes([GroupPlanType.criticalIllness])
      paramCheck(paramsObject, GroupPlanType.criticalIllness, criticalIllnessPlans)
      setSelectedTab(GroupPlanType.criticalIllness)
      setWorksiteSelected(true)
      break
    case 'hospital':
      setSelectedTypes([GroupPlanType.hospital])
      paramCheck(paramsObject, GroupPlanType.hospital, hospitalPlans)
      setSelectedTab(GroupPlanType.hospital)
      setWorksiteSelected(true)
      break
    case 'std':
      setSelectedTypes([GroupPlanType.std])
      paramCheck(paramsObject, GroupPlanType.std, stdPlans)
      setSelectedTab(GroupPlanType.std)
      setDisabilitySelected(true)
      break
    default:
      paramCheck(paramsObject, GroupPlanType.dental, dentalPlans)
      break
    }
    setWorksiteCarrierOnLoad()
  }, [])

  const filterPlansByCarrier = (plans: AncillaryPlanUnion[], carriers: string[]) => {
    if (carriers.length) {
      return plans.filter((p) => carriers.includes(p.plan.carrier.toLowerCase()))
    } else {
      return plans
    }
  }
  const filterPlansByContributionType = (plans: AncillaryPlanUnion[], contributionType?: string[]) => {
    const EEContPlans = [GroupPlanType.ltdEE, GroupPlanType.lifeEE, GroupPlanType.stdEE]
    const ERContPlans = [GroupPlanType.ltdER, GroupPlanType.lifeER, GroupPlanType.stdER]
    if (contributionType && contributionType.length > 0) {
      return contributionType[0] === 'voluntary' ? plans.filter(p => EEContPlans.includes(p.plan.type)) : plans.filter(p => ERContPlans.includes(p.plan.type))
    } else {
      return plans
    }
  }

  const paramCheck = (pObj: ParamsObj, type: AncillaryGroupPlan, plans: AncillaryPlanUnion[]) => {
    let newPlansArray = [...plans]
    params.set('type', paramKeys[type].type)
    if (pObj[paramKeys[type].sort as keyof ParamsObj]) {
      if (String(pObj[paramKeys[type].sort as keyof ParamsObj]!).toLowerCase() === 'premium') {
        currentState[type].sort = SortType.premium
        newPlansArray = sortByPremium(newPlansArray)
      } else if (String(pObj[paramKeys[type].sort as keyof ParamsObj]!).toLowerCase() === 'annualmax') {
        if (type === GroupPlanType.dental) {
          currentState[type].sort = SortType.max
          newPlansArray = sortByAnnualMax(newPlansArray)
        }
      }
    } else {
      if (type !== GroupPlanType.life) {
        currentState[type].sort = SortType.premium
        newPlansArray = sortByPremium(newPlansArray)
      }
    }

    tabsOrder.map(t => {
      const key = t as AncillaryGroupPlan
      if (configObject[key].filters[0].data.some(e => pObj[paramKeys[key].carriers as keyof ParamsObj]!.includes(e.toLowerCase()))) {
        currentState[key].filters.carriers = pObj[paramKeys[key].carriers as keyof ParamsObj] as string[]
      }
      if (configObject[key].filters[1]) {
        if (configObject[key].filters[1].data.some(e => pObj[paramKeys[key].contributionType as keyof ParamsObj]!.includes(e.toLowerCase()))) {
          currentState[key].filters.contributionType = pObj[paramKeys[key].contributionType as keyof ParamsObj] as string[]
        }
      }
      currentPlansObj[key].set(sortAndFilterPlans(newPlansArray, key, currentState[key].filters))
    })

    tabsOrder.map(t => {
      t = t as AncillaryGroupPlan
      if (pObj[paramKeys[t].selectedToTop as keyof ParamsObj] === 'true') {
        currentState[t].selectedToTop = true
      }
    })

    setCurrentState(currentState)
    history.push({ search: params.toString() })
  }

  const findSortType = (plans: AncillaryPlanUnion[], type: AncillaryGroupPlan) => {
    if (currentState[type].sort === SortType.premium) {
      return sortByPremium(plans)
    } else if (currentState[type].sort === SortType.max) {
      return sortByAnnualMax(plans)
    } else {
      return plans
    }
  }

  const sortAndFilterPlans = (plans: AncillaryPlanUnion[], type: AncillaryGroupPlan, filters: {carriers: string[], contributionType?: string[]}) => {
    plans = filterPlansByCarrier(plans, filters.carriers)
    plans = filterPlansByContributionType(plans, filters.contributionType)
    plans = findSortType(plans, type)
    return plans
  }

  const sortByPremium = (plansArray: AncillaryPlanUnion[]) => {
    // A new copy of the array must be created before sorting or else react won't rerender the sorted array
    // https://stackoverflow.com/questions/64754132/state-is-not-updating-in-react-after-sorting
    const newPlansArray = [...plansArray]
    const sortedPlans = newPlansArray.sort((a: AncillaryPlanUnion, b: AncillaryPlanUnion) => {
      if (numeral(a.rate.individual).value() > numeral(b.rate.individual).value()) {
        return 1
      }
      if (numeral(a.rate.individual).value() < numeral(b.rate.individual).value()) {
        return -1
      } else return 0
    })
    return sortedPlans
  }

  const sortByAnnualMax = (plansArray: AncillaryPlanUnion[]) => {
    const newPlansArray = [...plansArray]

    const findAnnualMaxProperty = (plan: AncillaryPlanUnion): string => {
      if ('preventativeMaxInNetwork' in plan.plan.data) {
        return 'preventativeMaxInNetwork'
      } else {
        return 'individualInNetworkCarrierPayOutMaximum'
      }
    }

    const sortedPlans = newPlansArray.sort((a: AncillaryPlanUnion, b: AncillaryPlanUnion) => {
      if (numeral(a.plan.data[findAnnualMaxProperty(a) as keyof DentalPlanData]).value() > numeral(b.plan.data[findAnnualMaxProperty(a) as keyof DentalPlanData]).value()) {
        return 1
      } else if (numeral(a.plan.data[findAnnualMaxProperty(a) as keyof DentalPlanData]).value() < numeral(b.plan.data[findAnnualMaxProperty(a) as keyof DentalPlanData]).value()) {
        return -1
      } else return 0
    })
    const unlimitedSortedPlans = sortedPlans.filter(p => { return p.plan.data[findAnnualMaxProperty(p) as keyof DentalPlanData]?.toLowerCase() === 'unlimited' })
    const filteredSortedPlans = sortedPlans.filter(p => { return p.plan.data[findAnnualMaxProperty(p) as keyof DentalPlanData]?.toLowerCase() !== 'unlimited' })
    return [...filteredSortedPlans, ...unlimitedSortedPlans]
  }
  const setSortParam = (type: GroupPlanType) => {
    type === GroupPlanType.dental ? params.delete('dsort') : params.delete('vsort')
    switch (type) {
    case GroupPlanType.dental:
      if (currentState.dental.sort === SortType.premium) {
        params.set('dsort', 'premium')
      } else if (currentState.dental.sort === SortType.max) {
        params.set('dsort', 'annualmax')
      }
      break
    case GroupPlanType.vision:
      if (currentState.vision.sort === SortType.premium) {
        params.set('vsort', 'premium')
      }
    }
  }

  return <>
    <CarrierModal isOpen={modalIsOpen} onClose={() => {
      setClickedWorksiteCarrier(selectedWorksiteCarrier)
      setModalIsOpen(false)
    }} setState={setWorksiteCarrierFromModal} carrier={clickedWorksiteCarrier || worksiteCarriers[0]} selectedTab={selectedTab}/>
    <Heading className={`${styles.ancilaryHeading} ${(worksiteSelected || disabilitySelected) && styles.worksiteTabActive}`} noPadding={true} noPMargin={true}>
      <div className={styles.titleFlexContainer}>
        {selectedTab === GroupPlanType.dental && <BackButton onBackClick={() => history.push(Route.erStargate_Plans)}>Medical Plans</BackButton>}
        {selectedTab !== GroupPlanType.dental && <BackButton onBackClick={() => setStateFromComponent('type', previousTab)}>{h2(previousTab as GroupPlanType, true)} Plans</BackButton>}
        <h1 className={styles.alignCenter}>Enhance Your Offer</h1><div className={styles.alignRight}></div></div>
      <div className={styles.flex}>
        <p className={styles.ancilaryHeading}>Employees can choose from the ancillary plans below. You’ll be able to adjust your specific contributions before finalizing your selected plans.</p>
        {onwardsButton()}
      </div>
      <div className={styles.tabsContainer}>
        {[GroupPlanType.dental, GroupPlanType.vision, GroupPlanType.life].map(t => {
          return <Tab selectedTab={selectedTab}
            key={t}
            tabName={t}
            setState={setStateFromComponent}
            titleFunc={h2}
            planCount={plansObj[t as AncillaryGroupPlan].filter(p => selected.includes(p.plan.id)).length}
            shop={'ER'}/>
        })}
        <TabWithSubTabs
          selected={disabilitySelected}
          setSelected={setDisabilitySelected}
          setState={setStateFromComponent}
          tabName={'Disability'}
          planCount={[...disabilityPlans, ...stdPlans].filter(p => selected.includes(p.plan.id)).length}
          shop={'ER'}/>
        <TabWithSubTabs
          selected={worksiteSelected}
          setSelected={setWorksiteSelected}
          setState={setStateFromComponent}
          tabName={'Worksite'}
          planCount={worksitePlans.filter(p => selected.includes(p.plan.id)).length}
          shop={'ER'}/>
      </div>
    </Heading>
    {(worksiteSelected || disabilitySelected) &&
    <div className={styles.subTabsContainer}>
      {worksiteSelected &&
      <>
        <div className={styles.subTabs}>
          <p>
            Please Select 1 carrier for worksite. All plans will be selected automatically, but you can customize your plan selections below.
            <br/><i>Note: Only 1 carrier can be selected for worksite.</i>
          </p>
        </div>
        <div className={styles.worksiteCarriers}>
          {worksiteCarriers.map(c => {
            return <CarrierButton key={c} setState={setClickedWorksiteCarrier} carrier={c} selectedWorksiteCarrier={selectedWorksiteCarrier} setIsOpen={setModalIsOpen} />
          })}
        </div>
        <div className={styles.subTabs}>
          {worksiteTabsArray.map(t => {
            return <SubTab tabName={t} selectedTab={selectedTab} setState={setStateFromComponent} planCount={plansObj[t as AncillaryGroupPlan].filter(p => selected.includes(p.plan.id)).length} key={t} titleFunc={h2}/>
          })}
        </div>
      </>
      }
      {disabilitySelected &&
        <div className={styles.subTabs}>
          {[GroupPlanType.disability, GroupPlanType.std].map(t => {
            return <SubTab tabName={t} selectedTab={selectedTab} setState={setStateFromComponent} planCount={plansObj[t as AncillaryGroupPlan].filter(p => selected.includes(p.plan.id)).length} key={t} titleFunc={h2}/>
          })}
        </div>
      }
    </div>
    }
    <div className={styles.plansAndFilter}>
      <FilterBar config={configObject[selectedTab]}
        state={currentState[selectedTab]}
        setState={setStateFromComponent}
        plans={plansObj[selectedTab]}
        currentPlans={currentPlansObj[selectedTab].get}
      />
      <section className={styles.mainContainer}>
        { selectedTypes.map(render) }
      </section>
    </div>
    {onwardsButton()}
  </>
  function render(type: GroupPlanType) {
    const planNames = h2(type)
    return <>
      {![GroupPlanType.disability, GroupPlanType.std].includes(selectedTab) &&
        <section className={styles.erSection}>
          <h2>Select at least one plan under each tab.</h2>
        </section>
      }
      {planNames &&
    <article key={type}>
      <h2>{planNames} Plans</h2>
      { contributionForm(type) }
      <div className={styles.plansContainer}>
        <div className={styles.dentalCarriers}>
          { currentState[selectedTab].selectedToTop ? currentPlansObj[selectedTab].get.filter(p => selected.includes(p.plan.id)).concat(currentPlansObj[selectedTab].get.filter(p => !selected.includes(p.plan.id))).map(plan)
            : currentPlansObj[selectedTab].get.map(plan)
          }
          {plansObj[selectedTab].length !== 0 && currentPlansObj[selectedTab].get.length === 0 && ![GroupPlanType.disability, GroupPlanType.std].includes(selectedTab) &&
            <section className={styles.erSectionWarning}>
              <h2>No plans available with selected filters. <small>Please change your filter selections to the left.</small></h2>
            </section>
          }
          {plansObj[selectedTab].length === 0 && currentPlansObj[selectedTab].get.length === 0 && ![GroupPlanType.disability, GroupPlanType.std].includes(selectedTab) &&
            <section className={styles.erSectionWarning}>
              <h2>No plans available at this time. <small>Please check carrier quoting requirements or contact support for more information.</small></h2>
            </section>
          }
          {currentPlansObj[selectedTab].get.length === 0 && [GroupPlanType.disability, GroupPlanType.std].includes(selectedTab) &&
            <section className={styles.erSection}>
              <h2>Coming Soon. <small>No plans available at this time.</small></h2>
            </section>
          }
        </div>
      </div>
    </article>
      }
    </>
  }

  function selectedEEERPlanCheck(plan: AncillaryPlanUnion) {
    const selectedLifePlans = lifePlans.filter(p => selected.includes(p.plan.id))
    const selectedLtdPlans = disabilityPlans.filter(p => selected.includes(p.plan.id))
    const selectedStdPlans = stdPlans.filter(p => selected.includes(p.plan.id))
    if ([GroupPlanType.life, GroupPlanType.lifeER, GroupPlanType.lifeEE].includes(plan.plan.type) && selectedLifePlans.length) {
      addToast('You can only select one life plan', 'warning')
      return false
    } else if ([GroupPlanType.ltdER, GroupPlanType.ltdEE].includes(plan.plan.type) && selectedLtdPlans.length) {
      addToast('You can only select one long term disability plan', 'warning')
      return false
    } else if ([GroupPlanType.stdER, GroupPlanType.stdEE].includes(plan.plan.type) && selectedStdPlans.length) {
      addToast('You can only select one short term disability plan', 'warning')
      return false
    } else {
      return true
    }
  }

  function plan(plan: AncillaryPlanUnion) {
    const currentContributions = contributions
    const isSelected = selected.includes(plan.plan.id)

    if (contributions) {
      contributions.baseContributions.dental = contribution[GroupPlanType.dental]?.value
      contributions.baseContributions.dentalEquitable = contribution[GroupPlanType.dental]?.splitType === ContributionSplitType.allTiers
      contributions.baseContributions.vision = contribution[GroupPlanType.vision]?.value
      contributions.baseContributions.visionEquitable = contribution[GroupPlanType.vision]?.splitType === ContributionSplitType.allTiers
      contributions.baseContributions.allAncillary = contributionMode === AncillaryContributionMode.allEligibleLines ? contribution.allAncillary || '$0' : undefined
    }

    const selectHandler = async() => {
      try {
        if (isSelected) {
          await v3.groups(stargate.group?.id).plans.options.ancillary.DELETE(plan.plan.id)
          setSelected(selected.filter(s => s !== plan.plan.id))
          if (!selected.filter(s => s !== plan.plan.id).some(p => worksitePlanIDs.includes(p))) {
            setSelectedWorksiteCarrier(null)
            setClickedWorksiteCarrier(null)
          }
        } else {
          if (selectedEEERPlanCheck(plan)) {
            await v3.groups(stargate.group?.id).plans.options.ancillary.POST(plan.plan.id)
            setSelected([...selected, plan.plan.id])
          }
        }
      } catch (error) {
        addToast(error as Error)
      }
    }

    const props: any = {
      selected: isSelected,
      selectHandler,
      contributions: currentContributions,
      key: plan.plan.id,
      splits: [],
      plan,
      showWeeklyPayments: config.showWeeklyPayments,
      members
    }
    switch (plan.plan.type) {
    case GroupPlanType.dental:
      return <DentalPlan {...props} />
    case GroupPlanType.vision:
      return <VisionPlan {...props} />
    case GroupPlanType.life:
      return hasABC ? <ABCLifePlan {...props}/> : <VTLPlan {...props}/>
    case GroupPlanType.lifeEE:
      return <LifeEE {...props} stargateGroup={group} members={members} showModal/>
    case GroupPlanType.lifeER:
      return <LifeER {...props} stargateGroup={group} members={members} showModal/>
    case GroupPlanType.accident:
      return <AccidentPlanComp {...props}/>
    case GroupPlanType.disability:
      return <LTDPlan {...props} />
    case GroupPlanType.ltdEE:
      return <LTDEEPlan {...props} stargateGroup={group} members={members}/>
    case GroupPlanType.ltdER:
      return <LTDERPlan {...props} stargateGroup={group} members={members}/>
    case GroupPlanType.cancer:
      return <CancerPlanComp {...props}/>
    case GroupPlanType.hospital:
      return <HospitalPlan {...props}/>
    case GroupPlanType.std:
      return <ShortTermDisability {...props}/>
    case GroupPlanType.stdEE:
      return <StdEE {...props} stargateGroup={group} members={members}/>
    case GroupPlanType.stdER:
      return <StdER {...props} stargateGroup={group} members={members}/>
    case GroupPlanType.criticalIllness:
      return <CriticleIllness {...props}/>
    }
  }

  function h2(type: GroupPlanType, header?: boolean) {
    switch (type) {
    case GroupPlanType.dental:
    case GroupPlanType.vision:
      return startCase(type)
    case GroupPlanType.accident:
      return startCase(type)
    case GroupPlanType.lifeEE:
    case GroupPlanType.lifeER:
    case GroupPlanType.life:
      if (config.label === Label.blacksmith) {
        return 'Life, Accidental Death and Dismemberment'
      }
      if (header) return 'Life'
      return 'Life'
    // case GroupPlanType.groupLife:
    //   return 'Group Life'
    case GroupPlanType.disability:
    case GroupPlanType.ltdEE:
    case GroupPlanType.ltdER:
      if (header) return startCase(type)
      return 'Long Term Disability'
    case GroupPlanType.cancer:
      return 'Cancer'
    case GroupPlanType.criticalIllness:
      return 'Critical Illness'
    case GroupPlanType.hospital:
      return 'Hospital Indemnity'
    case GroupPlanType.stdEE:
    case GroupPlanType.stdER:
    case GroupPlanType.std:
      return 'Short Term Disability'
    }
  }

  function go() {
    // ONWARDS!!
    if (!validate()) { return }

    setDisabled(true)
    const contributions = {
      dental: mangleContribution(contribution[GroupPlanType.dental]),
      vision: mangleContribution(contribution[GroupPlanType.vision]),
      life: mangleContribution(contribution[GroupPlanType.life]),
      disability: mangleContribution(contribution[GroupPlanType.disability]),
      allAncillary: contributionMode === AncillaryContributionMode.allEligibleLines ? contribution.allAncillary : undefined
    }

    const contributionsFunc = async() => {
      try {
        await v3.groups(group?.id).PUT({ contributions })
      } finally {
        setDisabled(false)
      }
    }
    contributionsFunc()
    onwards(Promise.resolve())
  }

  function validate(): boolean {
    const hasDental = plans.some(p => p.plan.type === GroupPlanType.dental)
    const hasVision = plans.some(p => p.plan.type === GroupPlanType.vision)
    const choseDental = plans.some(p => p.plan.type === GroupPlanType.dental && selected.includes(p.plan.id))
    const choseVision = plans.some(p => p.plan.type === GroupPlanType.vision && selected.includes(p.plan.id))

    if (hasDental && !choseDental) {
      addToast('You must choose at least one dental offering', 'warning')
      return false
    }

    if (hasVision && !choseVision) {
      if (selectedTab === GroupPlanType.dental) setStateFromComponent('type', GroupPlanType.vision)
      addToast('You must choose at least one vision offering', 'warning')
      return false
    }

    const selectedWorksitePlans = worksitePlans.filter(p => selected.includes(p.plan.id))
    const selectedWorksiteCarriers = uniq(selectedWorksitePlans.map(p => p.plan.carrier))
    if (selectedWorksiteCarriers.length !== 0 && selectedWorksiteCarriers.length > 1) {
      addToast('All selected worksite plans must be from the same carrier')
      return false
    }

    return true
  }

  function contributionForm(type: GroupPlanType) {
    if (contributionMode === AncillaryContributionMode.allEligibleLines) return null

    return <div className={styles.contributionContainer}>
      <p><span>How would you like to contribute to {type} plans?</span> Your contribution amount and employee participation in ancillary coverage may affect plan availability.</p>
      <ContributionAmount
        planType = {GroupPlanType.medical}
        onlyPercent
        value={contribution[type]?.value}
        splitType={contribution[type]?.splitType}
        onChange={(value, splitType) => {
          setContribution({ [type]: { value, splitType } })
        }}
      />
    </div>
  }

  function mangleContribution(c?: { value: string, splitType: ContributionSplitType}) {
    return { value: c?.value || '0%', isEquitable: c?.splitType === ContributionSplitType.allTiers }
  }

  function defaultContributions() {
    const rv: { [key in GroupPlanType]?: {value: string, splitType: ContributionSplitType} } & { allAncillary?: string } = {}
    const bc = contributions?.baseContributions

    const bcDentalEquitable = bc?.dentalEquitable || true
    const bcVisionEquitable = bc?.visionEquitable || true
    const bcLifeEquitable = bc?.visionEquitable || true
    const bcDisabilityEquitable = bc?.visionEquitable || true

    const bcDental = bc?.dental || '50%'
    const bcVision = bc?.vision || '50%'
    const bcLife = bc?.life || '50%'
    const bcDisablity = bc?.disability || '50%'
    const bcAllAncillary = bc?.allAncillary || '$25'

    // const ancillaryContributionData = { value: bc?.dental || '51%', splitType: contributionType(!!bc?.dentalEquitable, bc?.dental) }
    rv[GroupPlanType.dental] = { value: bcDental, splitType: contributionType(bcDentalEquitable, bcDental) }
    rv[GroupPlanType.vision] = { value: bcVision, splitType: contributionType(bcVisionEquitable, bcVision) }
    rv[GroupPlanType.life] = { value: bcLife, splitType: contributionType(bcLifeEquitable, bcLife) }
    rv[GroupPlanType.disability] = { value: bcDisablity, splitType: contributionType(bcDisabilityEquitable, bcDisablity) }
    rv.allAncillary = moneyString(bcAllAncillary)
    return rv
  }

  function onwardsButton() {
    return <>
      <GAButton
        analytics={`Next (${ERShopPlansAncillary.name})`}
        className={classNames('shop-next-button', styles.nextButton)}
        onClick={() => {
          tabsOrder.indexOf(selectedTab) + 1 !== tabsOrder.length ? setStateFromComponent('type', nextTab) : go()
        }}
        disabled={disabled}
      >Next {disabled && 'Loading...'}</GAButton>
    </>
  }
}

export default ERShopPlansAncillary
