import React, { useEffect } from 'react'
import styles from 'Routes/shop/employer/confirm/Confirmation.module.scss'
import Laptop from 'Assets/LaptopMan.svg'
import { Link } from 'react-router-dom'
import { post } from 'Utilities/fetch++'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { Venue } from 'Utilities/pharaoh.types'
import { GAButton } from 'Components/Tracking'

const EEShopConfirm: React.FC<PrivateWizardPageProps> = ({ stargate }) => {
  const exploreDashCopy = 'Explore my Employee Dashboard'
  useEffect(() => {
    const el = document.getElementById('content')!
    el.style.padding = '0'
    el.style.maxWidth = 'unset'
    return () => {
      el.style.removeProperty('padding')
      el.style.removeProperty('max-width')
    }
  })

  const header = hasPlan() ? 'All done! You made it!' : 'Thanks for Stopping By!'
  const copy = hasPlan()
    ? <>We will be in contact as soon as possible letting you know about your new benefits.</>
    : <>We will be in contact as soon as possible about next steps.</>

  useEffect(() => {
    post('/v3/souvenir', { context: stargate.group?.id, venue: Venue.employee }).catch(console.error)
  }, [stargate.group])

  return <div className={styles.mainContainer}>
    <div className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.checkTitle}>
          <div className={styles.confirmCheck}>
            <i className="material-icons">check</i>
          </div>
          <h1>{header}</h1>
        </div>
        <p className={styles.mediumInstructions}>{copy}</p>
      </div>
      <div className={styles.linksContainer}>
        <div className={styles.linkBoxWide}>
          <img src={Laptop} alt="Man with laptop" className={styles.laptop} />
          <div className={styles.linkBoxTextContainer}>
            <h2>Explore Your Dashboard</h2>
            <p>Check out your profile, review your plans, find providers and services, and make changes to coverage on the employee dashboard.</p>
            <Link to="/dashboard/employee">
              {/* (${ERShopCongratulations.name}) */}
              <GAButton className={styles.exploreDashboard} analytics={`${exploreDashCopy}`}>{exploreDashCopy}
                <i className={`material-icons ${styles.inlineIcon}`}>chevron_right</i>
              </GAButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>

  function hasPlan() {
    const { groupMember, user } = stargate
    if (!groupMember) return !!user.enrolled_individual_plan_id
    return groupMember.enrolled_dental_plan_id ||
    groupMember.enrolled_disability_plan_id ||
    groupMember.enrolled_group_plan_id ||
    user.enrolled_individual_plan_id ||
    groupMember.enrolled_life_plan_id ||
    groupMember.enrolled_vision_plan_id
  }
}

export default EEShopConfirm
