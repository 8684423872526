import React from 'react'
import styles from 'Routes/shop/agency/shop-setup/index.module.scss'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form'
import * as api from 'Utilities/pharaoh'
import useToast from 'Utilities/Hooks/useToast'
import { Landing } from 'Utilities/Hooks/useStargate'
import { startCase } from 'lodash'
import Panel from 'Components/Panel/Panel'
import { useUploadLogo, useUploadLogoTypes } from 'Routes/shop/agency/shop-setup/LogoUpload/useUploadFile'
import { FetchError } from 'Utilities/fetch++'
import useUser from 'Utilities/Hooks/useUser'
import Loader from 'Components/Rudimentary/Loader'
import SectionInfo from 'Routes/shop/agency/shop-setup/SectionInfo'
import UrlPreviewCopy from 'Routes/shop/agency/shop-setup/UrlPreviewCopy'
import { FormBody } from 'Routes/shop/agency/shop-setup/index'

/* eslint-disable camelcase */

interface Payload extends Landing {
  saveOnly: boolean
}

const AgencyShopSetup: React.FC<PrivateWizardPageProps> = ({
  onwards,
  stargate
}) => {
  const uploadLogo = useUploadLogo() as useUploadLogoTypes
  const addToast = useToast()

  const { agency } = stargate || {}
  if (!agency) throw new Error('Stargate\'s Agency object have no data')
  const landing = agency.landing
  if (!landing) throw new Error('agency.landing have no data')
  // const label = useWhiteLabel().label

  const defaults: Payload = {
    name: landing.name,
    address1: landing.address1,
    address2: landing.address2,
    city: landing.city,
    state: landing.state,
    zip: landing.zip,
    phone: landing.phone,
    email: landing.email,
    website: landing.website,
    calendly: landing.calendly,
    additionalText: landing.additionalText,
    saveOnly: false
  }
  const form = useForm({ defaultValues: defaults })

  const {
    value: user,
    loading
  } = useUser()
  if (loading) return <Loader/>

  return (
    <div className={styles.shopSetup}>
      <h1>Personalize Your Page</h1>

      <Panel>
        Create a personalized page to direct clients to sign up and shop with
        your agency. They may reach out to you for assistance or input their own
        census. Clients will be prevented from viewing and selecting plans
        without your assistance.
      </Panel>

      <SectionInfo
        header="your Landing page url"
        main="This uses the same agency slug you created during the onboarding process to match your unique agency shop link."
        aside={(
          <UrlPreviewCopy key="1" url={`${location.origin}/landing/${agency?.slug}`}/>
        )}
      ></SectionInfo>
      <Form
        form={form}
        useUploadLogo={uploadLogo}
        callback={save}
        logo={user?.avatar}
      />
    </div>
  )

  function makeFormData() {
    const formData = new FormData()
    const actionType = 'agencyLogo' // TodoList.tsx one of Action Type
    formData.append('file', uploadLogo.selectedFile)
    formData.append('type', actionType)
    formData.append('note', startCase(actionType))
    return formData
  }

  function save(data: Payload): Promise<void> {
    return api.v3.agency.landing.POST(data).then(async rsp => {
      if (uploadLogo.selectedFile) {
        await api.v3.agency.logo.POST(makeFormData())
      }
      localStorage.overrideGroupID = rsp.id
      if (!data.saveOnly) {
        return onwards(Promise.resolve())
      } else {
        addToast('Data successfully saved', 'success')
      }
    })
  }
}

type FormProps = {
  form: UseFormMethods<Payload>
  useUploadLogo: useUploadLogoTypes
  callback: (payload: Payload) => Promise<void>
  logo?: string
}

const Form: React.FC<FormProps> = ({
  form,
  useUploadLogo,
  callback,
  logo
}) => {
  const addToast = useToast()
  const {
    register,
    handleSubmit
  } = form
  const requireCopy = (field: string, type: 'fill in' | 'select' = 'fill in') =>
    `Please ${type} your ${field}`
  return (
    <FormProvider {...form}>
      <form className={styles.formContainer} onSubmit={handleSubmit(data => go(data, false))}>
        {/** Business Information */}
        <FormBody register={register} requireCopy={requireCopy} logo={logo} useUploadLogo={useUploadLogo} form={form}/>
        <div className={styles.saveContainer}>
          <button className={styles.saveButton} onClick={handleSubmit((data) => go(data, true))}>Save</button>
        </div>
      </form>
    </FormProvider>
  )

  async function go(data: Payload, saveOnly: boolean) {
    try {
      const data_: Payload = {
        ...data,
        saveOnly
      }
      await callback(data_)
    } catch (error) {
      addToast(error as FetchError)
    }
  }
}

export default AgencyShopSetup
