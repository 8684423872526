import moment from 'moment/moment'
import XlsxPopulate, { Workbook } from 'xlsx-populate'
import { D2 } from '../../../../../../../Components/Plans/DentalPlan/index.helpers'
import { LifeEEPlan, LifeERPlan } from '../../../../../../../Components/Plans/LifePlan/index.helpers'
import { LtdEEPlan, LtdERPlan } from '../../../../../../../Components/Plans/LTDPlan/index.helpers'
import { StdEEPlan, StdERPlan } from '../../../../../../../Components/Plans/STDPlan/index.helpers'
import SupplementalPlan from '../../../../../../../Components/Plans/SupplementalPlan/index.helpers'
import VisionPlan from '../../../../../../../Components/Plans/VisionPlan/index.helpers'
import { Broker, MedicalPlan, SampleQuote, TieredRates } from '../../../../../../../Utilities/pharaoh.types'
import ContributionsCalculator, {
  AncillaryPlanUnion,
  GroupPlanType,
  moneyString
} from '../../../../../../../Utilities/Plans/ContributionCalculator'
import { relativePath } from '../../../../../../index'
import { dentalPlanType } from '../PlanProposal/DentalPlanProposal'
import { getMedicalPremiums } from '../PlanProposal/MedicalPlanProposal'
import { getPremiums } from '../PlanProposal/PlanProposal'
import { ProposalGroupInfo, TierCount } from '../Proposal'

export function groupFileName(name: string) {
  return name.trim()
    .replace(/[^a-z0-9]/gi, '-')
    .replace(/^-+|-+(?=-|$)/g, '')
    .toLowerCase()
}
export function downloadExcel(broker: Broker, group: ProposalGroupInfo, enrollCount: TierCount, proposedMedical: MedicalPlan[], proposedAncillary: AncillaryPlanUnion[], renewalPlans: MedicalPlan[], calc: ContributionsCalculator) {
  const fileName = `proposal-for-${groupFileName(group.name)}`
  function getWorkbook():Promise<Workbook> {
    return new Promise(function(resolve, reject) {
      const req = new XMLHttpRequest()
      const url = `${relativePath()}/proposal.xlsx`
      req.open('GET', url, true)
      req.responseType = 'arraybuffer'
      req.onreadystatechange = function() {
        if (req.readyState === 4) {
          if (req.status === 200) {
            resolve(XlsxPopulate.fromDataAsync(req.response))
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Received a ' + req.status + ' HTTP code.')
          }
        }
      }
      req.send()
    })
  }

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const cellIndexes = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  const ancillaryPlansObj = {
    [GroupPlanType.dental]: proposedAncillary.filter(p => p.plan.type === GroupPlanType.dental),
    [GroupPlanType.vision]: proposedAncillary.filter(p => p.plan.type === GroupPlanType.vision) as {rate: TieredRates, plan: VisionPlan}[],
    [GroupPlanType.life]: proposedAncillary.filter(p => [GroupPlanType.lifeER, GroupPlanType.lifeEE].includes(p.plan.type)) as {rate: TieredRates, plan: LifeERPlan | LifeEEPlan}[],
    [GroupPlanType.std]: proposedAncillary.filter(p => [GroupPlanType.stdER, GroupPlanType.stdEE].includes(p.plan.type)) as {rate: TieredRates, plan: StdEEPlan | StdERPlan, sampleQuote: SampleQuote}[],
    [GroupPlanType.ltdEE]: proposedAncillary.filter(p => [GroupPlanType.ltdER, GroupPlanType.ltdEE].includes(p.plan.type)) as {rate: TieredRates, plan: LtdERPlan | LtdEEPlan, sampleQuote: SampleQuote}[],
    [GroupPlanType.accident]: proposedAncillary.find(p => p.plan.type === GroupPlanType.accident) as {rate: TieredRates, plan: SupplementalPlan},
    [GroupPlanType.cancer]: proposedAncillary.find(p => p.plan.type === GroupPlanType.cancer) as {rate: TieredRates, plan: SupplementalPlan},
    [GroupPlanType.criticalIllness]: proposedAncillary.find(p => p.plan.type === GroupPlanType.criticalIllness) as {rate: TieredRates, plan: SupplementalPlan},
    [GroupPlanType.hospital]: proposedAncillary.find(p => p.plan.type === GroupPlanType.hospital) as {rate: TieredRates, plan: SupplementalPlan}
  }
  const styleObj = {
    header1: {
      fontColor: 'FFFFFF',
      fontSize: 24,
      fill: '134461',
      fontFamily: 'Ariel'
    },
    header2: {
      fontColor: 'FFFFFF',
      fontSize: 20,
      fill: '134461',
      fontFamily: 'Ariel'
    },
    header2Light: {
      fontColor: 'FFFFFF',
      fontSize: 20,
      fill: '1D6592',
      fontFamily: 'Ariel',
      verticalAlignment: 'center'
    },
    header3: {
      fontColor: 'FFFFFF',
      fontSize: 16,
      fill: 4,
      fontFamily: 'Ariel'
    },
    p: {
      fontColor: 'FFFFFF',
      fontSize: 12,
      fill: '1D6592',
      fontFamily: 'Ariel'
    },
    blackRow: {
      fontSize: 10,
      fill: '101010',
      fontFamily: 'Consolas',
      bold: true,
      fontColor: 'FFFFFF',
      horizontalAlignment: 'center',
      verticalAlignment: 'center'
    },
    planName: {
      fontSize: 10,
      fill: '48A3DB',
      fontColor: 'FFFFFF',
      fontFamily: 'Consolas',
      horizontalAlignment: 'center',
      verticalAlignment: 'center',
      wrapText: true
    },
    carrier: {
      fontSize: 10,
      fill: '1D6592',
      fontColor: 'FFFFFF',
      fontFamily: 'Consolas',
      bold: true,
      horizontalAlignment: 'center',
      verticalAlignment: 'center',
      wrapText: true
    },
    renewalData: {
      fontSize: 10,
      fill: 'D0F4E3',
      fontFamily: 'Consolas',
      horizontalAlignment: 'center',
      verticalAlignment: 'center'
    },
    planData: {
      fontSize: 10,
      fontFamily: 'Consolas',
      horizontalAlignment: 'center',
      verticalAlignment: 'center'
    },
    annualPremium: {
      fontSize: 10,
      fontFamily: 'Consolas',
      fill: 'B6DAF1',
      horizontalAlignment: 'center',
      verticalAlignment: 'center'
    }
  }
  // Landing Page
  getWorkbook().then(function(workbook: Workbook) {
    workbook.sheet('Landing Page').cell('C4').value(group.name).style(styleObj.header2)
    workbook.sheet('Landing Page').cell('C6').value(moment().format('MMMM Do, YYYY')).style({
      fontColor: 'FFFFFF',
      fontSize: 18,
      bold: true,
      fill: '1D6592'
    })
    workbook.sheet('Landing Page').cell('C7').value(broker.name).style(styleObj.header2Light)
    workbook.sheet('Landing Page').cell('C8').value(broker.agency?.name).style(styleObj.p)
    workbook.sheet('Landing Page').cell('C9').value(`${broker.agency?.address1} ${broker.agency?.address2}`).style(styleObj.p)
    workbook.sheet('Landing Page').cell('C10').value(`${broker.agency?.city} ${broker.agency?.state} ${broker.agency?.zip}`).style(styleObj.p)
    workbook.sheet('Landing Page').cell('C12').value(broker.phone).style(styleObj.p)
    workbook.sheet('Landing Page').cell('C113').value(broker.email).style(styleObj.p)
    const populateMedicalSheet = (sheet: XlsxPopulate.Sheet, i: number, plan: MedicalPlan) => {
      let isRenewal = false
      if (i < renewalPlans.length) {
        isRenewal = true
      }
      const renewalHeaderText = () => {
        if (isRenewal && (renewalPlans.length > 1)) {
          return `Current ${i + 1}`
        } else if (isRenewal && renewalPlans.length === 1) {
          return 'Current'
        } else {
          return `Option ${i + 1}`
        }
      }
      const renewalStyleOrNot = isRenewal ? styleObj.renewalData : styleObj.planData
      sheet.cell(`${cellIndexes[i]}1`).value(renewalHeaderText()).style(styleObj.blackRow)
      if (plan.sbc && isValidUrl(plan.sbc)) {
        sheet.cell(`${cellIndexes[i]}2`).value(plan.name).hyperlink(plan.sbc).style(styleObj.planName)
      } else {
        sheet.cell(`${cellIndexes[i]}2`).value(plan.name).style(styleObj.planName)
      }
      sheet.cell(`${cellIndexes[i]}3`).value(plan.carrier).style(styleObj.carrier)
      sheet.cell(`${cellIndexes[i]}4`).value(plan.type).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}5`).value(plan.hasProsper ? 'Included' : 'Not Included').style(renewalStyleOrNot)
      // RX heading
      sheet.cell(`${cellIndexes[i]}6`).style({ fill: '1D6592' })
      sheet.cell(`${cellIndexes[i]}7`).value(plan.prescription.generic).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}8`).value(plan.prescription.preferredBrand).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}9`).value(plan.prescription.specialty).style(renewalStyleOrNot)
      // In Network Heading
      sheet.cell(`${cellIndexes[i]}10`).style({ fill: '1D6592' })
      sheet.cell(`${cellIndexes[i]}11`).value(plan.deductible).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}12`).value(plan.oopMax).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}13`).value(plan.coinsurance).style(renewalStyleOrNot)
      // Copay Heading
      sheet.cell(`${cellIndexes[i]}14`).style({ fill: '1D6592' })
      sheet.cell(`${cellIndexes[i]}15`).value(plan.copay.primaryCarePhysician).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}16`).value(plan.copay.specialist).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}17`).value(plan.copay.urgentCare).style(renewalStyleOrNot)
      // Monthly Premiums Heading
      sheet.cell(`${cellIndexes[i]}18`).style({ fill: '1D6592' })
      sheet.cell(`${cellIndexes[i]}19`).value(plan.premium.employee.individual).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}20`).value(plan.premium.employee.couple).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}21`).value(plan.premium.employee.singleParent).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}22`).value(plan.premium.employee.family).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}23`).value(getMedicalPremiums(enrollCount, plan).monthly).style(styleObj.blackRow)
      sheet.cell(`${cellIndexes[i]}24`).value(getMedicalPremiums(enrollCount, plan).annual).style(styleObj.annualPremium)
      // Contributions Header
      sheet.cell(`${cellIndexes[i]}25`).style({ fill: '1D6592' })
      sheet.cell(`${cellIndexes[i]}26`).value(moneyString(calc?.premiums([plan], false).er)).style(renewalStyleOrNot)
      sheet.cell(`${cellIndexes[i]}27`).value(moneyString(calc?.premiums([plan], false).er * 12)).style(renewalStyleOrNot)
    }
    const medicalPlans = [...renewalPlans, ...proposedMedical]
    // Medical Plans
    if (medicalPlans.length) {
      const sheet = workbook.sheet('Medical Plans')
      sheet.cell('A1')
        .value(`Medical: ${moment(group.effectiveDate).format('MM/DD/YYYY')}`)
        .style(styleObj.blackRow)
        .style({ horizontalAlignment: 'left' })
      medicalPlans.forEach((medicalPlan, i) => {
        populateMedicalSheet(sheet, i, medicalPlan)
      })
    } else {
      workbook.deleteSheet('Medical Plans')
    }
    // Dental Plans
    if (ancillaryPlansObj[GroupPlanType.dental].length) {
      const sheet = workbook.sheet('Dental Plans')
      sheet.cell('A1')
        .value(`Dental: ${moment(group.effectiveDate).format('MM/DD/YYYY')}`)
        .style(styleObj.blackRow)
        .style({ horizontalAlignment: 'left' })
      ancillaryPlansObj.dental.forEach((dentalPlan, i) => {
        sheet.cell(`${cellIndexes[i]}1`).value(`Option ${i + 1}`).style(styleObj.blackRow)
        if (dentalPlan.plan.data.sbc && isValidUrl(dentalPlan.plan.data.sbc)) {
          sheet.cell(`${cellIndexes[i]}2`).value(dentalPlan.plan.name).hyperlink(dentalPlan.plan.data.sbc).style(styleObj.planName)
        } else {
          sheet.cell(`${cellIndexes[i]}2`).value(dentalPlan.plan.name).style(styleObj.planName)
        }
        sheet.cell(`${cellIndexes[i]}3`).value(dentalPlan.plan.carrier).style(styleObj.carrier)
        sheet.cell(`${cellIndexes[i]}4`).value(dentalPlanType(dentalPlan.plan.name)).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}5`).value((dentalPlan.plan.data as D2).deductibleCombined).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}6`).value((dentalPlan.plan.data as D2).familyDeductibleMaximum).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}7`).value((dentalPlan.plan.data as D2).maxCombined).style(styleObj.planData)
        // In Network
        sheet.cell(`${cellIndexes[i]}8`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}9`).value((dentalPlan.plan.data as D2).basicDeductibleInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}10`).value((dentalPlan.plan.data as D2).preventativeDeductibleInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}11`).value((dentalPlan.plan.data as D2).majorDeductibleInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}12`).value((dentalPlan.plan.data as D2).basicMaxInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}13`).value((dentalPlan.plan.data as D2).preventativeMaxInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}14`).value((dentalPlan.plan.data as D2).majorMaxInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}15`).value((dentalPlan.plan.data as D2).basicCoinsuranceInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}16`).value((dentalPlan.plan.data as D2).preventativeCoinsuranceInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}17`).value((dentalPlan.plan.data as D2).majorCoinsuranceInNetwork).style(styleObj.planData)
        // Out Of Network
        sheet.cell(`${cellIndexes[i]}18`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}19`).value((dentalPlan.plan.data as D2).basicDeductibleOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}20`).value((dentalPlan.plan.data as D2).preventativeDeductibleOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}21`).value((dentalPlan.plan.data as D2).majorDeductibleOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}22`).value((dentalPlan.plan.data as D2).basicMaxOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}23`).value((dentalPlan.plan.data as D2).preventativeMaxOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}24`).value((dentalPlan.plan.data as D2).majorMaxOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}25`).value((dentalPlan.plan.data as D2).basicCoinsuranceOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}26`).value((dentalPlan.plan.data as D2).preventativeCoinsuranceOutOfNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}27`).value((dentalPlan.plan.data as D2).majorCoinsuranceOutOfNetwork).style(styleObj.planData)
        // Monthly Premiums
        sheet.cell(`${cellIndexes[i]}28`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}29`).value(dentalPlan.rate.individual).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}30`).value(dentalPlan.rate.couple).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}31`).value(dentalPlan.rate.singleParent).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}32`).value(dentalPlan.rate.family).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}33`).value(getPremiums(enrollCount, dentalPlan.rate).monthly).style(styleObj.blackRow)
        sheet.cell(`${cellIndexes[i]}34`).value(getPremiums(enrollCount, dentalPlan.rate).annual).style(styleObj.annualPremium)
      })
    } else {
      workbook.deleteSheet('Dental Plans')
    }
    // Vision Plans
    if (ancillaryPlansObj[GroupPlanType.vision].length) {
      const sheet = workbook.sheet('Vision Plans')
      sheet.cell('A1')
        .value(`Vision: ${moment(group.effectiveDate).format('MM/DD/YYYY')}`)
        .style(styleObj.blackRow)
        .style({ horizontalAlignment: 'left' })
      ancillaryPlansObj[GroupPlanType.vision].forEach((visionPlan, i) => {
        sheet.cell(`${cellIndexes[i]}1`).value(`Option ${i + 1}`).style(styleObj.blackRow)
        if (visionPlan.plan.data.sbc && isValidUrl(visionPlan.plan.data.sbc)) {
          sheet.cell(`${cellIndexes[i]}2`).value(visionPlan.plan.name).hyperlink(visionPlan.plan.data.sbc).style(styleObj.planName)
        } else {
          sheet.cell(`${cellIndexes[i]}2`).value(visionPlan.plan.name).style(styleObj.planName)
        }
        sheet.cell(`${cellIndexes[i]}3`).value(visionPlan.plan.carrier).style(styleObj.carrier)
        sheet.cell(`${cellIndexes[i]}4`).value(visionPlan.plan.data.comprehensiveVisionExamInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}5`).value(visionPlan.plan.data.comprehensiveVisionExamFrequencyInMonths).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}6`).value(visionPlan.plan.data.framesAllowanceInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}7`).value(visionPlan.plan.data.framesAllowanceFrequencyInMonths).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}8`).value(visionPlan.plan.data.contactLensesInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}9`).value(visionPlan.plan.data.contactLensesFrequencyInMonths).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}10`).value(visionPlan.plan.data.materialsLensesFramesAndContactsInNetwork).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}11`).value(visionPlan.plan.data.materialsLensesFramesAndContactsFrequencyInMonths).style(styleObj.planData)
        // Monthly Premiums
        sheet.cell(`${cellIndexes[i]}12`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}13`).value(visionPlan.rate.individual).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}14`).value(visionPlan.rate.couple).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}15`).value(visionPlan.rate.singleParent).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}16`).value(visionPlan.rate.family).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}17`).value(getPremiums(enrollCount, visionPlan.rate).monthly).style(styleObj.blackRow)
        sheet.cell(`${cellIndexes[i]}18`).value(getPremiums(enrollCount, visionPlan.rate).annual).style(styleObj.annualPremium)
      })
    } else {
      workbook.deleteSheet('Vision Plans')
    }
    // Life Plans
    if (ancillaryPlansObj[GroupPlanType.life].length) {
      const sheet = workbook.sheet('Life Plans')
      sheet.cell('A1')
        .value(`Life: ${moment(group.effectiveDate).format('MM/DD/YYYY')}`)
        .style(styleObj.blackRow)
        .style({ horizontalAlignment: 'left' })
      ancillaryPlansObj[GroupPlanType.life].forEach((lifePlan, i) => {
        sheet.cell(`${cellIndexes[i]}1`).value(`Option ${i + 1}`).style(styleObj.blackRow)
        if (lifePlan.plan.data.sbc && isValidUrl(lifePlan.plan.data.sbc)) {
          sheet.cell(`${cellIndexes[i]}2`).value(lifePlan.plan.name).hyperlink(lifePlan.plan.data.sbc).style(styleObj.planName)
        } else {
          sheet.cell(`${cellIndexes[i]}2`).value(lifePlan.plan.name).style(styleObj.planName)
        }
        sheet.cell(`${cellIndexes[i]}3`).value(lifePlan.plan.carrier).style(styleObj.carrier)
        sheet.cell(`${cellIndexes[i]}4`).value(lifePlan.plan.data.benefitAmount).style(styleObj.planData)
        if (lifePlan.plan.type === GroupPlanType.lifeER) {
          sheet.cell(`${cellIndexes[i]}5`).value(lifePlan.plan.data.accidentalDeathAndDismemberment).style(styleObj.planData)
          sheet.cell(`${cellIndexes[i]}6`).value(lifePlan.plan.data.conversion).style(styleObj.planData)
        } else {
          sheet.cell(`${cellIndexes[i]}7`).value(lifePlan.plan.data.planTerm).style(styleObj.planData)
          sheet.cell(`${cellIndexes[i]}8`).value(lifePlan.plan.data.portability).style(styleObj.planData)
        }
        // Monthly Premiums
        sheet.cell(`${cellIndexes[i]}9`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}10`).value(lifePlan.rate.individual).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}11`).value(lifePlan.rate.couple).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}12`).value(lifePlan.rate.singleParent).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}13`).value(lifePlan.rate.family).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}14`).value(getPremiums(enrollCount, lifePlan.rate).monthly).style(styleObj.blackRow)
        sheet.cell(`${cellIndexes[i]}15`).value(getPremiums(enrollCount, lifePlan.rate).annual).style(styleObj.annualPremium)
      })
    } else {
      workbook.deleteSheet('Life Plans')
    }
    // STD Plans
    if (ancillaryPlansObj[GroupPlanType.std].length) {
      const sheet = workbook.sheet('Short Term Disability Plans')
      sheet.cell('A1')
        .value(`Short Term Disability: ${moment(group.effectiveDate).format('MM/DD/YYYY')}`)
        .style(styleObj.blackRow)
        .style({ horizontalAlignment: 'left' })
      ancillaryPlansObj[GroupPlanType.std].forEach((stdPlan, i) => {
        sheet.cell(`${cellIndexes[i]}1`).value(`Option ${i + 1}`).style(styleObj.blackRow)
        if (stdPlan.plan.data.sbc && isValidUrl(stdPlan.plan.data.sbc)) {
          sheet.cell(`${cellIndexes[i]}2`).value(stdPlan.plan.name).hyperlink(stdPlan.plan.data.sbc).style(styleObj.planName)
        } else {
          sheet.cell(`${cellIndexes[i]}2`).value(stdPlan.plan.name).style(styleObj.planName)
        }
        sheet.cell(`${cellIndexes[i]}3`).value(stdPlan.plan.carrier).style(styleObj.carrier)
        sheet.cell(`${cellIndexes[i]}4`).value(stdPlan.plan.data.monthlyPayment).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}5`).value(stdPlan.plan.data.benefitPeriod).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}6`).value(stdPlan.plan.data.eliminationPeriodAccident).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}7`).value(stdPlan.plan.data.eliminationPeriodSickness).style(styleObj.planData)
        // Monthly Premium
        sheet.cell(`${cellIndexes[i]}8`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}9`).value(stdPlan.rate.individual).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}10`).value(stdPlan.rate.couple).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}11`).value(stdPlan.rate.singleParent).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}12`).value(stdPlan.rate.family).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}13`).value(getPremiums(enrollCount, stdPlan.rate).monthly).style(styleObj.blackRow)
        sheet.cell(`${cellIndexes[i]}14`).value(getPremiums(enrollCount, stdPlan.rate).annual).style(styleObj.annualPremium)
        // Sample Quote
        sheet.cell(`${cellIndexes[i]}15`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}16`).value(stdPlan.sampleQuote.rate).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}17`).value(stdPlan.sampleQuote.aveAge).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}18`).value(stdPlan.sampleQuote.sampleSalary).style(styleObj.planData)
      })
    } else {
      workbook.deleteSheet('Short Term Disability Plans')
    }
    // LTD Plans
    if (ancillaryPlansObj[GroupPlanType.ltdEE].length) {
      const sheet = workbook.sheet('Long Term Disability Plans')
      sheet.cell('A1')
        .value(`Long Term Disability: ${moment(group.effectiveDate).format('MM/DD/YYYY')}`)
        .style(styleObj.blackRow)
        .style({ horizontalAlignment: 'left' })
      ancillaryPlansObj[GroupPlanType.ltdEE].forEach((ltdPlan, i) => {
        sheet.cell(`${cellIndexes[i]}1`).value(`Option ${i + 1}`).style(styleObj.blackRow)
        if (ltdPlan.plan.data.sbc && isValidUrl(ltdPlan.plan.data.sbc)) {
          sheet.cell(`${cellIndexes[i]}2`).value(ltdPlan.plan.name).hyperlink(ltdPlan.plan.data.sbc).style(styleObj.planName)
        } else {
          sheet.cell(`${cellIndexes[i]}2`).value(ltdPlan.plan.name).style(styleObj.planName)
        }
        sheet.cell(`${cellIndexes[i]}3`).value(ltdPlan.plan.carrier).style(styleObj.carrier)
        sheet.cell(`${cellIndexes[i]}4`).value(ltdPlan.plan.data.monthlyPayment).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}5`).value(ltdPlan.plan.data.benefitPeriod).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}6`).value(ltdPlan.plan.data.eliminationPeriod).style(styleObj.planData)
        // Monthly Premiums
        sheet.cell(`${cellIndexes[i]}7`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}8`).value(ltdPlan.rate.individual).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}9`).value(ltdPlan.rate.couple).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}10`).value(ltdPlan.rate.singleParent).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}11`).value(ltdPlan.rate.family).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}12`).value(getPremiums(enrollCount, ltdPlan.rate).monthly).style(styleObj.blackRow)
        sheet.cell(`${cellIndexes[i]}13`).value(getPremiums(enrollCount, ltdPlan.rate).annual).style(styleObj.annualPremium)
        // Sample Quote
        sheet.cell(`${cellIndexes[i]}14`).style({ fill: '1D6592' })
        sheet.cell(`${cellIndexes[i]}15`).value(ltdPlan.sampleQuote.rate).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}16`).value(ltdPlan.sampleQuote.aveAge).style(styleObj.planData)
        sheet.cell(`${cellIndexes[i]}17`).value(ltdPlan.sampleQuote.sampleSalary).style(styleObj.planData)
      })
    } else {
      workbook.deleteSheet('Long Term Disability Plans')
    }
    // Worksite Plans
    if (ancillaryPlansObj[GroupPlanType.accident]) {
      const sheet = workbook.sheet('Worksite Plans')
      sheet.cell('B1').value('Option 1').style(styleObj.blackRow)
      if (ancillaryPlansObj[GroupPlanType.accident].plan.data.sbc && isValidUrl(ancillaryPlansObj[GroupPlanType.accident].plan.data.sbc)) {
        sheet.cell('B2').value(ancillaryPlansObj[GroupPlanType.accident].plan.name).hyperlink(ancillaryPlansObj[GroupPlanType.accident].plan.data.sbc).style(styleObj.planName)
      } else {
        sheet.cell('B2').value(ancillaryPlansObj[GroupPlanType.accident].plan.name).style(styleObj.planName)
      }
      sheet.cell('B3').value(ancillaryPlansObj[GroupPlanType.accident].plan.carrier).style(styleObj.carrier)
      sheet.cell('B4').value(ancillaryPlansObj[GroupPlanType.accident].plan.data.accidentalDeathBenefit).style(styleObj.planData)
      sheet.cell('B5').value(ancillaryPlansObj[GroupPlanType.accident].plan.data.initialAccidentHospitalizationBenefit).style(styleObj.planData)
      sheet.cell('B6').value(ancillaryPlansObj[GroupPlanType.accident].plan.data.accidentHospitalConfinementBenefit).style(styleObj.planData)
      // Monthly Premiums
      sheet.cell('B7').style({ fill: '1D6592' })
      sheet.cell('B8').value(ancillaryPlansObj[GroupPlanType.accident].rate.individual).style(styleObj.planData)
      sheet.cell('B9').value(ancillaryPlansObj[GroupPlanType.accident].rate.couple).style(styleObj.planData)
      sheet.cell('B10').value(ancillaryPlansObj[GroupPlanType.accident].rate.singleParent).style(styleObj.planData)
      sheet.cell('B11').value(ancillaryPlansObj[GroupPlanType.accident].rate.family).style(styleObj.planData)
      sheet.cell('B12').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.accident].rate).monthly).style(styleObj.blackRow)
      sheet.cell('B13').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.accident].rate).annual).style(styleObj.annualPremium)
    }
    if (ancillaryPlansObj[GroupPlanType.cancer]) {
      const sheet = workbook.sheet('Worksite Plans')
      sheet.cell('D1').value('Option 1').style(styleObj.blackRow)
      if (ancillaryPlansObj[GroupPlanType.cancer].plan.data.sbc && isValidUrl(ancillaryPlansObj[GroupPlanType.cancer].plan.data.sbc)) {
        sheet.cell('D2').value(ancillaryPlansObj[GroupPlanType.cancer].plan.name).hyperlink(ancillaryPlansObj[GroupPlanType.cancer].plan.data.sbc).style(styleObj.planName)
      } else {
        sheet.cell('D2').value(ancillaryPlansObj[GroupPlanType.cancer].plan.name).style(styleObj.planName)
      }
      sheet.cell('D3').value(ancillaryPlansObj[GroupPlanType.cancer].plan.carrier).style(styleObj.carrier)
      sheet.cell('D4').value(ancillaryPlansObj[GroupPlanType.cancer].plan.data.benefitAmount).style(styleObj.planData)
      sheet.cell('D5').value(ancillaryPlansObj[GroupPlanType.cancer].plan.data.hospiceCare).style(styleObj.planData)
      sheet.cell('D6').value(ancillaryPlansObj[GroupPlanType.cancer].plan.data.stemCellAndBoneMarrowTransplant).style(styleObj.planData)
      sheet.cell('D7').value(ancillaryPlansObj[GroupPlanType.cancer].plan.data.surgicalAndAnesthesia).style(styleObj.planData)
      // Monthly Premiums
      sheet.cell('D8').style({ fill: '1D6592' })
      sheet.cell('D9').value(ancillaryPlansObj[GroupPlanType.cancer].rate.individual).style(styleObj.planData)
      sheet.cell('D10').value(ancillaryPlansObj[GroupPlanType.cancer].rate.couple).style(styleObj.planData)
      sheet.cell('D11').value(ancillaryPlansObj[GroupPlanType.cancer].rate.singleParent).style(styleObj.planData)
      sheet.cell('D12').value(ancillaryPlansObj[GroupPlanType.cancer].rate.family).style(styleObj.planData)
      sheet.cell('D13').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.cancer].rate).monthly).style(styleObj.blackRow)
      sheet.cell('D14').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.cancer].rate).annual).style(styleObj.annualPremium)
    }
    if (ancillaryPlansObj[GroupPlanType.criticalIllness]) {
      const sheet = workbook.sheet('Worksite Plans')
      sheet.cell('F1').value('Option 1').style(styleObj.blackRow)
      if (ancillaryPlansObj[GroupPlanType.criticalIllness].plan.data.sbc && isValidUrl(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.data.sbc)) {
        sheet.cell('F2').value(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.name).hyperlink(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.data.sbc).style(styleObj.planName)
      } else {
        sheet.cell('F2').value(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.name).style(styleObj.planName)
      }
      sheet.cell('F3').value(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.carrier).style(styleObj.carrier)
      sheet.cell('F4').value(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.data.majorCriticalIllnessEvent).style(styleObj.planData)
      sheet.cell('F5').value(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.data.subsequentCriticalIllnessEvent).style(styleObj.planData)
      sheet.cell('F6').value(ancillaryPlansObj[GroupPlanType.criticalIllness].plan.data.suddenCardiacArrestBenefit).style(styleObj.planData)
      // Monthly Premiums
      sheet.cell('F7').style({ fill: '1D6592' })
      sheet.cell('F8').value(ancillaryPlansObj[GroupPlanType.criticalIllness].rate.individual).style(styleObj.planData)
      sheet.cell('F9').value(ancillaryPlansObj[GroupPlanType.criticalIllness].rate.couple).style(styleObj.planData)
      sheet.cell('F10').value(ancillaryPlansObj[GroupPlanType.criticalIllness].rate.singleParent).style(styleObj.planData)
      sheet.cell('F11').value(ancillaryPlansObj[GroupPlanType.criticalIllness].rate.family).style(styleObj.planData)
      sheet.cell('F12').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.criticalIllness].rate).monthly).style(styleObj.blackRow)
      sheet.cell('F13').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.criticalIllness].rate).annual).style(styleObj.annualPremium)
    }
    if (ancillaryPlansObj[GroupPlanType.hospital]) {
      const sheet = workbook.sheet('Worksite Plans')
      sheet.cell('H1').value('Option 1').style(styleObj.blackRow)
      if (ancillaryPlansObj[GroupPlanType.hospital].plan.data.sbc && isValidUrl(ancillaryPlansObj[GroupPlanType.hospital].plan.data.sbc)) {
        sheet.cell('H2').value(ancillaryPlansObj[GroupPlanType.hospital].plan.name).hyperlink(ancillaryPlansObj[GroupPlanType.hospital].plan.data.sbc).style(styleObj.planName)
      } else {
        sheet.cell('H2').value(ancillaryPlansObj[GroupPlanType.hospital].plan.name).style(styleObj.planName)
      }
      sheet.cell('H3').value(ancillaryPlansObj[GroupPlanType.hospital].plan.carrier).style(styleObj.carrier)
      sheet.cell('H4').value(ancillaryPlansObj[GroupPlanType.hospital].plan.data.hospitalConfinementBenefit).style(styleObj.planData)
      sheet.cell('H5').value(ancillaryPlansObj[GroupPlanType.hospital].plan.data.emergencyRoomBenefit).style(styleObj.planData)
      sheet.cell('H6').value(ancillaryPlansObj[GroupPlanType.hospital].plan.data.hospitalShortStay).style(styleObj.planData)
      // Monthly Premiums
      sheet.cell('H7').style({ fill: '1D6592' })
      sheet.cell('H8').value(ancillaryPlansObj[GroupPlanType.hospital].rate.individual).style(styleObj.planData)
      sheet.cell('H9').value(ancillaryPlansObj[GroupPlanType.hospital].rate.couple).style(styleObj.planData)
      sheet.cell('H10').value(ancillaryPlansObj[GroupPlanType.hospital].rate.singleParent).style(styleObj.planData)
      sheet.cell('H11').value(ancillaryPlansObj[GroupPlanType.hospital].rate.family).style(styleObj.planData)
      sheet.cell('H12').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.hospital].rate).monthly).style(styleObj.blackRow)
      sheet.cell('H13').value(getPremiums(enrollCount, ancillaryPlansObj[GroupPlanType.hospital].rate).annual).style(styleObj.annualPremium)
    }
    if (!ancillaryPlansObj[GroupPlanType.accident] && !ancillaryPlansObj[GroupPlanType.cancer] && !ancillaryPlansObj[GroupPlanType.criticalIllness] && !ancillaryPlansObj[GroupPlanType.hospital]) {
      workbook.deleteSheet('Worksite Plans')
    } else {
      workbook.sheet('Worksite Plans').cell('A1')
        .value(`Worksite: ${moment(group.effectiveDate).format('MM/DD/YYYY')}`).style(styleObj.blackRow)
        .style({ horizontalAlignment: 'left' })
    }
    // Set first tab to selected tab
    workbook.activeSheet('Landing Page')
    workbook.outputAsync()
      .then(function(blob: any) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // If IE, you must use a different method.
          window.navigator.msSaveOrOpenBlob(blob, `${fileName}.xlsx`)
        } else {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url
          a.download = `${fileName}.xlsx`
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }
      })
  })
}
