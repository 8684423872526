import React from 'react'
import { useAsync } from 'react-use'
import { Redirect } from 'react-router-dom'

import * as api from 'Utilities/pharaoh'
import { Route } from '../../Utilities/Route'

const Reset: React.FC = () => {
  const async = useAsync(api.v1.users.sessions.DELETE)

  // NOTE we ignore errors
  if (async.loading) {
    return <h1>Clearing your session...</h1>
  } else {
    // Clear the local storage
    localStorage.clear()
    return <Redirect to={Route.landing}/>
  }
}

export default Reset
