import React from 'react'
import styles from './AssignGroupManager.module.scss'
import { useAsync } from 'react-use'
import * as api from 'Utilities/pharaoh'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import Select from 'Components/Rudimentary/Select'
import { sortBy } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import useToast from 'Utilities/Hooks/useToast'

interface GroupManager {
    id: string
    name: string
}

const AssignAccountManager: React.FC<{ groupManagerID? : string, groupID: string }> = ({ groupManagerID, groupID }) => {
  const async = useAsync(async() => {
    const rsp = await api.v3.groups(groupID).agency.brokers.GET() as [GroupManager]
    return sortBy(rsp, 'name')
  })

  const form = useForm()
  const addToast = useToast()

  if (async.loading) return <Loader />
  if (async.error) return <Error error={async.error} />

  const managers = async.value!.map(b => ({ value: b.id, label: b.name }))

  if (groupManagerID && managers.length > 0) {
    const groupManager = managers.filter(manager => manager.value === groupManagerID)
    return <div className={styles.container}>
      <h3>Account Manager</h3>
      <div className={styles.producers}>
        <label>{groupManager[0].label}</label>
      </div>
    </div>
  }
  async function assignGroupManager(data: any) {
    try {
      await api.v2.brokers.managers(data.manager).assign.to(groupID)
      addToast('Account Manager assigned to group', 'info')
    } catch (error) {
      addToast(error as Error)
    }
  }

  return <div className={styles.container}>
    <h2 className={styles.header}>Assign Account Manager</h2>
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(assignGroupManager)} style={{ width: '100%' }} >
        <Select name='manager' control={form.control} options={managers} placeholder='Account Manager' backgroundColor='#fff'/>
        <button className={styles.assignButton} type='submit'>Assign</button>
      </form>
    </FormProvider>
  </div>
}

export default AssignAccountManager
