import React from 'react'
import { Contributions } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates, AncillaryMemberPremium } from 'Utilities/pharaoh.types'
import PlanTitle from '../plan-subcomponents/PlanTitle'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import Plan, { PlanBody } from '../plan-subcomponents/Plan'
import SupplementalPlan from '../SupplementalPlan/index.helpers'
import { isEmployerProps, EmployerProps } from '../plan-subcomponents/PremiumBreakdown'
import EREERate from '../ERSupplementalPlans/ERSupplementalSubComp/EREERate'
import BenifitAmountComp from './ERSupplementalSubComp/BenifitAmountComp'
import PeriodComp from './ERSupplementalSubComp/PeriodComp'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { useToggle } from 'react-use'
import { Group } from 'Utilities/Hooks/useStargate'
import { LifeEEPlan } from '../LifePlan/index.helpers'
import TotalRate from './ERSupplementalSubComp/TotalRate'

interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan | LifeEEPlan, isRenewal: boolean, memberPremiums?: AncillaryMemberPremium}
  contributions: Contributions
  selected?: boolean
  selectHandler?(plan: SupplementalPlan | LifeEEPlan): void
  label: Label
  showWeeklyPayments: boolean
  stargateGroup?: Group
  showModal: boolean
}

const VTLPlan: React.FC< EmployerProps & Props> = (props) => {
  const [showMemberPremiums, toggleShowMemberPremiums] = useToggle(false)
  const { plan, selected, selectHandler, label, showWeeklyPayments, contributions } = props
  const sbc = plan.plan.data.sbc
  return <Plan selected={!!selected}>

    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier={plan.plan.carrier}
      selected={selected}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
      includesProsperBenefits={false}
      planType={props.plan.plan.type}
      label={label}
      sbc={sbc}
      members={props.members}
      stargateGroup={props.stargateGroup}
      ancillaryMemberPremiums={plan.memberPremiums}
      needsMemberPremiumsShown={true}
      showModal={props.showModal}
      showMemberPremiums={showMemberPremiums}
      toggleShowMemberPremiums={toggleShowMemberPremiums}
    />
    <PlanBody planType={plan.plan.type}>
      {breakdown()}
      <BenifitAmountComp name={GlobalStringConstant[0].benefit_amount} benifitAmount={'20,000'} benifitLabel={'face value'}/>
      <div className={`${styles.endSection} ${styles.fourSpecs} ${styles.halfHeight}`}>
        <PeriodComp compName={GlobalStringConstant[0].plan_term} daysOrWeak={'10 years'} label={' '}/>
        <PeriodComp compName={GlobalStringConstant[0].portability} daysOrWeak={'An employee is able to take the policy with them after their employment ends.'} label={' '}/>
      </div>
      <div className={styles.planDescFooter}><p><span>*</span>The term life plan rates depend on the age and tobacco use of each employee.</p></div>
    </PlanBody>
  </Plan>

  function breakdown() {
    const base = { plan, contributions, showWeeklyPayments }
    if (isEmployerProps(props)) {
      return <div className={`${styles.endSection} ${styles.fourSpecs} ${styles.halfHeight}`}><EREERate {...base} members={props.members}/><TotalRate {...base}/></div>
    }
  }
}

export default VTLPlan
