/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import styles from './ShowPlans.module.scss'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { useAsync, useToggle, useLocalStorage } from 'react-use'
import { get } from 'Utilities/fetch++'
import Error from 'Components/Primitives/Error'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import PlansFilter, { FilterType } from 'Components/Stargate/ShowPlans/Filters/PlansFilter'
import PlanCart from '../../../../Components/Stargate/ShowPlans/Components/PlanCart'
import Loader from 'Components/Rudimentary/Loader'
import { sortBy, uniq, compact, inRange, intersection } from 'lodash'
import useToast from 'Utilities/Hooks/useToast'
import * as api from 'Utilities/pharaoh'
import { contributionType as extractContributionType, ContributionSplitType } from 'Components/Stargate/Contribution/ContributionAmount'
import { useHistory, useLocation } from 'react-router-dom'
import { moneyNumber } from 'Utilities/Plans/ContributionCalculator'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import CompareModal from 'Components/Stargate/ShowPlans/Components/CompareModal'
import MedicalPlanComponent from 'Components/Plans/MedicalPlan'
import ALFMedicalPlanComponent from 'Components/Plans/ALFMedicalPlan/ALFMedicalPlan'
import headerStyles from 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss'
import LazyLoad from 'react-lazyload'
import { PlanPlaceholder } from 'Components/Plans/plan-subcomponents/Plan'
import { isAllstate, Carriers, FundingType, isHBA, isApex, isOptionsPlus, isSidecar, isVault } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { GAButton } from 'Components/Tracking'
import BackToTop from 'Components/BackToTop'
import moment from 'moment'
import numeral from 'numeral'

interface medicalPayload {
  metadata: PlansMeta
  medicalPlans: MedicalPlan[]
  selectionsData: {
    selections: {
      planID: string
      selectedAlfCarriers: string[]
    }[]
  }
  alfCarriers: string[]
}

export interface PlansMeta {
  totalNumberOfPlans: number
  carriers: string[]
  largestDeductible: string
  largestPremium: string
  largestOOPMax: string
}

export enum SortingMethod {
  premium = 'premium', deductible = 'deductible', oopMax = 'oop≥'
}

export enum RateType {
  composite = 'tiered',
  ageBanded = 'ageBanded'
}

export interface PlansWithCarriers {
  planID: string
  selectedAlfCarriers: string[]
}

const ERShopPlans: React.FC<PrivateWizardPageProps> = ({ stargate, ...props }) => {
  const { group, members, splits, contributions } = stargate
  const [filteredPlans, setFilteredPlans] = React.useState<MedicalPlan[]>([])
  const [medicalPlans, setMedicalPlans] = React.useState<MedicalPlan[]>([])
  const baseContributions = contributions?.baseContributions
  const [selectedPlanIDs, setSelectedPlanIDs, removeSelectedPlans] = useLocalStorage<string[]>(`selected-plans-${group?.id}`, localStorage.plans as string[] || [])
  const [isCompareModalOpen, toggleCompare] = useToggle(false)
  const [disabled, setDisabled] = useToggle(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [contribution] = useState(baseContributions?.medical || '50%')
  const [contributionType] = useState<ContributionSplitType>(extractContributionType(baseContributions ? !!baseContributions.medicalEquitable : true, baseContributions?.medical || '50%'))
  const rateType = stargate.group?.rateType || RateType.composite
  const [selectedToTop, setSelectedToTop] = useState(false)
  const [selectedALFPlans, setSelectedALFPlans] = useState<PlansWithCarriers[]>([{ planID: '0000', selectedAlfCarriers: [] }])
  const history = useHistory()
  const addToast = useToast()
  const { search, pathname } = useLocation()
  useFullContentArea()

  if (!group || !members) return <Error error='Please complete earlier steps' />

  const { value, loading: loadingPlans, error } = useAsync(async() => {
    const medicalPlansRsp = await get(`/v3/groups/${group?.id}/medicalPlans`) as medicalPayload
    setMedicalPlans(medicalPlansRsp.medicalPlans)
    const { alfCarriers, selectionsData } = medicalPlansRsp
    setSelectedALFPlans(selectionsData.selections.filter(p => p.selectedAlfCarriers && p.selectedAlfCarriers.length))
    setSelectedPlanIDs(selectionsData.selections.map(p => p.planID))
    const metadata = medicalPlansRsp.metadata
    return { medicalPlans, metadata, alfCarriers, selectionsData }
  })

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  React.useMemo(() => {
    // Default filters for first time load
    if (loadingPlans && search.length === 0) {
      history.push(`${pathname}?fundingTypes[]=Medically+Underwritten&fundingTypes[]=Fully+Insured&sort=premium`)
    }
    applyFilters()
    window.scrollBy(0, 1) // Seems like a LazyLoad Bug, the PlaceHolder runs infinitely unless you manually scroll the page, but this fixes it
  }, [search, medicalPlans])

  if (error) return <Error error={error} />
  if (loadingPlans) return <Loader />
  const { metadata, alfCarriers } = value!

  const plans = sortedPlans(filteredPlans, loadingPlans)
  const selectedPlans = sortedPlans(medicalPlans, loadingPlans)?.filter(plan => selectedPlanIDs?.some((id: string) => id === plan.id)) || []
  const isNextButtonDisabled = (selectedPlanIDs || []).length <= 0 || disabled
  const buttonCopy = isNextButtonDisabled ? 'Select a plan to continue' : 'Next'
  return <>
    {renderShowPlans()}
  </>

  function renderHeader() {
    return <header className={headerStyles.header}>
      <div>
        <div className={headerStyles.titleArea}>
          <h1>Select Group Plans</h1>
          {includesMQPlans(selectedPlans) &&
            <p>You have selected a level-funded plan, which requires all employees and their dependents seeking medical coverage to provide medical history in an Individual Medical Questionnaire (IMQ). This process allows us to get the best and most accurate rates so we can find the right plan for you.</p>
          }
        </div>
        <div className={headerStyles.buttonsContainer}>
          <GAButton
            analytics={`${buttonCopy} (${ERShopPlans.name})`}
            className={headerStyles.nextButton}
            onClick={() => ALFCheckOrOnwards()}
            disabled={isNextButtonDisabled}
          >
            {buttonCopy}
          </GAButton>
        </div>
      </div>
    </header>
  }

  function renderPlans() {
    if (loadingPlans) return <Loader />
    let plansOrder
    if (selectedPlanIDs) {
      plansOrder = selectedToTop ? selectedPlans.concat(plans!.filter(p => !selectedPlanIDs!.includes(p.id))) : plans!
    } else {
      plansOrder = plans!
    }

    return plansOrder.map(plan => {
      return <LazyLoad
        offset={100}
        key={plan.id}
        placeholder={<PlanPlaceholder/>}
      >
        {plan.carrier === 'Anonymous Level Funded' ? <ALFMedicalPlanComponent
          plan={plan}
          contributions={mangleContribution()}
          selected={!!(selectedPlanIDs as string[])?.find(id => id === plan.id)}
          selectHandler={onPlanSelectToggled}
          splits={splits}
          members={members}
          key={plan.id}
          showWeeklyPayments={stargate.config.showWeeklyPayments}
          label={stargate.config.label}
          stargateGroup={group}
          enableAgeBanded={true}
          carriersDisabled={!selectedPlanIDs?.includes(plan.id)}
          selectedALFPlans={selectedALFPlans}
          setSelectedALFPlans={setSelectedALFPlans}
          ALFCarrierSelect={onALFPlanSelect}
          alfCarriers={alfCarriers}
          showModal
        /> : <MedicalPlanComponent
          plan={plan}
          contributions={mangleContribution()}
          selected={!!(selectedPlanIDs as string[])?.find(id => id === plan.id)}
          selectHandler={onPlanSelectToggled}
          splits={splits}
          members={members}
          key={plan.id}
          showWeeklyPayments={stargate.config.showWeeklyPayments}
          label={stargate.config.label}
          stargateGroup={group}
          enableAgeBanded={true}
          carriersDisabled={false}
          showModal
        />}
      </LazyLoad>
    }
    )
  }

  function mangleContribution() {
    const c = contributions!
    c.baseContributions.medical = contribution
    c.baseContributions.medicalEquitable = contributionType === ContributionSplitType.allTiers
    return c
  }

  function onALFPlanSelect(carrier: string, planID: string) {
    if (!selectedALFPlans.find(o => o.planID === planID)!.selectedAlfCarriers.includes(carrier)) {
      const updatedCarriersObj = selectedALFPlans.find(o => o.planID === planID)!
      updatedCarriersObj!.selectedAlfCarriers.push(carrier)
      setSelectedALFPlans([...selectedALFPlans.filter(o => o.planID !== planID), updatedCarriersObj])
    } else {
      const updatedCarriersObj = selectedALFPlans.find(o => o.planID === planID)!
      updatedCarriersObj.selectedAlfCarriers = updatedCarriersObj.selectedAlfCarriers.filter((o:string) => o !== carrier)
      setSelectedALFPlans([...selectedALFPlans.filter(o => o.planID !== planID), updatedCarriersObj])
    }
  }

  function ALFCheckOrOnwards() {
    let noCarrierFlag = false
    if (selectedALFPlans.length) {
      selectedALFPlans.map(p => {
        if (p.selectedAlfCarriers.length < 1) noCarrierFlag = true
      })
    }
    if (noCarrierFlag) {
      return addToast('Please select at least one Illustrative Level Funding Carrier', 'warning')
    } else if (selectedPlans.filter(p => p.carrier === Carriers['Health Benefit Alliance']).length && selectedALFPlans.length) {
      return addToast('Remove either Health Benefits Alliance or Illustrative Level Funding plan(s) to continue', 'warning')
    } else {
      onwards(selectedPlans, selectedALFPlans).catch(addToast)
    }
  }

  function renderShowPlans() {
    // The Compare Plans modal originally thought that contributions were 50% for level funded plans,
    // which meant it only displayed 50% of the medical rates in CompareModal.
    // So here we are making it think contributions are 100% in CompareModal to fix the issue.
    const mangledContribution = mangleContribution()
    const contributionForCompareModal = {
      ...mangledContribution,
      baseContributions: {
        ...mangledContribution.baseContributions
      }
    }
    contributionForCompareModal.baseContributions.medical = '100%'

    return <>
      {renderHeader()}
      <div className={styles.mainContainer}>
        <div className={styles.plansAndFilter} style={{ display: 'grid' }}>
          <div className={styles.filterBarContainer}>
            <div className={`${styles.fixedFilter}
            ${(selectedPlans.length <= 0 && scrollPosition < 117) && styles.filterExtraHeight}
            ${(selectedPlans.length <= 0 && scrollPosition >= 117) && styles.filterExtraHeightNoHeader}
            ${(selectedPlans.length > 0 && scrollPosition >= 117) && styles.filterNoHeader}`
            }>
              <PlansFilter
                activeFilters={activeFilters()}
                callback={applyFilter}
                meta={metadata!}
                filteredPlansCount={plans?.length}
                plansCount={medicalPlans.length}
              />
            </div>
          </div>
          <div className={styles.plansListContainer}>
            <section className={styles.erSection}>
              { filteredPlans.length ? <h2>
                <b>Selected plans will be added into your shopping cart to compare.</b>
                &nbsp;You’ll be able to adjust your specific contributions before finalizing your selected plans.
              </h2>
                : <h2>This combination of filters chosen produced no plans. Please change your criteria to be able to view more options. {<br/>} Contact us at (888) 272-1513 or support@myhealthily.com if you need assistance.</h2>}
            </section>
            <div className={styles.hideProposalContent}>
              <div className={styles.helperDiv} />
            </div>
            {renderPlans()}
          </div>
        </div>
        <PlanCart
          selectedPlans={selectedPlans}
          removePlan={onPlanSelectToggled}
          compare={toggleCompare}
          editingCart={disabled}
          NBtnAnalytics={`${buttonCopy} (${ERShopPlans.name})`}
          NBtnonClick={() => ALFCheckOrOnwards()}
          removeALF={removeALFFromCart}
        />
        <CompareModal
          isOpen={isCompareModalOpen}
          onRequestClose={toggleCompare}
          plans={selectedPlans}
          contributions={contributionForCompareModal}
          splits={splits}
          members={members}
          group={group!}
          removePlanHandler={onPlanSelectToggled}
        />
      </div>
      <BackToTop/>
    </>
  }

  function runFilter(mPlan: MedicalPlan, filter: FilterType, value: string) {
    switch (filter) {
    case FilterType.sort:
    case FilterType.premium:
    case FilterType.deductible:
    case FilterType.oop:
      return isInRange(mPlan, filter, value)
    case FilterType.carrier:
      return mPlan.carrier === value
    case FilterType.planType:
      return mPlan.type.toString() === value
    case FilterType.fundingType:
      if (value === FundingType.mec) return (isApex(mPlan.carrier) || isHBA(mPlan.carrier) || isOptionsPlus(mPlan.carrier) || isVault(mPlan.carrier)) && mPlan.name.toUpperCase().includes('MEC')
      else if (value === FundingType.levelFunded) return mPlan.isLevelFunded
      else if (value === FundingType.fullyFunded) return (!mPlan.isLevelFunded && !(isApex(mPlan.carrier) || isHBA(mPlan.carrier) || isOptionsPlus(mPlan.carrier) || isSidecar(mPlan.carrier) || isVault(mPlan.carrier)))
      else if (value === FundingType.alternative) return mPlan.carrier === Carriers['Sidecar Health'].toString()
      else if (value === FundingType.hsa) return mPlan.hsaEligible || mPlan.name.toLowerCase().includes('hsa')
    }

    function isInRange(plan: MedicalPlan, filterType: FilterType, range: string) {
      const minAndMax = { min: numeral(range.split('..')[0]).value(), max: numeral(range.split('..')[1]).value() }
      if (filterType === FilterType.premium) return inRange(numeral(plan.premium.employee.individual).value(), minAndMax.min, minAndMax.max)
      if (filterType === FilterType.deductible) return inRange(numeral(plan.deductible).value(), minAndMax.min, minAndMax.max)
      if (filterType === FilterType.oop) return inRange(numeral(plan.oopMax).value(), minAndMax.min, minAndMax.max)
    }
  }

  function applyFilters() {
    const allFiltersApplied: Array<Array<MedicalPlan>> = new Array<Array<MedicalPlan>>()
    const filters = activeFilters().filter(f => ![FilterType.sort, FilterType.selected].includes(f[0]))
    if (filters.length === 0) { setFilteredPlans(medicalPlans); return }
    if (filters.length === 1) { setFilteredPlans(medicalPlans.filter(p => runFilter(p, filters[0][0], filters[0][1]))); return }

    const filterMap: Map<FilterType, string[]> = new Map<FilterType, string[]>()

    filters.forEach(f => filterMap.set(f[0], [...filterMap.get(f[0]) || [], f[1]]))

    filterMap.forEach((value, key) => {
      const sameCategoryPlans = new Array<MedicalPlan>()
      value.forEach(v => medicalPlans.filter(p => runFilter(p, key, v)).forEach(p => sameCategoryPlans.push(p)))
      allFiltersApplied.push(sameCategoryPlans)
    })
    setFilteredPlans(intersection(...allFiltersApplied))
  }

  function activeFilters(): [FilterType, any][] {
    const pp = new URLSearchParams(search)
    // TODO should verify it is actually a FilterType or we may screw up
    const rv: [FilterType, any][] = compact(Array.from(pp.entries()).map(transform))
    return rv

    function transform(input: [string, any]): [FilterType, any] | undefined {
      let [key, value] = input
      if (key.endsWith('[]')) key = key.slice(0, -2)
      for (const typeKey in FilterType) {
        if (FilterType[typeKey as keyof typeof FilterType] === key) return [key, value]
      }
    }
  }

  function sortingMethod(): SortingMethod {
    const pp = new URLSearchParams(search)
    const query = pp.get(FilterType.sort)
    for (const key in SortingMethod) {
      const type = SortingMethod[key as keyof typeof SortingMethod]
      if (type === query) return type
    }
    return SortingMethod.premium
  }

  function applyFilter(type: FilterType, value: any) {
    const pp = new URLSearchParams(search)

    if (type === FilterType.sort) {
      pp.set(type, value)
    } else if (type === FilterType.selected) {
      if (pp.get('selected') === 'top') {
        pp.delete('selected')
        setSelectedToTop(false)
      } else {
        setSelectedToTop(true)
        pp.set(type, value)
      }
    } else {
      const key = `${type}[]`
      if (activeFilters().find(([a, b]) => type === a && value === b)) {
        const values = pp.getAll(key).filter(vv => vv !== value)
        pp.delete(key)
        values.forEach(value => pp.append(key, encodeURIComponent(value)))
      } else {
        pp.append(key, encodeURIComponent(value))
      }
    }
    // decode or [] becomes percent encoded and looks gross
    history.push({ search: decodeURIComponent(pp.toString()) })
  }

  function onPlanSelectToggled(plan: MedicalPlan) {
    const planIDs: string[] = selectedPlanIDs || []
    const has = planIDs.some(id => id === plan.id)
    if (has) {
      setSelectedPlanIDs(planIDs.filter(id => id !== plan.id))
    } else {
      setSelectedPlanIDs([...planIDs, plan.id])
      if (plan.isLevelFunded && moment(group?.effectiveDate).subtract(10, 'days').isBefore(moment())) {
        addToast('One or more plans in your cart may not meet carrier timeline for issuance.', 'warning')
      }
    }
  }

  function removeALFFromCart(plan: MedicalPlan) {
    if (selectedALFPlans.find(p => p.planID === plan.id)) {
      selectedALFPlans.find(p => p.planID === plan.id)!.selectedAlfCarriers = []
      setSelectedALFPlans(selectedALFPlans.filter(p => p.planID !== plan.id))
    }
  }

  function onwards(plans: MedicalPlan[], selectedALFPlans: PlansWithCarriers[]) {
    if (!plans.length) return Promise.reject(new window.Error('Please pick a plan to continue'))
    const selectedHBAplans = plans.filter(p => p.carrier === Carriers['Health Benefit Alliance'])
    if (selectedHBAplans.every(p => p.name.includes('Ultra')) && selectedHBAplans.length > 0) Promise.reject(new window.Error('HBA Ultra plans require employers to offer an additional HBA plan option.'))

    setDisabled(true)
    return props.onwards(saveSelections(plans.map(p => p.id), selectedALFPlans) as any)
      .then(() => saveContributions(contribution, contributionType))
      .then(() => setDisabled(false))
  }

  function saveSelections(planIDs: string[], ALFCarriers: PlansWithCarriers[]) {
    let renamedArray = ALFCarriers.map(({
      selectedAlfCarriers: carriers,
      ...rest
    }) => ({
      carriers,
      ...rest
    }))

    renamedArray.map(obj => {
      planIDs = planIDs.filter(p => p !== obj.planID)
    })
    renamedArray = renamedArray.concat(planIDs.map(p => { return { planID: p, carriers: [] } }))
    return api.v2.groups(group?.id).plans.POST(uniq(renamedArray)).then(removeSelectedPlans)
  }

  function saveContributions(value: string, type: ContributionSplitType = ContributionSplitType.perEmployee) {
    return api.v3.groups(group?.id).PUT({
      rateType: rateType,
      contributions: {
        medical: {
          value,
          isEquitable: type === ContributionSplitType.allTiers
        }
      }
    })
  }

  function includesMQPlans(plans: MedicalPlan[]): boolean {
    return plans?.some(p => isAllstate(p.carrier)) || false
  }

  function sortedPlans(plans?: MedicalPlan[], loading?: boolean): MedicalPlan[] | undefined {
    if (plans && !loading) {
      // This is gonna be good.
      const renewalPlans = plans.filter(plan => plan.isRenewalPlan)
      const renewalPlanIDs = renewalPlans.map(plan => plan.id)
      const nonRenewalPlans = plans.filter(plan => !plan.isRenewalPlan && !renewalPlanIDs.includes(plan.id))
      return sortBy(renewalPlans, func()).concat(sortBy(nonRenewalPlans, func()))
    }

    function func(): ((plan: MedicalPlan) => number)[] {
      const premium = (plan: MedicalPlan) => moneyNumber(plan.premium.employee.individual)
      const deductible = (plan: MedicalPlan) => moneyNumber(plan.deductible)
      const oopMax = (plan: MedicalPlan) => moneyNumber(plan.oopMax)
      switch (sortingMethod()) {
      case SortingMethod.premium:
        return [premium, deductible, oopMax]
      case SortingMethod.deductible:
        return [deductible, premium, oopMax]
      case SortingMethod.oopMax:
        return [oopMax, premium, deductible]
      }
    }
  }
}

export default ERShopPlans
