import React from 'react'
import filterStyles from 'Components/Stargate/ShowPlans/Filters/FilterItem.module.scss'
import { logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { GroupPlanType, AncillaryPlanUnion } from 'Utilities/Plans/ContributionCalculator'
import { classNames } from 'Utilities/etc'
import Modal from 'Components/Modals/Modal'
import { GAButton } from 'Components/Tracking'
import { SortType } from '../index'
import styles from '../index.module.scss'
import checkStyles from 'Components/Plans/plan-subcomponents/MedicalPlanAdditionalSpecs.module.scss'
import { startCase } from 'lodash'

export type AncillaryGroupPlan = GroupPlanType.dental|
GroupPlanType.vision|
GroupPlanType.disability|
GroupPlanType.life|
GroupPlanType.accident|
GroupPlanType.cancer|
GroupPlanType.criticalIllness|
GroupPlanType.hospital|
GroupPlanType.std

export enum AncillaryFilterType {
  type = 'type',

  dsort = 'dsort',
  vsort = 'vsort',
  dissort = 'dissort',
  lsort = 'lsort',
  asort = 'asort',
  csort = 'csort',
  crisort = 'crisort',
  hsort = 'hsort',
  stdsort = 'stdsort',

  dcarrier = 'dcarrier',
  vcarrier = 'vcarrier',
  discarrier = 'discarrier',
  lcarrier = 'lcarrier',
  acarrier = 'acarrier',
  ccarrier = 'ccarrier',
  cricarrier = 'cricarrier',
  hcarrier = 'hcarrier',
  stdcarrier = 'stdcarrier',

  discont = 'discont',
  lcont = 'lcont',
  stdcont = 'stdcont',

  dselected = 'dselected',
  vselected = 'vselected',
  disselected = 'disselected',
  lselected = 'lselected',
  aselected = 'aselected',
  cselected = 'cselected',
  criselected = 'criselected',
  hselected = 'hselected',
  stdselected = 'stdselected'
}

type ParamKeysFilters = {
  selectedToTop: string
  carriers: string
  contributionType?: string
  sort: string
  type: string
}
type ParamKeysType = {
  dental: ParamKeysFilters
  vision: ParamKeysFilters
  disability: ParamKeysFilters
  accident: ParamKeysFilters
  cancer: ParamKeysFilters
  criticalIllness: ParamKeysFilters
  hospital: ParamKeysFilters
  life: ParamKeysFilters
  std: ParamKeysFilters
}

export const paramKeys: ParamKeysType = {
  dental: {
    selectedToTop: 'dselected',
    carriers: 'dcarrier',
    sort: 'dsort',
    type: 'dental'
  },
  vision: {
    selectedToTop: 'vselected',
    carriers: 'vcarrier',
    sort: 'vsort',
    type: 'vision'
  },
  disability: {
    selectedToTop: 'disselected',
    carriers: 'discarrier',
    sort: 'dissort',
    contributionType: 'discont',
    type: 'disability'
  },
  accident: {
    selectedToTop: 'aselected',
    carriers: 'acarrier',
    sort: 'asort',
    type: 'accident'
  },
  cancer: {
    selectedToTop: 'cselected',
    carriers: 'ccarrier',
    sort: 'csort',
    type: 'cancer'
  },
  criticalIllness: {
    selectedToTop: 'criselected',
    carriers: 'cricarrier',
    sort: 'crisort',
    type: 'criticalillness'
  },
  hospital: {
    selectedToTop: 'hselected',
    carriers: 'hcarrier',
    sort: 'hsort',
    type: 'hospital'
  },
  life: {
    selectedToTop: 'lselected',
    carriers: 'lcarrier',
    sort: 'lsort',
    contributionType: 'lcont',
    type: 'life'
  },
  std: {
    selectedToTop: 'stdselected',
    carriers: 'stdcarrier',
    sort: 'stdsort',
    contributionType: 'stdcont',
    type: 'std'
  }
}

type FilterBarType = {
  sort: any
  filters: {
    carriers: string[]
    contributionType?: string[]
  }
  selectedToTop: boolean
}

export interface StateObjectType {
  dental: FilterBarType
  vision: FilterBarType
  disability: FilterBarType
  life: FilterBarType
  accident: FilterBarType
  cancer: FilterBarType
  criticalIllness: FilterBarType
  hospital: FilterBarType
  std: FilterBarType
}

export interface ParamsObj {
  dsort: string | null
  vsort: string | null
  dissort: string | null
  lsort: string | null
  asort: string | null
  csort: string | null
  crisort: string | null
  hsort: string | null
  stdsort: string | null

  dcarrier: string[]
  vcarrier: string[]
  discarrier: string[]
  lcarrier: string[]
  acarrier: string[]
  ccarrier: string[]
  cricarrier: string[]
  hcarrier: string[]
  stdcarrier: string[]

  discont: string[]
  lcont: string[]
  stdcont: string[]

  dselected: string | null
  vselected: string | null
  disselected: string | null
  lselected: string | null
  aselected: string | null
  cselected: string | null
  criselected: string | null
  hselected: string | null
  stdselected: string | null
}

interface TabProps {
 selectedTab: string
 tabName: string
 setState: (key: string, value: string|boolean) => void
 titleFunc(type: GroupPlanType, header?: boolean): string | undefined
 shop: 'ER' | 'EE'
 planCount: number
}

interface TabWithSubtabProps {
  selected: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  setState: (key: string, value: string|boolean) => void
  tabName: string
  shop: 'ER' | 'EE'
  planCount: number
}

interface SubTabProps {
  tabName: GroupPlanType
  setState: (key: string, value: string|boolean) => void
  selectedTab: string
  titleFunc(type: GroupPlanType, header?: boolean): string | undefined
  planCount: number
}

interface CarrierButtonProps {
  carrier: string
  setState: (carrier: string) => void
  selectedWorksiteCarrier: string | null
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface CarrierModalProps {
  isOpen: boolean
  onClose: () => void
  setState: (carrier: string) => void
  carrier: string
  selectedTab: AncillaryGroupPlan
}

interface AncillaryConfigObj {
  sort: string[]
  filters: {title: string, data: string[], key: string}[]
  selectedToTop: boolean
}

interface AncillaryStateObj {
  sort: string
  filters: {
    carriers: string[]
    contributionType?: string[]
  }
  selectedToTop: boolean

}

export const Tab: React.FC<TabProps> = ({ selectedTab, tabName, setState, shop, titleFunc, planCount }) => {
  return <div className={`${shop === 'ER' ? styles.ERtab : styles.EEtab} ${selectedTab === tabName && styles.active}`} onClick={() => {
    setState('type', tabName)
  }}><div>{titleFunc(tabName as GroupPlanType, true)}</div>
    <div title={`${planCount} Selected`} className={`${styles.circleBadge} ${selectedTab === tabName && styles.active}`}>
      <span className={styles.circleContent}>{planCount}</span>
    </div>
  </div>
}
export const TabWithSubTabs: React.FC<TabWithSubtabProps> = ({ selected, setSelected, setState, planCount, shop, tabName }) => {
  return <div className={`${styles.worksiteTab} ${shop === 'ER' ? styles.ERtab : styles.EEtab} ${selected && styles.active}`} onClick={() => {
    setSelected(true)
    tabName === 'Worksite' && setState('type', GroupPlanType.accident)
    tabName === 'Disability' && setState('type', GroupPlanType.disability)
  }}>
    <div>{tabName}</div>
    <div className={`${styles.circleBadge} ${selected && styles.active}`}>
      <span className={styles.circleContent}>{planCount}</span>
    </div>
  </div>
}

export const SubTab: React.FC<SubTabProps> = ({ tabName, selectedTab, setState, titleFunc, planCount }) => {
  return <button className={`${styles.subTab} ${tabName === selectedTab && styles.active}`} onClick={() => {
    setState('type', tabName)
  }}><div>{titleFunc(tabName)}</div>
    <div className={`${styles.circleBadge} ${selectedTab === tabName && styles.active}`}>
      <span className={styles.circleContent}>{planCount}</span>
    </div>
  </button>
}

export const CarrierButton: React.FC<CarrierButtonProps> = ({ carrier, setState, selectedWorksiteCarrier, setIsOpen }) => {
  return <div key={carrier} className={`${styles.worksiteCarrier} ${selectedWorksiteCarrier === carrier && styles.active}`} onClick={() => {
    if (carrier !== selectedWorksiteCarrier) {
      setIsOpen(true)
      setState(carrier)
    }
  }}><div className={styles.carrierCheck}>
      <label className={checkStyles.carrierLabel}>
        <input type="checkbox" checked={selectedWorksiteCarrier === carrier} readOnly/>
        <span className={`${styles.radio} ${carrier !== selectedWorksiteCarrier && styles.radioBackground}`}></span>
      </label></div>{logoFor(carrier, undefined, true)}</div>
}

export const CarrierModal: React.FC<CarrierModalProps> = ({ isOpen, onClose, setState, carrier }) => {
  return <Modal
    gaModalName={'Worksite Carrier'}
    isOpen={isOpen}
    onRequestClose={onClose}
    header='Are You Sure You Want To Change Carriers?'
    contentClassName={styles.whatIsSidecarModal}
  >
    <div className={styles.modalInfo}><h3>Selecting a new carrier will invalidate previous worksite plan selections and select plans from {carrier}.</h3></div>
    <div className={styles.modalButtons}>
      <GAButton analytics={'Worksite Carrier Cancel'} className={classNames('shop-next-button', styles.cancel)}
        onClick={() => {
          onClose()
        }}>Cancel</GAButton>
      <GAButton analytics={'Worksite Carrier Confirm'} className={classNames('shop-next-button', styles.confirm)}
        onClick={() => {
          setState(carrier)
          onClose()
        }}>Confirm</GAButton>
    </div>
  </Modal>
}

interface FilterBarProps {
  config: AncillaryConfigObj
  state: AncillaryStateObj
  setState: (key: string, value: string|boolean) => void
  plans: AncillaryPlanUnion[]
  currentPlans: AncillaryPlanUnion[]
}

export const FilterBar: React.FC<FilterBarProps> = ({ config, state, setState, plans, currentPlans }) => {
  return (<div className={styles.filterBarContainer}>
    <div className={styles.stickyFilters}>
      { plans.length > 0 &&
      <>
        <div className={styles.planCount}>Showing <span className={styles.boldText}>{currentPlans.length}</span> of <span className={styles.boldText}>{plans.length}</span> plans</div>
        <hr/>
        <div className={`${styles.filterTitle} ${styles.boldText}`}>Selected To Top</div>
        <label className={filterStyles.radioContainer}>
          <input value={'Selected To Top'} onClick={() => {
            setState('selectedToTop', !state.selectedToTop)
          }} checked={state.selectedToTop} type="radio" readOnly />
            Selected To Top
          <span className={filterStyles.radio}></span>
        </label>
        <hr/>
        <div className={`${styles.filterTitle} ${styles.boldText}`}>Sort By Lowest</div>
        {config.sort.filter(s => s !== SortType.none).map(s => {
          return (<label key={s} className={filterStyles.radioContainer}>
            <input value={s} onClick={() => {
              setState('sort', s)
            }} checked={state.sort === s} type="radio" readOnly />
            {s}
            <span className={filterStyles.radio}></span>
          </label>)
        })}
        {config.filters.map((f, i) => {
          return <>{f.data.length > 0 && <><hr key={`${i}hr`}/><div className={`${styles.filterTitle} ${styles.boldText}`} key={i}>{f.title}</div></>}
            {f.data.map((c, i) => {
              return <div key={i}>
                <label className={filterStyles.radioContainer}>
                  <input value={c} onClick={() => {
                    setState(f.key, c)
                  }} checked={state.filters[f.key as keyof AncillaryStateObj['filters']] ? state.filters[f.key as keyof AncillaryStateObj['filters']]!.map((n) => n.toLowerCase()).includes(c.toLowerCase()) : false} type="radio" readOnly />
                  {startCase(c)}
                  <span className={filterStyles.radio}></span>
                </label>
              </div>
            })}</>
        })}
      </>}
    </div>
  </div>)
}

interface BackButtonProps {
  children: any
  onBackClick: any
}

export const BackButton: React.FC<BackButtonProps> = ({ children, onBackClick }) => {
  return <span className={styles.alignLeft} onClick={() => onBackClick()}><span className={styles.triangle}>&#9664;</span> <span className={styles.underline}>Back To <i>{children}</i></span></span>
}
