import { Dependents } from 'Components/Anubis/AgeBands/index.helpers'
import React from 'react'
import stylesDefault from './PlanMemberRatesTable.module.scss'
import stylesProposal from './PlanMemberRatesProposalTable.module.scss'
import { ContributionSplit, Member } from 'Utilities/Hooks/useStargate'
import { Tier } from 'Utilities/pharaoh.types'
import ContributionsCalculator, {
  isMedical, isMemberPremiumArray,
  moneyNumber, PlanUnion
} from 'Utilities/Plans/ContributionCalculator'
import moment from 'moment'
import { camelCaseToHuman, logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import StyledRate from '../StyledRate/StyledRate'
import numeral from 'numeral'

interface Pagination {
  page: number
  limit: number
}

interface PlanMemberRatesTableProps {
  calc: ContributionsCalculator
  plan: PlanUnion
  split?: ContributionSplit
  showAll?: boolean
  showClasses?: boolean
  showContributions?: boolean
  showTitle?: boolean
  showDependents?: boolean
  showTier?: boolean
  scrollable?: boolean
  paginate?: Pagination
  forProposal?: boolean
}

interface PlanMemberRatesTableTitleProps {
  plan: PlanUnion
  pagination?: {
    page: number
    total: number
  }
  forProposal?: boolean
}

const PlanMemberRatesTableTitle: React.FC<PlanMemberRatesTableTitleProps> = ({ plan, pagination, forProposal }) => {
  const styles = forProposal ? stylesProposal : stylesDefault
  if (isMedical(plan)) {
    return <><div className={styles.tableTitle}>
      {logoFor(plan.carrier, plan.name) || <div>{plan.carrier}</div>}
      <div>{plan.carrier}</div>
      <div>{plan.name}</div>
      {pagination && <div className={styles.pagination}>{pagination.page} of {pagination.total}</div>}
    </div></>
  } else {
    return <><div className={styles.tableTitle}>
      {logoFor(plan.plan.carrier, plan.plan.name) || <div>{plan.plan.carrier}</div>}
      <div>{plan.plan.carrier}</div>
      <div>{plan.plan.name}</div>
      {pagination && <div className={styles.pagination}>{pagination.page} of {pagination.total}</div>}
    </div></>
  }
}

class PlanMemberRatesTable extends React.Component<PlanMemberRatesTableProps> {
  public static defaultProps = {
    showAll: false,
    showClasses: false,
    showContributions: false,
    showTitle: true,
    showTier: true,
    showDependents: true,
    scrollable: true
  };

  render() {
    const { plan, split, calc, showAll, showClasses, showContributions, showTitle, showDependents, scrollable, paginate, forProposal, showTier } = this.props
    const total = { er: 0, ee: 0, all: 0 }

    const styles = forProposal ? stylesProposal : stylesDefault
    // Always pull the nonWaved members for calculations
    const membersList = calc.filterSplits(calc.nonWaivedMembers, split, !showAll).map(m => m.id)
    const memberRates = plan.memberPremiums?.premiums
    const tableClass = scrollable ? styles.height : undefined
    const filteredMembers = calc.nonWaivedMembers.filter(filterMembers).sort(sortMembers)
    const members = paginate ? paginateArray(filteredMembers, paginate.limit, paginate.page) : filteredMembers
    const pagination = paginate ? {
      total: Math.ceil(filteredMembers.length / paginate.limit),
      page: paginate.page
    } : undefined

    if (pagination) {
      const t = calc.premiums([plan], !showAll, calc.nonWaivedMembers, split)
      total.ee = t.ee
      total.er = t.er
      total.all = t.total
    }

    return <div className={styles.moduleBlock}>
      {showTitle && <PlanMemberRatesTableTitle plan={plan} pagination={pagination} forProposal={forProposal}/>}
      <div className={tableClass}>
        {!memberRates &&
          <div className={styles.noop}>This plan is unable to be selected while using age banded contributions</div>}
        {memberRates &&
          <table className={`${styles.table} ${styles.full}`}>
            <thead>
              <tr>
                <th>Employee</th>
                <th>Age</th>
                {showClasses && <th>Class</th>}
                {showTier && <th>Plan Tier</th>}
                {showContributions && <th>Contribution</th>}
                <th>Plan Cost</th>
                {showContributions && <th className={styles.employerShare}>Employer Share</th>}
                {showContributions && <th className={styles.employeeShare}>Employee Share</th>}
              </tr>
            </thead>
            <tbody>
              {members.map((m, i) => {
                if (!memberRates) {
                  return <></>
                }
                let memberRate
                if (isMemberPremiumArray(memberRates)) {
                  memberRate = memberRates.find(id => id.memberID === m.id)
                } else {
                  memberRate = memberRates.find(id => id.memberID === m.id)
                }
                const premiums = calc.premiumBreakdownForMemberForPlan(m, plan)
                const contribution = calc.contributionForMemberPerPlanString(m, plan)
                if (!memberRate) return <></>
                const dependents: Dependents = {
                  spouse: false,
                  children: 0
                }
                const age = moment().diff(m.dateOfBirth, 'years', false)
                m.dependents.map((d) => {
                  switch (d.relationship) {
                  case 'spouse':
                    dependents.spouse = true
                    break
                  case 'child':
                    dependents.children += 1
                    break
                  }
                })

                function hasDependents() {
                  return (dependents.spouse || dependents.children > 0) && showDependents
                }

                if (!pagination) {
                  total.er += premiums.member.er + (premiums.dependant?.er ? premiums.dependant?.er : 0)
                  total.ee += premiums.member.ee + (premiums.dependant?.ee ? premiums.dependant?.ee : 0)
                  total.all = total.er + total.ee
                }

                return <tr key={m.id}>
                  <td>
                    <div>{m.name}</div>
                    {hasDependents() && <div className={styles.dependentTitle}>Dependents</div>}
                    {hasDependents() && <div className={styles.dependentDescription}>{dependents.spouse && 'Spouse'}
                      {(dependents.spouse && dependents.children > 0) && ', '}
                      {dependents.children > 1 && `${dependents.children} Children`}
                      {dependents.children === 1 && `${dependents.children} Child`}
                    </div>}
                  </td>
                  <td className={styles.lightFont}>
                    <div>{age}</div>
                    {hasDependents() && <div className={styles.dependentDash}>&#8212;</div>}</td>
                  {showClasses && <td className={styles.lightFont}>
                    {calc.getMemberSplit(m)?.name}
                  </td>}
                  {showTier && <td className={styles.uppercase}>{camelCaseToHuman(Tier[m.tier])}</td>}
                  {showContributions && <td>
                    {moneyNumber(contribution.employee) > 0 && <div>{contribution.employee}</div>}
                    {moneyNumber(contribution.employee) <= 0 && <div>No Contribution</div>}
                    {(hasDependents() && moneyNumber(contribution.dependant) > 0) &&
                      <div className={styles.dependantPremium}>{contribution.dependant}</div>}
                    {(hasDependents() && moneyNumber(contribution.dependant) <= 0) &&
                      <div className={styles.dependantPremium}>No Contribution</div>}
                  </td>}
                  <td className={styles.planCost}>
                    <div>{numeral(memberRate.insured_premium).value() === 0 ? <>Not Quoted</> : <StyledRate rate={memberRate.insured_premium} precision={calc.precision}/>}</div>
                    {hasDependents() && calc.isAgeBanded() &&
                      <div className={styles.dependantPremium}><StyledRate rate={memberRate.dependant_premium} precision={calc.precision}/></div>}
                  </td>
                  {showContributions && <td className={(i % 2) ? styles.employerShare : styles.employerShareDark}>
                    <div><StyledRate rate={premiums.member.er} precision={calc.precision}/></div>
                    {hasDependents() && calc.isAgeBanded() && premiums.dependant &&
                      <div className={styles.dependantPremium}><StyledRate rate={premiums.dependant.er} precision={calc.precision}/></div>}
                  </td>}
                  {showContributions && <td className={(i % 2) ? styles.employeeShare : styles.employeeShareDark}>
                    <div><StyledRate rate={premiums.member.ee} precision={calc.precision}/></div>
                    {hasDependents() && calc.isAgeBanded() && premiums.dependant &&
                      <div className={styles.dependantPremium}><StyledRate rate={premiums.dependant.ee} precision={calc.precision}/></div>}
                  </td>}
                </tr>
              })}
            </tbody>
            <tfoot className={scrollable ? styles.sticky : undefined}>
              <tr>
                <td colSpan={2 + (showTier ? 1 : 0) + (showClasses ? 1 : 0) + (showContributions ? 1 : 0)}>Total</td>
                <td><StyledRate rate={total.all} precision={calc.precision}/></td>
                {showContributions && <td className={styles.employerTotal}><StyledRate rate={total.er} precision={calc.precision}/></td>}
                {showContributions && <td className={styles.employeeTotal}><StyledRate rate={total.ee} precision={calc.precision}/></td>}
              </tr>
            </tfoot>
          </table>}
      </div>
      <div className={styles.disclaimer}>* Final price will depend on employee participation and final carrier rates</div>
      <div className={styles.disclaimer}>* Illustrative rates show in contributions for Not Quoted members</div>
    </div>

    function paginateArray(array: Member[], pageSize: number, pageNumber:number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
    }

    function filterMembers(m: Member) {
      return membersList.includes(m.id)
    }

    function sortMembers(a: Member, b: Member) {
      return a.name.split(' ').pop()!.localeCompare(b.name.split(' ').pop()!) ||
        a.name.split(' ').pop()!.localeCompare(b.name.split(' ').pop()!)
    }
  }
}

export default PlanMemberRatesTable
