/* eslint-disable camelcase */
import React from 'react'
import Select from 'Components/Rudimentary/Select'
import styles from './EHQQuestions.module.scss'
import Input from 'Components/Rudimentary/CandorInput'
import { FormProvider, useForm } from 'react-hook-form'
import useToast from 'Utilities/Hooks/useToast'
import useContentAdjustment from 'Utilities/Hooks/useContentAdjustment'
import * as api from 'Utilities/pharaoh'
import { Group } from 'Utilities/Hooks/useStargate'
import moment from 'moment'

interface Props {
  group: Group
  callback?: () => void
}

const EHQQuestions: React.FC<Props> = ({ group, callback }) => {
  const form = useForm()
  const { handleSubmit, watch, register } = form
  const effectiveDate = group.effectiveDate || new Date()
  const watchers: { [key in string]: string } = {}
  for (const k of questions().keys) {
    watchers[k] = watch(k, k === 'best_of_knowledge' ? 'true' : 'false')
  }
  const addToast = useToast()
  useContentAdjustment({ paddingTop: 0 })

  return <div className={styles.formContainer} >
    <FormProvider {...form}>
      <div className={styles.applicationComponent}>
        <h1>Employer Health Questionnaire</h1>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

          <p>Number of employees currently enrolled in your health insurance:</p>
          <Input name="current_enrolled" required />

          <p>Employer Plan Sponsor-Responsible Party Name:</p>
          <Input name="responsible_party" required />

          <p>Employer Plan Sponsor-Responsible Party Email:</p>
          <Input name="responsible_party_email" required />

          { questions().descriptions.map(d => <>
            <p>{d.text}</p>
            { d.text_only && <Input name={`${d.key}_explanation`} required /> }

            { !d.text_only && <>
              <Select name={d.key} required options={[
                { value: 'true', label: 'Yes' },
                { value: 'false', label: 'No' }
              ]} />

              { watchers[d.key] === (d.invert ? 'false' : 'true') && <>
                <p>If { d.invert ? 'no' : 'yes' }, please explain:</p>
                <textarea className={styles.candorInput} name={`${d.key}_explanation`} ref={register} required />
              </>}

            </> }
          </>)}

          <div className={styles.twoCol} style={{ marginTop: '3em' }}>
            <input className={styles.nextButton} type='submit' value='Submit' />
          </div>
        </form>
      </div>
    </FormProvider>
  </div>

  type Questions = {
    keys: string[]
    descriptions: Description[]
  }

  type Description = {
    key: 'best_of_knowledge' | 'prior_sponsorship' | 'percent_tobacco' | 'illegal_drug_use' | 'five_days_out' | 'serious_disorder' | 'injury' | 'hospitalization' | 'fmla_leave' | 'surgery' | 'injury' | 'pregnancy' | 'workers_comp' | 'shock_loss'
    text: string
    invert?: boolean
    text_only?: boolean
  }

  function questions(): Questions {
    const edition = moment(effectiveDate).isBefore('2022-09-01') ? 1 : 2
    switch (edition) {
    case 1: return {
      keys:
        [
          'best_of_knowledge',
          'five_days_out',
          'serious_disorder',
          'shock_loss',
          'hospitalization',
          'surgery',
          'injury',
          'pregnancy'
        ],
      descriptions: [
        {
          key: 'best_of_knowledge',
          text: 'I will answer the following questions for all plan participants and dependents to be covered under this health insurance coverage to the best of my knowledge.',
          invert: true
        },
        {
          key: 'five_days_out',
          text: 'Has anyone missed more than five consecutive workdays in the last 12 months due to injury or illness by them or their dependents?'
        },
        {
          key: 'serious_disorder',
          text: 'Has anyone been treated in the past five years or anticipate being treated for a serious illness, immune system disorder, hemophilia, cancer, heart disorder/disease, Hepatitis C, kidney, or organ or tissue disorder/transplant, stroke, AIDS/ARC, type 1 diabetes, mental or nervous disorder, substance abuse or other accident/injury?'
        },
        {
          key: 'shock_loss',
          text: 'Are there other known potential Shock Loss Claims and/or have any plan participant (employee or dependents) incurred $10,000 or more in accident and/or health and Rx claims within the last 12 months?'
        },
        {
          key: 'hospitalization',
          text: 'Are there any employees, spouses or dependents who are disabled, or confined in a hospital or treatment facility, or have been pre-certified within the last three months to have an upcoming procedure or treatment, or any employees who are on leave of absence to care for a dependent who will be a plan participant of this health plan? (For employees, disabled means absent from work and/or on leave of absence or Family and Medical Leave Act [FMLA] benefits due to his or her medical condition; for dependents, disabled means unable to perform his or her normal functions of a person of like age.'
        },
        {
          key: 'surgery',
          text: 'Has anyone within the last six months been advised to have surgery or does anyone anticipate hospitalization or treatment/outpatient procedure for any other reason?'
        },
        {
          key: 'injury',
          text: 'Are there any employees who are not performing his or her normal duties due to illness or injury?'
        },
        {
          key: 'pregnancy',
          text: 'Are any employees or their dependents pregnant and/or considered to be high risk for complications of pregnancy, or carrying multiple fetuses?'
        }
      ]
    }
    case 2: return {
      keys: [
        'best_of_knowledge',
        'prior_sponsorship',
        'percent_tobacco',
        'illegal_drug_use',
        'five_days_out',
        'serious_disorder',
        'shock_loss',
        'hospitalization',
        'fmla_leave',
        'surgery',
        'injury',
        'pregnancy',
        'workers_comp'
      ],
      descriptions: [
        {
          key: 'best_of_knowledge',
          text: 'I will answer the following questions for all plan participants and dependents who may be covered under this healthcare coverage (referred to as “anyone” throughout the rest of this questionnaire) truthfully, completely, following reasonable commercial effort, and to the best of my knowledge.',
          invert: true
        },
        {
          key: 'prior_sponsorship',
          text: 'Have you sponsored a health plan before? If so, please list the carriers/third party administrators you have worked with over the past three (3) years.'
        },
        {
          key: 'percent_tobacco',
          text: 'What percentage of eligible participants and dependents smoke, vape, or use tobacco products.',
          text_only: true
        },
        {
          key: 'illegal_drug_use',
          text: 'Does anyone use illegal drugs or substances (including, even if legal in your local jurisdiction, cannabis products)? If so, please add an explanation of the number or percentage of eligible participants and dependents who use illegal drugs or substances, including cannabis products.'
        },
        {
          key: 'five_days_out',
          text: 'Has anyone missed more than five (5) consecutive workdays in the last twelve (12) months due to injury or illness by them or their dependents?'
        },
        {
          key: 'serious_disorder',
          text: 'Has anyone been treated in the last five (5) years, or does anyone anticipate being treated for a serious illness; immune system disorder; hemophilia; cancer; heart disorder/disease; hepatitis C; kidney, organ, or tissue disorder/transplant; stroke; AIDS/ARC; mental or nervous disorder; substance abuse; or other accident/injury?'
        },
        {
          key: 'shock_loss',
          text: 'Does anyone have any known potential medical issues such that anyone has incurred or is anticipated to incur $10,000 or more in accident and/or health or prescription drug claims or costs within the last twelve (12) months or anticipated over the next twelve (12) months? To assist in responding to this question, please see the Potentially Catastrophic Diagnosis and High Cost Drug listing page. This page is intendedto help you accurately complete this questionnaire.'
        },
        {
          key: 'hospitalization',
          text: 'Is anyone, or is anyone anticipated to become, disabled, confined in a hospital or treatment facility, certified/re-certified within the last three (3) months to have an upcoming procedure or treatment?'
        },
        {
          key: 'fmla_leave',
          text: 'Is anyone on a leave of absence to care for a dependent? If so, will either the caretaker or the dependent be eligible to participate in this health plan? (For employees, “disabled” means absent from work, on leave of absence, or Family and Medical Leave Act (“FMLA”) benefits due to said employee’s medical condition. For dependents, “disabled” means unable to perform his or her normal functions of a person of like age.)'
        },
        {
          key: 'surgery',
          text: 'Has anyone within the last six (6) months been advised to have surgery or does anyone anticipate hospitalization or an outpatient surgical procedure?'
        },
        {
          key: 'injury',
          text: 'Are there any employees who are not performing or are anticipated over the next twelve (12) months to become unable to perform his or her normal duties due to illness or injury?'
        },
        {
          key: 'pregnancy',
          text: 'Is anyone now or anticipated to become pregnant, considered to be at high risk for complications of pregnancy, or carrying multiple fetuses?'
        },
        {
          key: 'workers_comp',
          text: 'Is anyone receiving treatment and/or medical services related to a worker’s compensation claim? If so, please note in the explanation both the treatment/medical services and whether that claim is in dispute or is anticipated to be denied/not classified as a worker’s compensation claim now or in the next twelve (12) months.'
        }
      ]
    }
    }
  }

  function onSubmit(data: any) {
    const { keys, descriptions } = questions()

    for (const key of keys) {
      if (!data[key] && !descriptions.find(d => d.key === key)?.text_only) {
        addToast(`Missing answer for ${key}`, 'error')
        return
      }

      data[key] = data[key] === 'true'
    }

    api.v3.groups(group.id).ehq.POST({ ...data, group_id: group.id }).then(callback).catch(addToast)
  }
}

export default EHQQuestions
