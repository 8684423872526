import { visitAndPrescCopy } from 'Components/Plans/plan-subcomponents/MedicalPlanAdditionalSpecs'
import { Carriers } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import React from 'react'
import { MedicalPlan, TieredRates } from 'Utilities/pharaoh.types'
import { calculatePremiumFigures, TierCount } from '../Proposal'
import styles from './PlanProposal.module.scss'
import { ProposalCompanyHeader } from './ProposalCompanyHeader'

interface PlanProposalProps {
  proposalHeader: ProposalType
  tablePlanHeaders: JSX.Element
  tableRows: JSX.Element
  enrollmentInfo: JSX.Element
  groupName: string
  groupEffectiveDate?: Date
  notes?: string[]
}

export enum ProposalType {
  medical = 'Medical Plan Proposal',
  dental = 'Dental Plan Proposal',
  vision = 'Vision Plan Proposal',
  worksite = 'Worksite Plans Proposal',
  supplemental = 'Supplemental Plans Proposal',
  groupLife = 'Group Life Proposal'
}

const PlanProposal: React.FC<PlanProposalProps> = ({
  proposalHeader,
  tablePlanHeaders: tableHeaders,
  tableRows,
  enrollmentInfo,
  groupName,
  groupEffectiveDate,
  notes
}) => {
  return <div className={styles.medicalPlanPropPrint}>
    <ProposalCompanyHeader groupName={groupName} effectiveDate={groupEffectiveDate}/>
    <div className={styles.frame2675}>
      <div className={styles.frame2671}>
        <div className={styles.frame2670}>
          <div className={styles.frame2669}>
            <div className={styles.frame2657}>
              {enrollmentInfo}
              {tableHeaders}
            </div>
            <div className={styles.frame2668}>
              {tableRows}
            </div>
          </div>
          {noteSection()}
        </div>
        <p className={styles.medicalPlanPropParagraph}>{proposalHeader}</p>
      </div>
      <div className={styles.frame2674}></div>
    </div>
  </div>

  function noteSection() {
    return <>
      <p className={styles.noteRates}>Note: Rates and benefits are for illustrative purposes only and are not a
        guarantee of coverage. Final rates will be based on insurance carrier confirmation and final group enrollment.</p>
      {notes && notes.map((note, i) => <p key={`notes-${i}`} className={styles.noteRates}>{Array(i + 2).join('*')} {note}</p>)}
    </>
  }
}

export function enrollmentInformation(eOnly: string, eeplusSpouse: string, eeplusChild: string, family: string) {
  return <div className={styles.frame2407}>
    <div className={styles.frame2409}>
      <div className={styles.frame24071}>
        <p style={{ order: 0 }} className={styles.enrollNums}>{eOnly}</p>
        <p style={{ order: 1 }} className={styles.enrollNums}>{eeplusSpouse}</p>
        <p style={{ order: 2 }} className={styles.enrollNums}>{eeplusChild}</p>
        <p style={{ order: 3 }} className={styles.enrollNums}>{family}</p>
      </div>
      <div className={styles.frame2408}>
        <p className={styles.enrollTitle} style={{ order: 0 }}>Employee only</p>
        <p className={styles.enrollTitle} style={{ order: 1 }}>Employee + spouse</p>
        <p className={styles.enrollTitle} style={{ order: 2 }}>employee + child</p>
        <p className={styles.enrollTitle} style={{ order: 3 }}>family</p>
      </div>
    </div>
    <p className={styles.currEnrollment}> Current Enrollment</p>
  </div>
}

export function tablePlanHeader(planType: string, planName: string, order: number, logo?: JSX.Element) {
  return <div style={{ order: order }} className={styles.frame2459}>
    <div className={styles.frame1}>
      <p className={styles.planName}>{planName}</p>
    </div>
    {logo && <span className={styles.carrierLogo}>{logo}</span>}
    <p className={styles.timeLineText} style={logo ? {} : { top: '45%' }}>{planType}</p>
  </div>
}

export function tableRow(column1: string, order: number, column2?: string, column3?: string, column4?: string, column5?: string, column6?: string) {
  const bColor = column1 === 'RATES' ? '#16346F' : '#4171C8'
  const divStylesForRow = (column1: string, columnNum: number) => {
    switch (column1) {
    case 'IN-NETWORK':
      return {
        order: columnNum,
        backgroundColor: '#4171C8',
        border: 'none'
      }
    case 'RATES':
      return {
        order: columnNum,
        backgroundColor: '#16346F',
        border: 'none'
      }
    default:
      return {}
    }
  }

  const paragraphStylesForRow = (column1: string) => {
    switch (column1) {
    case 'monthly premium':
      return { fontWeight: 700 }
    case 'annual premium':
      return { fontWeight: 700 }
    default:
      return {}
    }
  }

  return <div style={{ order: order }} className={styles.frame2637}>
    <div className={styles.frame2632} style={(column1 === 'IN-NETWORK' || column1 === 'RATES') ? { backgroundColor: bColor } : {}}>
      {column2?.length !== undefined && <div style={divStylesForRow(column1, 0)} className={styles.frame2631}><p style={paragraphStylesForRow(column1)} className={styles.percentValue}>{column2}</p></div>}
      {column3?.length !== undefined && <div style={divStylesForRow(column1, 1)} className={styles.frame2631}><p style={paragraphStylesForRow(column1)} className={styles.percentValue}>{column3}</p></div>}
      {column4?.length !== undefined && <div style={divStylesForRow(column1, 2)} className={styles.frame2631}><p style={paragraphStylesForRow(column1)} className={styles.percentValue}>{column4}</p></div>}
      {column5?.length !== undefined && <div style={divStylesForRow(column1, 3)} className={styles.frame2631}><p style={paragraphStylesForRow(column1)} className={styles.percentValue}>{column5}</p></div>}
      {column6?.length !== undefined && <div style={divStylesForRow(column1, 4)} className={styles.frame2631}><p style={paragraphStylesForRow(column1)} className={styles.percentValue}>{column6}</p></div>}
    </div>
    <div className={styles.frame2635} style={(column1 === 'IN-NETWORK' || column1 === 'RATES') ? {
      backgroundColor: bColor,
      left: '50%',
      justifyContent: 'center'
    } : {}}>
      <p className={styles.percentChangeText}
        style={(column1 === 'IN-NETWORK' || column1 === 'RATES') ? { color: '#FFFFFF' } : {}}>{column1}</p>
    </div>
  </div>
}

export function tableRowCopayOrRx(copay: boolean, column2?: MedicalPlan, column3?: MedicalPlan, column4?: MedicalPlan, column5?: MedicalPlan, column6?: MedicalPlan) {
  const order = copay ? 7 : 2
  return <div style={{ order: order, height: '35px' }} className={styles.frame2637}>
    <div className={styles.frame2632} style={{ height: '35px' }}>
      {column2 !== undefined && <div style={{ order: 0, height: '35px' }} className={styles.frame2631}>{copay ? copayBox(column2) : rxBox(column2)}</div> }
      {column3 !== undefined && <div style={{ order: 1, height: '35px' }} className={styles.frame2631}>{copay ? copayBox(column3) : rxBox(column3)}</div> }
      {column4 !== undefined && <div style={{ order: 2, height: '35px' }} className={styles.frame2631}>{copay ? copayBox(column4) : rxBox(column4)}</div> }
      {column5 !== undefined && <div style={{ order: 3, height: '35px' }} className={styles.frame2631}>{copay ? copayBox(column5) : rxBox(column5)}</div> }
      {column6 !== undefined && <div style={{ order: 4, height: '35px' }} className={styles.frame2631}>{copay ? copayBox(column6) : rxBox(column6)}</div> }
    </div>
    <div className={styles.frame2635} style={{ height: '35px' }}>
      <p className={styles.percentChangeText}>{copay ? 'COPAY' : 'RX'}</p>
    </div>
  </div>
}

export function tablePlanHeaderSupplementalNonTiered(planType: string, planName: string, order: number, logo?: JSX.Element) {
  return <div style={{ order: order }} className={styles.frame2459}>
    <div className={styles.frame1}>
      <p className={styles.planName}>{planName}</p>
    </div>
    {logo && <span className={styles.carrierLogo}>{logo}</span>}
    <p className={styles.timeLineText} style={logo ? {} : { top: '45%' }}>{planType}</p>
  </div>
}

function copayBox(plan: MedicalPlan) {
  const isLimitedPlan = plan.carrier === Carriers['Sidecar Health']
  return <div className={styles.medicalVisitAndPrescLabel} style={{ fontSize: '7px' }}>
    {isLimitedPlan
      ? limitedPlanCopy('Prescriptions', 'If prescription coverage is elected, pays a fixed amount for any eligible prescriptions')
      : <>
        <div style={{ order: 0, display: 'flex' }} >
          <p>Primary:&nbsp;</p>
          {visitAndPrescCopy(plan.copay.primaryCarePhysician, plan, isLimitedPlan, true)}
        </div>
        <div style={{ order: 1, display: 'flex' }} >
          <p>Specialist:&nbsp;</p>
          {visitAndPrescCopy(plan.copay.specialist, plan, isLimitedPlan, true)}
        </div>
        <div style={{ order: 2, display: 'flex' }} >
          <p>Urgent Care:&nbsp;</p>
          {visitAndPrescCopy(plan.copay.urgentCare, plan, isLimitedPlan, true)}
        </div>
      </>
    }
  </div>
}

function rxBox(plan: MedicalPlan) {
  const isLimitedPlan = plan.carrier === Carriers['Sidecar Health']
  return <div className={styles.medicalVisitAndPrescLabel} style={{ fontSize: '7px' }}>
    {isLimitedPlan
      ? limitedPlanCopy('Medically Necessary Care', 'Pays a fixed amount for any eligible service needed to preserve or restore your health')
      : <>
        <div style={{ order: 0, display: 'flex' }} >
          <p>Generic:&nbsp;</p>
          {visitAndPrescCopy(plan.prescription.generic, plan, isLimitedPlan, true)}
        </div>
        <div style={{ order: 1, display: 'flex' }} >
          <p>Preferred:&nbsp;</p>
          {visitAndPrescCopy(plan.prescription.preferredBrand, plan, isLimitedPlan, true)}
        </div>
        <div style={{ order: 2, display: 'flex' }} >
          <p>Speciality:&nbsp;</p>
          {visitAndPrescCopy(plan.prescription.specialty, plan, isLimitedPlan, true)}
        </div>
      </>
    }
  </div>
}

function limitedPlanCopy(title: string, text: string) {
  return <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Proxima Nova', fontWeight: 600, fontSize: '7px', alignItems: 'center' }}>
    <p style={{ order: 0, marginBottom: '2px', fontWeight: 'bold' }}>{title}</p>
    <p style={{ order: 1 }}>{text}</p>
  </div>
}

export function enrollInfo(enrollCount: TierCount) {
  return <>
    {enrollmentInformation(enrollCount.individual.toString(), enrollCount.couple.toString(), enrollCount.single.toString(), enrollCount.family.toString())}
  </>
}

export function getPremiums(enrollCount: TierCount, data: TieredRates) {
  return calculatePremiumFigures(
    ({ premium: parseFloat(data.individual.replace('$', '')), enrolled: enrollCount.individual }),
    ({ premium: parseFloat(data.couple.replace('$', '')), enrolled: enrollCount.couple }),
    ({ premium: parseFloat(data.singleParent.replace('$', '')), enrolled: enrollCount.single }),
    ({ premium: parseFloat(data.family.replace('$', '')), enrolled: enrollCount.family })
  )
}

export default PlanProposal
