import { logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import React from 'react'
import { TieredRates } from 'Utilities/pharaoh.types'
import { AncillaryPlanUnion } from 'Utilities/Plans/ContributionCalculator'
import { TierCount } from '../Proposal'
import PlanProposal, { ProposalType, enrollInfo, tablePlanHeader, getPremiums, tableRow } from './PlanProposal'

export const TieredSupplementalPlanProposal: React.FC<{ suplementaryPlans: AncillaryPlanUnion[], enrollCount: TierCount, groupName: string, groupEffectiveDate?: Date, counts: string[] }> = ({ suplementaryPlans, enrollCount, groupName, groupEffectiveDate, counts }) => {
  const sPlans = suplementaryPlans.map(plan => plan as {rate: TieredRates, plan: SupplementalPlan})
  return <PlanProposal groupName={groupName} groupEffectiveDate={groupEffectiveDate} tablePlanHeaders={tablePlanHeaders(counts, sPlans[0].plan, sPlans[1]?.plan, sPlans[2]?.plan, sPlans[3]?.plan, sPlans[4]?.plan)} proposalHeader={ProposalType.worksite} tableRows={tableRows(enrollCount, sPlans[0]?.rate, sPlans[1]?.rate, sPlans[2]?.rate, sPlans[3]?.rate, sPlans[4]?.rate)} enrollmentInfo={enrollInfo(enrollCount)}/>
}

function tablePlanHeaders(counts: string[], proposedA: SupplementalPlan, proposedB?: SupplementalPlan, proposedC?: SupplementalPlan, proposedD?: SupplementalPlan, proposedE?: SupplementalPlan) {
  return <>
    {tablePlanHeader(counts[0], proposedA.name, 0, logoFor(proposedA.carrier))}
    {proposedB && tablePlanHeader(counts[1], proposedB?.name, 1, logoFor(proposedB?.carrier))}
    {proposedC && tablePlanHeader(counts[2], proposedC?.name, 2, logoFor(proposedC?.carrier))}
    {proposedD && tablePlanHeader(counts[3], proposedD?.name, 3, logoFor(proposedD?.carrier))}
    {proposedE && tablePlanHeader(counts[4], proposedE?.name, 4, logoFor(proposedE?.carrier))}
  </>
}

function tableRows(enrollCount: TierCount, proposedA: TieredRates, proposedB?: TieredRates, proposedC?: TieredRates, proposedD?: TieredRates, proposedE?: TieredRates) {
  const premiumsA = getPremiums(enrollCount, proposedA)
  const premiumsB = proposedB && getPremiums(enrollCount, proposedB)
  const premiumsC = proposedC && getPremiums(enrollCount, proposedC)
  const premiumsD = proposedD && getPremiums(enrollCount, proposedD)
  const premiumsE = proposedE && getPremiums(enrollCount, proposedE)

  return <>
    {tableRow('RATES', 0, proposedA ? '' : undefined, proposedB ? '' : undefined, proposedC ? '' : undefined, proposedD ? '' : undefined, proposedE ? '' : undefined)}
    {tableRow('employee', 1, proposedA?.individual, proposedB?.individual, proposedC?.individual, proposedD?.individual, proposedE?.individual)}
    {tableRow('employee + spouse', 2, proposedA?.couple, proposedB?.couple, proposedC?.couple, proposedD?.couple, proposedE?.couple)}
    {tableRow('employee + child', 3, proposedA?.singleParent, proposedB?.singleParent, proposedC?.singleParent, proposedD?.singleParent, proposedE?.singleParent)}
    {tableRow('family', 4, proposedA?.family, proposedB?.family, proposedC?.family, proposedD?.family, proposedE?.family)}
    {tableRow('monthly premium', 5, premiumsA.monthly, premiumsB?.monthly, premiumsC?.monthly, premiumsD?.monthly, premiumsE?.monthly)}
    {tableRow('annual premium', 6, premiumsA.annual, premiumsB?.annual, premiumsC?.annual, premiumsD?.annual, premiumsE?.annual)}
  </>
}
