import React from 'react'
import styles from './AgeBands.module.scss'
import { AgeBandsV2, MedicalPlan } from 'Utilities/pharaoh.types'
import { logoFor, massagedPlanName } from '../../Plans/plan-subcomponents/Plan.helpers'
import StyledRate from '../StyledRate/StyledRate'

interface AgeBandedRatesModalProps {
  bands: AgeBandsV2
  plan: MedicalPlan
  showTitle: boolean
}

class AgeBands extends React.Component<AgeBandedRatesModalProps> {
  public static defaultProps = {
    showTitle: true
  };

  render() {
    const { bands, plan, showTitle } = this.props
    return <div className={styles.moduleBlock}>
      {showTitle &&
      <div className={styles.modalTitle}>
        <td>{logoFor(plan.carrier, plan.name) || plan.carrier}</td>
        <td>{massagedPlanName(plan.name, plan.carrier)}</td>
      </div>}
      <div className={styles.height}>
        <table className={`${styles.table} ${styles.narrow}`}>
          <thead>
            <tr>
              <th>Age</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            {bands.standard.map((band, i) => {
              const range = band.minimumAge === band.maximumAge ? band.minimumAge : `${band.minimumAge} - ${band.maximumAge}`
              return <tr key={i}>
                <td>{range}</td>
                <td className={styles.planCost}><StyledRate rate={band.rate} precision={2}/></td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  }
}

export default AgeBands
