import React from 'react'
import styles from 'Components/Plans/ERSupplementalPlans/index.module.scss'
import SampleQuoteBox from 'Components/Plans/ERSupplementalPlans/ERSupplementalSubComp/SampleQuoteBox'
import Plan, { PlanBody } from '../plan-subcomponents/Plan'
import { Contributions, hasCustomPlanContributionFor } from 'Utilities/Plans/ContributionCalculator'
import BenifitAmountComp from 'Components/Plans/ERSupplementalPlans/ERSupplementalSubComp/BenifitAmountComp'
import PeriodComp from 'Components/Plans/ERSupplementalPlans/ERSupplementalSubComp/PeriodComp'
import { AncillaryMemberPremium, TieredRates } from 'Utilities/pharaoh.types'
import PlanTitle from '../plan-subcomponents/PlanTitle'
import { Label } from 'Utilities/config'
import { LtdEEPlan } from './index.helpers'
import { isEmployerProps, EmployeeProps } from '../plan-subcomponents/PremiumBreakdown'
import { AppMode, useAppMode } from 'Components/Stargate/TableOfContents'
import { Member, Group } from 'Utilities/Hooks/useStargate'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { useToggle } from 'react-use'

interface Props {
  plan: { rate: TieredRates, plan: LtdEEPlan, isRenewal: boolean, memberPremiums?: AncillaryMemberPremium }
  contributions: Contributions
  selectHandler?(plan: LtdEEPlan): void
  selected?: boolean
  nonWaivedMemberCount?: number
  showWeeklyPayments: boolean
  label: Label
  members?: Member[]
  stargateGroup?: Group
  showModal: boolean
}

const LTDEEComponent: React.FC<EmployeeProps & Props> = ({ plan, selectHandler, selected, contributions, showWeeklyPayments, ...props }) => {
  const [showMemberPremiums, toggleShowMemberPremiums] = useToggle(false)
  const { data, name, carrier, id } = plan.plan
  const isEmployer = useAppMode() === AppMode.employer
  const hasCustomPlanContribution = isEmployer && hasCustomPlanContributionFor(plan, contributions)
  const sbc = plan.plan.data.sbc || `https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/${id.toLowerCase()}.pdf`

  return <Plan selected={!!selected}>
    <PlanTitle
      id={id}
      planName={name}
      carrier={carrier}
      customContributionApplied={hasCustomPlanContribution}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
      selected={selected}
      sbc={sbc}
      includesProsperBenefits={false}
      planType={plan.plan.type}
      label={props.label}
      stargateGroup={props.stargateGroup}
      ancillaryMemberPremiums={plan.memberPremiums}
      needsMemberPremiumsShown={true}
      showMemberPremiums={showMemberPremiums}
      toggleShowMemberPremiums={toggleShowMemberPremiums}
      showModal={props.showModal}
      members={props.members}
    />
    <PlanBody planType={plan.plan.type}>
      {breakdown()}
      <BenifitAmountComp name={GlobalStringConstant[0].benefit_period} benifitAmount={data.benefitPeriod} benifitLabel={''} noDollarSign/>
      <div className={`${styles.endSection} ${styles.fourSpecs} ${styles.halfHeight}`}>
        <PeriodComp compName={GlobalStringConstant[0].monthly_benefit} daysOrWeak={data.monthlyPayment} label={' '}/>
        <PeriodComp compName={GlobalStringConstant[0].elimination_period} daysOrWeak={data.eliminationPeriod} label={' '}/>
      </div>
      <div className={styles.planDescFooter}>
        <p><span>*</span>Rates shown are illustrative for plan comparison purposes. Rates will vary based on employee age and salary. Contact <a href='mailto: support@myhealthily.com'>support@myhealthily.com</a> for more detailed rating information.</p>
      </div>
    </PlanBody>
  </Plan>

  function breakdown() {
    const base = { plan, contributions, showWeeklyPayments }
    if (isEmployerProps(props)) {
      return <SampleQuoteBox {...base} members={props.members} showWeeklyPayments={showWeeklyPayments}/>
    }
  }
}

export default LTDEEComponent
