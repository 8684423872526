import React from 'react'
import PlanTitle from './plan-subcomponents/PlanTitle'
import PlanBox from './plan-subcomponents/PlanBox'
import styles from './ProsperPlan.module.scss'
import eeAmountStyles from './plan-subcomponents/EEPremiumBreakdown.module.scss'
import { GroupPlanType, moneyString } from 'Utilities/Plans/ContributionCalculator'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import { useAppMode, AppMode } from 'Components/Stargate/TableOfContents'
import { classNames } from 'Utilities/etc'
import { Label } from 'Utilities/config'
import baseStyles from './plan-subcomponents/Plan.module.scss'

interface Props {
  isIncluded: boolean
  nonWaivedMemberCount: number
  label: Label
  prosperOnlyCost?: number
  selected?: boolean
  selectHandler?: () => void
}

const ProsperPlan: React.FC<Props> = props => {
  return <Plan selected={!!props.selected}>
    <PlanTitle
      id='prosper'
      carrier='Prosper'
      planName='Prosper Benefits+'
      includesProsperBenefits={false}
      planType={GroupPlanType.prosper}
      label={props.label}
      selectHandler={props.selectHandler}
      selected={props.selected}
    />
    <PlanBody>
      <div className={classNames(baseStyles.section, styles.prosperCostSection)}>
        <PlanBox className={classNames(styles.prosperBox, styles.prosperCostBox)}>
          <PlanBoxInner {...props} />
        </PlanBox>
        <PlanBox className={styles.prosperDescription}>
          <p><b>Prosper Benefits</b></p>
          <p>For when you or your family need to see a doctor, counselor, or health consultant. Use of Prosper Benefits is unlimited and includes a $0 copay. Search for and save on prescription drugs using Prosper’s alternative discount drug card.</p>
        </PlanBox>
      </div>
      <div className={classNames(baseStyles.section, styles.prosperDetailsSection)}>
        <PlanBox className={classNames(styles.prosperBox, styles.benefits)}>
          <p><b>Medical Suite</b> Unlimited use with $0 copay of eHealthcare, mental health therapy, medical advocacy, COVID PCR tests, medical bill help, and medical diagnosis support</p>
          <p><b>Personal Suite</b> Get support in other areas of your life with personal assistance, housing services, childcare, adoption resources, special needs support, elder/adult care, and life coaching</p>
          <p><b>Consultative Suite</b> Financial consultation with certified financial planners, legal consultation featuring an in-house legal team and attorney network for more specialized needs</p>
          <p><b>Prescription Suite</b> With our alternative discount drug card, you can save up to 80% off drugs and beat copay prices. Free to use to members and their family members. Never overpay for prescriptions again.</p>
        </PlanBox>
      </div>
    </PlanBody>
  </Plan>
}

const PlanBoxInner: React.FC<Props> = ({ isIncluded, nonWaivedMemberCount, ...props }) => {
  const mode = useAppMode()
  const prosperIncludedCost = props.prosperOnlyCost || 34.95
  const prosperOnlyCost = props.prosperOnlyCost || 25
  const employeesString = `${nonWaivedMemberCount} ${nonWaivedMemberCount > 1 ? 'employees' : 'employee'}`

  if (isIncluded) {
    const cost = moneyString(prosperIncludedCost * nonWaivedMemberCount)
    return <>
      <p className={styles.prosperIncluded}>Included</p>
      <p className={eeAmountStyles.afterContribution}>with your healthcare coverage</p>
      { mode === AppMode.employer
        ? <p className={eeAmountStyles.er} style={{ color: '#29B573' }}>You are saving <em>{cost}/mo</em><br/>for {employeesString}</p>
        : <p className={eeAmountStyles.er} style={{ color: '#29B573' }}>You are saving <em>{moneyString(prosperIncludedCost, 2)}/mo</em></p>
      }
    </>
  } else {
    if (mode === AppMode.employer) {
      const cost = moneyString(prosperOnlyCost * nonWaivedMemberCount).split('$')
      return <>
        <p className={eeAmountStyles.afterContribution}>Employer Pays</p>
        <div className={eeAmountStyles.ee} style={{ marginTop: '1rem' }}><span>$</span>{cost}<span>/mo</span></div>
        <p className={eeAmountStyles.afterContribution}>For {employeesString}</p>
      </>
    } else {
      return <>
        <div className={eeAmountStyles.ee}><span>$</span>{props.prosperOnlyCost}<span>/mo</span></div>
      </>
    }
  }
}

export default ProsperPlan
