import { logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import React from 'react'
import { TieredRates } from 'Utilities/pharaoh.types'
import {
  AncillaryPlanUnion,
  getMinMaxFromMemberPremiums,
  getTotalFromMemberPremium,
  GroupPlanType,
  moneyNumber,
  moneyString
} from 'Utilities/Plans/ContributionCalculator'
import { TierCount } from '../Proposal'
import PlanProposal, {
  enrollmentInformation,
  ProposalType,
  tablePlanHeaderSupplementalNonTiered,
  tableRow
} from './PlanProposal'
import numeral from 'numeral'

export const SupplementalPlanProposal: React.FC<{
  suplementaryPlans: AncillaryPlanUnion[]
  enrollCount: TierCount
  groupName: string
  groupEffectiveDate?: Date
  counts: string[]
}> = ({
  suplementaryPlans,
  enrollCount,
  groupName,
  groupEffectiveDate,
  counts
}) => {
  const sPlans = suplementaryPlans.map(plan => plan as { rate: TieredRates, plan: SupplementalPlan })
  const censusTotal = enrollCount.individual + enrollCount.couple + enrollCount.family + enrollCount.single
  const note = suplementaryPlans.map(plan => notes(plan))

  return <PlanProposal
    groupName={groupName} groupEffectiveDate={groupEffectiveDate}
    tablePlanHeaders={tablePlanHeaders(counts, sPlans[0].plan, sPlans[1]?.plan, sPlans[2]?.plan, sPlans[3]?.plan, sPlans[4]?.plan)}
    proposalHeader={ProposalType.supplemental}
    tableRows={tableRows(censusTotal, suplementaryPlans[0], suplementaryPlans[1], suplementaryPlans[2], suplementaryPlans[3], suplementaryPlans[4])}
    enrollmentInfo={enrollmentInformation(censusTotal.toString(), '0', '0', '0')}
    notes={note}
  />
}

function notes(plan?: AncillaryPlanUnion) {
  function illustrative() {
    if (plan) {
      return `Rates shown are illustrative based on a Monthly rate for a ${plan.sampleQuote?.aveAge} year old with an annual salary of ${moneyString(plan.sampleQuote?.sampleSalary)}`
    }
  }

  switch (plan?.plan.type) {
  case 'std':
    return 'Short-Term Disability plan costs are based on age and income.'
  case 'life':
  case 'lifeEE':
    return 'Voluntary Term Life is non-contributory and intended to be an employee paid benefit, as an employee is able to ' +
      'take the policy with them after leaving your company. Monthly total is calculated based on full participation of the group.'
  case 'lifeER':
    return 'Group Life is 100% Employer Paid and enrollment is required for all employees.'
  case 'stdEE':
    return `Short-Term Disability plan costs are based on age and income, is non-contributory and intended to be an employee paid benefit. 
    Monthly total is calculated based on full participation of the group.${canGetActualValue(plan) ? '' : illustrative()}`
  case 'stdER':
    return `Short-Term Disability plan costs are based on age and income. 100% Employer Paid and enrollment is required for all employees.${canGetActualValue(plan) ? '' : illustrative()}`
  case 'ltdEE':
    return `Long-Term Disability plan costs are based on age and income, is non-contributory and intended to be an employee paid benefit. 
    Monthly total is calculated based on full participation of the group.${canGetActualValue(plan) ? '' : illustrative()}`
  case 'ltdER':
    return `Long-Term Disability plan costs are based on age and income. 100% Employer Paid and enrollment is required for all employees.${canGetActualValue(plan) ? '' : illustrative()}`
  default:
    return ''
  }
}

function tablePlanHeaders(counts: string[], proposedA: SupplementalPlan, proposedB?: SupplementalPlan, proposedC?: SupplementalPlan, proposedD?: SupplementalPlan, proposedE?: SupplementalPlan) {
  counts = counts || []
  return <>
    {tablePlanHeaderSupplementalNonTiered(userFriendlyType(proposedA?.type), proposedA?.name, 0, logoFor(proposedA?.carrier))}
    {proposedB && tablePlanHeaderSupplementalNonTiered(userFriendlyType(proposedB?.type), proposedB?.name, 1, logoFor(proposedB?.carrier))}
    {proposedC && tablePlanHeaderSupplementalNonTiered(userFriendlyType(proposedC?.type), proposedC?.name, 2, logoFor(proposedC?.carrier))}
    {proposedD && tablePlanHeaderSupplementalNonTiered(userFriendlyType(proposedD?.type), proposedD?.name, 3, logoFor(proposedD?.carrier))}
    {proposedE && tablePlanHeaderSupplementalNonTiered(userFriendlyType(proposedE?.type), proposedE?.name, 4, logoFor(proposedE?.carrier))}
  </>

  function userFriendlyType(type: string | undefined) {
    switch (type) {
    case GroupPlanType.stdER:
    case GroupPlanType.stdEE:
    case GroupPlanType.std:
      return 'Short-Term Disability'
    case GroupPlanType.ltdER:
    case GroupPlanType.ltdEE:
      return 'Long-Term Disability'
    case GroupPlanType.lifeER:
    case GroupPlanType.lifeEE:
    case GroupPlanType.life:
      return 'Life'
    default:
      return ''
    }
  }
}

function canGetActualValue(proposed: AncillaryPlanUnion | undefined) {
  return !!(proposed && proposed?.memberPremiums && getMinMaxFromMemberPremiums(proposed.memberPremiums)[0] !== '$0')
}

function tableRows(censusTotal: number, proposedA: AncillaryPlanUnion, proposedB?: AncillaryPlanUnion, proposedC?: AncillaryPlanUnion, proposedD?: AncillaryPlanUnion, proposedE?: AncillaryPlanUnion) {
  function getTotal(proposed: AncillaryPlanUnion | undefined, multiplier = 1) {
    if (!proposed) {
      return undefined
    }
    switch (proposed.plan.type) {
    case GroupPlanType.lifeER:
    case GroupPlanType.lifeEE:
      if (!proposed?.memberPremiums) {
        return 'N/A'
      }
      return moneyString(numeral(getTotalFromMemberPremium(proposed.memberPremiums)).value() * multiplier, 2)
    case GroupPlanType.ltdER:
    case GroupPlanType.ltdEE:
    case GroupPlanType.stdER:
    case GroupPlanType.stdEE:
      if (!proposed?.sampleQuote) {
        return 'N/A'
      }
      // If all member Premiums are accounted for, then calculate the actual total.
      if (canGetActualValue(proposed)) {
        return moneyString(numeral(getTotalFromMemberPremium(proposed.memberPremiums)).value() * multiplier, 2)
      }
      // Else Calculate the total based on the illistrative sample
      return moneyString(moneyNumber(proposed.sampleQuote.rate, 2) * multiplier * censusTotal, 2)
    }
  }

  function getRate(proposed?: AncillaryPlanUnion) {
    if (!proposed) {
      return undefined
    }

    switch (proposed.plan.type) {
    case GroupPlanType.lifeER:
      if (!proposed?.memberPremiums) {
        return 'N/A'
      }
      return moneyString(numeral(getTotalFromMemberPremium(proposed.memberPremiums)).value() / censusTotal, 2)
    case GroupPlanType.lifeEE:
      if (!proposed?.memberPremiums) {
        return 'N/A'
      }
      return getMinMaxFromMemberPremiums(proposed.memberPremiums).join('-')
    case GroupPlanType.stdEE:
    case GroupPlanType.stdER:
    case GroupPlanType.ltdEE:
    case GroupPlanType.ltdER:
      if (!proposed?.sampleQuote) {
        return 'n/a'
      }
      if (canGetActualValue(proposed)) {
        return moneyString(numeral(getTotalFromMemberPremium(proposed.memberPremiums)).value() / censusTotal, 2)
      } else {
        return moneyString(proposed?.sampleQuote.rate, 2)
      }
    default:
      return 'N/A'
    }
  }

  return <>
    {tableRow('employee', 0, getRate(proposedA), getRate(proposedB), getRate(proposedC), getRate(proposedD), getRate(proposedE))}
    {tableRow('monthly premium', 1, getTotal(proposedA), getTotal(proposedB), getTotal(proposedC), getTotal(proposedD), getTotal(proposedE))}
    {tableRow('annual premium', 2, getTotal(proposedA, 12), getTotal(proposedB, 12), getTotal(proposedC, 12), getTotal(proposedD, 12), getTotal(proposedE, 12))}
  </>
}
