import { logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import VisionPlan from 'Components/Plans/VisionPlan/index.helpers'
import React from 'react'
import { TieredRates } from 'Utilities/pharaoh.types'
import { AncillaryPlanUnion } from 'Utilities/Plans/ContributionCalculator'
import { TierCount } from '../Proposal'
import PlanProposal, { ProposalType, enrollInfo, tablePlanHeader, getPremiums, tableRow } from './PlanProposal'

export const VisionPlanProposal: React.FC<{ visionPlans: AncillaryPlanUnion[], enrollCount: TierCount, groupName: string, groupEffectiveDate?: Date, counts: string[] }> = ({ visionPlans, enrollCount, groupName, groupEffectiveDate, counts }) => {
  const vPlans = visionPlans.map(plan => plan as {rate: TieredRates, plan: VisionPlan})
  return <PlanProposal groupName={groupName} groupEffectiveDate={groupEffectiveDate} tablePlanHeaders={tablePlanHeaders(counts, vPlans[0], vPlans[1], vPlans[2], vPlans[3], vPlans[4])} proposalHeader={ProposalType.vision} tableRows={tableRows(enrollCount, vPlans[0], vPlans[1], vPlans[2], vPlans[3], vPlans[4])} enrollmentInfo={enrollInfo(enrollCount)}/>
}

function tablePlanHeaders(counts: string[], current: {rate: TieredRates, plan: VisionPlan}, proposedA?: {rate: TieredRates, plan: VisionPlan}, proposedB?: {rate: TieredRates, plan: VisionPlan}, proposedC?: {rate: TieredRates, plan: VisionPlan}, proposedD?: {rate: TieredRates, plan: VisionPlan}) {
  return <>
    {tablePlanHeader(counts[0], current.plan.name, 0, logoFor(current.plan.carrier))}
    {proposedA && tablePlanHeader(counts[1], proposedA?.plan?.name, 1, logoFor(proposedA?.plan?.carrier))}
    {proposedB && tablePlanHeader(counts[2], proposedB?.plan?.name, 2, logoFor(proposedB?.plan?.carrier))}
    {proposedC && tablePlanHeader(counts[3], proposedC?.plan?.name, 3, logoFor(proposedC?.plan?.carrier))}
    {proposedD && tablePlanHeader(counts[4], proposedD?.plan?.name, 4, logoFor(proposedD?.plan?.carrier))}
  </>
}

function tableRows(enrollCount: TierCount, current: {rate: TieredRates, plan: VisionPlan}, proposedA?: {rate: TieredRates, plan: VisionPlan}, proposedB?: {rate: TieredRates, plan: VisionPlan}, proposedC?: {rate: TieredRates, plan: VisionPlan}, proposedD?: {rate: TieredRates, plan: VisionPlan}) {
  const premiumsA = proposedA && getPremiums(enrollCount, proposedA.rate)
  const premiumsB = proposedB && getPremiums(enrollCount, proposedB.rate)
  const premiumsC = proposedC && getPremiums(enrollCount, proposedC.rate)
  const premiumsD = proposedD && getPremiums(enrollCount, proposedD.rate)
  const premiumsCurr = getPremiums(enrollCount, current.rate)

  return <>
    {tableRow('IN-NETWORK', 0, '', proposedA ? '' : undefined, proposedB ? '' : undefined, proposedC ? '' : undefined, proposedD ? '' : undefined)}
    {tableRow('vision exam', 1, current.plan.data.comprehensiveVisionExamInNetwork, proposedA?.plan.data.comprehensiveVisionExamInNetwork, proposedB?.plan.data.comprehensiveVisionExamInNetwork, proposedC?.plan.data.comprehensiveVisionExamInNetwork, proposedD?.plan.data.comprehensiveVisionExamInNetwork)}
    {tableRow('frame allowance', 2, current.plan.data.framesAllowanceInNetwork, proposedA?.plan.data.framesAllowanceInNetwork, proposedB?.plan.data.framesAllowanceInNetwork, proposedC?.plan.data.framesAllowanceInNetwork, proposedD?.plan.data.framesAllowanceInNetwork)}
    {tableRow('contact lenses', 3, current.plan.data.contactLensesInNetwork, proposedA?.plan.data.contactLensesInNetwork, proposedB?.plan.data.contactLensesInNetwork, proposedC?.plan.data.contactLensesInNetwork, proposedD?.plan.data.contactLensesInNetwork)}
    {tableRow('RATES', 4, '', proposedA ? '' : undefined, proposedB ? '' : undefined, proposedC ? '' : undefined, proposedD ? '' : undefined)}
    {tableRow('employee', 5, current.rate.individual, proposedA?.rate.individual, proposedB?.rate.individual, proposedC?.rate.individual, proposedD?.rate.individual)}
    {tableRow('employee + spouse', 6, current.rate.couple, proposedA?.rate.couple, proposedB?.rate.couple, proposedC?.rate.couple, proposedD?.rate.couple)}
    {tableRow('employee + child', 7, current.rate.singleParent, proposedA?.rate.singleParent, proposedB?.rate.singleParent, proposedC?.rate.singleParent, proposedD?.rate.singleParent)}
    {tableRow('family', 8, current.rate.family, proposedA?.rate.family, proposedB?.rate.family, proposedC?.rate.family, proposedD?.rate.family)}
    {tableRow('monthly premium', 9, premiumsCurr?.monthly, premiumsA?.monthly, premiumsB?.monthly, premiumsC?.monthly, premiumsD?.monthly)}
    {tableRow('annual premium', 10, premiumsCurr?.annual, premiumsA?.annual, premiumsB?.annual, premiumsC?.annual, premiumsD?.annual)}
  </>
}
