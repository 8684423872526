import React from 'react'
import styles from './ProposalCompanyHeader.module.scss'

export const ProposalCompanyHeader: React.FC<{groupName: string, effectiveDate?: Date}> = ({ groupName, effectiveDate }) => {
  return <>
    <div className={styles.frame2615}>
      <div className={styles.frame2614}>
        <p className={styles.groupNameText}>{groupName}</p>
        <p className={styles.groupNameText}>{`Effective Date: ${effectiveDate?.toLocaleDateString('en-US')}`}</p>
      </div>
      <div className={styles.frame2613}>
      </div>
    </div>

  </>
}
