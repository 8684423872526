import React, { ReactElement, useRef } from 'react'
import PlanTitle from '../plan-subcomponents/PlanTitle'
import PlanSnapshotSection from '../plan-subcomponents/PlanSnapshotSection'
import PlanBox from '../plan-subcomponents/PlanBox'
import ALFMedicalPlanAdditionalSpecs from '../plan-subcomponents/ALFMedicalPlanAdditionalSpecs'
import styles from '../MedicalPlan.module.scss'
import ContributionsCalculator, { GroupPlanType, moneyString, moneyWeekly } from 'Utilities/Plans/ContributionCalculator'
import Plan, { PlanBody } from '../plan-subcomponents/Plan'
import { fundingTypeFor, Carriers } from '../plan-subcomponents/Plan.helpers'
import { Tier } from 'Utilities/pharaoh.types'
import { useToggle } from 'react-use'
import { $enum } from 'ts-enum-util'
import { ALFMedicalPlanProps } from 'Components/Plans/MedicalPlan'

const ALFMedicalPlanComponent: React.FC<ALFMedicalPlanProps> = ({ showWeeklyPayments, plan, selectHandler, splits, enableAgeBanded, selectedALFPlans, setSelectedALFPlans, ...props }) => {
  const isLimitedPlan = plan.carrier === Carriers['Sidecar Health']
  const [showLimitedPlanInfo, setShowLimitedPlanInfo] = useToggle(false)
  const [showSBC, toggleShowSBC] = useToggle(false)
  const ref = useRef<HTMLElement>()
  const calc = new ContributionsCalculator([plan], props.contributions, splits, props.members, 0, selectHandler ? props.selected : true)
  const fundingType = fundingTypeFor(plan)
  return <>
    <Plan selected={props.selected}>
      <PlanTitle
        {...plan}
        planName={plan.name}
        customContributionApplied={calc.hasCustomPlanContributionFor(plan)}
        includesProsperBenefits={true}
        fundingType={fundingType}
        selectHandler={selectHandler && (() => {
          if (!props.selected) {
            setSelectedALFPlans!([...selectedALFPlans!, { planID: plan.id, selectedAlfCarriers: [] }])
          } else {
            selectedALFPlans!.find(p => p.planID === plan.id)!.selectedAlfCarriers = []
            setSelectedALFPlans!(selectedALFPlans!.filter(p => p.planID !== plan.id))
          }
          selectHandler(plan)
        })}
        selected={props.selected}
        removeHandler={props.deleteHandler && onRemoveChange}
        selectedForRemoval={props.selectedForRemoval}
        isLimitedPlan={isLimitedPlan}
        infoHandler={setShowLimitedPlanInfo}
        showLimitedPlanInfo={showLimitedPlanInfo}
        showSBC={showSBC}
        toggleShowSBC={toggleShowSBC}
        showAgeBanded={false}
        planType={GroupPlanType.medical}
        label={props.label}
        members={props.members}
        stargateGroup={props.stargateGroup}
        canBeAgeBanded={false}
        showModal={props.showModal}
      />
      <PlanBody fundingType={fundingType} selectedForRemoval={props.selectedForRemoval}>{medicalSnapshot()}</PlanBody>
    </Plan>
  </>

  function medicalSnapshot() {
    return <>
      <PlanSnapshotSection sectionWidth={0.4}>
        <PlanBox height={ref.current?.getBoundingClientRect().height || 0} className={styles.medicalTableBox} refCallback={(rof: HTMLElement) => { ref.current = rof }}>
          <div className={styles.medicalPremiumTypeContainer}>
            <div>
              Individual{calc.numberOf(Tier.individual) > 1 ? 's' : ''}<br/>
              <span>cost {planPaymentsPeriod()}</span>
            </div>
            <div>Couples<br/><span>cost {planPaymentsPeriod()}</span></div>
            <div>Employee/Child<br/><span>cost {planPaymentsPeriod()}</span></div>
            <div>Family<br/><span>cost {planPaymentsPeriod()}</span></div>
          </div>
          <div className={styles.medicalPremiumBreakdownContainer}>
            { $enum(Tier).map(t =>
              <div key={t}>
                {/* Extra Div to make mint cell bg */}
                <div className={styles.premiumLabel}>
                  {planCostString(plan.premium.employee[t])}
                </div>
              </div>)
            }
          </div>
        </PlanBox>
      </PlanSnapshotSection>
      <ALFMedicalPlanAdditionalSpecs
        sectionWidth={0.6}
        plan={plan}
        isLimitedPlan={isLimitedPlan}
        infoHandler={setShowLimitedPlanInfo}
        includesProsperBenefits={plan.hasProsper}
        selectedALFCarriers={selectedALFPlans!}
        setSelectedALFCarriers={setSelectedALFPlans!}
        carriersDisabled={props.carriersDisabled}
        ALFCarrierSelect={props.ALFCarrierSelect!}
        alfCarriers={props.alfCarriers}
        selected={props.selected!}
        selectHandler={selectHandler!}
      />
    </>

    function moneyify(input: number | string): string {
      if (input === 0) {
        return '—'
      } else {
        return showWeeklyPayments ? moneyWeekly(input) : moneyString(input)
      }
    }

    function planCostString(input: number | string): string | ReactElement {
      if (input === 0) {
        return '–'
      } else {
        // Remove '$' from string
        const amt = moneyify(input).split('$')
        return <p className={styles.planCost}><span>$</span>{amt}<span>{planPaymentsPeriod().replace(/\s/g, '')}</span></p>
      }
    }

    function planPaymentsPeriod(): string {
      return showWeeklyPayments ? '/ wk' : '/ mo'
    }
  }

  function onRemoveChange() {
    if (props.deleteHandler) {
      props.deleteHandler(plan.id)
    }
  }
}

export default ALFMedicalPlanComponent
