import React from 'react'
import styles from './index.module.scss'
import ContributionsCalculator, { PlanUnion, GroupPlanType, moneyString, moneyWeekly } from 'Utilities/Plans/ContributionCalculator'
import { extractPlanNameAndCarrier, extractPlanType } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { $enum } from 'ts-enum-util'
import { ContributionSplit } from 'Utilities/Hooks/useStargate'
import { Tier } from 'Utilities/pharaoh.types'
import { upperFirst } from 'lodash'

interface PlanContributionTableProps {
  plans: Array<PlanUnion>
  type: string
  calc: ContributionsCalculator
  split?: ContributionSplit
  showWeeklyPayments: boolean
  fixed: boolean
}

enum AmountType {
  erAverage,
  eeAverage,
  erTotal
}

const FixedAncPlanContributionTable: React.FC<PlanContributionTableProps> = ({ plans, calc, split, ...props }) => {
  plans.sort((a, b) => extractPlanType(a).localeCompare(extractPlanType(b)))
  return <table className={styles.planContributionTable} data-columns={'five-col'}>
    <thead>
      <tr>
        <th>{planType()}</th>
        {$enum(Tier).map((v, _, __, i) => <th key={v}>{tierHead(v, i + 1)}</th>)}
      </tr>
    </thead>
    <tbody className={styles.fixedAncTable}>
      {plans.map((plan, i) => {
        const type = extractPlanType(plan)
        const { name, carrier } = extractPlanNameAndCarrier(plan)
        return <tr key={`${name}.${i}`}>
          <th>
            <div className={styles.pct_planCarrier}>{carrier}</div>
            <div className={styles.pct_planName}>{name}</div>
          </th>
          {type !== 'std' ? $enum(Tier).map(v => amountFor(plan, v, AmountType.eeAverage))
            : <div className={styles.stdRatesDisclaimer}
            >Short-Term Disability plan costs are based on age and income.</div>}
        </tr>
      })
      }
    </tbody>
  </table>

  function amountFor(plan: PlanUnion, key: Tier, type: AmountType) {
    const amount = getAmount()
    return <td key={key}>
      { amount
        ? <><span className={styles.amount}>{props.showWeeklyPayments ? moneyWeekly(amount) : moneyString(amount)}</span>/<span>{props.showWeeklyPayments ? 'wk' : 'mo'}</span></>
        : '—'
      }
    </td>

    function getAmount() {
      switch (type) {
      case AmountType.erAverage:
        return calc.avgPremiumsForPlanForTier(plan, key, split, props.type === GroupPlanType.medical).er || 0
      case AmountType.eeAverage:
        return calc.avgPremiumsForPlanForTier(plan, key, split, props.type === GroupPlanType.medical).ee || 0
      case AmountType.erTotal:
        return calc.premiumsForPlanForTier(plan, key, split, props.type === GroupPlanType.medical).er || 0
      }
    }
  }

  function planType() {
    return <div className={styles.planTypeCellWrapper}>
      <div className={styles.columnHeader}>{`${upperFirst(props.type)} Plan`}</div>
      <div className={styles.numberOfEmployees}># of people</div>
    </div>
  }

  function tierHead(tier: Tier, step: number) {
    return <>
      <div className={styles.columnHeader}>{`Tier ${step}`}</div>
      <div className={styles.columnTier}>{tierCopy(tier)}</div>
      <input className={styles.contributionInput} type='number' defaultValue={calc.numberOf(tier, split, props.type === GroupPlanType.medical)} disabled/>
    </>

    // Different names from `tierMarketingCopy` in `Plan.helpers`
    function tierCopy(tier: Tier) {
      switch (tier) {
      case Tier.individual:
        return 'Employee Only'
      case Tier.singleParent:
        return 'Employee & Child(ren)'
      case Tier.couple:
        return 'Employee & Spouse'
      case Tier.family:
        return 'Family'
      }
    }
  }
}

export default FixedAncPlanContributionTable
