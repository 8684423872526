import React, { ReactElement, useRef } from 'react'
import PlanTitle from './plan-subcomponents/PlanTitle'
import PlanSnapshotSection from './plan-subcomponents/PlanSnapshotSection'
import PlanBox from './plan-subcomponents/PlanBox'
import MedicalPlanAdditionalSpecs from './plan-subcomponents/MedicalPlanAdditionalSpecs'
import styles from './MedicalPlan.module.scss'
import ContributionsCalculator, { Contributions, GroupPlanType, moneyString, moneyWeekly } from 'Utilities/Plans/ContributionCalculator'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import { fundingTypeFor, Carriers } from './plan-subcomponents/Plan.helpers'
import { MedicalPlan, Tier } from 'Utilities/pharaoh.types'
import { useToggle } from 'react-use'
import { PlansWithCarriers } from 'Routes/shop/employer/plans'
import { Member, ContributionSplit, Group } from 'Utilities/Hooks/useStargate'
import { Label } from 'Utilities/config'
import { $enum } from 'ts-enum-util'

interface MedicalPlanProps {
  plan: MedicalPlan
  selected?: boolean
  selectedForRemoval?: boolean
  selectHandler?: (plan: MedicalPlan) => void
  deleteHandler?: (planID: string) => void
  members: Member[]
  splits: ContributionSplit[]
  contributions: Contributions
  showWeeklyPayments: boolean
  label: Label
  stargateGroup?: Group
  groupID?: string
  enableAgeBanded?: boolean
  carriersDisabled: boolean
  showModal?: boolean
}

export interface ALFMedicalPlanProps extends MedicalPlanProps {
  carriersDisabled: boolean
  selectedALFPlans?: PlansWithCarriers[]
  setSelectedALFPlans?: React.Dispatch<React.SetStateAction<PlansWithCarriers[]>>
  alfCarriers: string[]
  ALFCarrierSelect?: (carrier: string, planID: string)=> void
  showModal?: boolean
}

const MedicalPlanComponent: React.FC<MedicalPlanProps> = ({ showWeeklyPayments, plan, selectHandler, splits, enableAgeBanded, ...props }) => {
  const isLimitedPlan = plan.carrier === Carriers['Sidecar Health']
  const [showLimitedPlanInfo, setShowLimitedPlanInfo] = useToggle(false)
  const [showSBC, toggleShowSBC] = useToggle(false)
  const [showAgeBanded, toggleShowAgeBanded] = useToggle(false)
  const ref = useRef<HTMLElement>()

  const calc = new ContributionsCalculator([plan], props.contributions, splits, props.members, 0, selectHandler ? props.selected : true)
  const fundingType = fundingTypeFor(plan)
  return <>
    <Plan selected={props.selected}>
      <PlanTitle
        {...plan}
        canBeAgeBanded={plan.premium.can_be_age_banded}
        customContributionApplied={calc.hasCustomPlanContributionFor(plan)}
        fundingType={fundingType}
        includesProsperBenefits={plan.hasProsper!}
        infoHandler={setShowLimitedPlanInfo}
        isLimitedPlan={isLimitedPlan}
        label={props.label}
        members={props.members}
        planName={plan.name}
        planType={GroupPlanType.medical}
        removeHandler={props.deleteHandler && onRemoveChange}
        selectHandler={selectHandler && (() => selectHandler(plan))}
        selected={props.selected}
        selectedForRemoval={props.selectedForRemoval}
        showAgeBanded={showAgeBanded}
        showLimitedPlanInfo={showLimitedPlanInfo}
        showSBC={showSBC}
        stargateGroup={props.stargateGroup}
        toggleShowAgeBanded={toggleShowAgeBanded}
        toggleShowSBC={toggleShowSBC}
        showModal={props.showModal}
      />
      <PlanBody fundingType={fundingType} selectedForRemoval={props.selectedForRemoval}>{medicalSnapshot()}</PlanBody>
    </Plan>
  </>

  function medicalSnapshot() {
    return <>
      <PlanSnapshotSection sectionWidth={0.4}>
        <PlanBox height={ref.current?.getBoundingClientRect().height || 0} className={styles.medicalTableBox} refCallback={(rof: HTMLElement) => { ref.current = rof }}>
          <div className={styles.medicalPremiumTypeContainer}>
            <div>
              Individual{calc.numberOf(Tier.individual) > 1 ? 's' : ''}<br/>
              <span>cost {planPaymentsPeriod()}</span>
            </div>
            <div>Couples<br/><span>cost {planPaymentsPeriod()}</span></div>
            <div>Employee/Child<br/><span>cost {planPaymentsPeriod()}</span></div>
            <div>Family<br/><span>cost {planPaymentsPeriod()}</span></div>
          </div>
          <div className={styles.medicalPremiumBreakdownContainer}>
            { $enum(Tier).map(t =>
              <div key={t}>
                {/* Extra Div to make mint cell bg */}
                <div className={styles.premiumLabel}>
                  {planCostString(plan.premium.employee[t])}
                </div>
              </div>)
            }
          </div>
        </PlanBox>
      </PlanSnapshotSection>
      <MedicalPlanAdditionalSpecs
        sectionWidth={0.6}
        plan={plan}
        isLimitedPlan={isLimitedPlan}
        infoHandler={setShowLimitedPlanInfo}
        showLimitedPlanInfo={showLimitedPlanInfo}
        toggleShowSBC={toggleShowSBC}
        includesProsperBenefits={plan.hasProsper}
      />
    </>

    function moneyify(input: number | string): string {
      if (input === 0) {
        return '—'
      } else {
        return showWeeklyPayments ? moneyWeekly(input) : moneyString(input)
      }
    }

    function planCostString(input: number | string): string | ReactElement {
      if (input === 0) {
        return '–'
      } else {
        // Remove '$' from string
        const amt = moneyify(input).split('$')
        return <p className={styles.planCost}><span>$</span>{amt}<span>{planPaymentsPeriod().replace(/\s/g, '')}</span></p>
      }
    }

    function planPaymentsPeriod(): string {
      return showWeeklyPayments ? '/ wk' : '/ mo'
    }
  }

  function onRemoveChange() {
    if (props.deleteHandler) {
      props.deleteHandler(plan.id)
    }
  }
}

export default MedicalPlanComponent
