import React from 'react'
import styles from './Landing.module.scss'
import { Broker } from 'Utilities/pharaoh.types'
import useUser from 'Utilities/Hooks/useUser'
import Loader from 'Components/Rudimentary/Loader'
import { Img } from 'react-image'

const Landing: React.FC <{ broker: Broker, groupName: string}> = ({ broker, groupName }) => {
  const today = new Date()
  const { value: user, loading } = useUser()

  if (loading) return <Loader />
  const createdForText = `Proposal Created for ${groupName}`

  return <div className={styles.landingMain}>
    <div className={styles.brokerNameText}>
      <p>{broker.name}</p>
      <p>{broker.agency?.name}</p>
      <p>{broker.agency?.address1}</p>
      <p>{broker.agency?.address2}</p>
      <p>{broker.agency?.city}, {broker.agency?.state} {broker.agency?.zip}</p>
      <p>{broker.phone}</p>
      <p>{broker.email}</p>
    </div>
    <div className={styles.frame2425}>
      <p className={styles.completePackage}>Your Complete Healthcare Coverage Package</p>
      <p className={styles.proposalCreated}>{createdForText}</p>
      <p className={styles.dateProposalCreated}>{today.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</p>
    </div>
    <div className={styles.frame2821}>
      <div className={styles.frame2376}>
        <Img src={user?.avatar || ''} crossOrigin='anonymous' style={{ width: 'inherit' }} />
      </div>
    </div>
  </div>
}

export default Landing
