import React from 'react'
import { isProduction } from 'Utilities/config'
import { StargateResponse } from 'Utilities/Hooks/useStargate'
import styles from './Underwriting.module.scss'
import moment from 'moment'
import { firstName, lastName } from 'Utilities/etc'
interface props {
  stargate: StargateResponse
}

const ALFImqForm: React.FC<props> = ({ stargate }) => {
  const urlObj = isProduction() ? { URL: new URL('https://forms.zohopublic.com/MyHealthily/form/ProductionILFform/formperma/cJshk79DotG6V4_XmoVR-VfCCV3SjTz8xtKjQpVtOxY'), name: 'prod' }
    : { URL: new URL('https://forms.zohopublic.com/MyHealthily/form/DEV2MasterMQ/formperma/a4DaXTQh2zumusfpGE_elWEP6eB9XFI-2HrmrBvfLuI'), name: 'dev' }

  const spouse = stargate.members.find(m => m.id === stargate!.groupMember!.id)!.dependents.find(d => d.relationship === 'spouse')
  const children = stargate.members.find(m => m.id === stargate!.groupMember!.id)!.dependents.filter(d => d.relationship === 'child')

  const mqFormInputs = (input: string | null | undefined) => input ?? ''
  // eslint-disable-next-line camelcase
  const waiving = stargate?.groupMember?.is_waived === undefined ? '' : stargate!.groupMember!.is_waived ? 'Yes, waiving coverage' : 'No, applying for health coverage'
  const sex = stargate?.groupMember?.gender === undefined ? '' : stargate!.groupMember!.gender === 'F' ? 'Female' : 'Male'

  // Employer
  urlObj.URL.searchParams.set('SingleLine16', mqFormInputs(stargate?.group?.name))
  urlObj.URL.searchParams.set('SingleLine7', mqFormInputs(stargate?.group?.id))

  // Employee
  urlObj.URL.searchParams.set('Address_AddressLine1', mqFormInputs(stargate?.groupMember?.address1))
  urlObj.URL.searchParams.set('Address_AddressLine2', mqFormInputs(stargate?.groupMember?.address2))
  urlObj.URL.searchParams.set('Address_City', mqFormInputs(stargate?.groupMember?.city))
  // eslint-disable-next-line camelcase
  urlObj.URL.searchParams.set('Address_ZipCode', mqFormInputs(stargate?.groupMember?.zip_code))
  urlObj.URL.searchParams.set('SingleLine2', mqFormInputs(stargate.user?.ssn?.replace(/\D/g, '')))
  urlObj.URL.searchParams.set('sex', sex)
  urlObj.URL.searchParams.set('waiving', waiving)
  urlObj.URL.searchParams.set('Date1', moment(stargate?.groupMember?.hireDate).format('MM/DD/YYYY') ?? '')
  const eeTaxWithholding = stargate?.userMetadata?.taxWithholdingStatus
  eeTaxWithholding && urlObj.URL.searchParams.set('Radio', mqFormInputs((eeTaxWithholding as any).value))
  // eslint-disable-next-line camelcase
  urlObj.URL.searchParams.set('Date', moment(stargate?.groupMember?.date_of_birth).format('MM/DD/YYYY') ?? '')
  // eslint-disable-next-line camelcase
  urlObj.URL.searchParams.set('PhoneNumber', mqFormInputs(stargate?.groupMember?.phone_number).replace(/\D/g, ''))
  urlObj.URL.searchParams.set('SingleLine', mqFormInputs(stargate?.groupMember?.jobTitle))
  urlObj.URL.searchParams.set('Name_First', firstName(stargate?.groupMember?.name) ?? '')
  urlObj.URL.searchParams.set('Name_Last', lastName(stargate?.groupMember?.name) ?? '')
  urlObj.URL.searchParams.set('SingleLine1', mqFormInputs(stargate?.groupMember?.id))
  urlObj.URL.searchParams.set('Email', mqFormInputs(stargate?.groupMember?.email))

  urlObj.URL.searchParams.set('env', urlObj.name)

  if (spouse) {
    const spouseSSN = stargate.dependents?.find(dependent => dependent.relationship === 'spouse')?.ssn
    urlObj.URL.searchParams.set('Dropdown22', 'Yes')
    urlObj.URL.searchParams.set('Name1_First', spouse.firstName)
    urlObj.URL.searchParams.set('Name1_Last', spouse.lastName)
    urlObj.URL.searchParams.set('Date2', moment(spouse.dateOfBirth).format('MM/DD/YYYY'))
    urlObj.URL.searchParams.set('Radio5', spouse.gender === 'M' ? 'Male' : 'Female')
    spouseSSN && urlObj.URL.searchParams.set('SingleLine3', spouseSSN.replace(/-/g, ''))
  }

  if (children.length) {
    // Children's SSNs are stored in a different object, find them here
    const childrenForSSNs = stargate.dependents?.filter(dependent => dependent.relationship !== 'spouse')
    urlObj.URL.searchParams.set('Dropdown23', 'Yes')
    let i = 2
    children.slice(0, 5).forEach((c, index) => {
      urlObj.URL.searchParams.set(`Name${i}_First`, c.firstName)
      urlObj.URL.searchParams.set(`Name${i}_Last`, c.lastName)
      urlObj.URL.searchParams.set(`Date${i + 1}`, moment(c.dateOfBirth).format('MM/DD/YYYY'))
      urlObj.URL.searchParams.set(`Radio${i + 4}`, c.gender === 'M' ? 'Male' : 'Female')
      // Array of child SSN field names - first is child 1, second is child 2, etc
      const childSSNFields = [
        'SingleLine4',
        'SingleLine17',
        'SingleLine18',
        'SingleLine19',
        'SingleLine20'
      ]
      if (childSSNFields[index] && childrenForSSNs) {
        urlObj.URL.searchParams.set(childSSNFields[index], (childrenForSSNs[index]?.ssn || '').replace(/-/g, ''))
      }
      i++
    })
  }

  return <div className={styles.mainContainer}>
    <h1 className='shop-h1-periwinkle'>Underwriting Form</h1>
    <h2 className='shop-h2-navy'>We need a little more information to complete your application</h2>

    <iframe src={urlObj.URL.toString()}/>

  </div>
}

export default ALFImqForm
