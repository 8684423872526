/* eslint-disable camelcase */
import React from 'react'
import styles from './EREERate.module.scss'
import {
  Contributions,
  AncillaryPlanUnion,
  GroupPlanType,
  getMinMaxFromMemberPremiums
} from 'Utilities/Plans/ContributionCalculator'
import { AncillaryMemberPremium } from 'Utilities/pharaoh.types'
import { Member } from 'Utilities/Hooks/useStargate'
interface Props {
  plan: AncillaryPlanUnion
  contributions: Contributions
  members: Member[]
}

const getMinMax = (memberPremiums: AncillaryMemberPremium | undefined) => {
  return getMinMaxFromMemberPremiums(memberPremiums).map(m => m.replace('$', ''))
}
// The individual tier of the lifeEE and lifeER plans contains the rate for the lowest age band, and the couple tier contains the rate with the highest age band.
// The backend is written this way in order to avoid having to rewrite a bunch of backend code to accomidate this plan type
const EREERate: React.FC<Props> = ({ plan }) => {
  const dol = <span className={styles.dollar}>$</span>
  const [min, max] = getMinMax(plan.memberPremiums)
  return (
    <div className={styles.rateContainer}>
      <div className={styles.title}>{(plan.plan.type === GroupPlanType.lifeEE) ? 'Employee Cost' : 'Cost Per Employee'}</div>
      <div className={styles.flex}>
        <div className={styles.priceRange}>
          {min !== max
            ? <>
              {dol}
              <span className={styles.price}>{min}</span>
              <span className={styles.dash}>-</span>
              {dol}
              <span className={styles.price}>{max}</span>
            </>
            : <>
              {dol}
              <span className={styles.price}>{min}</span>
            </>}
        </div>
      </div>
    </div>
  )
}
export default EREERate
