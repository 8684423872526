import React from 'react'
import styles from './MedicalPlanAdditionalSpecs.module.scss'
import PlanSnapshotSection from './PlanSnapshotSection'
import PlanBox from './PlanBox'
import { moneyString } from 'Utilities/Plans/ContributionCalculator'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { isHBA } from './Plan.helpers'
import { logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { PlansWithCarriers } from 'Routes/shop/employer/plans/index'

interface Props {
  plan: MedicalPlan
  sectionWidth?: number
  refCallback?: any
  isProsper?: boolean // unused now
  isLimitedPlan?: boolean
  infoHandler?: () => void
  includesProsperBenefits: boolean
  selectedALFCarriers: PlansWithCarriers[]
  setSelectedALFCarriers: React.Dispatch<React.SetStateAction<PlansWithCarriers[]>>
  carriersDisabled: boolean
  ALFCarrierSelect: (carrier: string, planID: string) => void
  alfCarriers: string[]
  selected: boolean
  selectHandler: (plan: MedicalPlan) => void
}

const ALFMedicalPlanAdditionalSpecs: React.FC<Props> = ({ plan, sectionWidth, refCallback, isLimitedPlan, selectedALFCarriers, setSelectedALFCarriers, carriersDisabled, ...props }) => {
  const hasOONCoverage = () => plan.oonCoverage
  const isHBAPlan = isHBA(plan.carrier)
  const hasNoOOPMax = isHBAPlan && !!plan.name.match(/MEC 1/)
  return <PlanSnapshotSection sectionWidth={sectionWidth} flexDirection={'column'} refCallback={refCallback}>
    <div className={styles.additionalPlanSpecsContainer}>

      <div className={styles.ALFPlanInfo}>
        <p>Plan Design and Rates shown are illustrative only and not bindable. By selecting a carrier below, employees may be required to complete a
          medical underwriting application. Final rates and full plan designs will be provided after the applications are complete and with underwriting approval.</p>
      </div>

      <div className={ styles.collapsedSpecs }>
        <div>
          <h4 className={styles.selectCarriers}>Select Carriers</h4>
          <div className={styles.medicalProsperContainer}>
            {props.alfCarriers.map(c => <ALFCarrierSpec
              key={c}
              plan={plan}
              carrier={c}
              planID={plan.id}
              selectedALFCarriers={selectedALFCarriers}
              carriersDisabled={carriersDisabled}
              selectFunction={() => props.ALFCarrierSelect(c, plan.id)}
              selected={props.selected}
              selectHandler={props.selectHandler}
              setSelectedALFCarriers={setSelectedALFCarriers}
              alfCarriers={props.alfCarriers}
            />)}
          </div>
        </div>

      </div>
      <div className={ styles.collapsedSpecs }>
        <div className={styles.medicalSpecsContainer}>
          <PlanBox className={styles.medicalSpecsPlanBox}>
            <p className={styles.medicalLabel}>Deductible{asterisk()}</p>
            {planSpecsCopy(plan.deductible, isLimitedPlan)}
          </PlanBox>
          <PlanBox className={styles.medicalSpecsPlanBox}>
            <p className={styles.medicalLabel}>{`${isLimitedPlan ? 'Annual Max Benefit' : 'OOP Max'}`}</p>
            { hasNoOOPMax
              ? <div className={styles.medicalPlanSpecsCopy}>n/a</div>
              : planSpecsCopy(plan.oopMax)
            }
          </PlanBox>
          <PlanBox className={styles.medicalSpecsPlanBox}>
            <p className={styles.medicalLabel}>Co-insurance</p>
            {planSpecsCopy(plan.coinsurance)}
          </PlanBox>
        </div>
      </div>
    </div>

  </PlanSnapshotSection>

  // For when we remove the asterisk box

  function asterisk() {
    return !hasOONCoverage() && !isLimitedPlan && <span className={styles.asterisk}>*</span>
  }

  function planSpecsCopy(amt: string, networkAgnostic = false) {
    amt = amt.split('.')[0]
    switch (true) {
    case isPercentageAmt(amt): {
      const youPayPercentage = parseInt(amt.substring(0, amt.length - 1))
      const coveredPercentage = 100 - youPayPercentage
      return <div className={styles.medicalPlanSpecsCopy}>
        <p className={styles.coveredFigure}>{`${coveredPercentage}%`}</p>
        <p className={styles.coveredText}>covered</p>
        { !isZeroAmt(amt) &&
          <>
            <p className={styles.youPayText}>you pay</p>
            <p className={styles.youPayFigure}>{`${youPayPercentage}%`}</p>
          </>
        }
      </div>
    }
    case isDollarAmt(amt):
      return <div className={styles.medicalPlanSpecsCopy}>
        <p style={{ color: isZeroAmt(amt) ? '#29B473' : '#17346F' }} className={styles.dollarFigure}>{moneyString(amt)}<span>/yr</span></p>
        <p style={{ color: isZeroAmt(amt) ? '#29B473' : '#17346F' }} className={styles.inNetwork}>
          { networkAgnostic
            ? 'this plan has no network requirements'
            : 'in-network'
          }
        </p>
        {/* Add after we have in/out network information in plans <p>NO out of network contribution</p> */}
      </div>
    default:
      return <div className={styles.medicalPlanSpecsCopy}>
        <p className={styles.dollarFigure}>{amt}</p>
        {!isLimitedPlan &&
          <p className={styles.inNetwork}>in-network</p>
        }
        {/* Add after we have in/out network information in plans <p>NO out of network contribution</p> */}
      </div>
    }
  }
}
interface ALFCarrierSpecProps {
  plan: MedicalPlan
  carrier: string
  planID: string
  selectedALFCarriers: PlansWithCarriers[]
  carriersDisabled: boolean
  selectFunction: (carrier: string, planID: string) => void
  selected: boolean
  selectHandler: (plan: MedicalPlan) => void
  setSelectedALFCarriers: React.Dispatch<React.SetStateAction<PlansWithCarriers[]>>
  alfCarriers: string[]
}

const ALFCarrierSpec:React.FC<ALFCarrierSpecProps> = ({ plan, carrier, selectedALFCarriers, selectFunction, carriersDisabled, planID, selected, selectHandler, setSelectedALFCarriers }) => {
  const carrierClickFunc = () => {
    if (selected) {
      selectFunction(carrier, planID)
    } else {
      setSelectedALFCarriers!([...selectedALFCarriers!, { planID: plan.id, selectedAlfCarriers: [carrier] }])
      selectHandler(plan)
    }
  }
  return <><PlanBox className={`${styles.ALFCarrierPlanBox} ${selectedALFCarriers.find(p => p.planID === planID)?.selectedAlfCarriers.includes(carrier) && styles.ALFCarrierSelected} ${carriersDisabled && styles.grayedOut}`} clickFunc={carrierClickFunc}>
    <div className={`${styles.carrierCheck}`} onClick={carrierClickFunc}>
      <label className={styles.carrierLabel}>
        <input type="checkbox" checked={selectedALFCarriers.find(p => p.planID === planID) ? selectedALFCarriers.filter(p => p.planID === planID)[0].selectedAlfCarriers.includes(carrier) : false} onClick={carrierClickFunc}/>
        <span className={`${styles.radio} ${!selected && styles.radioBackground}`}></span>
      </label>
    </div>
    {logoFor(carrier, carrier, true) ? logoFor(carrier, carrier, true) : <div className={`${styles.ALFCarrier} ${styles.alignCenter}`}>{carrier}</div>}
  </PlanBox></>
}

const isZeroAmt = (amt: string) => amt === '0' || amt === '$0' || amt === '0%'
const isDollarAmt = (amt: string) => amt.startsWith('$') && amt.length < 5
const isPercentageAmt = (amt: string) => amt.endsWith('%') && amt.length < 5

export default ALFMedicalPlanAdditionalSpecs
