import React from 'react'
import { Contributions, GroupPlanType } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import Plan, { PlanBody } from 'Components/Plans/plan-subcomponents/Plan'
import PlanTitle from 'Components/Plans/plan-subcomponents/PlanTitle'
import EETierRate from './EESupplementalSubComp/EETierRate'
import { Member } from 'Utilities/Hooks/useStargate'
import BenefitComp from './EESupplementalSubComp/BenefitComp'
import BenefitAmountContainer from './EESupplementalSubComp/BenefitAmountContainer'
import benefitStyle from './EESupplementalSubComp/EESupplementalSubComp.module.scss'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { GetStringNumber } from './CriticalIllnessEE'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/Plan.helpers'

interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan, isRenewal: boolean }
  contributions: Contributions
  selected?: boolean
  label: Label
  selectHandler?(plan: SupplementalPlan): void
  showWeeklyPayments: boolean
  premium?: PremiumSplits
  member: Member
}
const HospitalEE: React.FC<Props> = (props) => {
  const { plan, selected, label, selectHandler } = props
  const sbc = plan.plan.data.sbc
  const hospitalConfinementBenefit = GetStringNumber(plan?.plan?.data?.hospitalConfinementBenefit)
  const emergencyRoomBenefit = GetStringNumber(plan?.plan?.data?.emergencyRoomBenefit)
  const hospitalShortStay = GetStringNumber(plan?.plan?.data?.hospitalShortStay)
  return <> <Plan selected={!!selected}>
    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier={plan.plan.carrier}
      selected={selected}
      includesProsperBenefits={false}
      planType={GroupPlanType.hospital}
      label={label}
      sbc={sbc}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
    />
    <PlanBody noWrap={true}>
      <EETierRate
        plan={plan}
        contributions={props.contributions}
        showWeeklyPayments={props.showWeeklyPayments}
        member={props.member}
        showAfterEmpCont={true}
      />
      <BenefitComp amountLabel={GlobalStringConstant[0].onceper_calendar_year} name={GlobalStringConstant[0].hospital_confinement_benefit} benefitAmount={hospitalConfinementBenefit?.[0]} />
      <div className={styles.vertContainer}>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].emergency_room_benefit}</div>

            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{emergencyRoomBenefit?.[0] || '0'}</span>
              <p className={benefitStyle.benefit_amount_label}>{GlobalStringConstant[0].payment_max_per_calendar_year}</p>
            </div>

          </div>
        </BenefitAmountContainer>
        <BenefitAmountContainer>
          <div className={styles.vertFlex}>
            <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].hospital_short_stay}</div>

            <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
              <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{hospitalShortStay?.[0] || '0'}</span>
              <p className={benefitStyle.benefit_amount_label}>{GlobalStringConstant[0].payment_max_per_calendar_year}</p>
            </div>

          </div>
        </BenefitAmountContainer>
      </div>
    </PlanBody>
  </Plan>
  </>
}

export default HospitalEE
