
export const GlobalStringConstant = [
  {
    EAP_serv_pro: 'EAP Service Provided',
    accident: 'ACCIDENT',
    accident_death_benefit: 'Accidental Death Benefit',
    accident_hospital_confinement: 'Accident Hospital Confinement Benefit',
    accidental_death_and_dismemberment: 'Accidental Death And Dismemberment',
    after_employer_contribution: 'AFTER EMPLOYER CONTRIBUTION',
    amount: '100',
    base_on_your_age_income: 'based on your age & Income',
    benefit_amount: 'Benefit Amount',
    benefit_payment: 'Benefit Payment',
    benefit_period: 'Benefit Period',
    conversion: 'Conversion',
    daily_benefit_amount: 'DAILY BENEFIT AMOUNT',
    days: '90 days',
    depends_on_age_income: 'Depends on age & income',
    dolar: '$',
    eeContribution_fixed_amount: 'Your employer is contributing a fixed amount of $100/month* to distribute across ancillary and supplemental plans for you and your family.',
    ee_remaining_amount: 'You have the following amount remaining:',
    ee_term_life_info: '*This amount does not apply to voluntary term life.',
    elimination_period: 'Elimination Period',
    elimination_period_accident: 'Elimination Period Accident',
    elimination_period_sickness: 'Elimination Period Sickness',
    emergency_room_benefit: 'Emergency Room Benefit',
    fixed_contribution: 'Fixed Contribution',
    hospice_care: 'Hospice Care',
    hospital_confinement: 'hospital confinement',
    hospital_confinement_benefit: 'Hospital Confinement Benefit',
    hospital_short_stay: 'Hospital Short Stay',
    individual_tier: 'INDIVIDUAL TIER',
    initial_accident_hospitalization_benefit: 'Initial Accident Hospitalization Benefit',
    initial_diagnosis_benift: 'Initial Diagnosis Benefit',
    lump_sum: 'LUMP SUM',
    major_criticalIllness_event: 'Major Critical Illness Event',
    min_benefit: 'Minimum Benefit',
    mon: '/mon',
    monthly: 'Monthly',
    monthly_benefit: 'Monthly Benefit',
    monthly_payment: 'Monthly Payment',
    onceper_calendar_year: 'once per calendar year',
    payable_one_per_lifetime: '*payable once per lifetime',
    payment_max_per_calendar_year: '2 payment maximum per calendar year',
    plan_term: 'Plan Term',
    please_refer_to_plan_detail: 'Please refer to plan details',
    portability: 'Portability',
    remaining_er_contribution: 'Remaining Employer Contribution',
    sickness: 'SICKNESS',
    stem_cell_bone_marrow_transplant: 'Stem Cell & Bone Marrow Transplant',
    subsequent_critical_illness_event: 'Subsequent Critical Illness Event',
    sudden_cardiac_arrest_benefit: 'Sudden Cardiac Arrest Benefit',
    supp_ad_and_d_inc: 'Supplemental AD&D Included',
    surgical_anesthesia: 'Surgical/Anesthesia',
    to_this_plan: 'to this plan',
    waiting_period: 'Waiting Period',
    weekly_benefit: 'Weekly Benefit',
    weeks: '12 Weeks',
    will_prep: 'Will Preparation and Estate Reslution Services Provided',
    your_employer_contributes: 'Your employer contributes',
    your_monthly_salary: 'of your Monthly salary'
  }
]
