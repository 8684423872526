import { BasePlan } from 'Utilities/pharaoh.types'
import { GroupPlanType } from 'Utilities/Plans/ContributionCalculator'

export default interface LTDPlan extends BasePlan {
  type: GroupPlanType.disability
  data: {
    continuityOfCoverage?: string
    conversion?: string
    eliminationPeriodInDays?: string
    monthlyBenefit?: string
    sbc?: string
    survivorBenefit?: string
    workLifeAssistance?: string
    benefitPeriod?: string
    monthlyPayment?: string
    eliminationPeriod: string
  }
}

export interface LtdEEPlan extends BasePlan {
  type: GroupPlanType.ltdEE
  data: {
    benefitPeriod?: string
    monthlyPayment?: string
    eliminationPeriod: string
    sbc?: string
  }
}

export interface LtdERPlan extends BasePlan {
  type: GroupPlanType.ltdER
  data: {
    benefitPeriod?: string
    monthlyPayment?: string
    eliminationPeriod: string
    sbc?: string
  }
}

export function isLTD(plan: any): plan is LTDPlan {
  return plan.type === 'disability' || plan?.plan?.type === 'disability'
}

export function isLtdEE(plan: any): plan is LtdEEPlan {
  return plan.type === 'ltdEE' || plan?.plan?.type === 'ltdEE'
}

export function isLtdER(plan: any): plan is LtdERPlan {
  return plan.type === 'ltdER' || plan?.plan?.type === 'ltdER'
}
