/* eslint-disable camelcase */
import React, { useState } from 'react'
import Modal from 'Components/Modals/Modal'
import styles from './MemberRatesModal.module.scss'
import { Tier } from 'Utilities/pharaoh.types'
import moment from 'moment'
import { useAsync } from 'react-use'
import * as api from 'Utilities/pharaoh'
import { Member } from 'Utilities/Hooks/useStargate'
import Error from 'Components/Primitives/Error'
import { moneyNumber } from 'Utilities/Plans/ContributionCalculator'
import Loader from '../../Rudimentary/Loader'
import { camelCaseToHuman } from '../../Plans/plan-subcomponents/Plan.helpers'
import StyledRate from 'Components/Anubis/StyledRate/StyledRate'

/* eslint-disable camelcase */
interface MemberRate {
  memberID: string
  dependant_premium: string
  insured_premium: string
  spouse_premium: string
  total_premium: string
}

interface Dependents {
  spouse: boolean
  children: number
}

interface MemberRatesModalProps {
  isOpen: boolean
  onRequestClose: any
  logo: JSX.Element
  planName: string
  carrier: string
  planID: string
  groupID: string
  members: Member[]
}

const MemberRatesModal: React.FC<MemberRatesModalProps> = ({ isOpen, onRequestClose, logo, planName, carrier, planID, groupID, members }) => {
  const [memberRates, setMemberRates] = useState<MemberRate[]>()
  const dependents: Dependents[] = []

  const async = useAsync(async() => {
    const rsp = await api.v2.groups(groupID).plans.member_rates(planID)
    setMemberRates(rsp)
  })

  if (async.loading) return <Loader className={styles.loader}/>

  if (!memberRates) return <Error error={'No age banded rates'}/>
  function sumTotal(items: MemberRate[], prop: string) {
    return items.reduce((a: any, b: any) => {
      return a + moneyNumber(b[prop], 2)
    }, 0)
  }
  const total = sumTotal(memberRates, 'total_premium')

  return <Modal header={'Cost Breakdown'} isOpen={isOpen} onRequestClose={onRequestClose}>
    <div className={styles.modalTitle}>
      <div>{logo}</div>
      <div>{carrier}</div>
      <div>{planName}</div>
    </div>
    <div className={styles.height}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Employee</th>
            <th>Age</th>
            <th>Plan Tier</th>
            <th>Plan Cost</th>
          </tr>
        </thead>
        <tbody>
          {members.filter(m => !m.is_waived).sort(function(a, b) {
            return a.name.split(' ').pop()!.localeCompare(b.name.split(' ').pop()!) ||
              a.name.split(' ').pop()!.localeCompare(b.name.split(' ').pop()!)
          }
          ).map((m, i) => {
            dependents[i] = { spouse: false, children: 0 }
            const memberRate = memberRates.find(id => id.memberID === m.id)
            if (!memberRate) return <Error error={'No age banded rates'}/>

            const age = moment().diff(m.dateOfBirth, 'years', false)

            m.dependents.map((d) => {
              switch (d.relationship) {
              case 'spouse':
                dependents[i].spouse = true
                break
              case 'child':
                dependents[i].children += 1
                break
              }
            })
            return <tr key={m.id}>
              <td>

                <div>{m.name}</div>
                {m.dependents.length !== 0 && <div className={styles.dependentTitle}>Dependents</div>}
                <div className={styles.dependentDescription}>{dependents[i].spouse && 'Spouse'}
                  {(dependents[i].spouse && (dependents[i].children > 0)) && ', ' }
                  {dependents[i].children > 1 && `${dependents[i].children} Children`}
                  {dependents[i].children === 1 && `${dependents[i].children} Child`}
                </div>
              </td>
              <td className={styles.lightFont}><div>{age}</div>{(dependents[i].spouse || dependents[i].children > 0) && <div className={styles.dependentDash}>&#8212;</div>}</td>
              <td className={styles.uppercase}>{camelCaseToHuman(Tier[m.tier])}</td>
              <td className={styles.planCost}><StyledRate rate={memberRate.insured_premium}/>{(dependents[i].spouse || dependents[i].children > 0) && <div className={styles.dependantPremium}><StyledRate rate={memberRate.dependant_premium}/></div>}</td>
            </tr>
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>Total</td>
            <td><StyledRate rate={total}/></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </Modal>
}

export default MemberRatesModal
