import DentalPlan, { D2 } from 'Components/Plans/DentalPlan/index.helpers'
import { logoFor } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import React from 'react'
import { TieredRates } from 'Utilities/pharaoh.types'
import { AncillaryPlanUnion } from 'Utilities/Plans/ContributionCalculator'
import PlanProposal, { enrollInfo, getPremiums, ProposalType, tablePlanHeader, tableRow } from './PlanProposal'
import { TierCount } from '../Proposal'

export const DentalPlanProposal: React.FC<{ dentalPlans: AncillaryPlanUnion[], enrollCount: TierCount, groupName: string, groupEffectiveDate?: Date, counts: string[] }> = ({ dentalPlans, enrollCount, groupName, groupEffectiveDate, counts }) => {
  const dPlans = dentalPlans.map(plan => plan as {rate: TieredRates, plan: DentalPlan})
  return <PlanProposal groupName={groupName} groupEffectiveDate={groupEffectiveDate} tablePlanHeaders={tablePlanHeaders(counts, dPlans[0], dPlans[1], dPlans[2], dPlans[3], dPlans[4])} proposalHeader={ProposalType.dental} tableRows={tableRows(enrollCount, dPlans[0], dPlans[1], dPlans[2], dPlans[3], dPlans[4])} enrollmentInfo={enrollInfo(enrollCount)}/>
}

function tablePlanHeaders(counts: string[], current: {rate: TieredRates, plan: DentalPlan}, proposedA?: {rate: TieredRates, plan: DentalPlan}, proposedB?: {rate: TieredRates, plan: DentalPlan}, proposedC?: {rate: TieredRates, plan: DentalPlan}, proposedD?: {rate: TieredRates, plan: DentalPlan}) {
  return <>
    {tablePlanHeader(counts[0], current.plan.name, 0, logoFor(current.plan.carrier))}
    {proposedA && tablePlanHeader(counts[1], proposedA?.plan?.name, 1, logoFor(proposedA?.plan?.carrier))}
    {proposedB && tablePlanHeader(counts[2], proposedB?.plan?.name, 2, logoFor(proposedB?.plan?.carrier))}
    {proposedC && tablePlanHeader(counts[3], proposedC?.plan?.name, 3, logoFor(proposedC?.plan?.carrier))}
    {proposedD && tablePlanHeader(counts[4], proposedD?.plan?.name, 4, logoFor(proposedD?.plan?.carrier))}
  </>
}

function tableRows(enrollCount: TierCount, current: {rate: TieredRates, plan: DentalPlan}, proposedA?: {rate: TieredRates, plan: DentalPlan}, proposedB?: {rate: TieredRates, plan: DentalPlan}, proposedC?: {rate: TieredRates, plan: DentalPlan}, proposedD?: {rate: TieredRates, plan: DentalPlan}) {
  const premiumsA = proposedA && getPremiums(enrollCount, proposedA.rate)
  const premiumsB = proposedB && getPremiums(enrollCount, proposedB.rate)
  const premiumsC = proposedC && getPremiums(enrollCount, proposedC.rate)
  const premiumsD = proposedD && getPremiums(enrollCount, proposedD.rate)
  const premiumsCurr = getPremiums(enrollCount, current.rate)

  return <>
    {tableRow('Plan type', 0, dentalPlanType(current.plan.name), dentalPlanType(proposedA?.plan.name), dentalPlanType(proposedB?.plan.name), dentalPlanType(proposedC?.plan.name), dentalPlanType(proposedD?.plan.name))}
    {tableRow('IN-NETWORK', 1, '', proposedA ? '' : undefined, proposedB ? '' : undefined, proposedC ? '' : undefined, proposedD ? '' : undefined)}
    {tableRow('deductible', 2, (current.plan.data as D2)?.preventativeDeductibleInNetwork, (proposedA?.plan.data as D2)?.preventativeDeductibleInNetwork, (proposedB?.plan.data as D2)?.preventativeDeductibleInNetwork, (proposedC?.plan.data as D2)?.preventativeDeductibleInNetwork, (proposedD?.plan.data as D2)?.preventativeDeductibleInNetwork)}
    {tableRow('annual max', 3, (current.plan.data as D2)?.preventativeMaxInNetwork, (proposedA?.plan.data as D2)?.preventativeMaxInNetwork, (proposedB?.plan.data as D2)?.preventativeMaxInNetwork, (proposedC?.plan.data as D2)?.preventativeMaxInNetwork, (proposedD?.plan.data as D2)?.preventativeMaxInNetwork)}
    {tableRow('major', 4, (current.plan.data as D2)?.majorCoinsuranceInNetwork, (proposedA?.plan.data as D2)?.majorCoinsuranceInNetwork, (proposedB?.plan.data as D2)?.majorCoinsuranceInNetwork, (proposedC?.plan.data as D2)?.majorCoinsuranceInNetwork, (proposedD?.plan.data as D2)?.majorCoinsuranceInNetwork)}
    {tableRow('RATES', 5, '', proposedA ? '' : undefined, proposedB ? '' : undefined, proposedC ? '' : undefined, proposedD ? '' : undefined)}
    {tableRow('employee', 6, current.rate.individual, proposedA?.rate.individual, proposedB?.rate.individual, proposedC?.rate.individual, proposedD?.rate.individual)}
    {tableRow('employee + spouse', 7, current.rate.couple, proposedA?.rate.couple, proposedB?.rate.couple, proposedC?.rate.couple, proposedD?.rate.couple)}
    {tableRow('employee + child', 8, current.rate.singleParent, proposedA?.rate.singleParent, proposedB?.rate.singleParent, proposedC?.rate.singleParent, proposedD?.rate.singleParent)}
    {tableRow('family', 9, current.rate.family, proposedA?.rate.family, proposedB?.rate.family, proposedC?.rate.family, proposedD?.rate.family)}
    {tableRow('monthly premium', 10, premiumsCurr.monthly, premiumsA?.monthly, premiumsB?.monthly, premiumsC?.monthly, premiumsD?.monthly)}
    {tableRow('annual premium', 11, premiumsCurr.annual, premiumsA?.annual, premiumsB?.annual, premiumsC?.annual, premiumsD?.annual)}  </>
}

export function dentalPlanType(planName?: string) {
  if (!planName) return undefined
  let planType = 'PPO'
  if (planName.includes('HMO')) planType = 'HMO'
  if (planName.includes('EPO')) planType = 'EPO'
  return planType
}
