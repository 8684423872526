import React from 'react'
import ProfileSection from 'Components/Anubis/ProfileSection'
import { useToggle } from 'react-use'
import { Alignment, Table } from 'Components/Rudimentary/Table'
import _, { compact } from 'lodash'
import AddEmployeeModal from 'Components/Anubis/AddEmployeeModal'
import { useHistory, useLocation } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import moment from 'moment'
import * as api from 'Utilities/pharaoh'
import { Venue } from 'Utilities/pharaoh.types'
import useToast from 'Utilities/Hooks/useToast'
import { extractPlanNameAndCarrier, getPlanIDFrom, massagedPlanName, typeOfPlan } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { ContributionSplit } from 'Utilities/Hooks/useStargate'
import ContributionsCalculator, { Contributions, isMedical, MemberPlus, moneyNumber, moneyString, PlanUnion } from 'Utilities/Plans/ContributionCalculator'

type Props = {
  ees: MemberPlus[]
  id: string
  splits: ContributionSplit[]
  contributions: Contributions
  defaultOpen: boolean
  hasProsper: boolean
}

const GroupsProfileEmployeesSection: React.FC<Props> = ({ ees, id, splits, contributions, defaultOpen, hasProsper }) => {
  const members = ees
  if (!splits || splits.length < 1) {
    return <EETable ees={members} id={id} contributions={contributions} defaultOpen={defaultOpen} hasProsper={hasProsper}/>
  } else {
    const all = new Set(members.map(({ id }) => id))
    const rv = splits.map(split => {
      const set = new Set(split.members)
      set.forEach(id => all.delete(id))
      const ees = members.filter(m => set.has(m.id))

      return <EETable
        key={split.id}
        ees={ees}
        name={split.name}
        split={split}
        contributions={contributions}
        id={id}
        defaultOpen={defaultOpen}
        hasProsper={hasProsper}
      />
    })
    if (all.size) {
      rv.push(<EETable
        key='key'
        ees={members.filter(member => all.has(member.id))}
        name='Ungrouped'
        contributions={contributions}
        id={id}
        defaultOpen={defaultOpen}
        hasProsper={hasProsper}
      />)
    }
    return <>{rv}</>
  }
}

interface EETableProps {
  split?: ContributionSplit
  name?: string
  ees: MemberPlus[]
  id: string
  contributions: Contributions
  defaultOpen: boolean
  hasProsper: boolean
}

const EETable: React.FC<EETableProps> = ({ split, name, ees, id, contributions, defaultOpen, hasProsper }) => {
  const [open, toggle] = useToggle(false)
  const history = useHistory()
  const location = useLocation()
  const addToast = useToast()

  let prettyName: string | JSX.Element | undefined

  if (name) {
    prettyName = <div style={{ lineHeight: '1.1em' }}>Employees <br/><span style={{ fontSize: '.8em', color: '#AAAAAA' }}>{name}</span></div>
  } else {
    prettyName = 'Employees'
  }

  async function onInviteStandard(ee: any) {
    try {
      await api.v3.tickets().POST({ name: ee.name, email: ee.email, venue: Venue.employee, venueID: id })
      const email = ee.email
      addToast(`Email invitation sent to ${email}`)
    } catch (error) {
      addToast(error)
    }
  }

  const members = ees.map(e => ({
    id: e.id,
    name: e.name,
    plans: compact([e.medical, e.dental, e.vision, e.life, e.disability, e.ltdEE, e.ltdER, e.stdEE, e.stdER, e.lifeEE, e.lifeER, e.std, e.accident, e.cancer, e.criticalIllness, e.hospital]),
    tier: e.tier,
    status: e.enrollmentStatus,
    hireDate: e.hireDate,
    terminationDate: e.terminationDate,
    invite: e.enrollmentStatus
  }))

  return <ProfileSection key={split?.id} name={prettyName} addButtonName="Add New Employee" expanded={defaultOpen} onAddButton={toggle}>
    <AddEmployeeModal isOpen={open} onRequestClose={toggle} groupID={id} callback={push} splitID={split?.id} />
    <Table
      data={members}
      sortable={true}
      sortValue={(key, value) => {
        switch (key) {
        case 'hireDate':
          return moment(value).valueOf()
        case 'terminationDate':
          return moment(value).valueOf()
        }
      }}
      order={['name', 'plans', 'tier', 'hireDate', 'terminationDate', 'status', 'invite']}
      heading={key => {
        switch (key) {
        case 'terminationDate':
          return 'Term. Date'
        }
      }}
      width={key => {
        switch (key) {
        case 'name':
          return '15%'
        case 'plans':
          return '35%'
        case 'tier':
          return '7%'
        case 'hireDate':
          return '8%'
        case 'terminationDate':
          return '8%'
        case 'status':
          return '12%'
        case 'invite':
          return '15%'
        }
      }}

      content={(key, value, row) => {
        switch (key) {
        case 'tier':
        case 'status':
          return prettyValue()
        case 'plans':
          return <Details plans={row.plans} contributions={contributions} member={ees.find(e => e.id === row.id)!} split={split} hasProsper={hasProsper}/>
        case 'hireDate':
          return value ? new Date(value).toLocaleString('en-US', { timeZone: 'UTC' }).split(',')[0] : null
        case 'terminationDate':
          return value ? new Date(value).toLocaleString('en-US', { timeZone: 'UTC' }).split(',')[0] : null
        case 'invite':
          return value === 'notInvited' && <a href='#' onClick={() => onInviteStandard(ees.find(e => e.id === row.id)!)}>Invite To Group</a>
        }

        function prettyValue() {
          let p: any = value
          p = _.startCase(p?.replace('MedicalUnderwriting', 'Application'))
          return p
        }
      }}
      alignment={() => Alignment.left}
      selectAction={push}
      selectable={() => true}
      reportFileBasename='employees'
      reportContent={(key, value, row) => {
        switch (key) {
        case 'name':
          return value
        case 'status':
          return _.startCase(value)
        case 'plans':
          return row.plans.map(plan => isMedical(plan) ? plan.name : plan.plan.name).join(', ')
        case 'hireDate':
          return value ? new Date(value) : null
        case 'terminationDate':
          return value ? new Date(value) : null
        }
      }}
    />
  </ProfileSection>

  function push(data: { id: string }) {
    const newEmployeeID = data.id
    const path = location.pathname.startsWith('/dashboard/agency')
      ? `${Route.agencyDashboardEmployees}/${newEmployeeID}`
      : Route.dashboardEmployerProfile
    history.push(path)

    !location.pathname.startsWith('/dashboard/agency') && window.location.reload()
  }
}

interface DetailProps {
  plans: PlanUnion[]
  contributions: Contributions
  member: MemberPlus
  split?: ContributionSplit
  hasProsper: boolean
}

const Details: React.FC<DetailProps> = ({ plans, contributions, split, member, hasProsper }) => {
  if (plans.length === 0) return <>—</>
  const calc = new ContributionsCalculator(compact(plans), contributions, compact([split]), [member], 2)

  const data = plans.map(p => {
    const poo = calc.premiumsFor(member)
    return {
      id: getPlanIDFrom(p),
      ...extractPlanNameAndCarrier(p),
      premium: poo ? poo[typeOfPlan(p)]?.ee : 0 as string | number | undefined,
      policyId: member.policyIDs[typeOfPlan(p)]?.policyID || '-',
      memberId: member.policyIDs[typeOfPlan(p)]?.memberID || '-'
    }
  })

  if (hasProsper) {
    data.push({
      id: member.id,
      carrier: 'Prosper Benefits',
      name: '',
      premium: member.medical ? 0 : moneyNumber(member.prosper),
      policyId: '',
      memberId: ''
    })
  }
  return <Table
    style={{ pointerEvents: 'none' }}
    data={data}
    order={['name', 'premium', 'policyId', 'memberId']}
    content={(key, value, row) => {
      switch (key) {
      case 'premium':
        return value === undefined ? <>—</> : moneyString(value, 2)
      case 'name':
        return <><b>{row.carrier}</b> {massagedPlanName(value, row.carrier)}</>
      }
    }}
    heading={key => {
      switch (key) {
      case 'name': return 'Plan Name'
      case 'policyId': return 'Policy #'
      case 'memberId': return 'Member ID'
      }
    }}
    width={key => {
      switch (key) {
      case 'name':
        return '45%'
      }
    }}
  />
}

export default GroupsProfileEmployeesSection
