import React from 'react'
import styles from './SampleQuote.module.scss'
import { Contributions, AncillaryPlanUnion } from 'Utilities/Plans/ContributionCalculator'
import numeral from 'numeral'
import { Member } from 'Utilities/Hooks/useStargate'
interface Props {
  plan: AncillaryPlanUnion
  contributions?: Contributions
  members?: Member[]
    showWeeklyPayments?: boolean
}

const SampleQuoteBox: React.FC<Props> = ({ plan, showWeeklyPayments }) => {
  return (
    <div className={styles.rateContainer}>
      <div className={styles.title}>Cost Per Employee</div>
      <div className={styles.flex}>
        <div className={styles.rateText}>Illustrative Rate</div>
        <div className={styles.priceRange}>
          <span className={styles.dollar}>$</span><span className={styles.price}>{plan.sampleQuote?.rate.replace(/[^0-9]/, '')}</span>
        </div>
        <div className={styles.rateDescription}>{`${showWeeklyPayments ? 'Weekly' : 'Monthly'} rate for a ${plan.sampleQuote?.aveAge} year old with an annual salary of `}<span className={styles.descDollar}>$</span>{`${numeral(plan.sampleQuote?.sampleSalary).format('0,0')}`}</div>
      </div>
    </div>
  )
}
export default SampleQuoteBox
