import React, { useEffect } from 'react'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import TodoList from 'Components/Anubis/TodoList'
import useGroupManager from 'Utilities/Hooks/useGroupManager'
import styles from './index.module.scss'
import moment from 'moment'
import { PipelineStatus, GroupToDos } from 'Utilities/pharaoh.types'
import { useToggle } from 'react-use'
import InviteEmployeesModal from 'Components/Modals/Anubis/InviteEmployeesModal'
import { GAButton } from 'Components/Tracking'

const DashboardEmployer: React.SFC = () => {
  const async = useGroupManager(async r => r)

  useEffect(() => {
    if (async.loading) return
    const el = document.getElementById('content')
    if (!el) return
    el.style.paddingTop = '0'
    return () => { el.style.paddingTop = '' }
  }, [async.loading])

  if (async.loading) return <Loader />
  if (async.error) return <Error error={async.error} />

  const group = async.value!

  return <div className={styles.container}>
    <header>
      <h1>{group.name}</h1>
      <p><b>Welcome to your companion app</b> for all of your Employee Benefits needs. View and manage employee benefits,
      add and remove employees, administer coverage changes, and more!
      </p>
      <div className={styles.onBoardBtn}>
        <button className={styles.button} onClick={openGuide}>
          <i className="material-icons">cloud_download</i>
                  download onboarding guide
        </button>
      </div>
    </header>
    <Invite
      effectiveDate={group.dates.effective}
      status={group.status}
      todos={group.todo}
      groupID={group.id} />
    <TodoList id={group.id} className={styles.todo} isFinalized={group.isFinalized} isEmployer={true}/>
  </div>
}

interface InviteProps {
  effectiveDate?: Date
  status?: PipelineStatus
  todos: GroupToDos
  groupID: string
}

const Invite: React.FC<InviteProps> = ({ effectiveDate, status, todos, groupID }) => {
  const [isOpen, toggle] = useToggle(false)

  let inner
  switch (status) {
  case PipelineStatus.enrolled:
    return <></>
  case PipelineStatus.suspect:
  case PipelineStatus.prospect:
  case PipelineStatus.proposed:
  case PipelineStatus.lead:
  case PipelineStatus.mqsent:
  case PipelineStatus.sent:
  case PipelineStatus.archived:
  case undefined: {
    let cutoff = 30
    if (effectiveDate) {
      // 14 business days for Candor to process the group
      cutoff = moment(effectiveDate).diff(moment(), 'days') - 14
    }
    if (cutoff < 0) {
      inner = <>
        <h2>Employee sign-up is overdue</h2>
        <p>Please contact support</p>
      </>
    } else {
      const invite = todos.invites
        ? <>
          <p>Click to send invitation email:</p>
          {/* Add this to the GAButton below to enable it again onClick={toggle} */}
          <GAButton analytics={`INVITE Employees! (${DashboardEmployer.name})`}>INVITE Employees!</GAButton>
        </>
        : <p>Your employees have been invited to elect benefits.</p>

      inner = <>
        <h2>Employee sign‑up ends in <b>{cutoff}</b> Days!</h2>
        {invite}
      </>
    }
    break
  }
  }

  return <div className={styles.inviteContainer}>
    {inner}
    <InviteEmployeesModal groupID={groupID} isOpen={isOpen} onRequestClose={toggle} />
  </div>
}

function openGuide() {
  window.open('https://public.myhealthily.com/uploads/MyHealthily-Dashboard-Employer.pdf', '_blank')
}

export default DashboardEmployer
