/* eslint-disable camelcase */
import React from 'react'
import styles from './EREERate.module.scss'
import {
  AncillaryPlanUnion,
  moneyString
} from 'Utilities/Plans/ContributionCalculator'
import { AncillaryMemberPremium } from 'Utilities/pharaoh.types'
import numeral from 'numeral'
interface Props {
  plan: AncillaryPlanUnion
}

const getTotal = (memberPremiums: AncillaryMemberPremium | undefined) => {
  if (!memberPremiums?.premiums) {
    return '0'
  }
  let total = 0
  memberPremiums.premiums.forEach((premium) => {
    const cost = numeral(premium.insured_premium).value()
    total = total + cost
  })
  return moneyString(total, 2).replace('$', '')
}
// The individual tier of the lifeEE and lifeER plans contains the rate for the lowest age band, and the couple tier contains the rate with the highest age band.
// The backend is written this way in order to avoid having to rewrite a bunch of backend code to accomidate this plan type
const TotalRate: React.FC<Props> = ({ plan }) => {
  const dol = <span className={styles.dollar}>$</span>
  const total = getTotal(plan.memberPremiums)
  return (
    <div className={styles.rateContainer}>
      <div className={styles.title}>{'Total Monthly Group Cost'}</div>
      <div className={styles.flex}>
        <div className={styles.priceRange}>
          {total !== '0'
            ? <>
              {dol}
              <span className={styles.price}>{total}</span>
            </>
            : <>
              <span className={styles.price}>Not Available</span>
            </>}
        </div>
      </div>
    </div>
  )
}
export default TotalRate
