import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './Breadcrumbs.module.scss'

interface BreadcrumbProps {
  breadcrumbs: {text: string, href?: string}[]
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
  const location = useLocation()
  const breadcrumbsArray: JSX.Element[] = []
  breadcrumbs.map((b, i) => {
    if (!b.href) { breadcrumbsArray.push(<div key={i}>{b.text}</div>) } else {
      breadcrumbsArray.push(<React.Fragment key={i}><div><Link key={i} to={b.href!} className={location.pathname === b.href! ? styles.breadcrumbActive : styles.breadcrumbNotActive}>{b.text}</Link></div><i className={'material-icons'}>chevron_right</i></React.Fragment>)
    }
  })
  return <div className={styles.breadcrumbMenu}>{breadcrumbsArray.map(b => b)}</div>
}

export default Breadcrumbs
