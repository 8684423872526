/* eslint-disable camelcase */
import React from 'react'
import Modal from 'Components/Modals/Modal'
import styles from './MemberPremiumsModal.module.scss'
import { AncillaryMemberPremium } from 'Utilities/pharaoh.types'
import moment from 'moment'
import { Member } from 'Utilities/Hooks/useStargate'
import { moneyNumber } from 'Utilities/Plans/ContributionCalculator'
import StyledRate from 'Components/Anubis/StyledRate/StyledRate'

/* eslint-disable camelcase */
interface MemberRate {
  memberID: string
  dependant_premium: string
  insured_premium: string
  spouse_premium: string
  total_premium: string
}

interface MemberRatesModalProps {
  isOpen: boolean
  onRequestClose: any
  logo: JSX.Element
  planName: string
  carrier: string
  planID: string
  groupID: string
  members: Member[]
  memberPremiums: AncillaryMemberPremium
}

const MemberPremiumsModal: React.FC<MemberRatesModalProps> = ({ isOpen, onRequestClose, logo, planName, carrier, members, memberPremiums }) => {
  function sumTotal(items: MemberRate[], prop: string) {
    return items.reduce((a: any, b: any) => {
      return a + moneyNumber(b[prop], 2)
    }, 0)
  }

  const memberRates = memberPremiums.premiums
  const total = sumTotal(memberRates, 'total_premium')

  return <Modal header={'Cost Breakdown'} isOpen={isOpen} onRequestClose={onRequestClose}>
    <div className={styles.modalTitle}>
      <div>{logo}</div>
      <div>{carrier}</div>
      <div>{planName}</div>
    </div>
    <div className={styles.height}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Employee</th>
            <th>Age</th>
            <th>Plan Cost</th>
          </tr>
        </thead>
        <tbody>
          {members.filter(m => !m.is_waived).sort(function(a, b) {
            return a.name.split(' ').pop()!.localeCompare(b.name.split(' ').pop()!) ||
              a.name.split(' ').pop()!.localeCompare(b.name.split(' ').pop()!)
          }
          ).map((m) => {
            const currentMemberPremium = memberPremiums!.premiums.find(mp => m.id === mp.memberID)!
            const age = moment().diff(m.dateOfBirth, 'years', false)

            return <tr key={m.id}>
              <td>
                <div>{m.name}</div>
              </td>
              <td className={styles.lightFont}><div>{age}</div></td>
              <td className={styles.planCost}><StyledRate rate={currentMemberPremium.insured_premium}/></td>
            </tr>
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>Total</td>
            <td><StyledRate rate={total}/></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </Modal>
}

export default MemberPremiumsModal
