import { BasePlan } from 'Utilities/pharaoh.types'
import { GroupPlanType } from 'Utilities/Plans/ContributionCalculator'

export interface StdEEPlan extends BasePlan {
  type: GroupPlanType.stdEE
  data: {
    eliminationPeriodAccident?: string
    eliminationPeriodSickness?: string
    benefitPeriod?: string
    monthlyPayment?: string
    sbc?: string
  }
}

export interface StdERPlan extends BasePlan {
  type: GroupPlanType.stdER
  data: {
    eliminationPeriodAccident?: string
    eliminationPeriodSickness?: string
    benefitPeriod?: string
    monthlyPayment?: string
    sbc?: string
  }
}

export function isStdEE(plan: any): plan is StdEEPlan {
  return plan.type === 'stdEE' || plan?.plan?.type === 'stdEE'
}

export function isStdER(plan: any): plan is StdERPlan {
  return plan.type === 'stdER' || plan?.plan?.type === 'stdER'
}
