import React from 'react'
import styles from './AssignProducer.module.scss'
import { useAsync } from 'react-use'
import * as api from 'Utilities/pharaoh'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import Select from 'Components/Rudimentary/Select'
import { sortBy } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import useToast from 'Utilities/Hooks/useToast'

type Props = {
  producerID?: string
  groupID: string
}

interface Producer {
  id: string
  name: string
}

const AssignProducer: React.FC<Props> = ({ producerID, groupID }) => {
  const async = useAsync(async() => {
    const rsp = await api.v3.groups(groupID).agency.brokers.GET() as [ Producer ]
    return sortBy(rsp, 'name')
  })
  const form = useForm()
  const addToast = useToast()

  if (async.loading) return <Loader/>
  if (async.error) return <Error error={async.error}/>

  const producers = async.value!.map(b => ({ value: b.id, label: b.name }))

  if (producerID && producers.length > 0) {
    const producer = producers.filter(producers => producers.value === producerID)
    return <div className={styles.container}>
      <h3>Producer</h3>
      <div className={styles.producers}>
        <label>{producer[0].label}</label>
      </div>
    </div>
  }

  async function assignProducer(data: any) {
    try {
      await api.v2.brokers.producers(data.producer).assign.to(groupID)
      addToast('Producer assigned to group', 'info')
    } catch (error) {
      addToast(error as Error)
    }
  }

  return <div className={styles.container}>
    <h2 className={styles.header}>Assign Producer</h2>
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(assignProducer)} style={{ width: '100%' }}>
        <Select name="producer" control={form.control} options={producers} placeholder="Producer" backgroundColor="#fff"/>
        <button className={styles.assignButton} type="submit">Assign</button>
      </form>
    </FormProvider>
  </div>
}

export default AssignProducer
