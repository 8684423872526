import React from 'react'
import { Contributions } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import { Label } from 'Utilities/config'
import styles from 'Components/Plans/EESupplementalPlans/index.module.scss'
import { StdERPlan } from './index.helpers'
import Plan, { PlanBody } from 'Components/Plans/plan-subcomponents/Plan'
import PlanTitle from 'Components/Plans/plan-subcomponents/PlanTitle'
import { Member } from 'Utilities/Hooks/useStargate'
import EETierRate from 'Components/Plans/EESupplementalPlans/EESupplementalSubComp/EETierRate'
import BenefitAmountContainer from 'Components/Plans/EESupplementalPlans/EESupplementalSubComp/BenefitAmountContainer'
import benefitStyle from 'Components/Plans/EESupplementalPlans/EESupplementalSubComp/EESupplementalSubComp.module.scss'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import { startCase } from 'lodash'

interface Props {
  plan: { rate: TieredRates, plan: StdERPlan, isRenewal: boolean }
  contributions: Contributions
  selected?: boolean
  label: Label
  selectHandler?(plan: StdERPlan): void
  showWeeklyPayments: boolean
  premium?: PremiumSplits
  member: Member
}

const replaceDollarSigns = (inputString: string) => {
  const resultsArray: JSX.Element[] = []
  if (inputString.includes('$')) {
    inputString.split('$').filter(s => s !== '').map(s => resultsArray.push(<>{ !s.includes('%') && <span className={benefitStyle.dolar}>$</span>}<span className={benefitStyle.amount}>{s}</span></>))
    return resultsArray
  } else {
    return <span className={benefitStyle.amount}>{inputString.match(/^[a-zA-Z]/) ? startCase(inputString) : inputString}</span>
  }
}

const STDEEPlanEE: React.FC<Props> = ({ plan, selected, label, selectHandler, contributions, showWeeklyPayments, member }) => {
  return <Plan selected={!!selected} noMovement>
    <PlanTitle
      available={false}
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier= {plan.plan.carrier}
      selected={selected}
      includesProsperBenefits={false}
      planType={plan.plan.type}
      label={label}
      sbc={plan.plan.data.sbc}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
    />
    <PlanBody planType={plan.plan.type}>
      <div className={styles.planBodyContainer}>
        <EETierRate
          plan={plan}
          contributions={contributions}
          showWeeklyPayments={showWeeklyPayments}
          member={member}
          showAfterEmpCont={true}
          onlyIndividual
        />
        <div style={{ display: 'flex', flexDirection: 'column' }} className={styles.vertContainer}>
          <BenefitAmountContainer innerclassname={benefitStyle.noLeftMargin}>
            <div className={styles.vertFlex}>
              <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].benefit_period}</div>
              <div>
                <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                  {replaceDollarSigns(plan.plan.data.benefitPeriod!)}
                </div>
              </div>
            </div>
          </BenefitAmountContainer>
          <BenefitAmountContainer innerclassname={benefitStyle.noLeftMargin}>
            <div className={styles.vertFlex}>
              <div className={`${benefitStyle.initial_accident_hospitalization_benefit} ${benefitStyle.benefitBoxTitle}`}>{GlobalStringConstant[0].elimination_period_accident}</div>
              <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                {replaceDollarSigns(plan.plan.data.eliminationPeriodAccident || '')}
              </div>
            </div>
          </BenefitAmountContainer>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={styles.vertContainer}>
          <BenefitAmountContainer>
            <div className={styles.vertFlex}>
              <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].weekly_benefit}</div>
              <div>
                <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                  {replaceDollarSigns(plan.plan.data.monthlyPayment!)}
                </div>
              </div>
            </div>
          </BenefitAmountContainer>
          <BenefitAmountContainer>
            <div className={styles.vertFlex}>
              <div className={`${benefitStyle.initial_accident_hospitalization_benefit} ${benefitStyle.benefitBoxTitle}`}>{GlobalStringConstant[0].elimination_period_sickness}</div>
              <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                {replaceDollarSigns(plan.plan.data.eliminationPeriodSickness || '')}
              </div>
            </div>
          </BenefitAmountContainer>
        </div>
      </div>
    </PlanBody>
  </Plan>
}

export default STDEEPlanEE
