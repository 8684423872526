import React from 'react'
import { Contributions, GroupPlanType } from 'Utilities/Plans/ContributionCalculator'
import { TieredRates } from 'Utilities/pharaoh.types'
import { Label } from 'Utilities/config'
import styles from './index.module.scss'
import SupplementalPlan from 'Components/Plans/SupplementalPlan/index.helpers'
import Plan, { PlanBody } from 'Components/Plans/plan-subcomponents/Plan'
import PlanTitle from 'Components/Plans/plan-subcomponents/PlanTitle'
import { Member } from 'Utilities/Hooks/useStargate'
import EETierRate from './EESupplementalSubComp/EETierRate'
import BenefitComp from './EESupplementalSubComp/BenefitComp'
import BenefitAmountContainer from './EESupplementalSubComp/BenefitAmountContainer'
import benefitStyle from './EESupplementalSubComp/EESupplementalSubComp.module.scss'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/Plan.helpers'
import numeral from 'numeral'

interface Props {
  plan: { rate: TieredRates, plan: SupplementalPlan, isRenewal: boolean }
  contributions: Contributions
  selected?: boolean
  label: Label
  selectHandler?(plan: SupplementalPlan): void
  showWeeklyPayments: boolean
  premium?: PremiumSplits
  member: Member
}

const AccidentplanEE: React.FC<Props> = ({ plan, selected, label, selectHandler, contributions, showWeeklyPayments, member }) => {
  let initialHospitalConfinement = plan.plan.data.initialAccidentHospitalizationBenefit

  const accidentHospitalConfinementBenefit = plan.plan.data.accidentHospitalConfinementBenefit.slice(1)
  const benefitAmount = numeral(plan.plan.data.accidentalDeathBenefit).format('0,0')

  // altering initialHospitalConfinement to display below
  switch (initialHospitalConfinement) {
  case '$500 for 18+ hour hospital confinement':
    initialHospitalConfinement = `${initialHospitalConfinement.replace(/ .*/, '').substring(1)} for 18 hours+`
    break
  default:
    initialHospitalConfinement = `${initialHospitalConfinement.replace(/ .*/, '').substring(1)} once per period`
  }
  return <Plan selected={!!selected}>
    <PlanTitle
      id={plan.plan.id}
      planName={plan.plan.name}
      carrier= {plan.plan.carrier}
      selected={selected}
      includesProsperBenefits={false}
      planType={GroupPlanType.accident}
      label={label}
      sbc={plan.plan.data.sbc}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
    />
    <PlanBody>
      <div className={styles.planBodyContainer}>
        <EETierRate
          plan={plan}
          contributions={contributions}
          showWeeklyPayments={showWeeklyPayments}
          member={member}
          showAfterEmpCont={true}
        />
        <BenefitComp amountLabel= '' name={GlobalStringConstant[0].accident_death_benefit} benefitAmount={benefitAmount}/>
        <div style={{ display: 'flex', flexDirection: 'column' }} className={styles.vertContainer}>
          <BenefitAmountContainer>
            <div className={styles.vertFlex}>

              <div className={benefitStyle.benefitBoxTitle}>{GlobalStringConstant[0].initial_accident_hospitalization_benefit}</div>
              <div>
                <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                  <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{initialHospitalConfinement}</span>
                </div>
                <div className={benefitStyle.hospital_confinement}>{GlobalStringConstant[0].hospital_confinement}</div>
              </div>

            </div>
          </BenefitAmountContainer>
          <BenefitAmountContainer>
            <div className={styles.vertFlex}>
              <div className={`${benefitStyle.initial_accident_hospitalization_benefit} ${benefitStyle.benefitBoxTitle}`}>{GlobalStringConstant[0].accident_hospital_confinement}</div>

              <div className={`${benefitStyle.accidental_death_price} ${benefitStyle.hospital_benifit_amount}`}>
                <span className={benefitStyle.dolar}>$</span><span className={benefitStyle.amount}>{accidentHospitalConfinementBenefit}</span>
              </div>

            </div>
          </BenefitAmountContainer>
        </div>
      </div>
    </PlanBody>
  </Plan>
}

export default AccidentplanEE
