import React from 'react'
import { Contributions } from 'Utilities/Plans/ContributionCalculator'
import { AncillaryMemberPremium, TieredRates } from 'Utilities/pharaoh.types'
import PlanTitle from '../plan-subcomponents/PlanTitle'
import { Label } from 'Utilities/config'
import styles from '../ERSupplementalPlans/index.module.scss'
import Plan, { PlanBody } from '../plan-subcomponents/Plan'
import { Member, Group } from 'Utilities/Hooks/useStargate'
import { StdERPlan } from 'Components/Plans/STDPlan/index.helpers'
import PeriodComp from '../ERSupplementalPlans/ERSupplementalSubComp/PeriodComp'
import { GlobalStringConstant } from 'GlobalStringConstant'
import { isEmployerProps } from '../plan-subcomponents/PremiumBreakdown'
import SampleQuoteBox from 'Components/Plans/ERSupplementalPlans/ERSupplementalSubComp/SampleQuoteBox'
import { useToggle } from 'react-use'

interface Props {
  plan: { rate: TieredRates, plan: StdERPlan, isRenewal: boolean, memberPremiums?: AncillaryMemberPremium }
  contributions: Contributions
  selectHandler?(plan: StdERPlan): void
  selected?: boolean
  nonWaivedMemberCount?: number
  showWeeklyPayments: boolean
  label: Label
  stargateGroup?: Group
  members?: Member[]
  showModal: boolean
}

const StdER: React.FC<Props> = ({ plan, selected, selectHandler, label, showWeeklyPayments, contributions, ...props }) => {
  const [showMemberPremiums, toggleShowMemberPremiums] = useToggle(false)
  const { data, name, carrier, id } = plan.plan
  const sbc = plan.plan.data.sbc || `https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/${id.toLowerCase()}.pdf`

  return <Plan selected={!!selected}>
    <PlanTitle
      id={id}
      planName={name}
      carrier={carrier}
      selectHandler={selectHandler && (() => selectHandler(plan.plan))}
      selected={selected}
      sbc={sbc}
      includesProsperBenefits={false}
      planType={plan.plan.type}
      label={label}
      stargateGroup={props.stargateGroup}
      members={props.members}
      ancillaryMemberPremiums={plan.memberPremiums}
      needsMemberPremiumsShown={true}
      showMemberPremiums={showMemberPremiums}
      toggleShowMemberPremiums={toggleShowMemberPremiums}
      showModal={props.showModal}
    />
    <PlanBody planType={plan.plan.type}>
      {breakdown()}
      <div className={`${styles.endSection} ${styles.fourSpecs} ${styles.halfHeight} ${styles.extraMargin}`}>
        <PeriodComp compName={GlobalStringConstant[0].benefit_period} daysOrWeak={data.benefitPeriod} label={' '}/>
        <PeriodComp compName={GlobalStringConstant[0].elimination_period_accident} daysOrWeak={data.eliminationPeriodAccident} label={' '}/>
      </div>
      <div className={`${styles.endSection} ${styles.fourSpecs} ${styles.halfHeight}`}>
        <PeriodComp compName={GlobalStringConstant[0].weekly_benefit} daysOrWeak={data.monthlyPayment} label={' '}/>
        <PeriodComp compName={GlobalStringConstant[0].elimination_period_sickness} daysOrWeak={data.eliminationPeriodSickness} label={' '}/>
      </div>
      <div className={styles.planDescFooter}>
        <p><span>*</span>Rates shown are illustrative for plan comparison purposes. Rates will vary based on employee age and salary. Contact <a href='mailto: support@myhealthily.com'>support@myhealthily.com</a> for more detailed rating information.</p>
      </div>
    </PlanBody>
  </Plan>

  function breakdown() {
    const base = { plan, contributions, showWeeklyPayments }
    if (isEmployerProps(props)) {
      return <SampleQuoteBox {...base} members={props.members}/>
    }
  }
}

export default StdER
